import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import { IPaginationResourceShort } from '../../../../../../props/general';
import Pagination from '../../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../../config';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import { NavLink } from 'react-router-dom';
import OrderService from '../../../../../../services/orders/order';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';
import Label from '../../../../../typography/label';
import PermissionsService from '../../../../../../services/permissions';
import SelectOrder from '../../../../../uiframeworks/forms/selectOrder';
import { Select } from 'antd';
import { IIncomePaymentResourceShortProps } from '../../../../../../props/finance/incomes/payments/payment';
import moment from 'moment';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import IncomePaymentService from '../../../../../../services/finance/incomes/payments/payment';
import IncomePaymentDetails from '../details';

interface IncomePaymentsListProps {
    qs?: string[];
    invoiceId?: string;
    paymentId?: string;
    payments?: IIncomePaymentResourceShortProps[];
    columns?: TIncomeInvoiceListCoumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TIncomeInvoiceListCoumn = 'paymentDate' | 'method' | 'transferto' | 'status' | 'amount' | 'history';

const IncomePaymentsList: React.FC<IncomePaymentsListProps> = (props: IncomePaymentsListProps) => {
    const shownColumns: TIncomeInvoiceListCoumn[] = props.columns || [
        'paymentDate',
        'method',
        'transferto',
        'amount',
        'status',
        'history'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [payments, setPayments] = React.useState<IIncomePaymentResourceShortProps[]>(props.payments || []);
    const [activePayment, setActivePayment] = React.useState<IIncomePaymentResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "paymentDate",
            name: "Date",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActivePayment(item);
                        setActiveSurface('payment.details');
                    }}><Text size={'small'}>{moment(item.paymentDate).format("DD/MM/YYYY")}</Text></Link>
                </Stack>
            }
        },
        {
            key: "method",
            name: "Method",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === item.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{method?.text || 'Cash'}</Text>
                </Stack>
            }
        },
        {
            key: "transferto",
            name: "Transfer to",
            minWidth: 175,
            maxWidth: 175,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <>
                    {item.transferTo ? <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{childrenGap: 2}}>
                        <Text size={'small'}>{item.transferTo.accountNumber} a/n {item.transferTo.accountName}</Text>
                        <Text size={'xsmall'}>{item.transferTo.bank.name}</Text>
                    </Stack> : null}
                    {!item.transferTo ? <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{childrenGap: 2}}>
                        <Text size={'small'}>-</Text>
                    </Stack> : null}
                </>
            }
        },
        {
            key: "history",
            name: "History",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {(item.notes || "").trim() !== "" ? <>
                        <Text size={'small'}>{item.notes}</Text>
                        <Stack className={'divider'}> </Stack>
                    </> : null}
                    <Text size={'small'} style={{fontStyle: 'italic'}}>Recorded by: {item.recordedBy.name} on {moment(item.recordedAt).format("DD/MM/YYYY")}</Text>
                    {item.verifiedBy && item.verifiedAt ? <Text className={'color-green'} size={'small'} style={{fontStyle: 'italic'}}>Verified by: {item.verifiedBy.name} on {moment(item.verifiedAt).format("DD/MM/YYYY")}</Text> : null}
                    {item.rejectedBy && item.rejectedAt ? <Text className={'color-red'} size={'small'} style={{fontStyle: 'italic'}}>Rejected by: {item.rejectedBy.name} on {moment(item.rejectedAt).format("DD/MM/YYYY")}</Text> : null}
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                let amount = item.amount;
                if (props.invoiceId) {
                    amount = item.invoices.find((invoice) => invoice.invoice.id === props.invoiceId)?.amount || "0";
                }

                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(amount))}</Text>
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 85,
            maxWidth: 85,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                let tagVariant: TTagVariant = 'success';
                let tagText: string = 'Verified';

                if (item.status === 'Pending') {
                    tagVariant = 'warning';
                    tagText = 'Pending'
                } else if (item.status === 'Rejected') {
                    tagVariant = 'error';
                }

                return <Stack horizontal>
                    <Tag size={'small'} variant={tagVariant} text={tagText} />
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.payments) {
            _onRetrievePayments();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrievePayments = async (pageNumber?: number) => {
        try {
            setLoaded(false);

            const qs: string[] = props.qs || [];
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if ((props.invoiceId || "").trim() !== "") { qs.push(`invoice_id=${props.invoiceId}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await IncomePaymentService.retrieve(qs.join("&"));
            setPayments(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_income_payments_list_error',
                text: 'Failed to retrieve income payments list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        const hasCreatePermission = PermissionsService.hasPermission(['incomes.payments.create'], user.permissions);
        if (!props.hideCreateButton && hasCreatePermission) {
            farItems.push({
                key: "create",
                text: "Add payment",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Add payment"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('payment.create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActivePayment(undefined);

        if (refresh) { _onRetrievePayments() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " payments found"} /> : null}
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && payments.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={payments}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TIncomeInvoiceListCoumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && payments.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                    <Text>Payments not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'payment.details' && activePayment ? <IncomePaymentDetails paymentId={activePayment.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default IncomePaymentsList;
