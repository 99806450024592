import { IDataTruckTypeResourceShortProps } from '../../../props/data/fleets/truckType';
import Request from './../../request';

// import props

const DataTruckTypeService = {
    retrieve: async (qs?: string): Promise<IDataTruckTypeResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/data/fleets/truck_types?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
};

export default DataTruckTypeService;