import moment from "moment";
import OrderService from "../orders/order";
import { IOrderResourceShortProps } from "../../props/orders/order";

export type EmployeePerformanceProps = {
    totalRevenue: number;
    totalProfit: number;
    profitPercentage: number;
    totalCompanies: number;
}

export default class EmployeePerformanceService {
    private userId: string;

    public constructor(userId: string) {
        this.userId = userId;
    }

    public monthly = async (date: string, basedOn: string): Promise<{currentMonth: EmployeePerformanceProps, previousMonth: EmployeePerformanceProps}> => {
        const currentMonthStart = moment(date).startOf('month').toISOString();
        const currentMonthEnd = moment(date).endOf('month').toISOString();

        const previousMonthStart = moment(currentMonthStart).add(-1, 'month').toISOString();
        const previousMonthEnd = moment(currentMonthEnd).add(-1, 'month').toISOString();

        let basedOnFilter = '';
        if (basedOn === 'loading date') {
            basedOnFilter = 'actual_departure_';
        }

        // get current month and previous month orders
        const currentMonthOrdersRequest = await OrderService.retrieve([`top=all`, `sales=${this.userId}`, `start_${basedOnFilter}date=${currentMonthStart}`, `end_${basedOnFilter}date=${currentMonthEnd}`].join("&"));
        const previousMonthOrdersRequest = await OrderService.retrieve([`top=all`, `sales=${this.userId}`, `start_${basedOnFilter}date=${previousMonthStart}`, `end_${basedOnFilter}date=${previousMonthEnd}`].join("&"));

        const [currentMonthOrders, previousMonthOrders] = await Promise.all([currentMonthOrdersRequest, previousMonthOrdersRequest]);
        
        return {
            currentMonth: this.calculate(currentMonthOrders.data),
            previousMonth: this.calculate(previousMonthOrders.data)
        }
    }

    private calculate = (orders: IOrderResourceShortProps[]): EmployeePerformanceProps => {
        let totalRevenue: number = 0;
        let totalProfit: number = 0;
        let companiesId: string[] = [];
        let totalCommission: number = 0;

        orders.forEach((order) => {
            totalRevenue += Number(order.totalAmount);
            totalProfit += Number(order.nettProfit);
            if (order.company && companiesId.indexOf(order.company.id) < 0) {
                companiesId.push(order.company.id);
            }
        });

        return {
            totalRevenue,
            totalProfit,
            profitPercentage: totalProfit / totalRevenue * 100,
            totalCompanies: companiesId.length
        }
    }
}