import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';
import BankAccountService from '../../../../services/bankaccounts';
import PermissionsService from '../../../../services/permissions';

// props
import { IBankAccountResourceProps } from '../../../../props/bankaccounts';
import { IBreadcrumbItemProps } from '../../../navigations/breadcrumb';
import { IBankAccountTransactionResourceShortProps } from '../../../../props/bankaccounts/transaction';

// components
import { CommandBar, DetailsList, Dropdown, DropdownMenuItemType, IColumn, ICommandBarItemProps, Link, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import LoadingComponent from '../../../feedbacks/loading';
import Text from '../../../typography/text';
import Label from '../../../typography/label';
import GeneralService from '../../../../services/general';
import { IIncomePaymentInvoiceResourceShortProps } from '../../../../props/finance/incomes/payments/invoice';
import IncomeInvoiceService from '../../../../services/finance/incomes/invoices/invoice';
import OrderService from '../../../../services/orders/order';
import OutcomePaymentDetails from '../../../finances/outcomes/payments/general/details';
import IncomePaymentDetails from '../../../finances/incomes/payments/general/details';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import { IBankAccountCutoffResourceShortProps } from '../../../../props/bankaccounts/cutoff';
import BankAccountCutoffService from '../../../../services/bankaccounts/cutoff';

type BankAccountTranscationHistoryProps = {
    bankAccountId: string;
}

type TSelectedDateType = 'today' | 'custom' | 'lastCutoff' | 'thisMonth' | 'lastMonth' | 'last3Months' | 'last6Months' | 'thisYear' | 'lastYear' | 'thisWeek' | 'lastWeek';

const BankAccountTranscationHistory: React.FC<BankAccountTranscationHistoryProps> = (props: BankAccountTranscationHistoryProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [bankAccount, setBankAccount] = React.useState<IBankAccountResourceProps>();
    const [lastCutoff, setLastCutoff] = React.useState<IBankAccountCutoffResourceShortProps | undefined>();
    const [transactions, setTransactions] = React.useState<IBankAccountTransactionResourceShortProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [activeTransactionId, setActiveTransactionId] = React.useState<string | undefined>();

    const [selectedDate, setSelectedDate] = React.useState<{type: TSelectedDateType;start: string; end: string}>({
        type: 'today',
        start: moment().startOf('day').toISOString(),
        end: moment().endOf('day').toISOString()
    });
    const [selectedTransactionTypes, setSelectedTransactionTypes] = React.useState<string[]>(["IN", "OUT"]);
    const [selectedSubsidiaries, setSelectedSubsidiaries] = React.useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

    const getIncomeInvoiceName = (invoice: IIncomePaymentInvoiceResourceShortProps) => {
        let name = invoice.invoice.name;
        if (!invoice.invoice.name && invoice.invoice.order) {
            name = `Invoice #${IncomeInvoiceService.getInvoiceNumber(invoice.invoice.id)} for order #${OrderService.getOrderNumber(invoice.invoice.order.orderNumber)}`
        } else if (!invoice.invoice.name) {
            name = `Invoice #${IncomeInvoiceService.getInvoiceNumber(invoice.invoice.id)}`
        }

        return name;
    }

    const columns: IColumn[] = [
        {
            key: 'date',
            name: 'Date',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IBankAccountTransactionResourceShortProps) => {
                return <Stack>
                    <Link onClick={() => {
                        if (item.oid) {
                            setActiveSurface('outcome.details');
                            setActiveTransactionId(item.oid);
                        } else if (item.iid) {
                            setActiveSurface('income.details');
                            setActiveTransactionId(item.iid);
                        }
                    }}>
                        <Text size={'small'}>{moment(item.date).format("DD/MM/YYYY HH:mm")}</Text>
                    </Link>
                </Stack>
            }
        },
        {
            key: 'categories',
            name: 'Categories',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IBankAccountTransactionResourceShortProps, iidx?: number) => {
                /*const categories: string[] = [];
                if (item.outcome) {
                    item.outcome.payments.forEach((payment) => {
                        categories.push(payment.invoice.category.name);
                    });
                } else if (item.income && item.income.invoices.filter((invoice) => !invoice.invoice.order).length < 1) {
                    categories.push("Order Payment");
                } else if (item.income && item.income.invoices.filter((invoice) => !invoice.invoice.order).length > 0 && item.income.invoices.filter((invoice) => invoice.invoice.order).length > 0) {
                    categories.push("Mixed Income");
                } else if (item.income && item.income.invoices.filter((invoice) => invoice.invoice.order).length < 1) {
                    categories.push("Custom Income");
                }*/

                return <Stack tokens={{ childrenGap: 5 }}>
                    <Text size={'small'}>{([...new Set(item.categories)]).join(", ")}</Text>
                </Stack>
            }
        },
        {
            key: 'details',
            name: 'Transaction Details',
            minWidth: 175,
            onRender: (item: IBankAccountTransactionResourceShortProps, iidx?: number) => {
                return <Stack tokens={{ childrenGap: 5 }}>
                    <Text size={'small'}>{item.name}</Text>
                    {item.details.length > 1 ? <Stack>
                        {item.details.map((detail, idx) => {
                            return <Stack key={`mpayment_${iidx}_${idx}`} horizontal tokens={{ childrenGap: 3 }}>
                                <Stack styles={{ root: { width: 15 } }}><Text size={'small'}>{(idx || 0) + 1}.</Text></Stack>
                                <Text size={'small'}>{detail}</Text>
                            </Stack>
                        })}
                    </Stack> : null}
                    {/*item.outcome && item.outcome.payments.length > 1 ? <Stack tokens={{ childrenGap: 5 }}>
                        <Text size={'small'}>Payment for multiple invoices:</Text>
                        <Stack>
                            {item.outcome.payments.map((payment, idx) => {
                                return <Stack key={`mpayment_${iidx}_${idx}`} horizontal tokens={{ childrenGap: 3 }}>
                                    <Stack styles={{ root: { width: 15 } }}><Text size={'small'}>{(idx || 0) + 1}.</Text></Stack>
                                    <Text size={'small'}>{payment.invoice.name} (Rp. {GeneralService.getNumberWithSeparator(payment.amount)})</Text>
                                </Stack>
                            })}
                        </Stack>
                    </Stack> : null}
                    {item.outcome && item.outcome.payments.length === 1 ? <>
                        {item.outcome.payments.map((payment, idx) => {
                            return <Stack key={`spayment_${iidx}_${idx}`}><Text size={'small'}>{payment.invoice.name}</Text></Stack>
                        })}
                    </> : null}
                    {(item.income?.invoices || []).length > 1 ? <Stack tokens={{ childrenGap: 5 }}>
                        <Text size={'small'}>Income from multiple invoices:</Text>
                        <Stack>
                            {(item.income?.invoices || []).map((payment, idx) => {
                                return <Stack key={`minvoice_${iidx}_${idx}`} horizontal tokens={{ childrenGap: 3 }}>
                                    <Stack styles={{ root: { width: 15 } }}><Text size={'small'}>{(idx || 0) + 1}.</Text></Stack>
                                    <Text size={'small'}>{getIncomeInvoiceName(payment)} (Rp. {GeneralService.getNumberWithSeparator(payment.amount)})</Text>
                                </Stack>
                            })}
                        </Stack>
                    </Stack> : null}
                    {item.income && item.income.invoices.length === 1 ? <>
                        {item.income.invoices.map((payment, idx) => {
                            return <Stack key={`sinvoice_${iidx}_${idx}`}><Text size={'small'}>{getIncomeInvoiceName(payment)}</Text></Stack>
                        })}
                    </> : null*/}
                </Stack>
            }
        },
        {
            key: 'paidBy',
            name: 'Paid By',
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IBankAccountTransactionResourceShortProps) => {
                /*const fromCompanies: string[] = [];
                if (item.income?.invoices && item.type === 'IN') {
                    item.income.invoices.forEach((invoice) => {
                        if (invoice.invoice.order?.company) {
                            fromCompanies.push(invoice.invoice.order.company.name);
                        }
                    })
                }*/

                return <Stack>
                    {item.type === 'IN' ? <>
                        {item.paidBy?.bankAccount ? <>
                            <Text size={'small'}>{item.paidBy.bankAccount.accountNumber} a/n {item.paidBy.bankAccount.accountName}</Text>
                            <Text size={'xsmall'}>{item.paidBy.bankAccount.bank.name}</Text>
                        </> : null}
                        {!item.paidBy?.bankAccount && item.paidBy?.company ? <>
                            <Text size={'small'}>{item.paidBy.company.name}</Text>
                        </> : null}
                    </> : null}

                    {/*item.outcome?.transferFrom && item.type === 'IN' ? <>
                        <Text size={'small'}>{item.outcome.transferFrom.accountNumber} a/n {item.outcome.transferFrom.accountName}</Text>
                        <Text size={'xsmall'}>{item.outcome.transferFrom.bank.name}</Text>
                    </> : null}
                    {item.income?.invoices && item.type === 'IN' ? <>
                        <Text size={'small'}>{fromCompanies.join(", ")}</Text>
                    </> : null*/}
                </Stack>
            }
        },
        {
            key: 'paidTo',
            name: 'Paid To',
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IBankAccountTransactionResourceShortProps) => {
                /*const samplePayment = item.outcome?.payments[0];

                return <Stack>
                    {item.outcome?.transferTo && item.type === 'OUT' ? <>
                        <Text size={'small'}>{item.outcome.transferTo.accountNumber} a/n {item.outcome.transferTo.accountName}</Text>
                        <Text size={'xsmall'}>{item.outcome.transferTo.bank.name}</Text>
                    </> : null}
                    {!item.outcome?.transferTo && samplePayment?.method === 'cash' && samplePayment?.invoice.vendor && item.type === 'OUT' ? <>
                        <Text size={'small'}>Cash to {samplePayment.invoice.vendor?.name}</Text>
                    </> : null}
                    {!item.outcome?.transferTo && samplePayment?.method === 'virtualaccount' && item.type === 'OUT' ? <>
                        <Text size={'small'}>VA to {samplePayment.targetVirtualAccountNumber || samplePayment.transferToVANumber}</Text>
                        {samplePayment?.invoice.vendor ? <Text size={'xsmall'}>{samplePayment.invoice.vendor.name}</Text> : null}
                    </> : null}
                </Stack>*/
                return <Stack>
                    {item.type === 'OUT' ? <>
                        {item.paidTo?.bankAccount ? <>
                            <Text size={'small'}>{item.paidTo.bankAccount.accountNumber} a/n {item.paidTo.bankAccount.accountName}</Text>
                            <Text size={'xsmall'}>{item.paidTo.bankAccount.bank.name}</Text>
                        </> : null}
                        {!item.paidTo?.bankAccount && item.paidTo?.text ? <>
                            <Text size={'small'}>{item.paidTo.text}</Text>
                        </> : null}
                        {item.paidTo?.vendor ? <>
                            <Text size={'xsmall'}>{item.paidTo.vendor.name}</Text>
                        </> : null}
                    </> : null}
                </Stack>
            }
        },
        {
            key: 'amount',
            name: 'Amount',
            minWidth: 175,
            maxWidth: 175,
            onRender: (item: IBankAccountTransactionResourceShortProps) => {
                return <Stack>
                    {item.type === 'IN' ? <Label size={'xsmall'} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Label> : null}
                    {item.type === 'OUT' ? <Label size={'xsmall'} className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Label> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        refresh();
    }, [selectedDate]);

    const refresh = async () => {
        try {
            setLoaded(false);

            if (!bankAccount) {
                const _cutoffs = await BankAccountCutoffService.retrieve(props.bankAccountId, `top=1`);
                setLastCutoff(_cutoffs.data[0]);

                const _bankAccount = await BankAccountService.read(props.bankAccountId);
                setBankAccount(_bankAccount);
                
                const breadcrumbs: IBreadcrumbItemProps[] = [];
                breadcrumbs.push({ key: "home", icon: faHome, href: "/" });
                if (_bankAccount.vendor) {
                    breadcrumbs.push({ key: "vendors", text: 'Vendors', href: "/operationals/vendors" });
                    breadcrumbs.push({ key: _bankAccount.vendor.id, text: _bankAccount.vendor.name, href: "/operationals/vendors/" + _bankAccount.vendor.id });
                } else if (_bankAccount.subsidiary) {
                    breadcrumbs.push({ key: "subsidiaries", text: 'Subsidiaries', href: "/settings/subsidiaries" });
                    breadcrumbs.push({ key: _bankAccount.subsidiary.id, text: _bankAccount.subsidiary.name, href: "/settings/subsidiaries/" + _bankAccount.subsidiary.id });
                } else if (_bankAccount.employee) {
                    breadcrumbs.push({ key: "employees", text: 'Employees', href: "/users/administrators" });
                    breadcrumbs.push({ key: _bankAccount.employee.id, text: _bankAccount.employee.name, href: "/operationals/employees/" + _bankAccount.employee.id });
                }
                breadcrumbs.push({ key: "bankaccounts", text: 'Bank accounts' });
                breadcrumbs.push({ key: _bankAccount.id, text: `${_bankAccount.accountNumber} a/n ${_bankAccount.accountName}` });

                topbar.show(`${_bankAccount.accountNumber} a/n ${_bankAccount.accountName}`, breadcrumbs);
            }

            const tqs: string[] = [`start_date=${selectedDate.start}`, `end_date=${selectedDate.end}`];
            const _transactions = await BankAccountService.transactions(props.bankAccountId, tqs.join("&"));
            setTransactions(_transactions.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)).reverse());

            const subsidiaries = getUniqueSubsidiaries(_transactions);
            setSelectedSubsidiaries(subsidiaries.map((s) => s.id));

            const categories = getUniqueCategories(_transactions);
            setSelectedCategories(categories);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_bank_account_error',
                text: 'Failed to get bank account details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getUniqueCategories = (_transactions: IBankAccountTransactionResourceShortProps[]) => {
        const categories: string[] = [];
        _transactions.forEach((t) => {
            t.categories.forEach((category) => {
                const idx = categories.findIndex((c) => category === c);
                if (idx < 0) {
                    categories.push(category);
                }
            });
        });

        return categories;
    }

    const getUniqueSubsidiaries = (_transactions: IBankAccountTransactionResourceShortProps[]) => {
        const subsidiaries: ISubsidiariesResourceShort[] = [];
        _transactions.forEach((t) => {
            t.subsidiaries.forEach((subsidiary) => {
                const idx = subsidiaries.findIndex((s) => subsidiary.id === s.id);
                if (idx < 0) {
                    subsidiaries.push(subsidiary);
                }
            });
        });

        return subsidiaries;
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'selectedDate',
            onRender: () => {
                return <Dropdown selectedKey={selectedDate.type}
                    label={'Date range'}
                    styles={{root: {width: 200}}}
                    options={[
                        {key: 'today', text: 'Today'},
                        {key: 'lastCutoff', text: 'From last cutoff date', disabled: lastCutoff === undefined},
                        {key: 'divider_1', text: '', itemType: DropdownMenuItemType.Divider},
                        {key: 'thisMonth', text: 'This month'},
                        {key: 'lastMonth', text: 'Last month'},
                        {key: 'last3Months', text: 'Last 3 months'},
                        {key: 'last6Months', text: 'Last 6 months'},
                        {key: 'divider_2', text: '', itemType: DropdownMenuItemType.Divider},
                        {key: 'thisYear', text: 'This year'},
                        {key: 'lastYear', text: 'Last year'},
                        {key: 'divider_3', text: '', itemType: DropdownMenuItemType.Divider},
                        {key: 'thisWeek', text: 'This week'},
                        {key: 'lastWeek', text: 'Last week'},
                        {key: 'divider_4', text: '', itemType: DropdownMenuItemType.Divider},
                        {key: 'custom', text: 'Custom'}
                    ]}
                    onChange={(ev, opt) => {
                        let _selectedDate = selectedDate;
                        _selectedDate.type = opt?.key as TSelectedDateType;

                        if (_selectedDate.type === 'lastCutoff' && lastCutoff) {
                            _selectedDate.start = moment(lastCutoff.cutoffDate).toISOString();
                            _selectedDate.end = moment().endOf('day').toISOString();
                        } else if (_selectedDate.type === 'thisMonth') {
                            _selectedDate.start = moment().startOf('month').toISOString();
                            _selectedDate.end = moment().endOf('month').toISOString();
                        } else if (_selectedDate.type === 'lastMonth') {
                            _selectedDate.start = moment().add(-1, 'month').startOf('month').toISOString();
                            _selectedDate.end = moment().add(-1, 'month').endOf('month').toISOString();
                        } else if (_selectedDate.type === 'last3Months') {
                            _selectedDate.start = moment().add(-2, 'month').startOf('month').toISOString();
                            _selectedDate.end = moment().endOf('month').toISOString();
                        } else if (_selectedDate.type === 'last6Months') {
                            _selectedDate.start = moment().add(-5, 'month').startOf('month').toISOString();
                            _selectedDate.end = moment().endOf('month').toISOString();
                        } else if (_selectedDate.type === 'thisYear') {
                            _selectedDate.start = moment().startOf('year').toISOString();
                            _selectedDate.end = moment().endOf('year').toISOString();
                        } else if (_selectedDate.type === 'lastYear') {
                            _selectedDate.start = moment().add(-1, 'year').startOf('year').toISOString();
                            _selectedDate.end = moment().add(-1, 'year').endOf('year').toISOString();
                        } else if (_selectedDate.type === 'thisWeek') {
                            _selectedDate.start = moment().startOf('week').toISOString();
                            _selectedDate.end = moment().endOf('week').toISOString();
                        } else if (_selectedDate.type === 'lastWeek') {
                            _selectedDate.start = moment().add(-1, 'week').startOf('week').toISOString();
                            _selectedDate.end = moment().add(-1, 'week').endOf('week').toISOString();
                        } else if (_selectedDate.type === 'today') {
                            _selectedDate.start = moment().startOf('day').toISOString();
                            _selectedDate.end = moment().endOf('day').toISOString();
                        }

                        setSelectedDate({..._selectedDate});
                    }} />
            }
        });

        items.push({
            key: 'selectedType',
            onRender: () => {
                return <Dropdown label={'Type'}
                    selectedKeys={selectedTransactionTypes}
                    multiSelect
                    styles={{root: {marginLeft: 20}}}
                    options={[
                        {key: 'IN', text: 'IN'},
                        {key: 'OUT', text: 'OUT'}
                    ]}
                    onChange={(evt, opt) => {
                        let _selectedTransactionTypes = selectedTransactionTypes;
                        const idx = selectedTransactionTypes.findIndex((t) => t === opt?.key);
                        if (idx > -1) {
                            _selectedTransactionTypes.splice(idx, 1);
                        } else {
                            _selectedTransactionTypes.push(opt?.key as string);
                        }

                        setSelectedTransactionTypes([...(_selectedTransactionTypes.sort())]);
                    }} />
            }
        });

        items.push({
            key: 'selectedSubsidiaries',
            onRender: () => {
                return <Dropdown label={'Subsidiaries'}
                    selectedKeys={selectedSubsidiaries}
                    multiSelect
                    styles={{root: {marginLeft: 20, width: 200}}}
                    options={getUniqueSubsidiaries(transactions).map((s) => {
                        return {key: s.id, text: s.name}
                    })}
                    onChange={(evt, opt) => {
                        let _selectedSubsidiaries = selectedSubsidiaries;
                        const idx = _selectedSubsidiaries.findIndex((t) => t === opt?.key);
                        if (idx > -1) {
                            _selectedSubsidiaries.splice(idx, 1);
                        } else {
                            _selectedSubsidiaries.push(opt?.key as string);
                        }

                        setSelectedSubsidiaries([...(_selectedSubsidiaries.sort())]);
                    }} />
            }
        });

        items.push({
            key: 'selectedCategories',
            onRender: () => {
                return <Dropdown label={'Categories'}
                    selectedKeys={selectedCategories}
                    multiSelect
                    styles={{root: {marginLeft: 20, width: 200}}}
                    options={getUniqueCategories(transactions).map((c) => {
                        return {key: c, text: c}
                    })}
                    onChange={(evt, opt) => {
                        let _selectedCategories = selectedCategories;
                        const idx = _selectedCategories.findIndex((t) => t === opt?.key);
                        if (idx > -1) {
                            _selectedCategories.splice(idx, 1);
                        } else {
                            _selectedCategories.push(opt?.key as string);
                        }

                        setSelectedCategories([...(_selectedCategories.sort())]);
                    }} />
            }
        });

        farItems.push({
            key: 'summary',
            onRender: () => {
                const _transactions = getRelatedTransactions();
                
                let incomes = 0;
                let outcomes = 0;
                _transactions.forEach((t) => {
                    if (t.type === 'IN') {
                        incomes += Number(t.amount);
                    } else if (t.type === 'OUT') {
                        outcomes += Number(t.amount);
                    }
                });
                const total = incomes - outcomes;

                return <Stack horizontal className={styles.transactionSummary}>
                    <Stack styles={{root: {width: 200}}} className={styles.incomes}>
                        <Text size={'small'}>Incomes</Text>
                        <Label style={{marginTop: -5}} className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(incomes)}</Label>
                    </Stack>
                    <Stack styles={{root: {width: 200}}} className={styles.outcomes}>
                        <Text size={'small'}>Outcomes</Text>
                        <Label style={{marginTop: -5}} className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(outcomes)}</Label>
                    </Stack>
                    <Stack styles={{root: {width: 200}}} className={styles.total}>
                        <Text size={'small'}>Total</Text>
                        {total > 0 ? <Label style={{marginTop: -5}} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(total)}</Label> : null}
                        {total < 1 ? <Label style={{marginTop: -5}} className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(total * -1)}</Label> : null}
                    </Stack>
                </Stack>;
            }
        })

        return { items, farItems };
    }

    const getRelatedTransactions = () => {
        let _transactions = transactions;

        // filter by transaction type
        _transactions = _transactions.filter((t) => selectedTransactionTypes.indexOf(t.type) > -1);

        // filter by subsidiaries
        _transactions = _transactions.filter((t) => {
            let included = false;
            included = t.subsidiaries.findIndex((s) => selectedSubsidiaries.indexOf(s.id) > -1) > -1;

            return included;
        });

        // filter by categories
        _transactions = _transactions.filter((t) => {
            let included = false;
            included = t.categories.findIndex((c) => selectedCategories.indexOf(c) > -1) > -1;

            return included;
        });

        return _transactions;
    }

    const _onSurfaceDismissed = (_refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveTransactionId(undefined);

        if (_refresh) { refresh() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded && !bankAccount ? <LoadingComponent label='Retrieving bank account details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {bankAccount ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                {!loaded || (loaded && transactions.length > 0) ? <ShimmeredDetailsList items={getRelatedTransactions()}
                    columns={columns}
                    shimmerLines={5}
                    enableShimmer={!loaded}
                    selectionMode={SelectionMode.none} /> : null}
                {loaded && transactions.length < 1 ? <Text size={'small'} style={{fontStyle: 'italic'}}>No transactions recorded during this period</Text> : null}
            </Stack>
        </> : null}
        {/*activeSurface === 'income.details' && activeTransaction?.income ? <IncomePaymentDetails paymentId={activeTransaction.income.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'outcome.details' && activeTransaction?.outcome?.payments[0] ? <OutcomePaymentDetails paymentId={activeTransaction.outcome.payments[0].id} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Stack>;
};

export default BankAccountTranscationHistory;
