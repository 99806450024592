import moment from 'moment';
import { IIncomeInvoiceResourceShortProps } from '../../../../props/finance/incomes/invoices/invoice';
import Request from './../../../request';
import { IDocumentProps, IPaginationResourceShort } from '../../../../props/general';

// import props

const IncomeInvoiceService = {
    getInvoiceNumber: (id: string) => {
        return id.split(".").splice(2).join(".");
    },
    retrieve: async (qs?: string): Promise<{ pagination: IPaginationResourceShort, data: IIncomeInvoiceResourceShortProps[] }> => {
        try {
            const result = await Request.get(`/api/incomes/invoices?${qs}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IIncomeInvoiceResourceShortProps> => {
        try {
            const result = await Request.post(`/api/incomes/invoices`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (incomeId: string, data: FormData): Promise<IIncomeInvoiceResourceShortProps> => {
        try {
            const result = await Request.post(`/api/incomes/invoices/${incomeId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    get: async (id: string): Promise<IIncomeInvoiceResourceShortProps> => {
        try {
            const result = await Request.get(`/api/incomes/invoices/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    cancel: async (id: string, data: FormData): Promise<void> => {
        try {
            await Request.post(`/api/incomes/invoices/${id}/cancel`, data, 'multipart/form-data');
            return;
        } catch (e) {
            throw (e);
        }
    },
    documents: {
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const result = await Request.get(`/api/incomes/invoices/${id}/documents?${qs || ''}`);
                return result;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/incomes/invoices/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/incomes/invoices/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    },
    download: async (invoiceId: string, format?: string, name?: string): Promise<any> => {
        try {
            const result = await Request.download(`/api/incomes/invoices/${invoiceId}/download?format=${format ?? 'docx'}`);

            const data = new Blob([result.data], { type: result.headers["content-type"] });
            const url = window.URL.createObjectURL(data);
            const linkTag = document.createElement('a');
            linkTag.href = url;
            linkTag.setAttribute('download', `Invoice ${(name || moment().format("DDMMYYYY"))}.${format ?? 'docx'}`);
            linkTag.click();

            return result;
        } catch (e) {
            throw (e);
        }
    }
}

export default IncomeInvoiceService;