import { ActionButton, ChoiceGroup, DefaultButton, Dialog, DialogFooter, DirectionalHint, Dropdown, Icon, Link, Panel, PanelType, PrimaryButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { NavLink } from 'react-router-dom';
import LoadingComponent from '../../../feedbacks/loading';
import { faChevronDown, faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faImage, faUpload, faWarning } from '@fortawesome/pro-light-svg-icons';
import FileFormats, { TFileType } from '../../../../manifests/fileFormats';
import GeneralService from '../../../../services/general';
import Dropzone, { useDropzone } from 'react-dropzone';
import { DocumentvisiblityType, IDocumentProps, IFileDetailsProps, IFileProps } from '../../../../props/general';
import { useId } from '@fluentui/react-hooks';
import DocumentVisibilities from '../../../../manifests/documentVisibilities';
import { IInternalDocumentResourceShort } from '../../../../props/internalDocuments';

export type TTagVariant = 'default' | 'active' | 'success' | 'light' | 'error' | 'warning' | 'transparent';


type IUploadFilesComponentProps = {
	title?: string;
	multiple?: boolean;
	limit?: number;
	maxSize?: number;
	allowedTypes?: TFileType[];
	existings?: IDocumentProps[] | IInternalDocumentResourceShort[];
	onUpload(files: IFileDetailsProps[]): void;
	documentTypes: {
		key: string;
		text: string;
	}[]
	onDeleFiles?: (ids: string[]) => void
	files?: IFileDetailsProps[]
}

const UploadFilesComponent: React.FC<IUploadFilesComponentProps> = (props: IUploadFilesComponentProps) => {
	const [uploading, setUploading] = React.useState<boolean>();
	const [files, setFiles] = React.useState<IFileDetailsProps[]>(props.files || []);
	const [deletedFilesId, setDeletedFilesid] = useState<string[]>([])


	useEffect(() => {
		props.onDeleFiles && props.onDeleFiles(deletedFilesId)
		return () => {

		}
	}, [deletedFilesId])

	const renderFileCard = (file: IFileDetailsProps, index: number) => {
		const isExist = (props.existings as any || []).find((ex: any) => {
			if (ex.basename) {
				if (ex.basename.toLowerCase() === file.data.name.toLowerCase()) {
					return true
				}
				return false
			}
			if (ex.name) {
				if (ex.name.toLowerCase() === file.data.name.toLowerCase()) {
					return true
				}
				return false
			}
			return false
		});
		const type = GeneralService.getFileType(file.data.name);

		return <Stack className={styles.item} horizontal tokens={{ childrenGap: 10 }}>
			<Stack.Item className={`${styles.icon} ${styles['bg' + type]}`} align="stretch">
				<Stack styles={{ root: { width: '100%', height: '100%' } }} verticalAlign='center' horizontalAlign='center'>
					{type === 'word' ? <Icon className={styles.image} iconName='WordDocument' /> : null}
					{type === 'excel' ? <Icon className={styles.image} iconName='ExcelDocument' /> : null}
					{type === 'powerpoint' ? <Icon className={styles.image} iconName='PowerPointDocument' /> : null}
					{type === 'video' ? <Icon className={styles.image} iconName='Video' /> : null}
					{type === 'pdf' ? <Icon className={styles.image} iconName='PDF' /> : null}
					{type === 'file' ? <FontAwesomeIcon className={styles.image} icon={faFile} /> : null}
					{type === 'image' ? <img className={styles.imagePreview} src={URL.createObjectURL(file.data)} alt={file.data.name} /> : null}
				</Stack>
			</Stack.Item>
			<Stack grow={1} className={styles.label} tokens={{ childrenGap: 10 }}>
				<Stack tokens={{ childrenGap: 5 }}>
					{isExist ? <Stack className={styles.exist} horizontal tokens={{ childrenGap: 10 }}>
						<FontAwesomeIcon icon={faWarning} style={{ marginTop: 1 }} />
						<Text size={'small'}>File with same name already exist. Continue to overwrite existing.</Text>
					</Stack> : null}
					<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='space-between'>
						<Label>{file.data.name}</Label>
						<Text size={'small'} style={{ marginTop: 4 }}>{GeneralService.getFriendlyFileSizeText(file.data.size)}</Text>
					</Stack>
				</Stack>
				<Stack className='divider'></Stack>
				<Stack tokens={{ childrenGap: 3 }}>
					<Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 5 }}>
						<Text size={'small'}>Type</Text>
						<Text size={'small'}>:</Text>
						<Label size={'xsmall'}>{props.documentTypes.find(d => d.key === file.type)?.text}</Label>
						<ActionButton
							styles={{ root: { height: 'unset !important', minHeight: 'unset !important' } }}
							menuProps={{
								items: props.documentTypes.map(d => ({
									...d, onClick: () => {
										const fls = [...files]
										fls[index].type = d.key as string;
										setFiles(fls)
									}
								})),
							}}
							iconProps={{ styles: { root: { fontSize: 12 } } }} />
					</Stack>

					<Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 5 }}>
						<Text size={'small'}>Visibility</Text>
						<Text size={'small'}>:</Text>
						<Label size={'xsmall'}>{DocumentVisibilities.find(d => d.key === file.visibility)?.text}</Label>
						<ActionButton
							styles={{ root: { height: 'unset !important', minHeight: 'unset !important' } }}
							menuProps={{
								items: DocumentVisibilities.map(d => ({
									...d, onClick: () => {
										const fls = [...files]
										fls[index].visibility = d.key as DocumentvisiblityType
										setFiles(fls)
									}
								})),
							}}
							iconProps={{ styles: { root: { fontSize: 12 } } }} />
					</Stack>

				</Stack>
			</Stack>
			<Stack verticalAlign='baseline' className={styles.actions}>
				<ActionButton iconProps={{ iconName: "Delete" }} onClick={() => _onDeleteFile(file)} disabled={uploading} />
			</Stack>
		</Stack>
	}

	const _onDeleteFile = (file: IFileDetailsProps) => {
		if (window.confirm(`Are you sure to delete file "${file.data.name}"?`)) {
			const _files = files.filter((f) => f.data.name !== file.data.name);
			setFiles(_files);
			if (file.id) {
				const dlts = [...deletedFilesId]
				dlts.push(file.id)
				setDeletedFilesid(dlts)
			}
		}
	}

	const _onFilesChanged = (evt: any) => {
		const _files: IFileDetailsProps[] = [];
		for (var ctr = 0; ctr < (evt?.target.files || []).length || 0; ctr++) {
			if (evt && evt.target.files && evt.target.files[ctr]) {
				_files.push({ data: evt.target.files[ctr], visibility: 'public', type: (props.documentTypes || [])[0]?.key || 'document' });
			}
		}

		if (props.multiple) {
			setFiles([...files, ..._files]);
		} else {
			setFiles([_files[0]]);
		}
	}

	const _onFilesDropped = (evt: any) => {
		evt.preventDefault();

		if (evt.dataTransfer.items) {
			const _files: IFileDetailsProps[] = [];

			[...evt.dataTransfer.items].forEach((item, i) => {
				if (item.kind === "file") {
					const _file = item.getAsFile();
					_files.push({ data: _file, visibility: 'public', type: 'document' });
				}
			});


			if (props.multiple) {
				setFiles([...files, ..._files]);
			} else {
				setFiles([_files[0]]);
			}
		}
	}

	useEffect(() => {
		props.onUpload(files)
		return () => { }
	}, [files])

	return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
		<Dropzone multiple={props.multiple} disabled={uploading}>
			{({ getRootProps, getInputProps }) => (
				<Stack tokens={{ childrenGap: 10 }} {...getRootProps({
					value: files,
					onChange: _onFilesChanged,
					onDrop: _onFilesDropped,
					className: `${styles.upload} dropzone`,
					for: 'upload_files'
				})}>
					<input {...getInputProps({ id: 'upload_files' })} />
					<FontAwesomeIcon icon={faUpload} fontSize={22} />
					<Stack tokens={{ childrenGap: 0 }} horizontalAlign='center'>
						<Label size={'small'}>Drop your file(s) here</Label>
						<Text size={'xsmall'}><Link>Browse file</Link> from your computer</Text>
					</Stack>
				</Stack>
			)}
		</Dropzone>
		<Stack className={styles.list} tokens={{ childrenGap: 10 }}>
			{files.map((file, index) => renderFileCard(file, index))}
		</Stack>
	</Stack>
};

export default UploadFilesComponent;