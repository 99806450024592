import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';
import BankAccountService from '../../../../services/bankaccounts';
import PermissionsService from '../../../../services/permissions';

// props
import { IBankAccountResourceProps } from '../../../../props/bankaccounts';
import { IBreadcrumbItemProps } from '../../../navigations/breadcrumb';

// components
import { CommandBar, Dropdown, ICommandBarItemProps, Stack } from '@fluentui/react';
import LoadingComponent from '../../../feedbacks/loading';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../uiframeworks/detailsStack';
import NoAccess from '../../../uiframeworks/noAccess';
import BankAccountCutoffsList from '../../cutoffs/general/list';
import BankAccountDetailsProperties from './properties';
import BankAccountDetailsSummary from './summary';
import { IBankAccountCutoffResourceShortProps } from '../../../../props/bankaccounts/cutoff';
import BankAccountCutoffService from '../../../../services/bankaccounts/cutoff';
import Label from '../../../typography/label';
import moment from 'moment';
import BankAccountTranscationHistory from './transactionhistory';

type BankAccountDetailsProps = {
    bankAccountId: string;
}

const BankAccountDetails: React.FC<BankAccountDetailsProps> = (props: BankAccountDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [bankAccount, setBankAccount] = React.useState<IBankAccountResourceProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        try {
            const _bankAccount = await BankAccountService.read(props.bankAccountId);
            setBankAccount(_bankAccount);

            const breadcrumbs: IBreadcrumbItemProps[] = [];
            breadcrumbs.push({ key: "home", icon: faHome, href: "/" });
            if (_bankAccount.vendor) {
                breadcrumbs.push({ key: "vendors", text: 'Vendors', href: "/operationals/vendors" });
                breadcrumbs.push({ key: _bankAccount.vendor.id, text: _bankAccount.vendor.name, href: "/operationals/vendors/" + _bankAccount.vendor.id });
            } else if (_bankAccount.subsidiary) {
                breadcrumbs.push({ key: "subsidiaries", text: 'Subsidiaries', href: "/settings/subsidiaries" });
                breadcrumbs.push({ key: _bankAccount.subsidiary.id, text: _bankAccount.subsidiary.name, href: "/operationals/subsidiaries/" + _bankAccount.subsidiary.id });
            } else if (_bankAccount.employee) {
                breadcrumbs.push({ key: "employees", text: 'Employees', href: "/users/administrators" });
                breadcrumbs.push({ key: _bankAccount.employee.id, text: _bankAccount.employee.name, href: "/operationals/employees/" + _bankAccount.employee.id });
            }
            breadcrumbs.push({ key: "bankaccounts", text: 'Bank accounts' });
            breadcrumbs.push({ key: _bankAccount.id, text: `${_bankAccount.accountNumber} a/n ${_bankAccount.accountName}` });

            topbar.show(`${_bankAccount.accountNumber} a/n ${_bankAccount.accountName}`, breadcrumbs);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_bank_account_error',
                text: 'Failed to get bank account details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        return { items, farItems };
    }

    const _onSurfaceDismissed = (_refresh?: boolean) => {
        setActiveSurface(undefined);
        if (_refresh) { refresh() }
    }

    const getTabsSection = (): IStackSectionProps => {
        const tabs: ISectionTabProps[] = [];

        tabs.push({
            key: 'cutoffs',
            title: 'Cutoff History',
            content: <Stack>
                <BankAccountCutoffsList bankAccountId={props.bankAccountId} />
            </Stack>
        });

        tabs.push({
            key: 'transacation_history',
            title: 'Transaction History',
            content: <Stack>
                <BankAccountTranscationHistory bankAccountId={props.bankAccountId} />
            </Stack>
        });

        return {
            key: 'details_tab',
            isCard: true,
            tabs
        }
    }

    const getLeftDetailsSection = (_bankAccount: IBankAccountResourceProps): IStackSectionProps => {
        return {
            key: 'summary',
            content: <BankAccountDetailsSummary bankAccount={_bankAccount} />
        }
    }

    const getRightDetailsSection = (_bankAccount: IBankAccountResourceProps): IStackSectionProps => {
        return {
            key: "bank_account_details",
            isCard: true,
            header: { title: 'Details' },
            content: <BankAccountDetailsProperties bankAccount={_bankAccount} />
        }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving bank account details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && bankAccount ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getLeftDetailsSection(bankAccount)]} right={getRightDetailsSection(bankAccount)} />
                <DetailsStack left={[getTabsSection()]} />
            </Stack>
        </> : null}
    </Stack>;
};

export default BankAccountDetails;
