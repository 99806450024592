import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../../services/general/error';
import VendorsService from '../../../../../services/data/vendors';

// props
import { IUserResourceShortProps } from '../../../../../props/users/user';
import { IPaginationResourceShort } from '../../../../../props/general';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { ActionButton, CommandBar, DefaultButton, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { faCheck, faInfoCircle, faLocation, faLocationDot, faPhone, faXmark, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from '../../../../typography/text';
import VendorForm from '../form';
import PermissionsService from '../../../../../services/permissions';
import Pagination from '../../../../uiframeworks/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from '../../../../uiframeworks/tag';
import VerifyVendorForm from '../form/verify';
import RejectVendorForm from '../form/reject';
import PivotButton from '../../../../uiframeworks/buttons/pivot';
import ResetVendorverificationStatusForm from '../form/resetVerification';
import VendorsListFilterButton from './filter';
import { FilterOptionProps } from '../../../../uiframeworks/filters/panel';
import SelectedFilter from '../../../../uiframeworks/filters/selected';

interface IVendorsListProps {
    hideFilter?: boolean;
    qs?: string[];
}

const VendorsList: React.FC<IVendorsListProps> = (props: IVendorsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [vendors, setVendors] = React.useState<IVendorResourceShortProps[]>([]);
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [activeVendor, setActiveVendor] = React.useState<IVendorResourceShortProps | undefined>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOptionProps[]>([]);
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selectedVerificationStatus, setSelectedVerificationStatus] = React.useState<'verified' | 'pending_verification' | 'rejected'>("verified");

    const hasPermission = PermissionsService.hasPermission(['vendors.read.all'], user.permissions)

    const columns: IColumn[] = [
        {
            key: "name",
            fieldName: "name",
            name: "Name",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IVendorResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.name}>
                        <NavLink to={`/operationals/vendors/${item.id}`}>
                            <Text size={'small'}>{item.name}</Text>
                        </NavLink>
                    </TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IVendorResourceShortProps) => {
                let menuItems: IContextualMenuItem[] = [];

                if (PermissionsService.hasPermission(['vendors.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveVendor(item);
                            setActiveSurface('update');
                        }
                    });
                }

                if (PermissionsService.hasPermission(['vendors.verification.verify'], user.permissions) && item.verificationStatus === 'pending_verification') {
                    menuItems.push({
                        key: "verify", text: "Set as verified", iconProps: { iconName: "CheckMark" }, onClick: () => {
                            setActiveVendor(item);
                            setActiveSurface('verification.verify');
                        }
                    });
                }

                if (PermissionsService.hasPermission(['vendors.verification.reject'], user.permissions) && item.verificationStatus === 'pending_verification') {
                    menuItems.push({
                        key: "reject", text: "Set as not verified", iconProps: { iconName: "Close" }, onClick: () => {
                            setActiveVendor(item);
                            setActiveSurface('verification.reject');
                        }
                    });
                }

                if (PermissionsService.hasPermission(['vendors.verification.reset'], user.permissions) && item.verificationStatus !== 'pending_verification') {
                    menuItems.push({
                        key: "reset", text: "Reset verification status", iconProps: { iconName: "Reset" }, onClick: () => {
                            setActiveVendor(item);
                            setActiveSurface('verification.reset');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "details",
            name: "Details",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IVendorResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 10 }}>
                    {(item.address || "").trim() !== "" ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { marginTop: 2, width: 16 } }}><FontAwesomeIcon icon={faLocationDot} fontSize={12} /></Stack>
                        <Text size={'small'}>{item.address}</Text>
                    </Stack> : null}
                    {(item.phoneNumber || "").trim() !== "" ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { marginTop: 2, width: 16 } }}><FontAwesomeIcon icon={faPhone} fontSize={12} /></Stack>
                        <Text size={'small'}>{item.phoneNumber}</Text>
                    </Stack> : null}
                </Stack>;
            }
        },
        {
            key: "fleetSummary",
            name: "Fleet Summary",
            minWidth: 275,
            maxWidth: 275,
            isMultiline: true,
            onRender: (item: IVendorResourceShortProps) => {
                return <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                    {item.fleetSummary.map((f) => {
                        return <Tag text={`${f.name} (x${f.total})`} />
                    })}
                </Stack>;
            }
        },
        {
            key: "npwp",
            fieldName: "npwp",
            name: "NPWP",
            minWidth: 175,
            maxWidth: 175,
            isMultiline: true,
            onRender: (item: IVendorResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    {(item.npwp || "").trim() !== "" ? <Text size={'small'}>{item.npwp || "-"}</Text> : null}
                    {(item.npwp || "").trim() === "" ? <Text size={'small'} className={'color-red'}>!!! NPWP missing</Text> : null}
                </Stack.Item>;
            }
        },
        {
            key: "status",
            name: "Verification Status",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IVendorResourceShortProps) => {
                return <Stack horizontal wrap verticalAlign={'center'} tokens={{ childrenGap: 8 }}>
                    {item.verificationStatus === 'pending_verification' ? <Tag size={'small'} variant={'warning'} text={'Pending'} /> : null}
                    {item.verificationStatus === 'verified' ? <Tag size={'small'} variant={'success'} icon={faCheck} text={'Verified'} /> : null}
                    {item.verificationStatus === 'rejected' ? <>
                        <Tag size={'small'} variant={'error'} icon={faXmark} text={'Rejected'} />
                        <TooltipHost content={<Text size={'small'}>{item.verificationReason || ""}</Text>}>
                            <FontAwesomeIcon fontSize={14} style={{ marginTop: 2 }} icon={faInfoCircle} />
                        </TooltipHost>
                    </> : null}
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveVendors();
    }, []);

    useEffect(() => {
        _onRetrieveVendors();
    }, [keyword, selectedFilter]);


    const getQueryString = (pageNumber?: number) => {
        const qs: string[] = props.qs || [];
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

        const groupedFilter: { key: string, options: FilterOptionProps[] }[] = [];
        selectedFilter.map((sel) => {
            const gfidx = groupedFilter.findIndex((gf) => gf.key === sel.filterby);
            if (gfidx > -1) {
                groupedFilter[gfidx].options.push(sel);
            } else {
                groupedFilter.push({
                    key: sel.filterby,
                    options: [sel]
                });
            }
        });

        groupedFilter.forEach((gf) => {
            qs.push(`${gf.key}=${gf.options.map((opt) => opt.key).join(';')}`);
        });

        return qs.join("&");
    }

    const _onRetrieveVendors = async (pageNumber?: number) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string = getQueryString(pageNumber);
            const results = await VendorsService.retrieve(qs);
            setVendors(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_vendors_error',
                text: 'Failed to retrieve vendors list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['vendors.read.all'], user.permissions)) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        items.push({
            key: "filter",
            onRender: () => {
                return <VendorsListFilterButton selected={selectedFilter}
                    styles={{ root: { marginLeft: 10 } }}
                    filters={['status', 'service', 'fleet type', 'service area']}
                    onChange={(selected) => {
                        setSelectedFilter([...selected]);
                    }} />
            }
        });

        if (PermissionsService.hasPermission(['vendors.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Vendor",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Vendor"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveVendor(undefined);

        if (refresh) { _onRetrieveVendors() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            {selectedFilter.length > 0 ? <SelectedFilter selected={selectedFilter} onChange={(selected) => {
                setSelectedFilter(selected);
            }} /> : null}
            <Stack tokens={{ childrenGap: 10 }}>
                {pagination && vendors.length > 0 ? <Pagination goToPage={_onRetrieveVendors} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " vendor(s) found"} /> : null}
                <Stack className={styles.list}>
                    {
                        !loaded || (loaded && vendors.length > 0) ? (
                            <>
                                <ShimmeredDetailsList
                                    setKey="items"
                                    items={vendors}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    enableShimmer={!loaded}
                                    onShouldVirtualize={() => false}
                                    ariaLabelForShimmer="Content is being fetched"
                                    ariaLabelForGrid="Item details" />
                            </>
                        ) : null
                    }
                    {loaded && vendors.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                        <Text>Vendor(s) not found</Text>
                    </Stack> : null}
                </Stack>
            </Stack>
            {activeSurface === 'create' ? <VendorForm onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update' && activeVendor ? <VendorForm vendorId={activeVendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'verification.verify' && activeVendor ? <VerifyVendorForm vendorId={activeVendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'verification.reject' && activeVendor ? <RejectVendorForm vendorId={activeVendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'verification.reset' && activeVendor ? <ResetVendorverificationStatusForm vendorId={activeVendor.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default VendorsList;
