import React from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';

// components
import { DetailsList, SelectionMode, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { IOutcomePaymentResourceProps, IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import { IOutcomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/outcomes/payments/invoice';
import OutcomePaymentsList from '../list';

interface OutcomePaymentDetailsPropertiesProps {
    payment: IOutcomePaymentResourceProps;
}

const OutcomePaymentDetailsProperties: React.FC<OutcomePaymentDetailsPropertiesProps> = (props: OutcomePaymentDetailsPropertiesProps) => {
    const { payment } = props;
    const { banner } = useStore();
    const paymentMethod = PaymentMethods.find((m) => m.key === payment.method);

    const renderStatusTag = () => {
        let tagText: string = 'Requested';
        let tagVariant: TTagVariant = 'active';
        if (payment.status === 'completed') {
            tagText = 'Paid';
            tagVariant = 'success';
        } else if (payment.status === 'rejected') {
            tagText = 'Rejected';
            tagVariant = 'error';
        }

        return <Stack horizontal><Tag variant={tagVariant} text={tagText} /></Stack>;
    }

    const getTotalAmount = () => {
        let totalAmount = 0;

        if (props.payment.relatedPayments) {
            props.payment.relatedPayments.forEach((payment) => {
                totalAmount += Number(payment.amount || "0");
            });
        } else {
            totalAmount = Number(props.payment.amount);
        }

        return totalAmount;
    }

    const getColumns = () => {

    }

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        {/*<Stack>
            <Label>Amount</Label>
            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(payment.amount))}</Text>
        </Stack>
        <Stack>
            <Label>Status</Label>
            {renderStatusTag()}
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack>
            <Label>Vendor</Label>
            <Text size={'small'}>{payment.invoice.vendor?.name || "-"}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '50%' } }}>
                <Label>Payment Method</Label>
                <Text size={'small'}>{paymentMethod?.text || "Cash"}</Text>
            </Stack>
            {payment.method === 'transfer' && payment.targetBankAccount ? <Stack styles={{ root: { width: '50%' } }}>
                <Label>Account Number</Label>
                <Text size={'small'}>{payment.targetBankAccount.accountNumber} - {payment.targetBankAccount.bankName} ({payment.targetBankAccount.bankCity})</Text>
            </Stack> : null}
            {payment.method === 'virtualaccount' && payment.targetVirtualAccountNumber ? <Stack styles={{ root: { width: '50%' } }}>
                <Label>Virtual Account Number</Label>
                <Text size={'small'}>{payment.targetVirtualAccountNumber}</Text>
            </Stack> : null}
</Stack>*/}

        {payment.status === 'rejected' ? <>
            <Stack>
                <Label size={'xsmall'}>Rejection reason</Label>
                <Text size={'small'}>{payment.cancellationReason}</Text>
            </Stack>
            <Stack className={'divider'}></Stack>
        </> : null}
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Requested at</Label>
                <Text size={'small'}>{moment(payment.createdAt).format("DD/MM/YYYY")}</Text>
            </Stack>
            {!payment.paidOn ? <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Due date</Label>
                <Text size={'small'}>{moment(payment.dueDate).format("DD/MM/YYYY")}</Text>
            </Stack> : null}
            {payment.paidOn ? <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>{payment.status === 'completed' ? 'Completed' : 'Rejected'} on</Label>
                <Text size={'small'}>{moment(payment.dueDate).format("DD/MM/YYYY")}</Text>
            </Stack> : null}
        </Stack>
        <Stack className={'divider'}></Stack>
        {(payment.relatedPayments || []).length > 0 ? <Stack>
            <Label size={'xsmall'}>Related payments</Label>
            <DetailsList items={payment.relatedPayments || []}
                selectionMode={SelectionMode.none}
                columns={[
                    {
                        key: 'name',
                        name: 'Details',
                        minWidth: 150,
                        maxWidth: 150,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                            return <Stack styles={{ root: { paddingTop: 8 } }}>
                                {item.invoice ? <>
                                    <Label size={'xsmall'}>{item.invoice.name}</Label>
                                    {(item.invoice.invoiceNumber || "").trim() !== "" ? <Text size={'xsmall'}>#{item.invoice.invoiceNumber}</Text> : null}
                                </> : null}
                            </Stack>;
                        }
                    },
                    {
                        key: 'notes',
                        name: 'Notes',
                        minWidth: 0,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                            return <Stack styles={{ root: { paddingTop: 8 } }}>
                                <Text size={'small'}>{item.notes || "-"}</Text>
                            </Stack>;
                        }
                    },
                    {
                        key: 'amount',
                        name: 'Payment Amount',
                        minWidth: 125,
                        maxWidth: 125,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                            return <Stack styles={{ root: { paddingTop: 8 } }}>
                                <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || '0'))}</Text>
                            </Stack>;
                        }
                    }
                ]} />
        </Stack> : null}
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Total amount</Label>
                <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalAmount())}</Text>
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        {payment.completion ? <>
            <Label size={'small'}>Source Payment Details</Label>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'xsmall'}>Payment date</Label>
                    <Text size={'small'}>{moment(payment.completion.completedAt).format("DD/MM/YYYY")} by {payment.completion.completedBy.name}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'xsmall'}>Transfer from</Label>
                    <Stack>
                        <Text size={'small'}>{payment.completion.transferFrom?.accountName}</Text>
                        <Text size={'xsmall'}>{payment.completion.transferFrom?.accountNumber} - {payment.completion.transferFrom?.bank.name}</Text>
                    </Stack>
                </Stack>
            </Stack>
        </> : null}
        <Stack className={'divider'}></Stack>
        <Label size={'small'}>Target Payment Details</Label>
        {payment.invoice?.vendor ? <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'xsmall'}>Vendor</Label>
            <Text size={'small'}>{payment.invoice?.vendor.name}</Text>
        </Stack> : null}
        {payment.invoice?.targetSubsidiary ? <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'xsmall'}>Subsidiary</Label>
            <Text size={'small'}>{payment.invoice?.targetSubsidiary.name}</Text>
        </Stack> : null}
        {payment.invoice?.targetEmployee ? <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'xsmall'}>Employee</Label>
            <Text size={'small'}>{payment.invoice?.targetEmployee.name}</Text>
        </Stack> : null}
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Payment method</Label>
                <Text size={'small'}>{payment.method}</Text>
            </Stack>
            {(payment.targetVirtualAccountNumber || "").trim() !== "" ? <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Virtual account number</Label>
                <Text size={'small'}>{payment.targetVirtualAccountNumber}</Text>
            </Stack> : null}
            {payment.method === 'transfer' && payment.targetBankAccount ? <Stack styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'}>Bank account</Label>
                <Text size={'small'}>{payment.targetBankAccount.accountNumber} a/n {payment.targetBankAccount.accountName}</Text>
                <Text size={'small'}>({payment.targetBankAccount.bank.name} - {payment.targetBankAccount.bankCity})</Text>
            </Stack> : null}
        </Stack>
        {payment.notes && !payment.completion ? <>
            <Stack className={'divider'}></Stack>
            <Stack>
                <Label size={'xsmall'}>Notes</Label>
                <Text size={'small'}>{payment.notes}</Text>
            </Stack>
        </> : null}
        {(payment.completion?.payments || []).length > 0 ? <>
            <Stack className={'divider'}></Stack>
            <Stack tokens={{ childrenGap: 5 }}>
                <Label size={'xsmall'}>Paid together with</Label>
                <OutcomePaymentsList payments={payment.completion?.payments || []}
                    columns={['details', 'totalAmount']}
                    variant={'plain'}
                    hideCommandBar
                    hidePivotFilter />
            </Stack>
        </> : null}
    </Stack>
};

export default OutcomePaymentDetailsProperties;
