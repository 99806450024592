import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourceShortProps, IOrderResourcerShorterProps } from '../../../../../props/orders/order';

// components
import { ActionButton, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompanyResourceShort } from '../../../../../props/users/company';
import { IFreightCategoryResourceShortProps } from '../../../../../props/orders/freightCategory';
import { IDeliveryTypeResourceShortProps } from '../../../../../props/orders/deliveryType';
import { SelectedFilterProps } from '../filters';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type OrdersDashboardIncomesOutcomesChartProps = {
    orders: IOrderResourceShortProps[];
    groupBy: string;
}

const OrdersDashboardIncomesOutcomesChart: React.FC<OrdersDashboardIncomesOutcomesChartProps> = (props: OrdersDashboardIncomesOutcomesChartProps) => {
    const { banner } = useStore();
    
    const groupData = () => {
        const data: {
            name: string;
            paidIncomes: number;
            unpaidIncomes: number;
            paidOutcomes: number;
            unpaidOutcomes: number;
        }[] = [];

        let monthly: { key: string, orders: IOrderResourceShortProps[] }[] = [];

        // group all orders based on month
        props.orders.forEach((order) => {
            let date: string | undefined = undefined;
            if (props.groupBy === 'created') {
                date = order.createdAt;
            } else if (props.groupBy === 'actualdeparture') {
                date = order.actualDeparture;
            } else if (props.groupBy === 'estimateddeparture') {
                date = order.estimatedDeparture;
            }

            if (date) {
                const orderMonth = moment(date).format("YYYYMM");
                const idx = monthly.findIndex((m) => m.key === orderMonth);
                if (idx < 0) {
                    monthly.push({
                        key: orderMonth,
                        orders: [order]
                    });
                } else if (monthly[idx]) {
                    monthly[idx].orders.push(order);
                }
            }
        });

        // sort group based on month
        monthly = monthly.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
        monthly.forEach((month) => {
            const name = moment(month.key + '', 'YYYYMM').format("MMM YYYY").toUpperCase();
            let _data = { name, paidIncomes: 0, unpaidIncomes: 0, paidOutcomes: 0, unpaidOutcomes: 0 };

            month.orders.forEach((order) => {
                _data.paidIncomes += Number(order.paidAmount);
                _data.unpaidIncomes += Number(order.unpaidAmount);
                _data.paidOutcomes += order.paidOutcomes;
                _data.unpaidOutcomes += order.unpaidOutcomes;
            });

            data.push(_data);
        });

        return data;
    }

    const renderTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            const paidIncomes = props.payload.find((p: any) => p.dataKey === 'paidIncomes');
            const unpaidIncomes = props.payload.find((p: any) => p.dataKey === 'unpaidIncomes');
            const paidOutcomes = props.payload.find((p: any) => p.dataKey === 'paidOutcomes');
            const unpaidOutcomes = props.payload.find((p: any) => p.dataKey === 'unpaidOutcomes');

            const profit = ((paidIncomes?.value || 0) + (unpaidIncomes?.value || 0)) - (paidOutcomes?.value || 0) - (unpaidOutcomes?.value || 0);

            let profitVariant: TTagVariant = 'warning';
            if (profit < 0) {
                profitVariant = 'error';
            } else if (profit > 250000) {
                profitVariant = 'success';
            }

            return <Stack tokens={{ childrenGap: 10 }} className={styles.tooltip}>
                <Label>{moment(props.label, "MMM").format("MMMM").toUpperCase()}</Label>
                <Stack className='divider'></Stack>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Paid incomes</Label>
                        <Text size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(paidIncomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Unpaid incomes</Label>
                        <Text size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(unpaidIncomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Paid outcomes</Label>
                        <Text size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(paidOutcomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Unpaid outcomes</Label>
                        <Text size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(unpaidOutcomes?.value)}</Text>
                    </Stack>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                    <Label size={'xsmall'}>Profit</Label>
                    <Stack horizontal><Tag text={`Rp. ${GeneralService.getNumberWithSeparator(profit)}`} variant={profitVariant} /></Stack>
                </Stack>
            </Stack>;
        }

        return null;
    };

    const renderLegend = (props: any) => {
        const { payload } = props;

        return <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='center'>
            {payload.map((entry: any, index: number) => {
                let text = 'Paid incomes';
                let variant = '#107C10';

                if (entry.value === 'unpaidIncomes') {
                    text = 'Unpaid incomes';
                    variant = '#caeaca';
                } else if (entry.value === 'paidOutcomes') {
                    text = 'Unpaid outcomes';
                    variant = '#a4262c';
                } else if (entry.value === 'unpaidOutcomes') {
                    text = 'Paid outcomes';
                    variant = '#f0d3d4';
                }

                return <Stack horizontal tokens={{childrenGap: 5}}>
                    <Stack.Item className={styles.indicator} styles={{root: {backgroundColor: variant}}}> </Stack.Item>
                    <Text size={'small'}>{text}</Text>
                </Stack>
            })}
        </Stack>;
    }

    const renderXAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={12} y={-4} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    {payload.value}
                </text>
            </g>
        );
    }

    const renderYAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={-12} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    {payload.value < 750000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000)} jt` : null}
                    {payload.value >= 750000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000000)} m` : null}
                    {payload.value >= 750000000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000000000)} t` : null}
                </text>
            </g>
        );
    }

    return <Stack className={styles.container} styles={{root: {width: '100%', height: 450}}}>
        <ResponsiveContainer>
            <BarChart data={groupData()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={renderXAxisTick} dataKey="name" />
                <YAxis tick={renderYAxisTick} />
                <Tooltip content={renderTooltip} />
                <Legend content={renderLegend} />
                <Bar dataKey="paidIncomes" stackId={'incomes'} fill="#107C10" />
                <Bar dataKey="unpaidIncomes" stackId={'incomes'} fill="#caeaca" />
                <Bar dataKey="paidOutcomes" stackId={'outcomes'} fill="#a4262c" />
                <Bar dataKey="unpaidOutcomes" stackId={'outcomes'} fill="#f0d3d4" />
            </BarChart>
        </ResponsiveContainer>
    </Stack>;
};

export default OrdersDashboardIncomesOutcomesChart;
