import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';

type IncomePaymentsPageProps = {
}

const IncomePaymentsPage: React.FC<IncomePaymentsPageProps> = (props: IncomePaymentsPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Income Payments", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance', href: "/finance"},
            {key: "incomes", text: 'Incomes', href: "/finance/incomes"},
            {key: "payments", text: 'Payments'}
        ])
    }, []);

    return <Stack>
        Income Payments
    </Stack>;
};

export default IncomePaymentsPage;
