import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services

// props
import { IPaginationResourceShort } from '../../../../../props/general';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { ActionButton, IColumn, IconButton, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import { IVendorPICResourceShortProps } from '../../../../../props/data/vendors/pic';
import Text from './../../../../typography/text';
import VendorPICService from '../../../../../services/data/vendors/pics';
import { IVendorServiceResourceShortProps } from '../../../../../props/data/vendors/service';
import VendorServiceService from '../../../../../services/data/vendors/service';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import GeneralService from '../../../../../services/general';
import Tag from '../../../../uiframeworks/tag';
import VendorServiceForm from '../form/services';
import { IVendorApprovalHistoryResourceShortProps } from '../../../../../props/data/vendors/approvalHistory';
import VendorApprovalHistoryService from '../../../../../services/data/vendors/approalHistory';
import Timeline, { TimelineItemProps, TTimelineItemLine, TTimelineItemStatus } from '../../../../uiframeworks/timeline';
import moment from 'moment';

type VendorDetailsApprovalsProps = {
    vendor: IVendorResourceShortProps;
}

const VendorDetailsApprovals: React.FC<VendorDetailsApprovalsProps> = (props: VendorDetailsApprovalsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [histories, setHistories] = React.useState<IVendorApprovalHistoryResourceShortProps[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(false);

        const _histories = await VendorApprovalHistoryService.retrieve(props.vendor.id);
        setHistories(_histories);
        setLoaded(true);
    }

    const getTimelineItems = () => {
        const items: TimelineItemProps[] = [];

        items.push({
            key: 'created',
            status: 'completed',
            date: moment(props.vendor.createdAt).format("DD/MM/YYYY"),
            title: 'Vendor created',
            line: histories.length > 0 ? 'solid' : 'dashed'
        });

        if (histories.length < 1) {
            items.push({
                key: 'pendingApproval',
                status: 'infuture',
                title: 'Pending verification'
            });
        }

        (histories.forEach((history) => {
            let status: TTimelineItemStatus = 'completed';
            let title: string = '';

            if (history.action === 'reject') {
                status = 'cancelled';
                title = 'Vendor rejected by ' + history.createdBy.name;
            } else if (history.action === 'reset verification') {
                status = 'inprogress';
                title = 'Verification reset to pending by ' + history.createdBy.name;
            } else if (history.action === 'verify') {
                title = 'Vendor verified by ' + history.createdBy.name;
                status = 'completed';
            }

            items.push({
                key: history.id,
                status: status,
                date: moment(history.createdAt).format("DD/MM/YYYY"),
                description: (history.justification || "").trim() !== "" ? history.justification : undefined,
                title: title,
                line: 'solid'
            });
        }));

        return items;
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label={'Retrieving approval histories ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <Stack tokens={{childrenGap: 10}}>
            <Timeline items={getTimelineItems()} />
        </Stack> : null}
    </Stack>;
};

export default VendorDetailsApprovals;