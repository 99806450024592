import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { DefaultButton, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import ErrorService from '../../../../../services/general/error';
import NoAccess from '../../../../uiframeworks/noAccess';
import Text from '../../../../typography/text';
import { IVendorResourceProps } from '../../../../../props/data/vendors';
import VendorDocumentsForm from './documents';
import VendorDetailsForm from './details';
import VendorPICForm from './pic';
import Label from '../../../../typography/label';
import { IDocumentProps, IFileProps } from '../../../../../props/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ActivateVendorFormProps = {
    vendorId: string;
    onDismissed(refresh?: boolean, vendor?: IVendorResourceProps): void;
}

const ActivateVendorForm: React.FC<ActivateVendorFormProps> = (props: ActivateVendorFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [vendor, setVendor] = React.useState<IVendorResourceProps | undefined>();

    let hasPermission = PermissionsService.hasPermission(['vendors.delete.all'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _vendor = await VendorsService.get(props.vendorId);

        setVendor(_vendor);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            if (window.confirm("Are you sure to re-activate this vendor?")) {
                setSubmitting(true);
                await VendorsService.activate(props.vendorId);

                banner.add({
                    key: 'activate_vendor_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `Vendor "${vendor?.name}" is now verified`
                });
                props.onDismissed(true);
            }
        } catch (e) {
            banner.add({
                key: 'activate_vendor_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Re-activate Vendor"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Re-activate"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Re-activating vendor ..."} /> : null}
            </Stack>;
        }}>
        <Stack>
            {!loaded ? <Stack horizontalAlign={"baseline"} styles={{ root: { marginTop: 20 } }}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && vendor && hasPermission ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure you want to re-activate vendor with details below?</Text>
                        <Stack>
                            <Label size={'xsmall'}>Name</Label>
                            <Text size={'small'}>{vendor.name}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Address</Label>
                            <Text size={'small'}>{vendor.address || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Phone number</Label>
                            <Text size={'small'}>{vendor.phoneNumber || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>NPWP</Label>
                            <Text size={'small'}>{vendor.npwp || "-"}</Text>
                        </Stack>
                        <Stack className={'divider'} />
                        <Stack>
                            <Label size={'xsmall'}>Owner name</Label>
                            <Text size={'small'}>{vendor.ownerName || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Onwer ID number</Label>
                            <Text size={'small'}>{vendor.ownerIdentification || "-"}</Text>
                        </Stack>
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default ActivateVendorForm;
