import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import ExternalBankAccountService from '../../../../services/data/bankaccounts/external';

// props
import { IExternalBankAccountResourceShortProps } from '../../../../props/data/bankaccounts/external';

// components
import { Dropdown, Link, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import ExternalBankAccountForm from '../../../data/bankaccounts/external/form';
import { IBankAccountResourceShortProps } from '../../../../props/bankaccounts';
import BankAccountService from '../../../../services/bankaccounts';
import BankAccountForm from '../../../bankaccounts/general/form';

type SelectBankAccountProps = {
    vendorId?: string;
    employeeId?: string;
    subsidiaryId?: string;
    selected?: IBankAccountResourceShortProps;
    options?: IBankAccountResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    waiting?: boolean;
    onChange(selected?: IBankAccountResourceShortProps): void;
}

const SelectBankAccount: React.FC<SelectBankAccountProps> = (props: SelectBankAccountProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IBankAccountResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [waiting, setWaiting] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IBankAccountResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        init();
    }, [props.waiting]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (!props.waiting) {
            setLoaded(false);
            setWaiting(false);

            const qs: string[] = [];
            if (props.vendorId) { qs.push(`vendor_id=${props.vendorId}`) }
            if (props.employeeId) { qs.push(`employee_id=${props.employeeId}`) }
            if (props.subsidiaryId) { qs.push(`subsidiary_id=${props.subsidiaryId}`) }

            const results = await BankAccountService.retrieve(qs.join("&"));
            setOptions(results.data);
            setLoaded(true);
        } else {
            setLoaded(true);
            setWaiting(true);
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} styles={{root: {width: '100%'}}}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack styles={{root: {width: '100%'}}}>
                    <Dropdown disabled={props.disabled || !loaded || waiting}
                        selectedKey={selected?.id}
                        options={options.map((ba) => {
                            return { key: ba.id, text: `${ba.accountName} - ${ba.accountNumber} (${ba.bank.name})`, metadata: ba }
                        })}
                        styles={{
                            dropdownItem: {
                                paddingTop: "5px !important",
                                paddingBottom: "5px !important",
                                borderBottom: "1px solid #eee",
                                height: "unset !important"
                            },
                            dropdownItemSelected: {
                                height: "unset !important"
                            }
                        }}
                        onRenderOption={(option: any) => {
                            if (option) {
                                const data = option.metadata as IBankAccountResourceShortProps;
                                const bankDetails: string[] = [];
                                if ((data.bankCity || "").trim() !== "" && (data.bankCity !== "-")) {
                                    bankDetails.push(data.bankCity || "");
                                }
                                bankDetails.push(data.bank.country);

                                return <Stack>
                                    <Label size={'xsmall'}>{data.accountName} - {data.accountNumber}</Label>
                                    <Text style={{marginTop: -2}} size={'xsmall'}>{data.bank.name} ({bankDetails.join(", ")})</Text>
                                </Stack>
                            } else {
                                return null;
                            }
                        }}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
            {props.allowCreate && (props.vendorId || props.employeeId || props.subsidiaryId) ? <Text size={'small'}>Cannot find bank account? <Link onClick={() => { setActiveSurface('create') }}>Click here</Link> to create bank account</Text> : null}
        </Stack>
        {activeSurface === 'create' ? <BankAccountForm vendorId={props.vendorId} employeeId={props.employeeId} subsidiaryId={props.subsidiaryId} onDismissed={async (refresh, bankAccount) => {
            setActiveSurface(undefined);
            setLoaded(false);
            await init();
            props.onChange(bankAccount);
        }} type={props.vendorId ? 'vendor' : 'employee'} /> : null}
    </Stack>;
};

export default SelectBankAccount;
