import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { useStore } from '../../../../stores/root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faContainerStorage, faHashtag, faListCheck, faText, faUser } from '@fortawesome/pro-light-svg-icons';
import Tag, { TTagVariant } from '../../tag';
import { IOutcomeResourceShorterProps } from '../../../../props/finance/outcomes';
import GeneralService from '../../../../services/general';
import { IIncomeInvoiceResourceShortProps } from '../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../services/finance/incomes/invoices/invoice';
import OrderService from '../../../../services/orders/order';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';

type SelectIncomeInvoiceProps = {
    selected?: IIncomeInvoiceResourceShortProps;
    options?: IIncomeInvoiceResourceShortProps[];
    subsidiary?: ISubsidiariesResourceShort;
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    error?: string;
    onChange(selected?: IIncomeInvoiceResourceShortProps): void;
}

const SelectIncomeInvoice: React.FC<SelectIncomeInvoiceProps> = (props: SelectIncomeInvoiceProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [error, setError] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IIncomeInvoiceResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
    }, [props]);

    const _onSearchInvoice = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                qs.push(`top=10`);
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
                if (props.subsidiary) {qs.push(`subsidiary_id=${props.subsidiary.id}`)}

                const options = (await IncomeInvoiceService.retrieve(qs.join("&"))).data.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    const renderSelected = (selected: IIncomeInvoiceResourceShortProps) => {
        let totalAmount = selected.totalAmount;
        let invoiceAmount = selected.invoiceAmount;
        if (selected.order) {
            totalAmount = selected.order.totalAmount + "";
            invoiceAmount = selected.order.invoiceAmount;
        }

        return <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
                <Label size={'xsmall'}>#{IncomeInvoiceService.getInvoiceNumber(selected.id)}</Label>
                {selected.order ? <>
                    <Stack className={'divider'}> </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 25 } }}><FontAwesomeIcon icon={faContainerStorage} /></Stack>
                        <Text size={'xsmall'}>#{OrderService.getOrderNumber(selected.order.orderNumber)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 25 } }}><FontAwesomeIcon icon={faUser} /></Stack>
                        <Text size={'xsmall'}>{selected.order.company?.name} ({selected.order.customer.name})</Text>
                    </Stack>
                </> : null}
                <Stack className={'divider'}> </Stack>
                <Stack tokens={{ childrenGap: 3 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 60 } }}><Text size={'xsmall'}>Total price</Text></Stack>
                        <Text size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(Number(totalAmount))}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 60 } }}><Text size={'xsmall'}>Invoice price</Text></Stack>
                        <Text size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(Number(invoiceAmount))}</Text>
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 3 }}>
                    <Stack className={'divider'}> </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 60 } }}><Text size={'xsmall'}>Paid</Text></Stack>
                        <Text size={'xsmall'} className={'color-green'}>: Rp. {GeneralService.getNumberWithSeparator(Number(selected.paid))}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 60 } }}><Text size={'xsmall'}>Unpaid</Text></Stack>
                        <Text size={'xsmall'} className={'color-red'}>: Rp. {GeneralService.getNumberWithSeparator(Number(selected.unpaid))}</Text>
                    </Stack>
                </Stack>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack>
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Invoice"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const invoice: IIncomeInvoiceResourceShortProps = props.metadata;
                    let statusVariant: TTagVariant = 'active';

                    if (Number(invoice.unpaid) <= 0) { statusVariant = 'success'; }
                    else if (Number(invoice.unpaid) > 0) { statusVariant = 'error'; }

                    return <Stack tokens={{ childrenGap: 10 }} horizontalAlign={'baseline'} styles={{ root: { width: '100%', padding: 5 } }}>
                        <Stack styles={{ root: { width: '100%' } }} horizontalAlign={'baseline'}>
                            <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Label size={'small'}>#{IncomeInvoiceService.getInvoiceNumber(invoice.id)}</Label>
                                <Tag text={Number(invoice.unpaid) > 0 ? 'Unpaid' : 'Paid'} size={'small'} variant={statusVariant} />
                            </Stack>
                            <Text size={'small'}>(Rp. {GeneralService.getNumberWithSeparator(Number(invoice.totalAmount))})</Text>
                        </Stack>
                        <Stack className={'divider'}> </Stack>
                        {invoice.order ? <Stack tokens={{childrenGap: 7}}>
                            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faHashtag} size={'sm'} style={{ marginTop: -1, width: 15 }} />
                                <Text size={'xsmall'}>{OrderService.getOrderNumber(invoice.order.orderNumber)}</Text>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faUser} size={'sm'} style={{ marginTop: -1, width: 15 }} />
                                <Text size={'xsmall'}>{invoice.order.company?.name} ({invoice.order.customer.name})</Text>
                            </Stack>
                        </Stack> : null}
                        {!invoice.order ? <Stack tokens={{childrenGap: 7}}>
                            <Text size={'small'}>{invoice.name}</Text>
                            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faListCheck} size={'sm'} style={{ marginTop: -1, width: 15 }} />
                                <Text size={'xsmall'}>{(invoice.items || []).map((item) => item.name).join(", ")}</Text>
                            </Stack>
                        </Stack> : null}
                    </Stack>
                }}
                onResolveSuggestions={_onSearchInvoice}
                onEmptyResolveSuggestions={() => _onSearchInvoice('')}
            />
        </> : null}
        {selected ? renderSelected(selected) : null}
        {error ? <Text size={'xsmall'} className={'color-red'} style={{ marginTop: 5 }}>{error}</Text> : null}
    </Stack>;
};

export default SelectIncomeInvoice;
