import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../../stores/root';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props
import { IEmployeeContractResourceProps } from '../../../../../../props/employees/contracts/contract';

// components
import { ActionButton, CommandBar, IButtonProps, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import Details from '../../../../../typography/details';
import GeneralService from '../../../../../../services/general';
import Tag from '../../../../../uiframeworks/tag';

interface EmployeeContractDetailsPropertiesProps {
    contract: IEmployeeContractResourceProps;
}

const EmployeeContractDetailsProperties: React.FC<EmployeeContractDetailsPropertiesProps> = observer((props: EmployeeContractDetailsPropertiesProps) => {
    const { banner, topbar, user } = useStore();
    const { contract } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Contract number'} text={'#' + contract.id} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}> </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Employee'} text={contract.employee.name} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Subsidiary'} text={contract.subsidiary.name} />
            </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Start date'} text={moment(contract.startDate).format("DD/MM/YYYY")} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'End date'} text={moment(contract.endDate).format("DD/MM/YYYY")} />
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Base salary'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(contract.salary))}`} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}> </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Details title={'Jobdesks'}>
            <Stack tokens={{childrenGap: 5}} horizontal wrap styles={{root: {position: 'relative', top: 3}}}>
                {contract.jobdesks.map((jobdesk) => <Tag size={'small'} text={jobdesk.name} />)}
            </Stack>
        </Details>
    </Stack>;
});

export default EmployeeContractDetailsProperties;
