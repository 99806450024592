import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { ISalesRecapResourceShortProps, IUserResourceShortProps } from '../../../../../props/users/user';

// components
import { ActionButton, Dropdown, Stack } from '@fluentui/react';
import { IOrderResourceProps } from '../../../../../props/orders/order';
import OrderService from '../../../../../services/orders/order';
import LoadingComponent from '../../../../feedbacks/loading';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import EmployeePerformanceService from '../../../../../services/employees/performance';

interface AdministratorDetailsPerformanceRecapProps {
    userId: string;
}

const AdministratorDetailsPerformanceRecap: React.FC<AdministratorDetailsPerformanceRecapProps> = (props: AdministratorDetailsPerformanceRecapProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [performance, setPerformance] = React.useState<{}>();
    const [selectedGrouping, setSelectedGrouping] = React.useState<string>('created date');
    const [selectedDate, setSelectedDate] = React.useState<{text: string; date: string}>({
        text: 'this month',
        date: moment().startOf('month').toISOString()
    });

    const performanceService = new EmployeePerformanceService(props.userId);

    useEffect(() => {
        init();
    }, [selectedDate, selectedGrouping]);

    const init = async () => {
        try {
            setLoaded(false);

            const performance = await performanceService.monthly(selectedDate.date, selectedGrouping);
            
            
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_sales_recap_error',
                text: 'Failed to get sales recap. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{childrenGap: 5}}>
            <Text size={'small'}>Based on </Text>
            <Stack horizontal>
                <Label size={'xsmall'} className={loaded ? 'color-blue' : 'color-gray'}>{selectedGrouping}</Label>
                <ActionButton disabled={!loaded} menuProps={{items: [
                    {key: 'created date', text: 'created date', onClick: () => setSelectedGrouping('created date')},
                    {key: 'loading date', text: 'loading date', onClick: () => setSelectedGrouping('loading date')}
                ]}} styles={{root: {
                    height: "initial !important",
                    minHeight: "initial !important",
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                    position: "relative",
                    top: "-1px !important"
                }}} />
            </Stack>
            <Text size={'small'}> on </Text>
            <Stack horizontal>
                <Label size={'xsmall'} className={loaded ? 'color-blue' : 'color-gray'}>{selectedDate.text}</Label>
                <ActionButton disabled={!loaded} menuProps={{items: [
                    {key: 'this month', text: 'this month', onClick: () => {
                        setSelectedDate({
                            text: 'this month',
                            date: moment().toISOString()
                        });
                    }},
                    {key: 'last month', text: 'last month', onClick: () => {
                        setSelectedDate({
                            text: 'last month',
                            date: moment().add(-1, 'month').toISOString()
                        });
                    }}
                ]}} styles={{root: {
                    height: "initial !important",
                    minHeight: "initial !important",
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                    position: "relative",
                    top: "-1px !important"
                }}} />
            </Stack>
        </Stack>
        {!loaded ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <>
            {/*recap.newCustomers !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Text size={'small'}>New Customers</Text>
                <Label>{recap.newCustomers}</Label>
            </Stack> : null}
            {recap.newCompanies !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Text size={'small'}>New Companies</Text>
                <Label>{recap.newCompanies}</Label>
            </Stack> : null}
            {recap.totalProfitByStartDate !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Total Profit</Text>
                    <Text size={'xsmall'}>(by actual start date)</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalProfitByStartDate)}</Label>
            </Stack> : null}
            {recap.totalProfitByInvoiceDate !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Total Profit</Text>
                    <Text size={'xsmall'}>(by final invoice date)</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalProfitByInvoiceDate)}</Label>
            </Stack> : null}
            {recap.totalCommissions !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Commissions</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalCommissions)}</Label>
            </Stack> : null*/}
        </> : null}
    </Stack>;
};

export default AdministratorDetailsPerformanceRecap;
