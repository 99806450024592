import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import VendorsService from '../../../../../services/data/vendors';

// props
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import VendorForm from './../form';
import VendorShipsList from '../../ships/list';
import VendorTrucksList from '../../trucks/list';
import VendorTrainsList from '../../trains/list';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import VendorDocumentListComponent from '../../documents/list';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';
import ExternalBankAccountsList from '../../../../data/bankaccounts/external/list';
import VendorPICsList from './pic';
import BankAccountsList from '../../../../bankaccounts/general/list';
import InactivateVendorForm from '../form/inactivate';
import ActivateVendorForm from '../form/activate';
import VendorServiceForm from '../form/services/index';
import VendorDetailsServices from './services';
import VendorDetailsApprovals from './approvals';

interface IVendorDetailsProps {
    vendorId: string;
}

const VendorDetails: React.FC<IVendorDetailsProps> = (props: IVendorDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [vendor, setVendor] = React.useState<IVendorResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['vendors.read.all'], user.permissions)

    useEffect(() => {
        _onGetVendor();
    }, []);

    const _onGetVendor = async () => {
        try {
            setLoaded(false);

            const _vendor = await VendorsService.get(props.vendorId);
            setVendor(_vendor);

            topbar.show(_vendor.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "operationals", text: 'Operationals' },
                { key: "vendors", text: 'Vendors', href: "/operationals/vendors" },
                { key: props.vendorId, text: _vendor.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_vendor_error',
                text: 'Failed to get vendor details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['vendors.update.all'], user.permissions) && vendor?.active) {
            items.push({
                key: 'update',
                text: 'Update details',
                onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
            });
        }

        if (PermissionsService.hasPermission(['vendors.delete.all'], user.permissions) && vendor?.active) {
            farItems.push({
                key: 'inactivate',
                text: 'Set as inactive',
                onRender: () => <ActionButton text={'Set as inactive'} onClick={() => setActiveSurface('inactivate')} />
            });
        }

        if (PermissionsService.hasPermission(['vendors.delete.all'], user.permissions) && !vendor?.active) {
            items.push({
                key: 'activate',
                text: 'Re-activate',
                onRender: () => <PrimaryButton text={'Re-activate'} onClick={() => setActiveSurface('activate')} />
            });
        }

        return { items, farItems };
    }

    const getVendorPropertiesSection = (_vendor: IVendorResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'vendor_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_vendor.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_vendor.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Phone Number</Label>
                        <Text>{_vendor.phoneNumber || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Address</Label>
                        <Text>{_vendor.address || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getAssetsRecapSection = (_vendor: IVendorResourceShortProps): IStackSectionProps[] => {
        return [
            {
                header: {
                    title: "Approval Histories"
                },
                key: 'approval_histories',
                isCard: true,
                content: <VendorDetailsApprovals vendor={_vendor} />
            },
            {
                header: {
                    title: "Services",
                    buttons: [{ text: 'Add service', iconProps: { iconName: 'Add' }, onClick: () => setActiveSurface('services.add') }]
                },
                key: 'assets_recap',
                isCard: true,
                content: <VendorDetailsServices vendorId={_vendor.id} />
            }
        ]
    }

    const getRelatedItemsSection = (_vendor: IVendorResourceShortProps): IStackSectionProps => {
        const tabs: ISectionTabProps[] = [];

        if (PermissionsService.hasPermission(['ships.read.all'], user.permissions)) {
            tabs.push({
                key: 'related_ships',
                title: 'Related Ship(s)',
                content: <Stack>
                    <VendorShipsList vendorId={_vendor.id} />
                </Stack>
            });
        }

        if (PermissionsService.hasPermission(['trucks.read.all'], user.permissions)) {
            tabs.push({
                key: 'related_trucks',
                title: 'Related Truck(s)',
                content: <Stack>
                    <VendorTrucksList vendorId={_vendor.id} />
                </Stack>
            });
        }

        if (PermissionsService.hasPermission(['ships.read.all'], user.permissions)) {
            tabs.push({
                key: 'related_trains',
                title: 'Related Train(s)',
                content: <Stack>
                    <VendorTrainsList vendorId={_vendor.id} />
                </Stack>
            });
        }

        tabs.push({
            key: 'pics',
            title: 'PICs',
            content: <Stack>
                <VendorPICsList vendorId={_vendor.id} />
            </Stack>
        });

        tabs.push({
            key: 'bank_accounts',
            title: 'Bank Account(s)',
            content: <Stack>
                <BankAccountsList vendorId={_vendor.id} />
            </Stack>
        });

        tabs.push({
            key: 'vendor_documents',
            title: 'Document(s)',
            content: <Stack>
                <VendorDocumentListComponent vendorId={_vendor.id} />
            </Stack>
        });

        return {
            key: 'vendor_tabs',
            isCard: true,
            tabs
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetVendor() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving vendor details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && vendor && hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getVendorPropertiesSection(vendor)]} right={getAssetsRecapSection(vendor)} />
                <DetailsStack left={[getRelatedItemsSection(vendor)]} />
            </Stack>
            {activeSurface === 'update' ? <VendorForm vendorId={vendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'inactivate' ? <InactivateVendorForm vendorId={vendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'activate' ? <ActivateVendorForm vendorId={vendor.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'services.add' && vendor ? <VendorServiceForm vendorId={vendor.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default VendorDetails;
