import { IDataShipTypeResourceShortProps } from '../../../props/data/fleets/shipType';
import Request from './../../request';

// import props

const DataShipTypeService = {
    retrieve: async (qs?: string): Promise<IDataShipTypeResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/data/fleets/ship_types?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
};

export default DataShipTypeService;