import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import EmployeeContractDetails from '../../../components/hr/contracts/general/details';
import { useParams } from 'react-router-dom';
import EmployeePayslipDetails from '../../../components/hr/payslips/general/details';
import EmployeePayslipForm from '../../../components/hr/payslips/general/form';

interface HRPayslipFormPageProps {
}

const HRPayslipFormPage: React.FC<HRPayslipFormPageProps> = (props: HRPayslipFormPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Payslip", [
            {key: "home", icon: faHome, href: "/"},
            {key: "payslips", text: 'Payslips', href: "/hr/payslips"},
            {key: "create", text: 'Create payslip'}
        ]);
    }, []);

    return <Stack>
        <EmployeePayslipForm payslipId={params.payslipId} />
    </Stack>;
};

export default HRPayslipFormPage;
