import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Tag from '../../../../uiframeworks/tag';
import UploadFilesComponent from '../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../props/general';

type CancelEmployeeContractFormProps = {
    contractId: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

const CancelEmployeeContractForm: React.FC<CancelEmployeeContractFormProps> = (props: CancelEmployeeContractFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _contract = await EmployeeContractService.read(props.contractId);
        setContract(_contract);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await EmployeeContractService.cancel(props.contractId);

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'cancel_contract_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to cancel contract. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Cancel Contract"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Cancel"} onClick={_onSubmit} />
                            <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Cancelling contract ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && contract ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure to cancel this active contract?</Text>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default CancelEmployeeContractForm;
