import React, { useEffect, useState } from 'react';

// assets
import Logo from './../../../assets/logo/logo-dark.webp';

import styles from './style.module.scss';
import { IStyle, Stack } from '@fluentui/react';
import Label from './label';
import Text from './text';

export type DetailsProps = React.PropsWithChildren<{
    title?: string;
    text?: string;
    style?: IStyle;
    href?: string;
    target?: "_blank" | "self";
    className?: {
        root?: string;
        title?: string;
        text?: string;
    };
    onClick?(): void;
}>

const Details: React.FC<DetailsProps> = (props: DetailsProps) => {
    return <Stack styles={{root: props.style}}>
        {props.title ? <Label className={`${props.className?.title || ""} color-darkgray`} size={'xsmall'}>{props.title}</Label> : null}
        {props.text ? <Text className={props.className?.text || ""}>{props.text}</Text> : null}
        {props.children ? props.children : null}
    </Stack>
};

export default Details;
