import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Checkbox, DefaultButton, Panel, PanelType, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import Label from '../../typography/label';
import Text from '../../typography/text';
import LoadingComponent from '../../feedbacks/loading';

interface IFilterPanelProps {
    title?: string;
    selected: FilterOptionProps[];
    onRenderOptions(): Promise<FilterOptionProps[]>;
    onApply(selected: FilterOptionProps[]): void;
    onCancel(): void;
}

export type FilterOptionProps = {
    filterby: string;
    filterbyText: string;
    key: string;
    title: string;
    description?: string;
    group?: string;
    metadata?: any;
}

export type GroupedFilterOptionProps = {
    key?: string;
    options: FilterOptionProps[];
}

const FilterPanel: React.FC<IFilterPanelProps> = (props: IFilterPanelProps) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<FilterOptionProps[]>([]);
    const [selected, setSelected] = React.useState<FilterOptionProps[]>(props.selected);
    const [keyword, setKeyword] = React.useState<string>("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _options = await props.onRenderOptions();
        setOptions(_options);
        setLoaded(true);
    }

    const renderOption = (option: FilterOptionProps) => {
        const isSelected = selected.find((sel) => sel.key === option.key) !== undefined;

        return <Stack className={styles.option}>
            <Checkbox checked={isSelected}
                onRenderLabel={() => {
                    return <Stack className={styles.content}>
                        <Label size={'small'}>{option.title}</Label>
                        {option.description ? <Text size={'small'}>{option.description}</Text> : null}
                    </Stack>
                }}
                onChange={() => {
                    if (isSelected) {
                        setSelected([...selected.filter((sel) => sel.key !== option.key)]);
                    } else {
                        setSelected([...selected, option]);
                    }
                }} />
        </Stack>
    }

    const filterOptions = (options: FilterOptionProps[], keyword: string): FilterOptionProps[] => {
        const key = keyword.toLowerCase();
        const filtered = options.filter((option) => {
            return option.key.toLowerCase().indexOf(key) > -1 || 
                option.title.toLowerCase().indexOf(key) > -1 || 
                (option.description || "").toLowerCase().indexOf(key) > -1 ||
                (option.group || "").toLowerCase().indexOf(key) > -1;
        });

        return filtered;
    }

    const groupOptions = (options: FilterOptionProps[]): GroupedFilterOptionProps[] => {
        const grouped: GroupedFilterOptionProps[] = [];

        options.forEach((opt) => {
            const idx = grouped.findIndex((g) => g.key === opt.group);
            if (idx > -1) {
                grouped[idx].options.push(opt);
            } else {
                grouped.push({key: opt.group, options: [opt]});
            }
        });

        return grouped.sort((a, b) => ((a.key || "") > (b.key || "")) ? 1 : (((b.key || "") > (a.key || "")) ? -1 : 0));
    }

    const renderOptions = (options: FilterOptionProps[]) => {
        const filtered = filterOptions(options, keyword);
        const grouped = groupOptions(filtered);

        return <>
            {filtered.length > 0 && grouped.length > 0 ? <Stack>
                {grouped.map((group, idx) => {
                    return <Stack tokens={{childrenGap: 10}}>
                        {group.key && group.key.trim() !== "" ? <Label size={'small'} className={'color-primary'} style={{marginTop: idx === 0 ? 0 : 10}}>{group.key}</Label> : null}
                        {group.options.map(renderOption)}
                    </Stack>
                })}
            </Stack> : null}
            {filtered.length < 1 ? <Text>No options found</Text> : null}
        </>
    }

    return <Panel headerText={props.title || "Filter"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onCancel()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={'Apply'} onClick={() => props.onApply(selected)} />
                <DefaultButton text={'Cancel'} onClick={() => props.onCancel()} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            {!loaded ? <LoadingComponent label='Retrieving filter ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <SearchBox value={keyword} placeholder='Search ...' onChange={(evt, val) => setKeyword(val || "")} />
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack className={styles.option}>
                        {options.length > 0 ? <Checkbox
                            onRenderLabel={() => {
                                return <Stack className={styles.content}>
                                    <Label size={'small'}>Select All</Label>
                                </Stack>
                            }}
                            checked={options.find((opt) => {
                                const isSelected = selected.find((sel) => sel.key === opt.key) !== undefined;
                                return !isSelected;
                            }) === undefined}
                            onChange={(ev?: any, checked?: boolean) => {
                                if (checked) {
                                    setSelected([...selected.filter((sel) => {
                                        const exist = options.find((opt) => opt.key === sel.key && opt.filterby === sel.filterby);
                                        return !exist;
                                    }), ...options]);
                                } else {
                                    setSelected(selected.filter((sel) => {
                                        const exist = options.find((opt) => opt.key === sel.key && opt.filterby === sel.filterby);
                                        return !exist;
                                    }));
                                }
                            }} /> : null}
                    </Stack>
                    {renderOptions(options)}
                </Stack>
            </> : null}
        </Stack>
    </Panel>
};

export default FilterPanel;
