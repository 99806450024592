import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Tag from '../../../../uiframeworks/tag';
import UploadFilesComponent from '../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../props/general';

type EmployeeContractResetApprovalFormProps = {
    contractId: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

const EmployeeContractResetApprovalForm: React.FC<EmployeeContractResetApprovalFormProps> = (props: EmployeeContractResetApprovalFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<IFileDetailsProps[]>([]);
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _contract = await EmployeeContractService.read(props.contractId);
        setContract(_contract);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await EmployeeContractService.approval.reset(props.contractId);

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'reset_contract_approval_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to reset contract approval. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Reset Contract Approval"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Reset"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Resetting contract approval ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && contract ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text className={'color-red'}>!!! WARNING: YOU ARE GOING TO RESET THIS CONTRACT APPROVAL. ALL APPROVAL THAT HAS BEEN MADE WILL BE RESET AND CONTRACT STATUS WILL BE RESET TO PENDING APPROVAL. THIS STEP CANNOT BE UNDO.</Text>
                        <Text>Are you sure to reset this contract approval?</Text>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployeeContractResetApprovalForm;
