import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props
import { IEmployeeContractCommissionResourceShortProps } from '../../../../../../props/employees/contracts/commission';

// components
import { DefaultButton, IconButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';

type EmployeeContractCommissionFormProps = {
    value: IEmployeeContractCommissionResourceShortProps[];
    onChange(commissions: IEmployeeContractCommissionResourceShortProps[]): void;
}

const EmployeeContractCommissionForm: React.FC<EmployeeContractCommissionFormProps> = (props: EmployeeContractCommissionFormProps) => {
    const [commissions, setCommissions] = React.useState<IEmployeeContractCommissionResourceShortProps[]>(props.value);
    const [surfaceMode, setSurfaceMode] = React.useState<string | undefined>();
    const [activeCommission, setActiveCommission] = React.useState<IEmployeeContractCommissionResourceShortProps | undefined>();

    useEffect(() => {
        setCommissions(props.value);
    }, [props.value]);

    return <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
            <Label required={true} size={'small'}>Commissions Percentage</Label>
            <PrimaryButton text={'Add commission'} iconProps={{ iconName: "Add" }} onClick={() => {
                setActiveCommission({
                    id: GeneralService.guid(),
                    commissionBase: 'profit',
                    startAmount: '0',
                    endAmount: '',
                    commissionType: 'percentage',
                    commissionPercentage: ''
                })
            }} />
        </Stack>
        {commissions.length < 1 ? <Text size={'small'} className='color-red'>This field is required</Text> : null}
        {commissions.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
            {commissions.map((commission) => {
                return <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack grow={1}>
                            <Tag>
                                <Stack tokens={{ childrenGap: 3 }}>
                                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(commission.startAmount)} - Rp. {GeneralService.getNumberWithSeparator(commission.endAmount)}</Text>
                                    <Text size={'xsmall'}>Commission: {GeneralService.getNumberWithSeparator(commission.commissionPercentage)}%</Text>
                                </Stack>
                            </Tag>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                                setActiveCommission(commission);
                                setSurfaceMode('update');
                            }} />
                            <IconButton iconProps={{ iconName: "Delete" }} />
                        </Stack>
                    </Stack>
                </>
            })}
        </Stack> : null}
        <Panel headerText={activeCommission ? "Add Commission" : "Update Commission"}
            isOpen={activeCommission !== undefined}
            type={PanelType.medium}
            onDismiss={() => {
                setSurfaceMode(undefined);
                setActiveCommission(undefined);
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={surfaceMode === 'add' ? "Add" : "Update"}
                        disabled={(activeCommission?.startAmount || "") === "" || (activeCommission?.endAmount || "") === '' || (activeCommission?.commissionPercentage || "") === ''}
                        onClick={() => {
                            if (activeCommission) {
                                const _commissions = commissions;
                                const idx = _commissions.findIndex((j) => j.id === activeCommission.id);

                                if (idx > -1) {
                                    _commissions[idx] = activeCommission;
                                } else {
                                    _commissions.push(activeCommission);
                                }

                                setCommissions(_commissions);
                                setActiveCommission(undefined);
                            }
                        }} />
                    <DefaultButton text={"Cancel"} onClick={() => {
                        setSurfaceMode(undefined);
                        setActiveCommission(undefined);
                    }} />
                </Stack>
            }}>
            {activeCommission ? <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <TextField label={"Profit (start from)"}
                                required
                                prefix={'Rp'}
                                onChange={(evt, value) => {
                                    if (activeCommission && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                        setActiveCommission({ ...activeCommission, startAmount: value || "" });
                                    }
                                }}
                                value={activeCommission.startAmount} />
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <TextField label={"Profit (end from)"}
                                required
                                prefix={'Rp'}
                                onChange={(evt, value) => {
                                    if (activeCommission && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                        setActiveCommission({ ...activeCommission, endAmount: value || "" });
                                    }
                                }}
                                value={activeCommission.endAmount || ""} />
                        </Stack>
                    </Stack>
                    <TextField label={"Commission percentage"}
                        required
                        suffix={'%'}
                        onChange={(evt, value) => {
                            if (activeCommission && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                setActiveCommission({ ...activeCommission, commissionPercentage: value || "" });
                            }
                        }}
                        value={activeCommission.commissionPercentage || ""} />
                </Stack>
            </> : null}
        </Panel>
    </Stack>
};

export default EmployeeContractCommissionForm;
