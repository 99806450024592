import Request from './../../request';

// import props
import { IVendorServiceResourceProps, IVendorServiceResourceShortProps } from '../../../props/data/vendors/service';
import { IPaginationResourceShort } from '../../../props/general';

const VendorServiceService = {
  retrieve: async (qs?: string): Promise<{ data: IVendorServiceResourceShortProps[]; pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/data/vendors/services?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IVendorServiceResourceProps> => {
    try {
      const result = await Request.post(`/api/data/vendors/services`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IVendorServiceResourceProps> => {
    try {
      const result = await Request.post(`/api/data/vendors/services/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  read: async (id: string): Promise<IVendorServiceResourceProps> => {
    try {
      const result = await Request.get(`/api/data/vendors/services/${id}`);
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/vendors/services/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export default VendorServiceService;
