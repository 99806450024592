import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Tag from '../../../../uiframeworks/tag';
import UploadFilesComponent from '../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../props/general';

type EmployeeContractCompanySignFormProps = {
    contractId: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

const EmployeeContractCompanySignForm: React.FC<EmployeeContractCompanySignFormProps> = (props: EmployeeContractCompanySignFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<IFileDetailsProps[]>([]);
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _contract = await EmployeeContractService.read(props.contractId);
        setContract(_contract);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            
            const fd = new FormData();
            files.map(f => {
                const file = f.data as File
                const allMetaData = {
                    name: file.name,
                    size: file.size,
                }
                fd.append('metadatas[]', JSON.stringify({
                    visibility: f.visibility,
                    type: f.type,
                    ...allMetaData
                }));
            });
            files.map(f => fd.append('documents[]', f.data));

            await EmployeeContractService.sign.company(props.contractId, fd);

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'sign_contract_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to sign contract. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Sign by Company Representative"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Sign"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Signing contract ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && contract ? <>
                    <Stack tokens={{ childrenGap: 40 }}>
                        <Text className={'color-red'}>!!! WARNING: YOU ARE GOING TO SIGN THIS CONTRACT ON BEHALF OF THIS COMPANY. ONCE SIGNED, CONTRACT WILL BE ACTIVE. MAKE SURE ALL COMPONENTS IN THIS CONTRACT ARE CORRECT.</Text>
                        <Stack tokens={{ childrenGap: 20 }}>
                            <Text>Please attach physical contract document that has been signed by both parties and all additional documents that support this contract (if any) here.</Text>
                            <UploadFilesComponent files={files}
                                multiple
                                documentTypes={[
                                    { key: 'Working Contract', text: 'Working Contract' },
                                    { key: 'Supporting Documents', text: 'Supporting Documents' }
                                ]} onUpload={(files) => {
                                    setFiles(files);
                                }} />
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployeeContractCompanySignForm;
