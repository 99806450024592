import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { SpinnerSize, Stack, Tooltip } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import { IOutcomeResourceShorterProps, IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import moment from 'moment';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Bar, BarChart } from 'recharts';
import OutcomeInvoiceSummaryChart from './summary';
import OutcomeInvoicesSummaryFilter, { SelectedFilterProps } from './filters';
import OutcomeInvoiceListChart from './list';
import OutcomeInvoiceGroupingChart from './grouping';

type OutcomeInvoiceChartProps = {
    invoices: IOutcomeResourceShortProps[];
    groupBy: string;
}

const OutcomeInvoiceChart: React.FC<OutcomeInvoiceChartProps> = (props: OutcomeInvoiceChartProps) => {
    const { banner } = useStore();
    const [invoices, setInvoices] = React.useState<IOutcomeResourceShortProps[]>(props.invoices);
    const [filteredInvoices, setFilteredInvoices] = React.useState<IOutcomeResourceShortProps[]>(props.invoices);
    const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilterProps>({});
    const [groupBy, setGroupBy] = React.useState<string>(props.groupBy);

    useEffect(() => {
        setInvoices(props.invoices);
        setGroupBy(groupBy);
        _onFilterChanged(selectedFilters)
    }, [props.invoices, props.groupBy]);

    const _onFilterChanged = (selectedFilter: SelectedFilterProps) => {
        let _invoices = props.invoices;

        if (selectedFilter.vendor && selectedFilter.vendor.length > 0) {
            const selectedIds = selectedFilter.vendor.map((vendor) => vendor.id);
            _invoices = _invoices.filter((invoice) => selectedIds.indexOf(invoice.vendor?.id || "") > -1);
        }

        if (selectedFilter.subsidiary && selectedFilter.subsidiary.length > 0) {
            const selectedIds = selectedFilter.subsidiary.map((subsidiary) => subsidiary.id);
            _invoices = _invoices.filter((invoice) => selectedIds.indexOf(invoice.subsidiary?.id || "") > -1);
        }

        if (selectedFilter.category && selectedFilter.category.length > 0) {
            const selectedIds = selectedFilter.category.map((category) => category.id);
            _invoices = _invoices.filter((invoice) => selectedIds.indexOf(invoice.category?.id || "") > -1);
        }

        if (selectedFilter.transferFrom && selectedFilter.transferFrom.length > 0) {
            const selectedIds = selectedFilter.transferFrom.map((account) => account.id);
            _invoices = _invoices.filter((invoice) => selectedIds.indexOf(invoice.bankAccount?.id || "") > -1);
        }

        if (selectedFilter.paymentStatus && selectedFilter.paymentStatus.length > 0) {
            _invoices = _invoices.filter((invoice) => {
                let related = false;
                if ((selectedFilter.paymentStatus || []).indexOf('paid') > -1 && Number(invoice.unpaid) < 0) {
                    related = true;
                }
                if ((selectedFilter.paymentStatus || []).indexOf('unpaid') > -1 && Number(invoice.unpaid) > 0) {
                    related = true;
                }
                if ((selectedFilter.paymentStatus || []).indexOf('pending') > -1 && Number(invoice.pendingPayment) > 0) {
                    related = true;
                }
                return related;
            });
        }

        setFilteredInvoices([..._invoices]);
        setSelectedFilters(selectedFilter);
    }

    return <Stack className={styles.parent} horizontal tokens={{ childrenGap: 20 }} verticalAlign={'start'}>
        {invoices.length > 0 ? <>
            <Stack styles={{ root: { maxWidth: 175 } }}>
                <OutcomeInvoicesSummaryFilter invoices={invoices} onChange={_onFilterChanged} />
            </Stack>
            <Stack grow={1} tokens={{childrenGap: 20}}>
                <Stack className={styles.card}>
                    <OutcomeInvoiceSummaryChart invoices={filteredInvoices} groupBy={groupBy} />
                </Stack>
                <Stack className={`${styles.card}`}>
                    <OutcomeInvoiceListChart invoices={filteredInvoices} groupBy={groupBy} />
                </Stack>
            </Stack>
            <Stack styles={{root: {width: 275}}}>
                <OutcomeInvoiceGroupingChart invoices={filteredInvoices} />
            </Stack>
        </> : null}
        {invoices.length < 1 ? <Text style={{ fontStyle: 'italic' }}>No data found. Please select different time period.</Text> : null}
    </Stack>
};

export default OutcomeInvoiceChart;
