import moment from "moment";
import { IOrderResourceShortProps } from "../../props/orders/order";
import { IUserResourceShortProps } from "../../props/users/user";
import OrderService from "../orders/order";
import { IEmployeeContractResourceProps } from "../../props/employees/contracts/contract";
import EmployeeContractService from "./contracts";
import { IOrderCommissionResourceShortProps } from "../../props/orders/commissions";
import { IEmployeePayslipCommissionResourceShortProps } from "../../props/employees/payslips/commision";
import GeneralService from "../general";
import { IOrderSalesResourceShortProps } from "../../props/orders/sales";
import { IEmployeeContractCommissionResourceShortProps } from "../../props/employees/contracts/commission";

type TGroupedOrder = {
    key: string,
    orders: {
        data: IOrderResourceShortProps; 
        commission?: IOrderCommissionResourceShortProps;
        sales: IOrderSalesResourceShortProps;
        message?: string;
    }[];
    contract?: IEmployeeContractResourceProps;
    relatedOrders: IOrderResourceShortProps[];
}

const helpers = {
    getActiveContract: async (sales: IUserResourceShortProps, month: string) => {
        if (month !== "" && month !== "-") {
            const qs: string[] = [];
            qs.push(`employee_id=${sales.id}`);
            qs.push(`effective_date=${moment(month, 'YYYY/MM').endOf('month').add('day', -1).toISOString()}`);

            const contract = await EmployeeContractService.active(qs.join('&'));
            return contract;
        }

        return undefined;
    },
    getRelatedOrders: async (sales: IUserResourceShortProps, filter: {loadingMonth?: string}) => {
        if (filter.loadingMonth && filter.loadingMonth !== "" && filter.loadingMonth !== "-") {
            const startDate = moment(filter.loadingMonth, 'YYYY/MM').startOf('month').toISOString();
            const endDate = moment(filter.loadingMonth, 'YYYY/MM').endOf('month').toISOString();

            const qs: string[] = [];
            qs.push(`top=all`);
            qs.push(`sales=${sales.id}`);
            qs.push(`start_actual_departure_date=${startDate}`);
            qs.push(`end_actual_departure_date=${endDate}`);

            const orders = await OrderService.retrieve(qs.join('&'));
            return orders.data;
        }

        return [];
    },
    getCommissionAmount: (employee: IUserResourceShortProps, orders: IOrderResourceShortProps[], paidCommissions: IEmployeePayslipCommissionResourceShortProps[], percentages: IEmployeeContractCommissionResourceShortProps[]): IEmployeePayslipCommissionResourceShortProps[] => {        
        // Sort percentage array by startAmount for consistent calculations
        const sortedPercentage = percentages.sort((a, b) => Number(a.startAmount) - Number(b.startAmount));

        const commissions: IEmployeePayslipCommissionResourceShortProps[] = []; // Store commission for each order
        let cumulativeProfit = paidCommissions.reduce((sum, entry) => sum + Number(entry.originalProfit || "0"), 0);

        // Iterate through orders using a for loop
        orders.forEach(order => {
            const sales = (order.sales || []).find((s) => s.sales.id === employee.id);
            if (!sales) {return;}

            const warnings = [];

            let remainingProfit = Number(order.nettProfit);
            let orderCommission = 0;

            sortedPercentage.forEach(percentage => {
                const { startAmount, endAmount, commissionPercentage } = percentage;

                // Determine the range's applicable amount
                const rangeStart: number = Math.max(Number(startAmount), cumulativeProfit);
                const rangeEnd = endAmount !== null ? Math.min(Number(endAmount), cumulativeProfit + remainingProfit) : cumulativeProfit + remainingProfit;

                if (rangeEnd > rangeStart) {
                    const applicableAmount = rangeEnd - rangeStart;
                    orderCommission += applicableAmount * (Number(commissionPercentage) / 100);
                    remainingProfit -= applicableAmount;

                    // Dynamically update cumulativeProfit *during* range calculations
                    cumulativeProfit += applicableAmount; // Update cumulative profit dynamically

                    if (remainingProfit <= 0) return; // Stop if all profit for the order is processed
                }
            });

            if (order.status.key === 'cancelled') {
                warnings.push("Order is cancelled");
            } else if (order.status.key !== 'completed') {
                warnings.push("Order is not yet completed");
            }

            // Add the commission for this order to the result array
            commissions.push({
                id: GeneralService.guid(),
                amount: orderCommission.toFixed(2),
                order: order,
                orderId: order.id,
                orderSalesId: sales.id,
                sales: sales,
                warnings,
                originalProfit: order.nettProfit
            });
        });

        return commissions;
    }
}

const groupOrders = {
    byActualDepartureDate: async (sales: IUserResourceShortProps, orders: IOrderResourceShortProps[]) => {
        let grouped: TGroupedOrder[] = [];

        orders.forEach((order) => {
            const departureDate = order.actualDeparture ? moment(order.actualDeparture).format("YYYY/MM") : "-";
            const orderSales = (order.sales || []).find((s) => s.sales.id === sales?.id);

            const idx = grouped.findIndex((g) => g.key === departureDate);
            if (idx > -1 && orderSales) {
                grouped[idx].orders.push({data: order, sales: orderSales});
            } else if (orderSales) {
                grouped.push({ key: departureDate, orders: [{data: order, sales: orderSales}], relatedOrders: [] });
            }
        });

        grouped = await Promise.all(grouped.map(async (group) => {
            const [contract, relatedOrders] = await Promise.all([
                await helpers.getActiveContract(sales, group.key),
                await helpers.getRelatedOrders(sales, {loadingMonth: group.key})
            ]);

            group.contract = contract;
            group.relatedOrders = relatedOrders;

            return group;
        }));

        grouped = grouped.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

        return grouped;
    }
}

const EmployeeCommissionService = {
    retrieve: {
        byPaymentDate: async (startDate: string, endDate: string, employee: IUserResourceShortProps) => {
            const qs: string[] = [];
            qs.push(`top=all`);
            qs.push(`status=active`);
            if (employee) { qs.push(`sales=${employee.id}`); }
            qs.push(`start_final_payment_date=${startDate}`);
            qs.push(`end_final_payment_date=${endDate}`);

            const paidOrders = ((await OrderService.retrieve(qs.join("&"))).data).filter((order) => {
                const sales = (order.sales || []).find((s) => s.sales.id === employee.id);
                return sales && !sales.commission;
            });
            const groupedOrders = await groupOrders.byActualDepartureDate(employee, paidOrders);
            const commissions = EmployeeCommissionService.generate(groupedOrders);

            return commissions;
        },
        byExistingCommissions: async (existings: IEmployeePayslipCommissionResourceShortProps[], sales: IUserResourceShortProps) => {
            const paidOrders: IOrderResourceShortProps[] = existings.map((commission) => commission.order);
            const groupedOrders = await groupOrders.byActualDepartureDate(sales, paidOrders);
            const commissions = EmployeeCommissionService.generate(groupedOrders);

            return commissions;
        }
    },
    generate: (groups: TGroupedOrder[]) => {
        const commissions: IEmployeePayslipCommissionResourceShortProps[] = [];
        
        groups.forEach((group) => {
            const employee = group.orders[0].sales.sales;

            if (group.contract) {
                const orderIds = group.orders.map((o) => o.data.id);
                const paidCommissions: IEmployeePayslipCommissionResourceShortProps[] = [];

                group.relatedOrders.forEach((order) => {
                    const sales = (order.sales || []).find((s) => s.sales.id === employee.id);
                    if (orderIds.indexOf(order.id) < 0 && sales?.commission) {
                        paidCommissions.push(sales.commission);
                    }
                });

                const _commissions = helpers.getCommissionAmount(employee, group.orders.map((o) => o.data), paidCommissions, group.contract.commissions || []);
                _commissions.forEach((c) => commissions.push(c));
            } else {
                group.orders.forEach((order) => {
                    const warnings: string[] = [];
                    if (!order.data.actualDeparture) {
                        warnings.push("No actual departure date recorded");
                    } else if (order.data.actualDeparture) {
                        warnings.push("No contract found during this period");
                    }

                    if (order.data.status.key === 'cancelled') {
                        warnings.push("Order is cancelled");
                    } else if (order.data.status.key !== 'completed') {
                        warnings.push("Order is not yet completed");
                    }
                    
                    commissions.push({
                        id: GeneralService.guid(),
                        amount: "0",
                        order: order.data,
                        orderId: order.data.id,
                        orderSalesId: order.sales.id,
                        sales: order.sales,
                        warnings,
                        originalProfit: order.data.nettProfit
                    });
                });
                /*const _commissions = helpers.getCommissionAmount(group.orders[0].sales, group.orders.map((o) => o.data), group.relatedOrders, [
                    {startAmount: "0", endAmount: "6000000", commissionBase: 'profit', commissionPercentage: '20', commissionType: 'percentage', id: GeneralService.guid()},
                    {startAmount: "6000000", endAmount: "9000000", commissionBase: 'profit', commissionPercentage: '22', commissionType: 'percentage', id: GeneralService.guid()},
                    {startAmount: "9000000", endAmount: "12000000", commissionBase: 'profit', commissionPercentage: '23', commissionType: 'percentage', id: GeneralService.guid()},
                    {startAmount: "12000000", endAmount: "15000000", commissionBase: 'profit', commissionPercentage: '24', commissionType: 'percentage', id: GeneralService.guid()},
                    {startAmount: "15000000", endAmount: "999999999", commissionBase: 'profit', commissionPercentage: '25', commissionType: 'percentage', id: GeneralService.guid()}
                ]);
                _commissions.forEach((c) => commissions.push(c));*/
            }
        });

        return commissions;
    }
};

export default EmployeeCommissionService;