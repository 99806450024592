import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import PermissionsService from '../../../../../services/permissions';
import ErrorService from '../../../../../services/general/error';
import EmployeeContractService from '../../../../../services/employees/contracts';

// props
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';

// components
import { ActionButton, CommandBar, DefaultButton, IButtonProps, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import EmployeeContractForm from '../form';
import NoAccess from '../../../../uiframeworks/noAccess';
import EmployeeContractDetailsProperties from './subdetails/properties';
import EmployeeContractDetailsApproval from './subdetails/approval';
import EmployeeContractHRApprovalForm from '../form/hrApproval';
import EmployeeContractLegalApprovalForm from '../form/legalApproval';
import EmployeeContractEmployeeSignForm from '../form/employeeSign';
import EmployeeContractCompanySignForm from '../form/companySign';
import EmployeeContractResetApprovalForm from '../form/resetApproval';
import EmployeeContractDetailsCommissions from './subdetails/commissions';
import CancelEmployeeContractForm from '../form/cancel';
import TerminateEmployeeContractForm from '../form/terminate';

interface EmployeeContractDetailsProps {
    contractId: string;
}

const EmployeeContractDetails: React.FC<EmployeeContractDetailsProps> = observer((props: EmployeeContractDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [hasPermission, setHasPermission] = React.useState<boolean>(false);

    useEffect(() => {
        _onGetContract();
    }, []);

    const _onGetContract = async () => {
        try {
            setLoaded(false);

            const _contract = await EmployeeContractService.read(props.contractId);
            setContract(_contract);

            const _hasPermission = PermissionsService.hasPermission(['employees.contracts.read.all'], user.permissions) || _contract.employee.id === user.data?.id;
            setHasPermission(_hasPermission);

            topbar.show("Contract #" + _contract.id, [
                { key: "", icon: faHome, href: "/" },
                { key: 'employees', text: 'Employees' },
                { key: 'contracts', text: 'Contracts' },
                { key: props.contractId, text: "#" + _contract.id }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_order_error',
                text: 'Failed to get order details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (['Cancelled', 'Terminated'].indexOf(contract?.status || "") < 0) {
            // make sure it is no longer updateable after approved by HR and Legal
            if (!contract?.approvedByHrAt || !contract?.approvedByLegalAt) {
                if (PermissionsService.hasPermission(['employees.contracts.update.all'], user.permissions)) {
                    items.push({
                        key: 'update',
                        text: 'Update details',
                        onRender: () => <PrimaryButton text={'Update details'} onClick={() => setActiveSurface('contract.update')} styles={{ root: { marginRight: 10 } }} />
                    });
                }
            }

            // if need hr approval
            if (!contract?.approvedByHrAt && PermissionsService.hasPermission(['employees.contracts.approve.hr'], user.permissions)) {
                items.push({
                    key: 'approveAsHR',
                    text: 'Approve as HR representative',
                    onRender: () => <PrimaryButton text={'Approve as HR representative'} onClick={() => setActiveSurface('contract.approval.hr')} styles={{ root: { marginRight: 10 } }} />
                });
            }

            // if need legal approval
            if (!contract?.approvedByLegalAt && contract?.approvedByHrAt && PermissionsService.hasPermission(['employees.contracts.approve.legal'], user.permissions)) {
                items.push({
                    key: 'approveAsLegal',
                    text: 'Approve as legal representative',
                    onRender: () => <PrimaryButton text={'Approve as legal representative'} onClick={() => setActiveSurface('contract.approval.legal')} styles={{ root: { marginRight: 10 } }} />
                });
            }

            // if need to be signed by employee
            if (!contract?.signedByEmployeeAt && contract?.approvedByHrAt && contract?.approvedByLegalAt && contract?.employee.id === user.data?.id) {
                items.push({
                    key: 'signByEmployee',
                    text: 'Sign this contract',
                    onRender: () => <PrimaryButton text={'Sign this contract'} onClick={() => setActiveSurface('contract.sign.employee')} styles={{ root: { marginRight: 10 } }} />
                });
            }

            // if need to be signed by company representative
            if (!contract?.signedByBodAt && contract?.approvedByHrAt && contract.approvedByLegalAt && contract.signedByEmployeeAt && PermissionsService.hasPermission(['employees.contracts.sign.bod'], user.permissions)) {
                items.push({
                    key: 'signByBOD',
                    text: 'Sign as company representative',
                    onRender: () => <PrimaryButton text={'Sign as company representative'} onClick={() => setActiveSurface('contract.sign.company')} styles={{ root: { marginRight: 10 } }} />
                });
            }

            if (contract?.status === 'Pending Approval' && PermissionsService.hasPermission(['employees.contracts.approval.reset'], user.permissions)) {
                farItems.push({
                    key: 'resetApproval',
                    text: 'Reset approval',
                    onRender: () => <ActionButton text={'Reset approval'} iconProps={{ iconName: "History" }} onClick={() => setActiveSurface('contract.approval.reset')} />
                });
            }
        }

        if (contract?.status !== 'Cancelled' && contract?.status !== 'Expired' && PermissionsService.hasPermission(['employees.contracts.cancel.all'], user.permissions)) {
            farItems.push({
                key: 'cancel',
                text: 'Cancel contract',
                onRender: () => <ActionButton text={'Cancel contract'} iconProps={{ iconName: "Xmark" }} onClick={() => setActiveSurface('contract.cancel')} />
            });
        }

        if (contract?.status === 'Active') {    
            if (PermissionsService.hasPermission(['employees.contracts.terminate.all'], user.permissions)) {
                items.push({
                    key: 'expired',
                    text: 'Terminate contract',
                    onRender: () => <DefaultButton text={'Terminate contract'} iconProps={{ iconName: "Xmark" }} onClick={() => setActiveSurface('contract.terminate')} />
                });
            }
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetContract() }
    }

    const leftSections = (_contract: IEmployeeContractResourceProps): IStackSectionProps[] => {
        return [
            {
                key: 'properties',
                header: { title: 'Contract Details' },
                isCard: true,
                content: <EmployeeContractDetailsProperties contract={_contract} />
            },
            {
                key: 'secondrow',
                subsections: [
                    {
                        key: 'commissions',
                        width: '50%',
                        header: { title: 'Commissions Breakdown' },
                        isCard: true,
                        content: <EmployeeContractDetailsCommissions contract={_contract} />
                    },
                    {
                        key: 'placeholder'
                    }
                ]
            }
        ];
    }

    const rightSections = (_contract: IEmployeeContractResourceProps): IStackSectionProps[] => {
        return [
            {
                key: 'approval',
                header: { title: 'Approval Flow' },
                isCard: true, content: <EmployeeContractDetailsApproval contract={_contract} />
            }
        ];
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving contract details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && hasPermission && contract ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={leftSections(contract)} right={rightSections(contract)} />
            </Stack>
            {activeSurface === 'contract.update' ? <EmployeeContractForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.cancel' ? <CancelEmployeeContractForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.terminate' ? <TerminateEmployeeContractForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.approval.hr' ? <EmployeeContractHRApprovalForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.approval.legal' ? <EmployeeContractLegalApprovalForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.sign.employee' ? <EmployeeContractEmployeeSignForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.sign.company' ? <EmployeeContractCompanySignForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'contract.approval.reset' ? <EmployeeContractResetApprovalForm contractId={contract.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {loaded && !hasPermission ? <NoAccess /> : null}
    </Stack>;
});

export default EmployeeContractDetails;
