import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import ValidationService from '../../../../../services/validation';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import ExternalBankAccountService from '../../../../../services/data/bankaccounts/external';
import SelectBank from '../../../../uiframeworks/forms/selectBank';
import { IExternalBankAccountResourceProps } from '../../../../../props/data/bankaccounts/external';
import VendorsService from '../../../../../services/data/vendors';
import UserService from '../../../../../services/users/user';
import VendorField from '../../../../uiframeworks/forms/vendor';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import { IBankAccountResourceProps, IBankAccountResourceShortProps } from '../../../../../props/bankaccounts';
import BankAccountService from '../../../../../services/bankaccounts';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import { IBankAccountCutoffResourceProps } from '../../../../../props/bankaccounts/cutoff';
import BankAccountCutoffService from '../../../../../services/bankaccounts/cutoff';
import moment from 'moment';
import SelectBankAccount from '../../../../uiframeworks/forms/bankaccounts';
import GeneralService from '../../../../../services/general';

type BankAccountCutoffFormProps = {
    id?: string;
    bankAccountId: string;
    onDismissed(refresh?: boolean, item?: IBankAccountCutoffResourceProps): void;
}

const BankAccountCutoffForm: React.FC<BankAccountCutoffFormProps> = (props: BankAccountCutoffFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IBankAccountCutoffResourceProps>>({ cutoffDate: moment().toISOString(), expectedAmount: "0" });
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.id) {
            const _data = await BankAccountCutoffService.read(props.bankAccountId, props.id);
            setData(_data);
        } else {
            const _bankaccount = await BankAccountService.read(props.bankAccountId);
            setData({ ...data, bankAccount: _bankaccount });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.actualAmount || error.justification) {
            return true;
        } else if ((data.actualAmount || "").trim() === '') {
            return true;
        } else if (Number(data.expectedAmount) !== Number(data.actualAmount) && (data.justification || "").trim() === "") {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { bankAccount, expectedAmount, actualAmount, cutoffDate } = data;

            // create form data
            let fd = new FormData();
            fd.append("bankAccountId", bankAccount?.id || "");
            fd.append("expectedAmount", expectedAmount || "");
            fd.append("actualAmount", actualAmount || "");
            fd.append("cutoffDate", cutoffDate || "");

            let result;
            if (props.id === undefined) {
                result = await BankAccountCutoffService.create(props.bankAccountId, fd);
            }

            banner.add({
                key: 'create_bankaccount_cutoff_success',
                variant: 'success',
                icon: faCheck,
                text: `Bank account cutoff created successfully`
            });

            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }


    return <Panel headerText={"Create Bank Account Cutoff"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Creating bank account cutoff ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <SelectSubsidiary label={"Subsidiary"}
                            selected={(data.bankAccount as IBankAccountResourceProps).subsidiary}
                            disabled={true}
                            onChange={() => { }} />
                    </Stack.Item>
                    <Stack.Item>
                        <SelectBankAccount label={"Bank account"}
                            waiting={!data.bankAccount}
                            subsidiaryId={data.bankAccount?.subsidiaryId}
                            selected={data.bankAccount}
                            disabled={true}
                            onChange={() => { }} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label={"Cutoff date"}
                            value={moment(data.cutoffDate).toDate()}
                            formatDate={GeneralService.formatDate}
                            onSelectDate={(date) => {
                                if (date) {
                                    const _data = data;
                                    _data.cutoffDate = moment(date).toISOString();
                                    setData({..._data});
                                }
                            }} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <TextField
                            label={"Expected amount"}
                            required={true}
                            disabled={true}
                            prefix={"Rp"}
                            value={data.expectedAmount || "0"} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Actual amount"}
                            required={true}
                            prefix={"Rp"}
                            value={data.actualAmount || ""}
                            onChange={(evt, value) => {
                                data.actualAmount = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 25 });
                                error.actualAmount = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.actualAmount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Justification / notes"}
                            required={Number(data.expectedAmount) !== Number(data.actualAmount)}
                            value={data.justification || ""}
                            multiline
                            rows={4}
                            autoAdjustHeight
                            resizable={false}
                            onChange={(evt, value) => {
                                data.justification = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 2000 });
                                error.justification = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.justification}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default BankAccountCutoffForm;
