import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { PPhPercentageOptions, PPNPercentageOptions } from '../../../../../../manifests/taxPercentage';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props
import { IIncomeInvoiceResourceProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import { IIncomeInvoiceItemResourceShortProps } from '../../../../../../props/finance/incomes/invoices/item';

// components
import { Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Details from '../../../../../typography/details';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import LoadingComponent from '../../../../../feedbacks/loading';

type IncomeInvoiceCancelFormProps = {
    invoice: IIncomeInvoiceResourceProps;
    onDismissed(refresh?: boolean): void;
}

const IncomeInvoiceCancelForm: React.FC<IncomeInvoiceCancelFormProps> = (props: IncomeInvoiceCancelFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [justification, setJustification] = React.useState<string>("");
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        setLoaded(true);
    }, []);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append("justification", justification);

            await IncomeInvoiceService.cancel(props.invoice.id, fd);

            banner.add({
                text: `Invoice $${IncomeInvoiceService.getInvoiceNumber(props.invoice.id)} has been cancelled`,
                icon: faCheck,
                variant: 'success'
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const isSubmitButtonDisabled = () => {
        if ((justification || "").trim() === "") {
            return true;
        }

        return false;
    }

    return <Panel headerText={'Add Related Order'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <>
                {submitting ? <LoadingComponent label={"Cancelling invoice ..."} spinnerPosition={'baseline'} labelPosition={'right'} /> : null}
                {!submitting ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                    <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
                </Stack> : null}
            </>
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Text>Are you sure to cancel income invoice below?</Text>
            <Details title={"Invoice number"} text={"#" + IncomeInvoiceService.getInvoiceNumber(props.invoice.id)} />
            <Stack className={'divider'}></Stack>
            <Stack>
                <TextField label={"Justification"}
                    value={justification}
                    required={true}
                    multiline
                    rows={5}
                    autoAdjustHeight
                    disabled={submitting}
                    resizable={false}
                    onChange={(e, value) => {
                        setJustification(value || "");
                    }} />
            </Stack>
        </Stack>
    </Panel>
};

export default IncomeInvoiceCancelForm;
