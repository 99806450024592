import React, { useEffect } from 'react';

// assets

// services
import DataTruckTypeService from '../../../../../services/data/fleets/truckType';

// props
import { IDataTruckTypeResourceShortProps } from '../../../../../props/data/fleets/truckType';

// components
import { Dropdown, DropdownMenuItemType, IDropdownOption, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import LoadingComponent from '../../../../feedbacks/loading';

type SelectTruckTypeProps = {
    key?: string;
    selected?: IDataTruckTypeResourceShortProps;
    options?: IDataTruckTypeResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IDataTruckTypeResourceShortProps): void;
}

const SelectTruckType: React.FC<SelectTruckTypeProps> = (props: SelectTruckTypeProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IDataTruckTypeResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IDataTruckTypeResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.options) {
            setOptions(props.options);
        } else {
            const _types = await DataTruckTypeService.retrieve();
            setOptions(_types);
        }
        setLoaded(true);
    }

    const groupOptions = (_options: IDataTruckTypeResourceShortProps[]) => {
        const groups: {name: string, items: IDataTruckTypeResourceShortProps[]}[] = [];
        
        _options.forEach((opt) => {
            const idx = groups.findIndex((g) => g.name.toLowerCase() === opt.group.toLowerCase());
            if (idx > -1) {
                groups[idx].items.push(opt);
            } else {
                groups.push({name: opt.group, items: [opt]});
            }
        });

        return groups;
    }

    const getDropdownOptions = (_options: IDataTruckTypeResourceShortProps[]) => {
        const groups = groupOptions(_options);
        const dropdownOptions: IDropdownOption[] = [];

        groups.forEach((group, idx) => {
            if (idx > 0) {dropdownOptions.push({key: 'divider_' + idx, text: "", itemType: DropdownMenuItemType.Divider});}
            dropdownOptions.push({key: 'group_' + idx, text: group.name, itemType: DropdownMenuItemType.Header});

            group.items.forEach((item) => {
                dropdownOptions.push({key: item.id, text: item.name});
            })
        });

        return dropdownOptions;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} styles={{root: {width: '100%'}}}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack styles={{root: {width: '100%'}}}>
                    <Dropdown disabled={props.disabled || !loaded}
                        key={props.key}
                        selectedKey={selected?.id}
                        options={getDropdownOptions(options)}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectTruckType;
