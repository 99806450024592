import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, PrimaryButton, Stack } from '@fluentui/react';
import UploadInvoiceSubform from './subforms/upload';
import FileService from '../../../../../../services/general/file';
import InvoiceDetailsSubform from './subforms/details';
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';
import InvoiceListSubform from './subforms/list';
import { faCircleX } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import OutcomeService from '../../../../../../services/finance/outcomes';
import { FormDataProps } from './props';
import moment from 'moment';

type OutcomeInvoiceCreateFormProps = {
}

type TOutcomeInvoicePage = 'uploadInvoice' | 'list';

const OutcomeInvoiceCreateForm: React.FC<OutcomeInvoiceCreateFormProps> = (props: OutcomeInvoiceCreateFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activePage, setActivePage] = React.useState<TOutcomeInvoicePage>('uploadInvoice');
    const [data, setData] = React.useState<Partial<FormDataProps>[]>([]);
    const pages: TOutcomeInvoicePage[] = ['uploadInvoice', 'list'];

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const _onChange = (data: Partial<FormDataProps>[]) => {
        setData([...data]);
    }

    const _onNextPage = () => {
        const currentIndex = pages.findIndex((p) => p.toLowerCase() === activePage.toLowerCase());
        if (currentIndex < pages.length - 1) {
            setActivePage(pages[currentIndex + 1]);
        }
    }

    const _onPrevPage = () => {
        const currentIndex = pages.findIndex((p) => p.toLowerCase() === activePage.toLowerCase());
        if (currentIndex > 0) {
            setActivePage(pages[currentIndex - 1]);
        }
    }

    const isSubmitButtonDisabled = () => {
        if (data.findIndex((d) => !d.systemVerified) > -1) {
            return true;
        }

        return false;
    }

    const processSubmitInvoice = async (details: Partial<FormDataProps>) => {
        const { systemFile, type, orders, subsidiary, status, includePPh, includePPn, ppnPercentage, pphPercentage, originalAmount, totalAmount, ppn, pph, vendor, dueDate, name, invoiceNumber, amount, notes, invoiceDate, accountingDate, ship, shipSchedule, train, trainSchedule, truck } = details;
        
        const fd = new FormData();
        fd.append("type", type || "others");
        fd.append("subsidiaryId", subsidiary?.id || "");
        fd.append("dueDate", dueDate || "");
        fd.append("name", name || "");
        fd.append("invoiceNumber", invoiceNumber || "");
        fd.append("notes", notes || "");
        fd.append("accountingDate", accountingDate || "");

        fd.append("includePPn", includePPn ? "1" : "0");
        fd.append("includePPh", includePPh ? "1" : "0");
        fd.append("ppnPercentage", ppnPercentage || "0");
        fd.append("pphPercentage", pphPercentage || "0");
        fd.append("ppn", (ppn || "0") + "");
        fd.append("pph", (pph || "0") + "");

        fd.append("amount", (amount || "0") + "");
        fd.append("originalAmount", (originalAmount || "0") + "");
        fd.append("totalAmount", (totalAmount || "0") + "");
        fd.append("invoiceDate", invoiceDate || moment().toISOString());
        if (vendor) {
            fd.append("vendorId", vendor.id);
        }
        if (ship) {
            fd.append("shipId", ship.id);
        }
        if (shipSchedule) {
            fd.append("shipScheduleId", shipSchedule.id);
        }
        if (train) {
            fd.append("trainId", train.id);
        }
        if (trainSchedule) {
            fd.append("trainScheduleId", trainSchedule.id);
        }
        if (truck) {
            fd.append("truckId", truck.id);
        }

        (orders || []).forEach((item) => {
            if (item.order) {
                fd.append('orders[]', JSON.stringify({
                    orderId: item.order.id,
                    amount: item.amount,
                    notes: item.notes
                }));
            }
        });

        if (systemFile) {
            fd.append("documents[]", systemFile);
            fd.append("metadatas[]", JSON.stringify({type: "Invoice", visibility: "Public"}));
        }

        return await OutcomeService.create(fd);
    }

    const _onRetry = async (details: Partial<FormDataProps>) => {
        const _data = data;
        const idx = _data.findIndex((d) => d.id === details.id);

        if (idx > -1) {
            try {
                _data[idx].systemSubmission = {loading: true};
                setData([..._data]);

                const invoice = await processSubmitInvoice(details);

                _data[idx].id = invoice.id;
                _data[idx].systemSubmission = {
                    loading: false,
                    success: true
                }
                setData([..._data]);
            } catch(e) {
                _data[idx].systemSubmission = {loading: false, success: false, error: e};
                setData([..._data]);
            }
        } else {
            banner.add({
                key: 'invoice_details_not_found',
                variant: 'error',
                icon: faCircleX,
                text: `Failed to retry. Message: Invoice details not found.`
            });
        }
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            // update submission loading status
            const _data: Partial<FormDataProps>[] = (data || []).map((d) => {
                return {...d, systemSubmission: {loading: true}};
            });
            setData([..._data]);

            // submit data
            for (var ctr = 0; ctr < _data.length; ctr++) {
                try {
                    const details = _data[ctr];
                    const invoice = await processSubmitInvoice(details);
                    
                    // update submission loading status to success
                    _data[ctr].id = invoice.id;
                    _data[ctr].systemSubmission = {
                        loading: false,
                        success: true
                    }
                    setData([..._data]);
                } catch(e) {
                    // update submission loading status to error
                    _data[ctr].systemSubmission = {
                        loading: false,
                        success: false,
                        error: e
                    }
                    setData([..._data]);
                }
            }
        } catch (e) {
            setSubmitting(false);
            banner.add({
                key: 'submit_outcome_invoice_error',
                variant: 'error',
                icon: faCircleX,
                text: `Failed to submit outcome invoice. Message: ${ErrorService.getMessage(e)}`
            });
        }
    }

    return <Stack className={styles.createForm}>
        <Stack className={styles.container}>
            {activePage === 'uploadInvoice' ? <UploadInvoiceSubform data={data} onChange={_onChange} /> : null}
            {activePage === 'list' ? <InvoiceListSubform data={data} onChange={_onChange} onRetry={_onRetry} /> : null}
        </Stack>
        <Stack className={styles.footer} horizontal tokens={{ childrenGap: 20 }} verticalAlign={'center'} horizontalAlign={'space-between'}>
            <ActionButton disabled={submitting} iconProps={{ iconName: "ChevronLeft" }} text={"Previous"} onClick={_onPrevPage} />
            {pages.findIndex((p) => p.toLowerCase() === activePage.toLowerCase()) < pages.length - 1 ? <ActionButton disabled={submitting} menuIconProps={{ iconName: "ChevronRight" }} text={'Next'} onClick={_onNextPage} /> : null}
            {pages.findIndex((p) => p.toLowerCase() === activePage.toLowerCase()) >= pages.length - 1 ? <PrimaryButton text={'Submit'} disabled={isSubmitButtonDisabled() || submitting} onClick={_onSubmit} /> : null}
        </Stack>
    </Stack>
};

export default OutcomeInvoiceCreateForm;
