import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { TSelectedFilterKey } from '.';

type OrdersDashboardChartFilterPaymentStatusProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: string[]): void;
}

const OrdersDashboardChartFilterPaymentStatus: React.FC<OrdersDashboardChartFilterPaymentStatusProps> = (props: OrdersDashboardChartFilterPaymentStatusProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<string[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniquePaymentStatus = (): string[] => {
        return ["Paid", "Unpaid"];
    }

    const renderOption = (paymentStatus: string) => {
        const isChecked = selected.findIndex((s) => s === paymentStatus) > -1;
        let totalItems = 0;
        if (paymentStatus === 'Paid') {
            totalItems = props.filteredOrders.filter((o) => Number(o.unpaidAmount) < 1).length;
        } else {
            totalItems = props.filteredOrders.filter((o) => Number(o.unpaidAmount) > 0).length;
        }

        return <Checkbox onChange={(ev, checked) => onChange(!checked, paymentStatus)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{paymentStatus} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, paymentStatus: string) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s !== paymentStatus);
        } else {
            _selected.push(paymentStatus);
        }

        setSelected([..._selected]);
        props.onChange('paymentStatus', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Payment status</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search payment status ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniquePaymentStatus().filter((f) => f.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((paymentStatus) => {
                return renderOption(paymentStatus);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterPaymentStatus;
