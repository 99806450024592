import { Stack } from '@fluentui/react';
import React from 'react';
import styles from './styles.module.scss';
import Text from '../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import Label from '../../typography/label';
import { faCheckCircle, faCircle as faCircleSolid, faClock, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// assets

export type TTagVariant = 'default' | 'active' | 'success' | 'light' | 'error' | 'warning' | 'transparent' | 'primary';

export type TimelineProps = {
    key?: string;
    items: TimelineItemProps[];
};

export type TimelineItemProps = {
    key: string;
    date?: string;
    title?: string;
    description?: string;
    content?: JSX.Element;
    notes?: string;
    status: TTimelineItemStatus;
    line?: TTimelineItemLine;
}

export type TTimelineItemStatus = 'inprogress' | 'completed' | 'cancelled' | 'infuture' | 'active';
export type TTimelineItemLine = 'solid' | 'dashed';

const Timeline: React.FC<TimelineProps> = (props: TimelineProps) => {
    const renderItem = (item: TimelineItemProps, idx?: number) => {
        return <Stack className={styles.item}>
            {item.line && ((idx || 0) < props.items.length - 1) ? <Stack className={`${styles.line} ${styles[item.line]}`}> </Stack> : null}
            <Stack className={styles.content} horizontal tokens={{childrenGap: 10}}>
                <Stack className={styles.dot}>
                    {item.status === 'infuture' ? <FontAwesomeIcon className={styles.infuture} icon={faCircle} fontSize={13} /> : null}
                    {item.status === 'active' ? <FontAwesomeIcon className={styles.active} icon={faCircleSolid as IconProp} fontSize={13} /> : null}
                    {item.status === 'completed' ? <FontAwesomeIcon className={styles.completed} icon={faCheckCircle as IconProp} fontSize={13} /> : null}
                    {item.status === 'inprogress' ? <FontAwesomeIcon className={styles.inprogress} icon={faClock as IconProp} fontSize={13} /> : null}
                    {item.status === 'cancelled' ? <FontAwesomeIcon className={styles.cancelled} icon={faXmarkCircle as IconProp} fontSize={13} /> : null}
                </Stack>
                <Stack>
                    {item.title ? <Label size={'small'} className={styles.title}>{item.title}</Label> : null}
                    {item.description ? <Text size={'small'}>{item.description}</Text> : null}
                    {item.date ? <Text size={'small'} className={styles.date}>{item.date}</Text> : null}
                    {item.notes ? <Text size={'small'} className={styles.notes}>{item.notes}</Text> : null}
                    {item.content ? item.content : null}
                </Stack>
            </Stack>
        </Stack>;
    }

    return <Stack className={styles.timeline} tokens={{childrenGap: 15}}>
        {props.items.map(renderItem)}
    </Stack>
};

export default Timeline;
