import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { observer } from 'mobx-react';

// assets

// services

// props
import { IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import { IIncomeInvoiceItemResourceShortProps } from '../../../../../../props/finance/incomes/invoices/item';

// components
import { DetailsList, IColumn, SelectionMode, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';

type IncomeInvoiceRelatedItemsProps = {
    invoice: IIncomeInvoiceResourceShortProps;
}

const IncomeInvoiceRelatedItems: React.FC<IncomeInvoiceRelatedItemsProps> = observer((props: IncomeInvoiceRelatedItemsProps) => {
    const { banner, user } = useStore();
    const { invoice } = props;

    const columns: IColumn[] = [
        {
            key: "no",
            name: "No.",
            minWidth: 40,
            maxWidth: 40,
            onRender: (item: IIncomeInvoiceItemResourceShortProps, idx?: number) => {
                return <Stack styles={{root: {padding: 4}}}>
                    <Text size={'small'}>{(idx || 0) + 1}</Text>
                </Stack>
            }
        },
        {
            key: "name",
            name: "Item Name",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IIncomeInvoiceItemResourceShortProps, idx?: number) => {
                return <Stack styles={{root: {padding: 4}}}>
                    <Text size={'small'}>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IIncomeInvoiceItemResourceShortProps, idx?: number) => {
                return <Stack styles={{root: {padding: 4}}}>
                    <Text size={'small'}>{item.notes || ""}</Text>
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: "Total Price",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IIncomeInvoiceItemResourceShortProps, idx?: number) => {
                let ppnPercentageText = "No PPn";
                if (item.ppnPercentage === 'custom') {
                    ppnPercentageText = 'custom';
                } else if (Number(item.ppnPercentage) > 0) {
                    ppnPercentageText = Number(item.ppnPercentage) + "%";
                }
                
                let pphPercentageText = "No PPh";
                if (item.pphPercentage === 'custom') {
                    pphPercentageText = 'custom';
                } else if (Number(item.pphPercentage) > 0) {
                    pphPercentageText = Number(item.pphPercentage) + "%";
                }

                return <Stack styles={{root: {padding: 4}}}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAmount))}</Text>
                    <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>PPn ({ppnPercentageText}): Rp. {GeneralService.getNumberWithSeparator(Number(item.ppn))}</Text>
                    <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>PPh ({pphPercentageText}): Rp. {GeneralService.getNumberWithSeparator(Number(item.pph))}</Text>
                </Stack>
            }
        }
    ]

    useEffect(() => {
    }, []);

    return <Stack>
        <DetailsList items={invoice.items || []}
            columns={columns}
            selectionMode={SelectionMode.none}
            compact />
    </Stack>
});

export default IncomeInvoiceRelatedItems;
