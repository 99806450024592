import React, { useEffect } from 'react';

// assets

// services
import OrderService from '../../../../../services/orders/order';

// props
import { ICompanyResourceShort } from '../../../../../props/users/company';
import { IDeliveryTypeResourceShortProps } from '../../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../../props/users/user';

// components
import { DefaultButton, IButtonStyles, IContextualMenuItem } from '@fluentui/react';
import FilterPanel, { FilterOptionProps } from '../../../../uiframeworks/filters/panel';
import { IOrderStatusResourceShortProps } from '../../../../../props/orders/orderStatus';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import DataServiceService from '../../../../../services/data/service';
import SystemFleetTypeService from '../../../../../services/systems/fleetType';
import DataTruckTypeService from '../../../../../services/data/fleets/truckType';
import DataShipTypeService from '../../../../../services/data/fleets/shipType';
import GenericFilterPanel from '../../../../uiframeworks/filters/panels/generic';
import AreaFilterPanel from '../../../../uiframeworks/filters/panels/area';

interface VendorsListFilterButtonProps {
    filters?: TVendorsListFilterBy[];
    selected: FilterOptionProps[];
    styles?: IButtonStyles;
    onChange(selected: FilterOptionProps[]): void;
}

export type TVendorsListFilterBy = 'status' | 'service' | 'service area' | 'fleet type';

const VendorsListFilterButton: React.FC<VendorsListFilterButtonProps> = (props: VendorsListFilterButtonProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [by, setBy] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<FilterOptionProps[]>(props.selected);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const _onFilterApplied = (_selected: FilterOptionProps[]) => {
        props.onChange(_selected);
        setActiveSurface(undefined);
        setBy(undefined);
    }

    const _onCancelled = () => {
        setBy(undefined);
        setActiveSurface(undefined);
    }

    const getQueryString = () => {
        const qs: string[] = [];

        const groupedFilter: { key: string, options: FilterOptionProps[] }[] = [];
        selected.map((sel) => {
            const gfidx = groupedFilter.findIndex((gf) => gf.key === sel.filterby);
            if (gfidx > -1) {
                groupedFilter[gfidx].options.push(sel);
            } else {
                groupedFilter.push({
                    key: sel.filterby,
                    options: [sel]
                });
            }
        });

        groupedFilter.forEach((gf) => {
            qs.push(`${gf.key}=${gf.options.map((opt) => opt.key).join(';')}`);
        });

        return qs.join("&");
    }

    const _onRenderOptions = async (): Promise<FilterOptionProps[]> => {
        if (by) {
            const key = by.toLowerCase().split(" ").join("_");
            const options: FilterOptionProps[] = [];
            const qs = getQueryString();

            if (key === 'status') {
                options.push({
                    filterby: key,
                    filterbyText: 'Status',
                    key: 'pending_verification',
                    title: 'Pending'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Status',
                    key: 'verified',
                    title: 'Verified'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Status',
                    key: 'rejected',
                    title: 'Rejected'
                });
            } else if (key === 'service') {
                const services = await DataServiceService.retrieve();
                services.map((service) => {
                    options.push({
                        filterby: key,
                        filterbyText: "Service type",
                        key: service.id,
                        title: service.name,
                        metadata: service
                    });
                })
            } else if (key === 'fleet_type') {
                const truckTypes = await DataTruckTypeService.retrieve();
                const shipTypes = await DataShipTypeService.retrieve();

                truckTypes.map((truckType) => {
                    options.push({
                        filterby: key,
                        filterbyText: "Truck type",
                        key: truckType.id,
                        title: truckType.name,
                        group: truckType.group,
                        metadata: truckType
                    });
                });

                shipTypes.map((shipType) => {
                    options.push({
                        filterby: key,
                        filterbyText: "Ship type",
                        key: shipType.id,
                        title: shipType.name,
                        group: shipType.group,
                        metadata: shipType
                    });
                });
            }

            return options;
        } else {
            return [];
        }
    }

    const renderFilterButton = () => {
        const items: IContextualMenuItem[] = [];
        const filteredby: string[] | undefined = props.filters ? (props.filters || []).map((f) => f.toLowerCase()) : undefined;

        if (!filteredby || (filteredby || "").indexOf('status') > -1) {
            items.push({
                key: 'status',
                text: 'Verification status',
                onClick: () => {
                    setBy('status');
                    setActiveSurface('generic');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('service') > -1) {
            items.push({
                key: 'service',
                text: 'Service type',
                onClick: () => {
                    setBy('service');
                    setActiveSurface('generic');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('service area') > -1) {
            items.push({
                key: 'service area',
                text: 'Service area',
                onClick: () => {
                    setBy('service area');
                    setActiveSurface('area');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('fleet type') > -1) {
            items.push({
                key: 'fleet type',
                text: 'Fleet type',
                onClick: () => {
                    setBy('fleet type');
                    setActiveSurface('generic');
                }
            });
        }

        return <DefaultButton styles={props.styles} menuIconProps={{ iconName: "Filter" }} text={"Filter"} menuProps={{ items }} />
    }

    return <>
        {renderFilterButton()}
        {activeSurface === 'generic' && by ? <>
            <GenericFilterPanel title={"Filter by " + by}
                onRenderOptions={_onRenderOptions}
                selected={selected}
                onCancel={_onCancelled}
                onApply={_onFilterApplied} />
        </> : null}
        {activeSurface === 'area' && by ? <>
            <AreaFilterPanel title={"Filter by " + by}
                filterBy={"service_area"}
                filterByText={"Service area"}
                selected={selected}
                onCancel={_onCancelled}
                onApply={_onFilterApplied} />
        </> : null}
    </>
};

export default VendorsListFilterButton;
