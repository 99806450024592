import { IBankAccountResourceProps, IBankAccountResourceShortProps } from '../../props/bankaccounts';
import { IBankAccountTransactionResourceShortProps } from '../../props/bankaccounts/transaction';
import Request from './../request';

// import props

const BankAccountService = {
    retrieve: async (qs?: string): Promise<{data: IBankAccountResourceShortProps[]}> => {
        try {
            const results = await Request.get(`/api/bankaccounts?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/bankaccounts`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (accountId: string, data: FormData): Promise<IBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/bankaccounts/${accountId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (accountId: string): Promise<IBankAccountResourceProps> => {
        try {
            const result = await Request.get(`/api/bankaccounts/${accountId}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    summary: async (accountId: string): Promise<any> => {
        try {
            const result = await Request.get(`/api/bankaccounts/${accountId}/summary`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    transactions: async (bankAccountId: string, qs?: string): Promise<IBankAccountTransactionResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/bankaccounts/${bankAccountId}/transactions?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
};

export default BankAccountService;