import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { IBankAccountResourceShortProps } from '../../../../../props/general/bankAccount';
import OrderInvoiceService from '../../../../../services/orders/invoices/invoice';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import OrderService from '../../../../../services/orders/order';
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../../props/orders/order';
import IncomeInvoiceService from '../../../../../services/finance/incomes/invoices/invoice';
import moment from 'moment';
import BankAccountService from '../../../../../services/general/bankAccount';
import { ITruckResourceShortProps } from '../../../../../props/order';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../props/data/ships';
import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../../../props/data/trains';
import OrderTrackingType from '../../../../../manifests/orderTrackingType';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorService from '../../../../../services/general/error';
import TrucksService from '../../../../../services/data/trucks';
import TrainScheduleService from '../../../../../services/data/trains/trainScedule';
import TrainsService from '../../../../../services/data/trains';
import ShipSchedulesService from '../../../../../services/data/ships/shipSchedules';
import ShipsService from '../../../../../services/data/ships';
import { ITruckResourceShort } from '../../../../../props/data/trucks';
import OrderTrackingService from '../../../../../services/orders/trackings/orderTracking';
import { IOrderTrackingResourceShortProps } from '../../../../../props/orders/trackings/orderTracking';
import DateTimePicker from '../../../../uiframeworks/forms/dateTimePicker';

interface IOrderTrackingStatusFormProps {
    orderId: string;
    trackingId: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    truck?: ITruckResourceShort;
    originAddress: string;
    destinationAddress: string;
    estimatedStartDate: string;
    estimatedEndDate: string;
    actualStartDate: string;
    actualEndDate: string;
    driverName: string;
    driverPhone: string;
    status: string;
}

type FormDataErrorProps = {
    originAddress?: string;
    destinationAddress?: string;
    estimatedStartDate?: string;
    estimatedEndDate?: string;
    driverName?: string;
    driverPhone?: string;
}

const OrderTrackingStatusForm: React.FC<IOrderTrackingStatusFormProps> = (props: IOrderTrackingStatusFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [order, setOrder] = React.useState<IOrderResourceProps | undefined>();
    const [tracking, setTracking] = React.useState<IOrderTrackingResourceShortProps | undefined>();
    const [data, setData] = React.useState<FormDataProps>({
        originAddress: "",
        destinationAddress: "",
        estimatedStartDate: moment().toISOString(),
        estimatedEndDate: moment().toISOString(),
        actualStartDate: moment().toISOString(),
        actualEndDate: moment().toISOString(),
        driverName: "",
        driverPhone: "",
        status: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.trackingId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _order = await OrderService.get(props.orderId);
        setOrder(_order);

        const _tracking = await OrderTrackingService.get(props.orderId, props.trackingId);
        setTracking(_tracking);

        const { shippingDetails, dooringDetails, trainDetails, loadDetails, unloadingDetails, container } = _tracking;

        const _data: FormDataProps = {
            originAddress: "",
            destinationAddress: "",
            estimatedStartDate: moment().toISOString(),
            estimatedEndDate: moment().add(1, 'day').toISOString(),
            actualStartDate: moment().toISOString(),
            actualEndDate: moment().add(1, 'day').toISOString(),
            truck: dooringDetails?.truck,
            driverName: dooringDetails?.driverName || "",
            driverPhone: dooringDetails?.driverPhone || "",
            status: _tracking.status || "estimated"
        };

        if (dooringDetails) {
            _data.originAddress = dooringDetails.originAddress;
            _data.destinationAddress = dooringDetails.destinationAddress;
            _data.estimatedStartDate = dooringDetails.estimatedDepartureDate;
            _data.estimatedEndDate = dooringDetails.estimatedArrivalDate;
            _data.actualStartDate = dooringDetails.actualDepartureDate || moment().toISOString();
            _data.actualEndDate = dooringDetails.actualArrivalDate || moment().add(1, 'day').toISOString();
        } else if (loadDetails) {
            _data.originAddress = loadDetails.address;
            _data.estimatedStartDate = loadDetails.estimatedStartDate || moment().toISOString();
            _data.actualStartDate = loadDetails.actualStartDate || moment().toISOString();
            _data.estimatedEndDate = loadDetails.estimatedEndDate || moment().toISOString();
            _data.actualEndDate = loadDetails.actualEndDate || moment().toISOString();
        } else if (unloadingDetails) {
            _data.destinationAddress = unloadingDetails.address;
            _data.estimatedStartDate = unloadingDetails.estimatedStartDate || moment().toISOString();
            _data.actualStartDate = unloadingDetails.actualStartDate || moment().toISOString();
            _data.estimatedEndDate = unloadingDetails.estimatedEndDate || moment().toISOString();
            _data.actualEndDate = unloadingDetails.actualEndDate || moment().toISOString();
        }

        setData(_data);

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (tracking) {
            if (tracking.type.trim() === "") {
                return true;
            } else if (tracking.type === 'truck' && (data.originAddress.trim() === "" || data.destinationAddress.trim() === "")) {
                return true;
            } else if (tracking.type === 'load' && (data.originAddress.trim() === "")) {
                return true;
            } else if (tracking.type === 'unload' && (data.destinationAddress.trim() === "")) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { status, originAddress, destinationAddress, actualStartDate, actualEndDate, estimatedStartDate, estimatedEndDate, truck, driverName, driverPhone } = data;
            const { type } = tracking || {};

            // create form data
            let fd = new FormData();
            fd.append('type', tracking?.type || "");
            fd.append('status', status);

            if (type === 'load' || type === 'unload') {
                if (status === 'estimated') {
                    fd.append('estimatedStartDate', estimatedStartDate);
                    fd.append('estimatedEndDate', estimatedEndDate);
                    fd.append('actualStartDate', "");
                    fd.append('actualEndDate', "");
                } else if (status === 'in progress') {
                    fd.append('estimatedStartDate', estimatedStartDate);
                    fd.append('estimatedEndDate', estimatedEndDate);
                    fd.append('actualStartDate', actualStartDate);
                    fd.append('actualEndDate', "");
                } else if (status === 'completed') {
                    fd.append('estimatedStartDate', estimatedStartDate);
                    fd.append('estimatedEndDate', estimatedEndDate);
                    fd.append('actualStartDate', actualStartDate);
                    fd.append('actualEndDate', actualEndDate);
                }
            } else if (type === 'truck') {
                fd.append('originAddress', originAddress);
                fd.append('destinationAddress', destinationAddress);
                if (status === 'estimated') {
                    fd.append('estimatedDepartureDate', estimatedStartDate);
                    fd.append('estimatedArrivalDate', estimatedEndDate);
                    fd.append('actualDepartureDate', "");
                    fd.append('actualArrivalDate', "");
                } else if (status === 'in progress') {
                    fd.append('estimatedDepartureDate', estimatedStartDate);
                    fd.append('estimatedArrivalDate', estimatedEndDate);
                    fd.append('actualDepartureDate', actualStartDate);
                    fd.append('actualArrivalDate', "");
                } else if (status === 'completed') {
                    fd.append('estimatedDepartureDate', estimatedStartDate);
                    fd.append('estimatedArrivalDate', estimatedEndDate);
                    fd.append('actualDepartureDate', actualStartDate);
                    fd.append('actualArrivalDate', actualEndDate);
                }
                fd.append('driverName', driverName);
                fd.append('driverPhone', driverPhone);
                if (truck) {
                    fd.append('truckId', truck.id);
                }
            }

            await OrderTrackingService.update(props.orderId, props.trackingId, fd);
            banner.add({
                key: 'update_order_tracking_status_success',
                variant: 'success',
                icon: faCheck,
                text: `Tracking "${type}" status updated successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchTruck = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const options = (await TrucksService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_truck_error",
                text: "Failed to search related truck(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const getApplyToAllText = (_order: IOrderResourceProps) => {
        if (_order.goodsType === 'fcl' || _order.goodsType === 'lcl') {
            return 'containers'
        } else if (_order.goodsType === 'ftl' || _order.goodsType === 'ltl') {
            return 'trucks';
        } else {
            return "goods";
        }
    }

    return <Panel headerText={'Update Tracking Status'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating tracking ..." : "Updating tracking ..."} /> : null}
            </Stack>;
        }}>
        <Stack className={styles.container} tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && order && tracking ? <>
                    <Dropdown selectedKey={data.status} options={[
                        { key: 'estimated', text: 'Estimasi' },
                        { key: 'in progress', text: 'Sedang Berjalan' },
                        { key: 'completed', text: 'Selesai' }
                    ]} label={'Status'} onChange={(evt, opt) => {
                        data.status = opt?.key as string || "estimated";
                        setData({ ...data });
                    }} />
                    {tracking.type === 'load' ? <>
                        <Stack.Item>
                            <Label>Loading Address</Label>
                            <Text>{tracking.loadDetails?.address || "-"}</Text>
                        </Stack.Item>
                        {data.status === 'in progress' ? <>
                            <DateTimePicker label={"ACTUAL Loading Start Date"}
                                required
                                enableTime
                                value={data.actualStartDate}
                                onChange={(date) => {
                                    data.actualStartDate = date || data.actualStartDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                            <DateTimePicker label={"ESTIMATED Loading End Date"}
                                required
                                enableTime
                                value={data.estimatedEndDate}
                                onChange={(date) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                        {data.status === 'completed' ? <>
                            <DateTimePicker label={"ACTUAL Loading End Date"}
                                required
                                enableTime
                                value={data.actualEndDate}
                                onChange={(date) => {
                                    data.actualEndDate = date || data.actualEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                    </> : null}
                    {tracking.type === 'unload' ? <>
                        <Stack.Item>
                            <Label>Unloading Address</Label>
                            <Text>{tracking.unloadingDetails?.address || "-"}</Text>
                        </Stack.Item>
                        {data.status === 'in progress' ? <>
                            <DateTimePicker label={"ACTUAL Unloading Start Date"}
                                required
                                enableTime
                                value={data.actualStartDate}
                                onChange={(date) => {
                                    data.actualStartDate = date || data.actualStartDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                            <DateTimePicker label={"ESTIMATED Unloading End Date"}
                                required
                                enableTime
                                value={data.estimatedEndDate}
                                onChange={(date) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                        {data.status === 'completed' ? <>
                            <DateTimePicker label={"ACTUAL Unloading End Date"}
                                required
                                enableTime
                                value={data.actualEndDate}
                                onChange={(date) => {
                                    data.actualEndDate = date || data.actualEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                    </> : null}
                    {tracking.type === 'truck' ? <>
                        <Stack.Item>
                            <Label required>Truck</Label>
                            {!data.truck ? <TagPicker
                                selectedItems={[]}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onItemSelected={(item: any) => {
                                    data.truck = item ? item.metadata : undefined;
                                    setData({ ...data });
                                    return null;
                                }}
                                onResolveSuggestions={_onSearchTruck}
                                onEmptyResolveSuggestions={() => _onSearchTruck('')}
                                disabled={submitting}
                            /> : null}
                            {data.truck ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                <Stack>
                                    <Label size={'small'}>{data.truck.name}</Label>
                                    <Text size={'small'}>{data.truck.registrationNumber}</Text>
                                </Stack>
                                <ActionButton className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                    data.truck = undefined;
                                    setData({ ...data });
                                }} />
                            </Stack> : null}
                        </Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <TextField label={"Driver Name"}
                                    value={data.driverName}
                                    errorMessage={error.driverName}
                                    disabled={submitting}
                                    onChange={(evt, value) => {
                                        data.driverName = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                        error.driverName = validation.message;

                                        setError({ ...error });
                                        setData({ ...data });
                                    }} />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <TextField label={"Driver Phone"}
                                    value={data.driverPhone}
                                    errorMessage={error.driverPhone}
                                    disabled={submitting}
                                    prefix={'+62'}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            data.driverPhone = value || "";

                                            const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                            error.driverPhone = validation.message;

                                            setError({ ...error });
                                            setData({ ...data });
                                        }
                                    }} />
                            </Stack.Item>
                        </Stack>
                        {data.status === 'in progress' ? <>
                            <DateTimePicker label={"ACTUAL Departure Date"}
                                required
                                enableTime
                                value={data.actualStartDate}
                                onChange={(date) => {
                                    data.actualStartDate = date || data.actualStartDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                            <DateTimePicker label={"ESTIMATED Arrival Date"}
                                required
                                enableTime
                                value={data.estimatedEndDate}
                                onChange={(date) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                        {data.status === 'completed' ? <>
                            <DateTimePicker label={"ACTUAL Arrival Date"}
                                required
                                enableTime
                                value={data.actualEndDate}
                                onChange={(date) => {
                                    data.actualEndDate = date || data.actualEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                    </> : null}
                    {tracking.type === 'other' ? <>
                        {data.status === 'in progress' ? <>
                            <DateTimePicker label={"ACTUAL Start Date"}
                                required
                                enableTime
                                value={data.actualStartDate}
                                onChange={(date) => {
                                    data.actualStartDate = date || data.actualStartDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                            <DateTimePicker label={"ESTIMATED End Date"}
                                required
                                enableTime
                                value={data.estimatedEndDate}
                                onChange={(date) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                        {data.status === 'completed' ? <>
                            <DateTimePicker label={"ACTUAL End Date"}
                                required
                                enableTime
                                value={data.actualEndDate}
                                onChange={(date) => {
                                    data.actualEndDate = date || data.actualEndDate;
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </> : null}
                    </> : null}
                    <Stack className='divider'></Stack>
                    {!tracking.container && !tracking.orderTruck ? <Stack.Item>
                        <Checkbox disabled label={`Apply to all ${getApplyToAllText(order)}`} checked={!tracking.container && !tracking.orderTruck} />
                    </Stack.Item> : null}
                    {tracking.container ? <Stack.Item>
                        <Label>Related Container</Label>
                        {tracking.container ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                            <Label size={'small'}>{tracking.container.containerNumber || "Container"}</Label>
                        </Stack> : null}
                    </Stack.Item> : null}
                    {tracking.orderTruck ? <Stack.Item>
                        <Label>Related Truck</Label>
                        {tracking.orderTruck ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                            <Label size={'small'}>{tracking.orderTruck.name || "Truck"}</Label>
                        </Stack> : null}
                    </Stack.Item> : null}
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderTrackingStatusForm;
