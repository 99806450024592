import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../../stores/root';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props
import { IEmployeeContractResourceProps } from '../../../../../../props/employees/contracts/contract';

// components
import { ActionButton, CommandBar, DetailsList, IButtonProps, ICommandBarItemProps, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import Details from '../../../../../typography/details';
import GeneralService from '../../../../../../services/general';
import Tag from '../../../../../uiframeworks/tag';
import { IEmployeeContractCommissionResourceShortProps } from '../../../../../../props/employees/contracts/commission';

interface EmployeeContractDetailsCommissionsProps {
    contract: IEmployeeContractResourceProps;
}

const EmployeeContractDetailsCommissions: React.FC<EmployeeContractDetailsCommissionsProps> = observer((props: EmployeeContractDetailsCommissionsProps) => {
    const { banner, topbar, user } = useStore();
    const { contract } = props;
    const commissions = (contract.commissions || []).sort((a, b) => (Number(a.startAmount) > Number(b.startAmount)) ? 1 : ((Number(b.startAmount) > Number(a.startAmount)) ? -1 : 0))

    return <Stack tokens={{ childrenGap: 20 }}>
        <DetailsList items={commissions}
            selectionMode={SelectionMode.none}
            compact={true}
            columns={[
                {
                    key: 'startAmount',
                    name: 'Start Amount',
                    minWidth: 150,
                    maxWidth: 150,
                    onRender: (item: IEmployeeContractCommissionResourceShortProps) => {
                        return <Stack>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.startAmount))}</Text>
                        </Stack>
                    }
                },
                {
                    key: 'endAmount',
                    name: 'End Amount',
                    minWidth: 150,
                    maxWidth: 150,
                    onRender: (item: IEmployeeContractCommissionResourceShortProps) => {
                        return <Stack>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.endAmount))}</Text>
                        </Stack>
                    }
                },
                {
                    key: 'percentage',
                    name: 'Percentage',
                    minWidth: 50,
                    onRender: (item: IEmployeeContractCommissionResourceShortProps) => {
                        return <Stack>
                            <Text size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.commissionPercentage))}%</Text>
                        </Stack>
                    }
                }
            ]} />
    </Stack>;
});

export default EmployeeContractDetailsCommissions;
