import React from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props
import { IBankAccountResourceProps } from '../../../../props/bankaccounts';

// components
import { Stack } from '@fluentui/react';
import Text from './../../../typography/text';
import Label from './../../../typography/label';
import Details from '../../../typography/details';

interface BankAccountDetailsPropertiesProps {
    bankAccount: IBankAccountResourceProps;
}

const BankAccountDetailsProperties: React.FC<BankAccountDetailsPropertiesProps> = (props: BankAccountDetailsPropertiesProps) => {
    const { bankAccount } = props;

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        {bankAccount.subsidiary ? <Details title={'Subsidiary'} text={bankAccount.subsidiary?.name} /> : null}
        {bankAccount.vendor ? <Details title={'Vendor'} text={bankAccount.vendor?.name} /> : null}
        {bankAccount.employee ? <Details title={'Employee'} text={bankAccount.employee?.name} /> : null}
        <Details title={'Account name'} text={bankAccount.accountName} />
        <Details title={'Account number'} text={bankAccount.accountNumber} />
        <Details title={'Bank'} text={bankAccount.bank.name} />
        <Details title={'City / Country'} text={[bankAccount.bankCity, bankAccount.bank.country].filter(Boolean).filter((d) => d !== "-").join(", ")} />
    </Stack>
};

export default BankAccountDetailsProperties;
