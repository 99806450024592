import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OutcomeService from '../../../../../../services/finance/outcomes';
import ErrorService from '../../../../../../services/general/error';

// props
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack, TooltipHost } from '@fluentui/react';
import LoadingComponent from '../../../../../feedbacks/loading';
import OutcomeDetailsProperties from './properties';
import OutcomeOrdersList from '../../../orders/general/list';
import OutcomePaymentsList from '../../../payments/general/list';
import OutcomePaymentForm from '../../../payments/general/form';
import OutcomeDocumentsList from '../../../documents/general/list';
import PermissionsService from '../../../../../../services/permissions';
import CancelOutcomeInvoiceForm from '../form/cancel';
import ActivateOutcomeInvoiceForm from '../form/activate';
import OutcomeInvoiceVersionHistory from '../versions';
import OutcomeInvoiceUpdateForm from '../form/update';
import InternalTransferDetailsForm from '../form/internal';
import EmployeeTransferDetailsForm from '../form/employee';

interface IOutcomeInvoiceDetailsProps {
    outcomeId: string;
    onDismissed(refresh?: boolean): void;
}

const OutcomeInvoiceDetails: React.FC<IOutcomeInvoiceDetailsProps> = (props: IOutcomeInvoiceDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [outcome, setOutcome] = React.useState<IOutcomeResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _outcome = await OutcomeService.get(props.outcomeId);
            setOutcome(_outcome);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_outcome_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (outcome) {
            if (PermissionsService.hasPermission(["outcomes.invoices.update.all"], user.permissions) && outcome.status.toLowerCase() !== 'cancelled' && Number(outcome.paid) <= 0) {
                items.push({
                    key: 'update',
                    text: 'Update',
                    onRender: () => {
                        return <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('details.update')} styles={{ root: { marginRight: 20 } }} />
                    }
                });
            }

            if (PermissionsService.hasPermission(["outcomes.invoices.payments.request"], user.permissions) && Number(outcome.unpaid) > 0 && outcome.status.toLowerCase() !== 'cancelled') {
                items.push({
                    key: 'record_payment',
                    text: 'Request payment',
                    onRender: () => {
                        const button = <ActionButton disabled={(!outcome.vendor && !outcome.targetSubsidiary && !outcome.targetEmployee) || Number(outcome.pendingPayment) === Number(outcome.unpaid)} text={"Request payment"} onClick={() => setActiveSurface("payments.request")} />;
                        
                        if (Number(outcome.pendingPayment) === Number(outcome.unpaid)) {
                            return <TooltipHost content={"There are no more amount left to be paid. Please make sure to reject/approve pending payment first."}>{button}</TooltipHost>;
                        } else if (outcome.vendor || outcome.targetSubsidiary || outcome.targetEmployee) { 
                            return button; 
                        } else {
                            return <TooltipHost content={"Request payment can be done after vendor/target subsidiary/target employee is specified. Please update vendor name first."}>{button}</TooltipHost>;
                        }
                    }
                });
            }

            if (PermissionsService.hasPermission(['outcomes.invoices.update.all'], user.permissions) && Number(outcome.paid) <= 0 && outcome.status.toLowerCase() !== 'cancelled') {
                farItems.push({
                    key: 'cancel',
                    text: 'Cancel',
                    onRender: () => {
                        return <ActionButton iconProps={{ iconName: "Cancel" }} text={"Cancel"} onClick={() => setActiveSurface('cancel')} />
                    }
                })
            }

            if (PermissionsService.hasPermission(['outcomes.invoices.update.all'], user.permissions) && outcome.status.toLowerCase() === 'cancelled') {
                items.push({
                    key: 'activate',
                    text: 'Re-activate',
                    onRender: () => {
                        return <PrimaryButton iconProps={{ iconName: "CheckMark" }} text={"Re-activate"} onClick={() => setActiveSurface('reactivate')} />
                    }
                })
            }

            if (PermissionsService.hasPermission(['outcomes.invoices.read.all'], user.permissions)) {
                items.push({
                    key: 'versionHistory',
                    text: 'Version history',
                    onRender: () => <ActionButton text={'Version history'} onClick={() => setActiveSurface('versionHistory')} />
                });
            }
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (activeSurface === 'delete' && refresh) {
            props.onDismissed(true);
        } else {
            setActiveSurface(undefined);
            if (refresh) { init(); }
        }
    }

    return <>
        <Panel headerText={outcome ? outcome.name : "Outcome Details"}
            isOpen={true}
            type={PanelType.large}
            onDismiss={() => props.onDismissed(false)}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
                </Stack>;
            }}>
            <Stack tokens={{ childrenGap: 15 }}>
                {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
                {loaded && outcome ? <>
                    <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />
                    <Pivot className={'linePivot'}>
                        <PivotItem key={'details'} headerText={"Details"}>
                            <Stack styles={{ root: { padding: 10 } }}><OutcomeDetailsProperties outcome={outcome} /></Stack>
                        </PivotItem>
                        <PivotItem key={'orders'} headerText={"Related Order(s)"}>
                            <Stack styles={{ root: { padding: 10 } }}><OutcomeOrdersList invoiceId={outcome.id} variant='plain' /></Stack>
                        </PivotItem>
                        {PermissionsService.hasPermission(['outcomes.invoices.payments.read.all'], user.permissions) ? <PivotItem key={'payments'} headerText={"Payments"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <OutcomePaymentsList qs={[`invoice_id=${outcome.id}`, `top=all`]}
                                    hideCommandBar
                                    hidePivotFilter
                                    columns={['paymentDate', 'notes', 'status', 'actions', 'transferFrom', 'transferTo', 'totalAmount', 'proofOfPayments']} />
                            </Stack>
                        </PivotItem> : null}
                        <PivotItem key={'documents'} headerText={"Documents"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <OutcomeDocumentsList outcomeId={props.outcomeId} />
                            </Stack>
                        </PivotItem>
                    </Pivot>
                </> : null}
            </Stack>
            {activeSurface === 'details.update' && outcome && !outcome.targetEmployee && !outcome.targetSubsidiary ? <OutcomeInvoiceUpdateForm invoiceId={outcome.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'details.update' && outcome && outcome.targetSubsidiary ? <InternalTransferDetailsForm outcomeId={outcome.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'details.update' && outcome && outcome.targetEmployee ? <EmployeeTransferDetailsForm outcomeId={outcome.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'payments.request' && outcome ? <OutcomePaymentForm invoiceId={outcome.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'cancel' && outcome ? <CancelOutcomeInvoiceForm invoice={outcome} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'reactivate' && outcome ? <ActivateOutcomeInvoiceForm invoice={outcome} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'versionHistory' && outcome ? <OutcomeInvoiceVersionHistory invoiceId={outcome.id} onDismissed={_onSurfaceDismissed} /> : null}
        </Panel>
    </>
};

export default OutcomeInvoiceDetails;
