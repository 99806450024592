import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props
import { IEmployeeContractJobdeskResourceShortProps } from '../../../../../../props/employees/contracts/jobdesk';

// components
import { ActionButton, DefaultButton, DetailsList, IconButton, Panel, PanelType, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';
import { IEmployeePayslipBonusResourceShortProps } from '../../../../../../props/employees/payslips/bonus';
import DetailsStack, { IStackSectionProps } from '../../../../../uiframeworks/detailsStack';

type EmployeePayslipBonusFormProps = {
    disabled?: boolean;
    bonuses: IEmployeePayslipBonusResourceShortProps[];
    onChange(bonuses: IEmployeePayslipBonusResourceShortProps[]): void;
}

const EmployeePayslipBonusForm: React.FC<EmployeePayslipBonusFormProps> = (props: EmployeePayslipBonusFormProps) => {
    const [bonuses, setBonuses] = React.useState<IEmployeePayslipBonusResourceShortProps[]>(props.bonuses);
    const [disabled, setDisabled] = React.useState<boolean>(props.disabled || false);
    const [surfaceMode, setSurfaceMode] = React.useState<'add' | 'update' | undefined>();
    const [activeBonus, setActiveBonus] = React.useState<IEmployeePayslipBonusResourceShortProps | undefined>();

    useEffect(() => {
        setBonuses(props.bonuses);
    }, [props.bonuses]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    const renderLeftStack = (): IStackSectionProps[] => {
        return [
            {
                key: 'bonuses',
                isCard: true,
                header: {
                    title: 'Bonuses', buttons: [{
                        text: 'Add bonus', iconProps: { iconName: "Add" }, disabled, onClick: () => {
                            setSurfaceMode('add');
                            setActiveBonus({
                                id: GeneralService.guid(),
                                name: "",
                                description: "",
                                amount: ""
                            });
                        }
                    }]
                },
                content: <Stack tokens={{ childrenGap: 10 }}>
                    {bonuses.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No bonuses on this payslip</Text> : null}
                    {bonuses.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
                        <DetailsList items={bonuses}
                            isHeaderVisible={false}
                            compact={true}
                            selectionMode={SelectionMode.none}
                            cellStyleProps={{ cellLeftPadding: 0, cellExtraRightPadding: 0, cellRightPadding: 0 }}
                            columns={[
                                {
                                    key: 'idx',
                                    name: 'idx',
                                    minWidth: 20,
                                    maxWidth: 20,
                                    isPadded: false,
                                    onRender: (item: IEmployeePayslipBonusResourceShortProps, idx?: number) => {
                                        return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                            <Text size={'small'}>{(idx || 0) + 1}.</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'details',
                                    name: 'Details',
                                    minWidth: 100,
                                    isPadded: false,
                                    onRender: (item: IEmployeePayslipBonusResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                            <Label size={'xsmall'}>{item.name}</Label>
                                            {(item.description || "").trim() !== "" ? <Text size={'xsmall'}>{item.description}</Text> : null}
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'amount',
                                    name: 'Amount',
                                    minWidth: 140,
                                    maxWidth: 140,
                                    isPadded: false,
                                    onRender: (item: IEmployeePayslipBonusResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '2px 0px', marginRight: 10 } }}>
                                            <Text size={'small'} style={{ textAlign: 'right' }} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'actions',
                                    name: '',
                                    minWidth: 65,
                                    maxWidth: 65,
                                    isPadded: false,
                                    onRender: (item: IEmployeePayslipBonusResourceShortProps) => {
                                        return <Stack>
                                            <Stack horizontal tokens={{ childrenGap: 2 }} horizontalAlign={'end'} className={"detailsListActionRow"}>
                                                <ActionButton disabled={disabled} className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                                    setSurfaceMode('update');
                                                    setActiveBonus({ ...item });
                                                }} />
                                                <ActionButton disabled={disabled} className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Delete' }} onClick={() => {
                                                    if (window.confirm('Are you sure to delete bonus "' + item.name + '"?')) {
                                                        const _bonuses = bonuses.filter((b) => b.id !== item.id);
                                                        props.onChange(_bonuses);
                                                    }
                                                }} />
                                            </Stack>
                                        </Stack>
                                    }
                                }
                            ]} />
                    </Stack> : null}
                </Stack>
            }
        ]
    }

    return <>
        <DetailsStack left={renderLeftStack()} />
        <Panel headerText={surfaceMode === 'add' ? "Add Bonus" : "Update Bonus"}
            isOpen={activeBonus !== undefined}
            type={PanelType.medium}
            onDismiss={() => {
                setSurfaceMode(undefined);
                setActiveBonus(undefined);
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={surfaceMode === 'add' ? "Add" : "Update"}
                        disabled={(activeBonus?.name || "") === "" || (activeBonus?.name || "").length > 100 || (activeBonus?.description || "").length > 2000 || (activeBonus?.amount || "").trim() === ""}
                        onClick={() => {
                            if (activeBonus) {
                                const _bonuses = bonuses;
                                const idx = _bonuses.findIndex((b) => b.id === activeBonus.id);

                                if (idx > -1) {
                                    _bonuses[idx] = activeBonus;
                                } else {
                                    _bonuses.push(activeBonus);
                                }

                                props.onChange(_bonuses);
                                setActiveBonus(undefined);
                            }
                        }} />
                    <DefaultButton text={"Cancel"} onClick={() => {
                        setSurfaceMode(undefined);
                        setActiveBonus(undefined);
                    }} />
                </Stack>
            }}>
            {activeBonus ? <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <TextField label={"Name"}
                        required
                        onChange={(evt, value) => {
                            if (activeBonus) {
                                setActiveBonus({ ...activeBonus, name: value || "" });
                            }
                        }}
                        value={activeBonus.name} />
                    <TextField label={"Description"}
                        multiline
                        rows={5}
                        autoAdjustHeight
                        resizable={false}
                        onChange={(evt, value) => {
                            if (activeBonus) {
                                setActiveBonus({ ...activeBonus, description: value || "" });
                            }
                        }}
                        value={activeBonus.description || ""} />
                    <TextField label={"Amount"}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if (activeBonus && (value || "").trim() === "" || !isNaN(Number(value))) {
                                setActiveBonus({ ...activeBonus, amount: value || "" });
                            }
                        }}
                        value={activeBonus.amount || ""} />
                </Stack>
            </> : null}
        </Panel>
    </>
};

export default EmployeePayslipBonusForm;
