import Request from './../../request';

// import props
import { IVendorPICResourceShortProps } from '../../../props/data/vendors/pic';

const VendorPICService = {
  retrieve: async (vendorId: string): Promise<IVendorPICResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/data/vendors/${vendorId}/pics`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default VendorPICService;
