import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { useStore } from '../../../../stores/root';
import { IOrderResourceShortProps } from '../../../../props/orders/order';
import OrderService from '../../../../services/orders/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faGlobe, faUser, faUserTag } from '@fortawesome/pro-light-svg-icons';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Tag, { TTagVariant } from '../../tag';
import { NavLink } from 'react-router-dom';

type SelectOrderProps = {
    selected?: IOrderResourceShortProps;
    options?: IOrderResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    hideAddress?: boolean;
    required?: boolean;
    disabled?: boolean;
    target?: '_blank' | 'current';
    onChange(selected?: IOrderResourceShortProps): void;
}

const SelectOrder: React.FC<SelectOrderProps> = (props: SelectOrderProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IOrderResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    const _onSearchOrder = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.orderNumber,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                qs.push(`top=10`);
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await OrderService.retrieve(qs.join("&"))).data.map((item) => ({
                    key: item.id,
                    name: item.orderNumber,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Order"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const order: IOrderResourceShortProps = props.metadata;
                    let statusVariant: TTagVariant = 'active';

                    if (order.status.key === 'completed') { statusVariant = 'success'; }
                    else if (order.status.key === 'cancelled') { statusVariant = 'error'; }
                    else if (order.status.key === 'active') { statusVariant = 'active'; }

                    return <Stack tokens={{childrenGap: 5}} horizontalAlign={'baseline'} styles={{root: {width: '100%', padding: 5}}}>
                        <Stack horizontal tokens={{childrenGap: 10}} styles={{root: {width: '100%'}}} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label size={'small'}>#{OrderService.getOrderNumber(order.orderNumber)}</Label>
                            <Tag text={order.status.name} size={'small'} variant={statusVariant} />
                        </Stack>
                        <Stack tokens={{childrenGap: 3}}>
                            <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faUser} size={'xs'} style={{marginTop: -1, width: 15}} />
                                <Text size={'xsmall'}>{order.customer.name} ({order.company?.name || ""})</Text>
                            </Stack>
                            <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faGlobe} size={'xs'} style={{marginTop: -1, width: 15}} />
                                <Text size={'xsmall'}>{order.freightCategory.name} - {order.deliveryType.name} - {DeliveredGoodsType.find((dg) => dg.key === order.goodsType)?.text}</Text>
                            </Stack>
                            {order.batch ? <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faCheckSquare} size={'xs'} style={{marginTop: -1, width: 15}} />
                                <Text size={'xsmall'}>Batch {order.batch}</Text>
                            </Stack> : null}
                            {(order.sales || []).length > 0 ? <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
                                <FontAwesomeIcon icon={faUserTag} size={'xs'} style={{marginTop: -1, width: 15}} />
                                <Text size={'xsmall'}>{(order.sales || []).map((s) => s.sales.name).join(", ")}</Text>
                            </Stack> : null}
                        </Stack>
                    </Stack>
                }}
                onResolveSuggestions={_onSearchOrder}
                onEmptyResolveSuggestions={() => _onSearchOrder('')}
            />
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack tokens={{childrenGap: 10}}>
                {props.target ? <NavLink to={'/orders/' + selected.id} target={props.target}><Label size={'small'}>#{OrderService.getOrderNumber(selected.orderNumber)}</Label></NavLink> : null}
                {!props.target ? <Label size={'small'}>#{OrderService.getOrderNumber(selected.orderNumber)}</Label> : null}
                <Stack className={'divider'}></Stack>
                <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faUser} size={'sm'} style={{marginTop: -2, width: 20}} />
                    <Text size={'small'}>{selected.customer.name} ({selected.company?.name || ""})</Text>
                </Stack>
                <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faGlobe} size={'sm'} style={{marginTop: -2, width: 20}} />
                    <Text size={'small'}>{selected.freightCategory?.name} - {selected.deliveryType?.name} - {DeliveredGoodsType.find((dg) => dg.key === selected.goodsType)?.text}</Text>
                </Stack>
                {(selected.sales || []).length > 0 ? <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faUserTag} size={'sm'} style={{marginTop: -2, width: 20}} />
                    <Text size={'small'}>{(selected.sales || []).map((s) => s.sales.name).join(", ")}</Text>
                </Stack> : null}
                {props.hideAddress ? null : <>
                    <Stack className={'divider'}></Stack>
                    <Stack tokens={{childrenGap: 3}}>
                        <Label size={'xsmall'}>Origin</Label>
                        <ul style={{margin: 0, padding: 0, paddingLeft: 18}}>
                                {selected.originAddresses.map((address) => {
                                    const text: string[] = [];
                                    text.push(address.address);
                                    if (address.city) {text.push(address.city)}
                                    if (address.province) {text.push(address.province)}

                                    return <li><Text size={'small'}>({address.type}) {text.join(", ")}</Text></li>
                                })}
                            </ul>
                    </Stack>
                    <Stack tokens={{childrenGap: 3}}>
                        <Label size={'xsmall'}>Destination</Label>
                            <ul style={{margin: 0, padding: 0, paddingLeft: 18}}>
                                {selected.destinationAddresses.map((address) => {
                                    const text: string[] = [];
                                    text.push(address.address);
                                    if (address.city) {text.push(address.city)}
                                    if (address.province) {text.push(address.province)}

                                    return <li><Text size={'small'}>({address.type}) {text.join(", ")}</Text></li>
                                })}
                            </ul>
                    </Stack>
                </>}
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
    </Stack>;
};

export default SelectOrder;
