import React, { useEffect } from 'react';
import styles from './../styles.module.scss';

// assets

// services

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import LoadingComponent from '../../../feedbacks/loading';
import { FilterOptionProps } from './generic';
import SelectArea from '../../forms/data/geographics/area';

interface AreaFilterPanelProps {
    filterBy: string;
    filterByText: string;
    title?: string;
    selected: FilterOptionProps[];
    onApply(selected: FilterOptionProps[]): void;
    onCancel(): void;
}

const AreaFilterPanel: React.FC<AreaFilterPanelProps> = (props: AreaFilterPanelProps) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<FilterOptionProps[]>(props.selected);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    return <Panel headerText={props.title || "Filter"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onCancel()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={'Apply'} onClick={() => props.onApply(selected)} />
                <DefaultButton text={'Cancel'} onClick={() => props.onCancel()} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            {!loaded ? <LoadingComponent label='Retrieving filter ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <SelectArea label={"Search service area"}
                    allowMultiple={true}
                    selected={{
                        countries: selected.filter((s) => s.group === 'countries').map((s) => s.metadata),
                        provinces: selected.filter((s) => s.group === 'provinces').map((s) => s.metadata),
                        cities: selected.filter((s) => s.group === 'cities').map((s) => s.metadata)
                    }}
                    onChange={(areas) => {
                        if (areas) {
                            const _selected: FilterOptionProps[] = [];

                            (areas.countries || []).forEach((c) => {
                                _selected.push({
                                    filterby: props.filterBy,
                                    filterbyText: props.filterByText,
                                    title: c.name,
                                    key: c.key,
                                    group: 'countries',
                                    metadata: c
                                });
                            });
                            
                            (areas.provinces || []).forEach((p) => {
                                _selected.push({
                                    filterby: props.filterBy,
                                    filterbyText: props.filterByText,
                                    title: p.name,
                                    key: p.id,
                                    group: 'provinces',
                                    metadata: p
                                });
                            });
                            
                            (areas.cities || []).forEach((c) => {
                                _selected.push({
                                    filterby: props.filterBy,
                                    filterbyText: props.filterByText,
                                    title: c.name,
                                    key: c.id,
                                    group: 'cities',
                                    metadata: c
                                });
                            });

                            setSelected([..._selected]);
                        }
                    }} />
            </> : null}
        </Stack>
    </Panel>
};

export default AreaFilterPanel;
