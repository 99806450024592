import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services

// props
import { IPaginationResourceShort } from '../../../../../props/general';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { IColumn, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import { IVendorPICResourceShortProps } from '../../../../../props/data/vendors/pic';
import Text from './../../../../typography/text';
import VendorPICService from '../../../../../services/data/vendors/pics';

type VendorPICsListProps = {
    vendorId: string;
}

const VendorPICsList: React.FC<VendorPICsListProps> = (props: VendorPICsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [keyword, setKeyword] = React.useState<string>("");
    const [pics, setPICs] = React.useState<IVendorPICResourceShortProps[]>([]);

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 200,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: "role",
            name: "Role",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.role || ""}</Text>
                </Stack>
            }
        },
        {
            key: "department",
            name: "Department",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.department || ""}</Text>
                </Stack>
            }
        },
        {
            key: "phoneNumber",
            name: "Phone Number",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.phoneNumber}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _pics = await VendorPICService.retrieve(props.vendorId);
        setPICs(_pics);
        setLoaded(true);
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded || (loaded && pics.length > 0) ? <ShimmeredDetailsList columns={columns}
            items={pics}
            enableShimmer={!loaded}
            selectionMode={SelectionMode.none} /> : null}
    </Stack>;
};

export default VendorPICsList;
