import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourceShortProps, IOrderResourcerShorterProps } from '../../../../../props/orders/order';

// components
import { ActionButton, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompanyResourceShort } from '../../../../../props/users/company';
import { IFreightCategoryResourceShortProps } from '../../../../../props/orders/freightCategory';
import { IDeliveryTypeResourceShortProps } from '../../../../../props/orders/deliveryType';
import { SelectedFilterProps } from '../filters';
import { Bar, BarChart, CartesianGrid, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ChartColors from '../../../../../manifests/chartColors';

type OrdersDashboardTotalProfitChartProps = {
    orders: IOrderResourceShortProps[];
    groupBy: string;
}

const OrdersDashboardTotalProfitChart: React.FC<OrdersDashboardTotalProfitChartProps> = (props: OrdersDashboardTotalProfitChartProps) => {
    const { banner } = useStore();

    const groupData = () => {
        const data: {
            name: string;
            key: string;
            legend: string;
            fill: string;
            value: number;
            orders: IOrderResourceShortProps[];
        }[] = [];

        let monthly: { key: string, orders: IOrderResourceShortProps[] }[] = [];

        // group all orders based on month
        props.orders.forEach((order) => {
            let date: string | undefined = undefined;
            if (props.groupBy === 'created') {
                date = order.createdAt;
            } else if (props.groupBy === 'actualdeparture') {
                date = order.actualDeparture;
            } else if (props.groupBy === 'estimateddeparture') {
                date = order.estimatedDeparture;
            }

            if (date) {
                const orderMonth = moment(date).format("YYYYMM");
                const idx = monthly.findIndex((m) => m.key === orderMonth);
                if (idx < 0) {
                    monthly.push({
                        key: orderMonth,
                        orders: [order]
                    });
                } else if (monthly[idx]) {
                    monthly[idx].orders.push(order);
                }
            }
        });

        // sort group based on month
        monthly = monthly.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
        monthly.forEach((month, idx) => {
            const name = moment(month.key + '', 'YYYYMM').format("MMM YYYY").toUpperCase();
            let profit = 0;

            month.orders.forEach((order) => {
                profit += Number(order.nettProfit);
            });

            data.push({
                key: month.key,
                name,
                legend: name,
                fill: ChartColors[idx],
                value: profit,
                orders: month.orders
            });
        });

        return data;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='center' wrap>
            {payload.map((entry: any, index: number) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Stack.Item className={styles.indicator} styles={{ root: { backgroundColor: entry.color } }}> </Stack.Item>
                    <Text size={'small'}>{entry.payload.legend}</Text>
                </Stack>
            })}
        </Stack>;
    }

    return <Stack className={styles.container} styles={{ root: { width: '100%', height: 300 } }}>
        <ResponsiveContainer>
            <PieChart>
                <Pie dataKey="value"
                    data={groupData()}
                    label={entry => `${entry.name}\n(Rp. ${GeneralService.getNumberWithSeparator(entry.value)})`}
                    paddingAngle={5}
                    innerRadius={60}
                    outerRadius={80} />
                <Legend content={renderLegend} verticalAlign="bottom" align="center" />
            </PieChart>
        </ResponsiveContainer>
    </Stack>;
};

export default OrdersDashboardTotalProfitChart;
