import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faExclamationTriangle, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import GeneralService from '../../../../../services/general';
import ValidationService from '../../../../../services/validation';
import UserService from '../../../../../services/users/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEmployeePayslipResourceProps } from '../../../../../props/employees/payslips/payslip';
import EmployeePayslipService from '../../../../../services/employees/payslip';
import Label from '../../../../typography/label';
import EmployeePayslipBonusForm from './subforms/bonus';
import EmployeePayslipDeductionForm from './subforms/deduction';
import Details from '../../../../typography/details';
import SelectEmployeeContract from '../../../../uiframeworks/forms/employees/contract';
import moment from 'moment';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import Tag from '../../../../uiframeworks/tag';
import LoadingComponent from '../../../../feedbacks/loading';
import EmployeePayslipCommissionForm from './subforms/commissions';
import EmployeeCommissionService from '../../../../../services/employees/commission';
import { IEmployeePayslipCommissionResourceShortProps } from '../../../../../props/employees/payslips/commision';

type EmployeePayslipFormProps = {
    payslipId?: string;
    employeeId?: string;
}

const EmployeePayslipForm: React.FC<EmployeePayslipFormProps> = (props: EmployeePayslipFormProps) => {
    const { banner, route, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IEmployeePayslipResourceProps>>({
        id: GeneralService.makeid(25, true, 'PSL'),
        payslipMonth: moment().format("MM/YYYY")
    });
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.payslipId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        if (props.payslipId) {
            const _payslip = await EmployeePayslipService.read(props.payslipId);

            topbar.show("Payslip", [
                {key: "home", icon: faHome, href: "/"},
                {key: "employees", text: 'Employees', href: '/users/administrators'},
                {key: "user", text: 'Administrators', href: '/users/administrators/' + _payslip.employee.id},
                {key: "payslips", text: 'Payslips', href: "/hr/payslips"},
                {key: _payslip.id, text: '#' + _payslip.id, href: "/hr/payslips/" + _payslip.id},
                {key: "update", text: 'Update'}
            ]);

            _data = {
                id: _payslip.id,
                payslipMonth: _payslip.payslipMonth,
                employee: _payslip.employee,
                contract: _payslip.contract,
                subsidiary: _payslip.subsidiary,
                salaryAmount: Number(_payslip.salaryAmount) + "",
                healthInsuranceAmount: Number(_payslip.healthInsuranceAmount) + "",
                workplaceInsuranceAmount: Number(_payslip.workplaceInsuranceAmount) + "",
                ppnAmount: Number(_payslip.ppnAmount) + "",
                pphAmount: Number(_payslip.pphAmount) + "",
                subtotalAmount: Number(_payslip.subtotalAmount) + "",
                totalAmount: Number(_payslip.totalAmount) + "",
                bonuses: _payslip.bonuses,
                deductions: _payslip.deductions
            };
        } else {
            if (props.employeeId) {
                const _employee = await UserService.get(props.employeeId);
                _data.employee = _employee;
            }
        }

        setData({ ..._data });
        setLoaded(true);
    }

    const generateCommissions = async (useExisting?: boolean) => {
        if (data.payslipMonth && data.employee) {
            const _data = data;
            let commissions: IEmployeePayslipCommissionResourceShortProps[] = [];

            setData({...data, commissions: undefined});

            if (useExisting) {
                const existings = data.commissions;

                commissions = await EmployeeCommissionService.retrieve.byExistingCommissions(existings || [], data.employee);
            } else {
                const startDate = moment(data.payslipMonth, 'MM/YYYY').startOf('month').toISOString();
                const endDate = moment(data.payslipMonth, 'MM/YYYY').endOf('month').toISOString();

                commissions = await EmployeeCommissionService.retrieve.byPaymentDate(startDate, endDate, data.employee);
            }

            _data.commissions = commissions;

            const { total, subtotal } = calculateTotalAmount(_data);

            setData({..._data, commissions: commissions || [], totalAmount: total, subtotalAmount: subtotal});
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (!data.employee || !data.subsidiary || (data.salaryAmount || "").trim() === "") {
            return true;
        } else if ((data.healthInsuranceAmount || "").trim() === "" || (data.workplaceInsuranceAmount || "").trim() === "") {
            return true;
        } else if ((data.ppnAmount || "").trim() === "" || (data.pphAmount || "").trim() === "") {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('id', data.id || "");
            fd.append('payslipMonth', data.payslipMonth || "");
            fd.append('employeeId', data.employee?.id || "");
            fd.append('contractId', data.contract?.id || "");
            fd.append('subsidiaryId', data.subsidiary?.id || "");
            fd.append('salaryAmount', data.salaryAmount || "");
            fd.append('healthInsuranceAmount', data.healthInsuranceAmount || "");
            fd.append('workplaceInsuranceAmount', data.workplaceInsuranceAmount || "");
            fd.append('ppnAmount', data.ppnAmount || "");
            fd.append('pphAmount', data.pphAmount || "");
            fd.append('subtotalAmount', data.subtotalAmount || "");
            fd.append('totalAmount', data.totalAmount || "");

            (data.bonuses || []).forEach((bonus) => {
                fd.append('bonuses[]', JSON.stringify(bonus));
            });

            (data.commissions || []).forEach((commission) => {
                fd.append('commissions[]', JSON.stringify({
                    orderId: commission.order.id,
                    orderSalesId: commission.orderSalesId,
                    description: commission.description || "",
                    amount: commission.amount,
                    originalProfit: commission.originalProfit
                }));
            });

            (data.deductions || []).forEach((deduction) => {
                fd.append('deductions[]', JSON.stringify(deduction));
            });

            let result;
            if (props.payslipId === undefined) {
                result = await EmployeePayslipService.create(fd);
            } else {
                result = await EmployeePayslipService.update(props.payslipId, fd);
            }

            route.route?.history.push("/hr/payslips/" + result.id);
        } catch (e) {
            banner.add({
                key: 'create_payslip_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to create payslip. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const calculateTotalAmount = (_data: Partial<IEmployeePayslipResourceProps>) => {
        let { salaryAmount, healthInsuranceAmount, workplaceInsuranceAmount, ppnAmount, pphAmount, contract, bonuses, deductions, commissions } = _data;

        let subtotal = 0;
        let total = 0;

        if (contract) {
            salaryAmount = contract.salary + "";
        }

        if ((salaryAmount || "").trim() !== "") {
            subtotal += Number(salaryAmount);
        }

        if ((healthInsuranceAmount || "").trim() !== "") {
            subtotal -= Number(healthInsuranceAmount);
        }

        if ((workplaceInsuranceAmount || "").trim() !== "") {
            subtotal -= Number(workplaceInsuranceAmount);
        }

        (bonuses || []).forEach((bonus) => {
            subtotal += Number(bonus.amount);
        });

        (deductions || []).forEach((deduction) => {
            subtotal -= Number(deduction.amount);
        });

        (commissions || []).forEach((commission) => {
            subtotal += Number(commission.amount);
        });

        total = subtotal;

        if ((ppnAmount || "").trim() !== "") {
            total -= Number(ppnAmount);
        }

        if ((pphAmount || "").trim() !== "") {
            total -= Number(pphAmount);
        }

        return { total: total.toFixed(2), subtotal: subtotal.toFixed(2) };
    }

    const renderLeftStack = (): IStackSectionProps[] => {
        const sections: IStackSectionProps[] = [];

        sections.push({
            key: 'general',
            isCard: true,
            header: { title: 'General Details' },
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <SelectEmployee label={'Select employee'}
                            required={true}
                            selected={data.employee}
                            errorMessage={error.employee}
                            disabled={submitting || props.employeeId !== undefined}
                            onChange={(employee) => {
                                data.employee = employee;

                                const validation = ValidationService.combination(employee, ['required'], {});
                                error.employee = validation.message;

                                if (data.employee && data.employee.subsidiaries && data.employee.subsidiaries.length === 1) {
                                    data.subsidiary = data.employee.subsidiaries[0];
                                }

                                setData({ ...data });
                                setError({ ...error });

                                generateCommissions();
                            }} />
                    </Stack>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <SelectEmployeeContract label={'Select contract'}
                            selected={data.contract}
                            employeeId={data.employee?.id || ""}
                            waiting={data.employee === undefined}
                            disabled={submitting}
                            onChange={(contract) => {
                                let _data = data;
                                _data.contract = contract;

                                if (_data.contract) {
                                    _data.subsidiary = _data.contract.subsidiary;
                                    _data.salaryAmount = _data.contract.salary;
                                }

                                const { total, subtotal } = calculateTotalAmount(_data);
                                _data.totalAmount = total;
                                _data.subtotalAmount = subtotal;

                                setData({ ..._data });
                                setError({ ...error });
                            }} />
                    </Stack>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <SelectSubsidiary label={'Select subsidiary'}
                            required
                            selected={data.subsidiary}
                            disabled={!data.employee || data.contract !== undefined || submitting}
                            options={data.employee ? data.employee.subsidiaries : (data.contract ? [data.contract.subsidiary] : [])}
                            onChange={(subsidiary) => {
                                data.subsidiary = subsidiary;

                                const validation = ValidationService.combination(subsidiary, ['required'], {});
                                error.subsidiary = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <Dropdown label={"Payment month"} selectedKey={moment(data.payslipMonth, "MM/YYYY").format("MM")}
                            disabled={submitting} options={[
                            { key: '01', text: 'January' },
                            { key: '02', text: 'February' },
                            { key: '03', text: 'March' },
                            { key: '04', text: 'April' },
                            { key: '05', text: 'May' },
                            { key: '06', text: 'June' },
                            { key: '07', text: 'July' },
                            { key: '08', text: 'August' },
                            { key: '09', text: 'September' },
                            { key: '10', text: 'October' },
                            { key: '11', text: 'November' },
                            { key: '12', text: 'December' }
                        ]} styles={{ root: { width: '100%' } }}
                            onChange={(ev, opt) => {
                                const month = opt ? opt.key as string : moment(data.payslipMonth, "MM/YYYY").format("MM");
                                const year = moment(data.payslipMonth, "MM/YYYY").format("YYYY");
                                data.payslipMonth = `${month}/${year}`;

                                setData({ ...data });
                                setError({ ...error });

                                generateCommissions();
                            }} />
                    </Stack>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <Dropdown label={"Year"} selectedKey={moment(data.payslipMonth, "MM/YYYY").format("YYYY")}
                            disabled={submitting} options={[
                            { key: '2024', text: '2024' },
                            { key: '2025', text: '2025' },
                            { key: '2026', text: '2026' },
                            { key: '2027', text: '2027' },
                            { key: '2028', text: '2028' },
                            { key: '2029', text: '2029' },
                            { key: '2030', text: '2030' },
                        ]} onChange={(ev, opt) => {
                            const year = opt ? opt.key as string : moment(data.payslipMonth, "MM/YYYY").format("YYYY");
                            const month = moment(data.payslipMonth, "MM/YYYY").format("MM");
                            data.payslipMonth = `${month}/${year}`;

                            setData({ ...data });
                            setError({ ...error });

                            generateCommissions();
                        }} />
                    </Stack>
                    <Stack styles={{ root: { width: '33%' } }}> </Stack>
                </Stack>
            </Stack>
        });

        sections.push({
            key: 'salary',
            header: { title: 'Salary Details' },
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '33%' } }}>
                        <TextField label={'Base salary'}
                            prefix={'Rp'}
                            required
                            errorMessage={error.salaryAmount}
                            disabled={data.contract !== undefined || submitting}
                            value={data.contract ? GeneralService.getNumberWithSeparator(data.contract.salary) : data.salaryAmount}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.salaryAmount = value || "";

                                    const { total, subtotal } = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.salaryAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                    </Stack>
                    <Stack styles={{ root: { width: '33%' } }}> </Stack>
                    <Stack styles={{ root: { width: '33%' } }}> </Stack>
                </Stack>
                <Stack className={"divider"}></Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className={'color-yellow'} style={{ position: 'relative', top: 1 }} />
                        <Text size={'small'} className={'color-yellow'}>Amount for both ppn and pph are only amount that is borned by the employee. This will act as a deduction to employee's salary.</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <TextField label={'PPn'}
                            prefix={'Rp'}
                            required
                            errorMessage={error.ppnAmount}
                            value={data.ppnAmount}
                            disabled={submitting}
                            styles={{ root: { width: '33%' } }}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.ppnAmount = value || "";

                                    const { total, subtotal } = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    setData({ ...data });

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.ppnAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <TextField label={'PPh'}
                            prefix={'Rp'}
                            required
                            errorMessage={error.pphAmount}
                            value={data.pphAmount}
                            disabled={submitting}
                            styles={{ root: { width: '33%' } }}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.pphAmount = value || "";

                                    const { total, subtotal } = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.pphAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <Stack styles={{ root: { width: '33%' } }}> </Stack>
                    </Stack>
                </Stack>
                <Stack className={"divider"}></Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className={'color-yellow'} style={{ position: 'relative', top: 1 }} />
                        <Text size={'small'} className={'color-yellow'}>Amount for both insurances are only amount that is borned by the employee. This will act as a deduction to employee's salary.</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <TextField label={'BPJS Kesehatan'}
                            prefix={'Rp'}
                            required
                            disabled={submitting}
                            errorMessage={error.healthInsuranceAmount}
                            value={data.healthInsuranceAmount}
                            styles={{ root: { width: '33%' } }}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.healthInsuranceAmount = value || "";

                                    const { total, subtotal } = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    setData({ ...data });

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.healthInsuranceAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <TextField label={'BPJS Ketenagakerjaan'}
                            prefix={'Rp'}
                            required
                            disabled={submitting}
                            errorMessage={error.workplaceInsuranceAmount}
                            value={data.workplaceInsuranceAmount}
                            styles={{ root: { width: '33%' } }}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.workplaceInsuranceAmount = value || "";

                                    const { total, subtotal } = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.workplaceInsuranceAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <Stack styles={{ root: { width: '33%' } }}> </Stack>
                    </Stack>
                </Stack>
            </Stack>
        });

        sections.push({
            key: 'bonusesAndDeductions',
            subsections: [
                {
                    key: 'bonuses',
                    content: <EmployeePayslipBonusForm disabled={submitting} bonuses={data.bonuses || []} onChange={(bonuses) => {
                        const _data = data;
                        _data.bonuses = bonuses || [];
        
                        const { total, subtotal } = calculateTotalAmount(_data);
                        _data.totalAmount = total;
                        _data.subtotalAmount = subtotal;
        
                        setData({ ...data });
                    }} />
                },
                {
                    key: 'deductions',
                    content: <EmployeePayslipDeductionForm disabled={submitting} deductions={data.deductions || []} onChange={(deductions) => {
                        const _data = data;
                        _data.deductions = deductions || [];

                        const { total, subtotal } = calculateTotalAmount(_data);
                        _data.totalAmount = total;
                        _data.subtotalAmount = subtotal;

                        setData({ ...data });
                    }} />
                }
            ]
        });

        sections.push({
            key: 'commissions',
            content: <EmployeePayslipCommissionForm loading={!data.commissions} 
                employee={data.employee}
                disabled={submitting} 
                commissions={data.commissions || []} 
                onRecalculate={() => {
                    generateCommissions(true);
                }}
                onChange={(commissions) => {
                    const _data = data;
                    _data.commissions = commissions;

                    const { total, subtotal } = calculateTotalAmount(_data);
                    _data.totalAmount = total;
                    _data.subtotalAmount = subtotal;

                    setData({ ...data });
                }} />
        });

        return sections;
    }

    const renderRightStack = (): IStackSectionProps[] => {
        const sections: IStackSectionProps[] = [];

        sections.push({
            key: 'payslipNumber',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Payslip id</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label size={'xsmall'}>#{data.id || ""}</Label>
                    </Stack>
                </Stack>
                {data.contract ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Contract number</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label size={'xsmall'}>#{data.contract.id}</Label>
                    </Stack>
                </Stack> : null}
                {data.subsidiary ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Subsidiary</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label size={'xsmall'}>{data.subsidiary.name}</Label>
                    </Stack>
                </Stack> : null}
            </Stack>
        });

        let totalTax = 0;
        totalTax += Number(data.ppnAmount || "0");
        totalTax += Number(data.pphAmount || "0");

        let totalInsurance = 0;
        totalInsurance += Number(data.healthInsuranceAmount || "0");
        totalInsurance += Number(data.workplaceInsuranceAmount || "0");

        let totalBonuses = 0;
        (data.bonuses || []).forEach((b) => totalBonuses += Number(b.amount || "0"));

        let totalDeductions = 0;
        (data.deductions || []).forEach((d) => totalDeductions += Number(d.amount || "0"));

        let totalCommissions = 0;
        (data.commissions || []).forEach((c) => totalCommissions += Number(c.amount || "0"));

        sections.push({
            key: 'totalAmount',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Base salary</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-green'} size={'xsmall'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(data.salaryAmount || "0"))}</Label>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>BPJS</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-red'} size={'xsmall'}>- Rp. {GeneralService.getNumberWithSeparator(totalInsurance)}</Label>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Bonus</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-green'} size={'xsmall'}>+ Rp. {GeneralService.getNumberWithSeparator(totalBonuses)}</Label>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Deductions</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-red'} size={'xsmall'}>- Rp. {GeneralService.getNumberWithSeparator(totalDeductions)}</Label>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Commissions</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-green'} size={'xsmall'}>+ Rp. {GeneralService.getNumberWithSeparator(totalCommissions)}</Label>
                    </Stack>
                </Stack>
            </Stack>
        });

        sections.push({
            key: 'total',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>Subtotal (bfr. tax)</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.subtotalAmount || "0"))}</Label>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }}>
                        <Text size={'small'}>BPJS</Text>
                    </Stack>
                    <Stack horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Label className={'color-red'} size={'xsmall'}>- Rp. {GeneralService.getNumberWithSeparator(totalTax)}</Label>
                    </Stack>
                </Stack>
                <Stack className={'divider'}> </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 115 } }} verticalAlign={'center'}>
                        <Text size={'small'}>Total (aft. tax)</Text>
                    </Stack>
                    <Stack verticalAlign={'center'} horizontalAlign={'end'} styles={{ root: { width: 225 } }}>
                        <Tag text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.totalAmount || "0"))}`} variant={'active'} />
                    </Stack>
                </Stack>
            </Stack>
        })

        sections.push({
            key: 'actions',
            content: <>
                {submitting ? <LoadingComponent size={SpinnerSize.small} spinnerPosition={'baseline'} label={props.payslipId ? 'Updating payslip ...' : 'Creating payslip ...'} labelPosition={'right'}  /> : null}
                {!submitting ? <PrimaryButton disabled={isSubmitButtonDisabled()} text={props.payslipId ? 'Update' : 'Create'} onClick={_onSubmit} /> : null}
            </>
        })

        return sections;
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
        {loaded ? <DetailsStack left={renderLeftStack()} right={renderRightStack()} rightWidth={350} /> : null}
    </Stack>;
};

export default EmployeePayslipForm;
