import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props

// components
import { Dropdown, DropdownMenuItemType, IDropdownOption, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { IOutcomeCategoryResourceShortProps } from '../../../../props/finance/outcomes/categories';
import OutcomeCategoryService from '../../../../services/finance/outcomes/categories';

type SelectOutcomeCategoryProps = {
    selected?: IOutcomeCategoryResourceShortProps;
    selectedKey?: string;
    options?: IOutcomeCategoryResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    errorMessage?: string;
    onChange(selected?: IOutcomeCategoryResourceShortProps): void;
}

const SelectOutcomeCategory: React.FC<SelectOutcomeCategoryProps> = (props: SelectOutcomeCategoryProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IOutcomeCategoryResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IOutcomeCategoryResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        setSelected(options.find((opt) => opt.id === props.selectedKey));
    }, [props.selectedKey]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _options = await OutcomeCategoryService.retrieve();
        let _selected = props.selected;

        if (props.selectedKey) {
            _selected = _options.find((opt) => opt.id === props.selectedKey)
        }

        setOptions(_options);
        setSelected(_selected);
        setLoaded(true);
    }

    const renderOptions = (categories: IOutcomeCategoryResourceShortProps[]) => {
        let groups: {name: string; categories: IOutcomeCategoryResourceShortProps[]}[] = [];

        // group options
        categories.map((category) => {
            const idx = groups.findIndex((group) => group.name.toLowerCase() === category.group.toLowerCase());
            if (idx > -1) {
                groups[idx].categories.push(category);
            } else {
                groups.push({name: category.group, categories: [category]})
            }
        });

        // sort group by name
        groups = groups.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        // buld dropdown options array
        const options: IDropdownOption[] = [];
        groups.forEach((group, idx) => {
            if (idx > 0) {options.push({key: '__divider_' + group.name, text: "-", itemType: DropdownMenuItemType.Divider})}
            options.push({key: '__group_' + group.name, text: group.name, itemType: DropdownMenuItemType.Header});

            group.categories.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).forEach((category) => {
                options.push({key: category.id, text: category.name});
            });
        });


        return options;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Outcome category"}</Label>}
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            <Stack grow={1}>
                <Dropdown disabled={props.disabled || !loaded}
                    selectedKey={selected?.id}
                    options={renderOptions(options)}
                    onChange={(evt, opt) => {
                        const selected = options.find((o) => o.id === opt?.key as string);
                        props.onChange(selected);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectOutcomeCategory;
