import React, { useEffect, useState } from 'react';
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';
import { DefaultButton, DetailsList, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { IOutcomePaymentResourceProps, IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import PermissionsService from '../../../../../../services/permissions';
import { useStore } from '../../../../../../stores/root';
import ErrorService from '../../../../../../services/general/error';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { IOutcomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/outcomes/payments/invoice';
import LoadingComponent from '../../../../../feedbacks/loading';

interface IOutcomePaymentRejectFormProps {
  payments: IOutcomePaymentResourceShortProps[];
  onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
  reason: string;
}

type FormDataErrorProps = {
  reason?: string;
}

const OutcomePaymentRejectForm: React.FC<IOutcomePaymentRejectFormProps> = (props: IOutcomePaymentRejectFormProps) => {
  const { banner, user } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [data, setData] = React.useState<FormDataProps>({
    reason: "",
  });
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [payments, setPayments] = React.useState<IOutcomePaymentResourceProps[] | undefined>();
  const [error, setError] = React.useState<FormDataErrorProps>({});

  const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.payments.reject'], user.permissions);

  const _onSubmit = async () => {
    setSubmitting(true)
    try {
      const fd = new FormData();
      props.payments.forEach((payment) => {
        fd.append('ids[]', payment.id);
      });
      fd.append('reason', data.reason);

      await OutcomePaymentService.reject(fd);
      props.onDismissed(true)
    } catch (error) {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    _onRetrievePayment();
  }, []);

  const _onRetrievePayment = async () => {
    try {
      setLoaded(false);
      setPayments(props.payments);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        text: 'Failed to retrieve payments. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  return <Panel
    headerText={'Reject Payments'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting && hasPermission ? (
            <>
              <PrimaryButton disabled={data.reason.trim() === '' || error.reason !== undefined} text={"Reject"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}
  >
    {hasPermission ? <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <LoadingComponent label={'Retrieving form ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
      {loaded && payments ? <>
        <Stack>
          <Label size={'xsmall'}>Related payments</Label>
          <DetailsList items={payments || []}
            selectionMode={SelectionMode.none}
            columns={[
              {
                key: 'name',
                name: 'Invoice Details',
                minWidth: 125,
                isMultiline: true,
                onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                  return <Stack styles={{ root: { paddingTop: 8 } }}>
                    {item.invoice ? <>
                      <Label size={'xsmall'}>{item.invoice.name}</Label>
                      {(item.invoice.invoiceNumber || "").trim() !== "" ? <Text size={'small'}>#{item.invoice.invoiceNumber}</Text> : null}
                      {item.invoice.vendor ? <Text size={'small'}>{item.invoice.vendor.name}</Text> : null}
                    </> : null}
                  </Stack>;
                }
              },
              {
                key: 'amount',
                name: 'Payment Amount',
                minWidth: 125,
                maxWidth: 125,
                isMultiline: true,
                onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                  return <Stack styles={{ root: { paddingTop: 8 } }}>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || '0'))}</Text>
                  </Stack>;
                }
              }
            ]} />
        </Stack>
        <Stack>
          <TextField
            label={"Reason"}
            value={data.reason}
            required
            multiline
            rows={3}
            resizable={false}
            autoAdjustHeight
            onChange={(evt, value) => {
              data.reason = value || "";

              const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
              error.reason = validation.message;

              setData({ ...data });
              setError({ ...error });
            }}
            errorMessage={error.reason}
            disabled={submitting} />
        </Stack>
      </> : null}
    </Stack> : null}
  </Panel>
};

export default OutcomePaymentRejectForm;
