import { IEmployeePayslipResourceProps, IEmployeePayslipResourceShortProps } from '../../props/employees/payslips/payslip';
import { IPaginationResourceShort } from '../../props/general';
import Request from '../request';

const EmployeePayslipService = {
  retrieve: async (qs?: string): Promise<{ data: IEmployeePayslipResourceShortProps[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/employees/payslips?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  read: async (id: string): Promise<IEmployeePayslipResourceProps> => {
    try {
      const results = await Request.get(`/api/employees/payslips/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IEmployeePayslipResourceProps> => {
    try {
      const result = await Request.post(`/api/employees/payslips`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IEmployeePayslipResourceProps> => {
    try {
      const result = await Request.post(`/api/employees/payslips/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/employees/payslips/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  }
};

export default EmployeePayslipService;
