import React from 'react';
import { observer } from 'mobx-react';

// assets

// services

// props

// components
import { DetailsList, SelectionMode, Stack } from '@fluentui/react';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import GeneralService from '../../../../../../services/general';
import Text from '../../../../../typography/text';
import { IEmployeePayslipDeductionResourceShortProps } from '../../../../../../props/employees/payslips/deduction';

interface EmployeePayslipDetailsDeductionsProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsDeductions: React.FC<EmployeePayslipDetailsDeductionsProps> = observer((props: EmployeePayslipDetailsDeductionsProps) => {
    const { payslip } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        {payslip.deductions.length < 1 ? <Text size={'small'} style={{fontStyle: 'italic'}}>No deductions found</Text> : null}
        {payslip.deductions.length > 0 ? <DetailsList items={payslip.deductions}
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: 'idx',
                    name: 'No.',
                    minWidth: 25,
                    maxWidth: 25,
                    onRender: (item: IEmployeePayslipDeductionResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{(idx || 0) + 1}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'name',
                    name: 'Name',
                    minWidth: 200,
                    maxWidth: 200,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipDeductionResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{item.name}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'description',
                    name: 'Description',
                    minWidth: 100,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipDeductionResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{item.description || ""}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'amount',
                    name: 'Amount',
                    minWidth: 100,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipDeductionResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                        </Stack>;
                    }
                }
            ]} /> : null}
    </Stack>;
});

export default EmployeePayslipDetailsDeductions;
