import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

// assets

// services

// props

// components
import { DetailsList, SelectionMode, Stack } from '@fluentui/react';
import Details from '../../../../../typography/details';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import GeneralService from '../../../../../../services/general';
import { IEmployeePayslipBonusResourceShortProps } from '../../../../../../props/employees/payslips/bonus';
import Text from '../../../../../typography/text';
import { IEmployeePayslipCommissionResourceShortProps } from '../../../../../../props/employees/payslips/commision';
import SelectOrder from '../../../../../uiframeworks/forms/selectOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

interface EmployeePayslipDetailsCommissionsProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsCommissions: React.FC<EmployeePayslipDetailsCommissionsProps> = observer((props: EmployeePayslipDetailsCommissionsProps) => {
    const { payslip } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        {(payslip.commissions || []).length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No commissions found</Text> : null}
        {(payslip.commissions || []).length > 0 ? <DetailsList items={(payslip.commissions || [])}
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: 'order',
                    name: 'Order Details',
                    minWidth: 100,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                        return <Stack>
                            <SelectOrder selected={item.order}
                                hideLabel
                                target={'_blank'}
                                disabled={true}
                                onChange={() => { }} />
                        </Stack>
                    }
                },
                {
                    key: 'loadingDate',
                    name: 'Start Date',
                    minWidth: 85,
                    maxWidth: 85,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                            {item.order.actualDeparture ? <Text size={'small'}>{moment(item.order.actualDeparture).format("DD/MM/YYYY")}</Text> : null}
                            {!item.order.actualDeparture ? <Text size={'small'}>-</Text> : null}
                        </Stack>
                    }
                },
                {
                    key: 'profit',
                    name: 'Profit',
                    minWidth: 125,
                    maxWidth: 125,
                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.order.nettProfit))}</Text>
                        </Stack>
                    }
                },
                {
                    key: 'description',
                    name: 'Description / Warnings',
                    minWidth: 250,
                    maxWidth: 250,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                        return <Stack styles={{ root: { padding: '10px 0px' } }} tokens={{ childrenGap: 10 }}>
                            {(item.description || "").trim() !== "" ? <Text size={'small'}>{item.description || ""}</Text> : null}
                            {(item.description || "").trim() !== "" && (item.warnings || []).length > 0 ? <Stack className={'divider'}> </Stack> : null}
                            {(item.warnings || []).length > 0 ? <Stack tokens={{ childrenGap: 5 }}>
                                {(item.warnings || []).map((warning) => {
                                    return <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <Stack styles={{ root: { width: 15, marginTop: 3 } }}><FontAwesomeIcon icon={faTriangleExclamation} fontSize={12} className={'color-yellow'} /></Stack>
                                        <Text size={'small'} className={'color-yellow'}>{warning}</Text>
                                    </Stack>
                                })}
                            </Stack> : null}
                        </Stack>
                    }
                },
                {
                    key: 'commission',
                    name: 'Commission',
                    minWidth: 125,
                    maxWidth: 125,
                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                            <Text size={'small'} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                        </Stack>
                    }
                }
            ]} /> : null}
    </Stack>;
});

export default EmployeePayslipDetailsCommissions;
