import { IDataServiceResourceShortProps } from '../../props/data/services';
import Request from './../request';

// import props

const DataServiceService = {
    retrieve: async (qs?: string): Promise<IDataServiceResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/data/services?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
};

export default DataServiceService;