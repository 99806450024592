import { IOrderSalesResourceShortProps } from '../../props/orders/sales';
import Request from './../request';

// import props

const OrderSalesService = {
    retrieve: async (qs?: string): Promise<IOrderSalesResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/orders/sales?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
};

export default OrderSalesService;