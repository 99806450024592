import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

// assets

// services

// props

// components
import { Stack } from '@fluentui/react';
import Details from '../../../../../typography/details';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import GeneralService from '../../../../../../services/general';
import Timeline, { TTimelineItemLine, TTimelineItemStatus } from '../../../../../uiframeworks/timeline';

interface EmployeePayslipDetailsPaymentsProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsPayments: React.FC<EmployeePayslipDetailsPaymentsProps> = observer((props: EmployeePayslipDetailsPaymentsProps) => {
    const { payslip } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        <Timeline items={(payslip.payments || []).map((payment, idx) => {
            let line: TTimelineItemLine | undefined;

            if (payment.status === 'completed') {
                return {
                    key: 'payment_' + idx,
                    status: 'completed',
                    title: 'Payment completed - Rp. ' + GeneralService.getNumberWithSeparator(Number(payment.totalAmount)),
                    date: moment(payment.paidOn).format("DD/MM/YYYY"),
                    line: 'solid'
                }
            } else if (payment.status === 'rejected') {
                return {
                    key: 'payment_' + idx,
                    status: 'cancelled',
                    title: 'Payment rejected - Rp. ' + GeneralService.getNumberWithSeparator(Number(payment.totalAmount)),
                    description: 'Reason: ' + payment.cancellationReason,
                    date: moment(payment.createdAt).format("DD/MM/YYYY"),
                    line: 'solid'
                }
            } else {
                return {
                    key: 'payment_' + idx,
                    status: 'inprogress',
                    title: 'Payment requested - Rp. ' + GeneralService.getNumberWithSeparator(Number(payment.totalAmount)),
                    date: moment(payment.createdAt).format("DD/MM/YYYY"),
                    line: 'solid'
                }
            }
        })} />
    </Stack>;
});

export default EmployeePayslipDetailsPayments;
