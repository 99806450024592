import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Tag from '../../../../uiframeworks/tag';

type EmployeeContractHRApprovalFormProps = {
    contractId: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

const EmployeeContractHRApprovalForm: React.FC<EmployeeContractHRApprovalFormProps> = (props: EmployeeContractHRApprovalFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _contract = await EmployeeContractService.read(props.contractId);
        setContract(_contract);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await EmployeeContractService.approval.hr(props.contractId);
            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'approve_contract_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to approve contract. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Approve by HR"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Approve"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Approving contract ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && contract ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure to approve contract with details below?</Text>
                        <TextField label={"Contract number"} prefix={'#'} value={contract.id} disabled />
                        <SelectEmployee label={'Employee'} selected={contract.employee} disabled onChange={(employee) => {}} />
                        <SelectSubsidiary label={'Subsidiary'} selected={contract.subsidiary} disabled onChange={(subsidiary) => {}} />
                        <Stack className={"divider"}></Stack>
                        <TextField label={'Base salary'} prefix={'Rp'} disabled value={GeneralService.getNumberWithSeparator(contract.salary)} />
                        <Stack className={"divider"}></Stack>
                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                            <Stack grow={1}>
                                <TextField label={'Start date'} value={moment(contract.startDate).format("DD/MM/YYYY")} disabled />
                            </Stack>
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 38 }} />
                            <Stack grow={1}>
                                <TextField label={'End date'} value={moment(contract.endDate).format("DD/MM/YYYY")} disabled />
                            </Stack>
                        </Stack>
                        <Stack className={"divider"}></Stack>
                        <Label size={'xsmall'}>Jobdesks</Label>
                        <Stack horizontal tokens={{childrenGap: 5}} wrap>{contract.jobdesks.map((jobdesk) => <Tag size={'small'} text={jobdesk.name} />)}</Stack>
                        <Stack className={"divider"}></Stack>
                        <Label size={'xsmall'}>Commissions</Label>
                        <Stack horizontal tokens={{childrenGap: 5}} wrap>{(contract.commissions || []).map((commission) => <Tag size={'small'} text={`${GeneralService.getNumberWithSeparator(Number(commission.startAmount))} - ${GeneralService.getNumberWithSeparator(Number(commission.endAmount))} (${GeneralService.getNumberWithSeparator(Number(commission.commissionPercentage))}%)`} />)}</Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployeeContractHRApprovalForm;
