import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import IncomeInvoicesList from '../../../components/finances/incomes/invoices/general/list';

type IncomeInvoicesPageProps = {
}

const IncomeInvoicesPage: React.FC<IncomeInvoicesPageProps> = (props: IncomeInvoicesPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Income Invoices", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance', href: "/finance"},
            {key: "incomes", text: 'Incomes', href: "/finance/incomes"},
            {key: "invoices", text: 'Invoices'}
        ])
    }, []);

    return <Stack>
        <IncomeInvoicesList />
    </Stack>;
};

export default IncomeInvoicesPage;
