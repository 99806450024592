import { IIncomePaymentResourceProps, IIncomePaymentResourceShortProps } from '../../../../props/finance/incomes/payments/payment';
import { IPaginationResourceShort } from '../../../../props/general';
import Request from './../../../request';

// import props

const IncomePaymentService = {
    retrieve: async (qs?: string): Promise<{data: IIncomePaymentResourceShortProps[], pagination: IPaginationResourceShort}> => {
        try {
            const result = await Request.get(`/api/incomes/payments?${qs || ""}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (id: string): Promise<IIncomePaymentResourceShortProps> => {
        try {
            const result = await Request.get(`/api/incomes/payments/${id}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    create: async (data: FormData): Promise<IIncomePaymentResourceProps> => {
        try {
            const result = await Request.post(`/api/incomes/payments`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/incomes/payments/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
}

export default IncomePaymentService;