import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IIncomeInvoiceResourceProps, IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import Label from '../../../../../typography/label';
import Tag from '../../../../../uiframeworks/tag';
import Text from '../../../../../typography/text';
import moment from 'moment';
import OrderService from '../../../../../../services/orders/order';
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';
import { observer } from 'mobx-react';
import PermissionsService from '../../../../../../services/permissions';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import IncomeInvoiceDocumentsList from '../../documents/general/list';
import IncomeInvoicePaymentList from '../../payments/general/list';
import IncomeInvoicePaymentForm from '../../payments/general/form';
import IncomeInvoiceRelatedItems from './relatedItems';
import Details from '../../../../../typography/details';
import IncomeInvoiceCancelForm from '../form/cancel';
import SimpleMessageBarComponent from '../../../../../feedbacks/simpleMessageBar';
import IncomeInvoiceForm from '../form';
import IncomePaymentForm from '../../../payments/general/form';
import IncomePaymentsList from '../../../payments/general/list';
import OrdersList from '../../../../../orders/general/list';

type IncomeInvoiceDetailsProps = {
    invoiceId: string;
    onDismissed(refresh?: boolean): void;
}

const IncomeInvoiceDetails: React.FC<IncomeInvoiceDetailsProps> = observer((props: IncomeInvoiceDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [invoice, setInvoice] = React.useState<IIncomeInvoiceResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [downloading, setDownloading] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(false);
        const _invoice = await IncomeInvoiceService.get(props.invoiceId);
        setInvoice(_invoice);
        setLoaded(true);
    }

    const getCommandBarItems = (_invoice: IIncomeInvoiceResourceShortProps) => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'download',
            text: 'Download',
            onRender: () => {
                return <PrimaryButton text={"Download"} iconProps={{ iconName: "Download" }} onClick={() => {
                    _onDownloadInvoice('docx');
                }} styles={{ root: { marginRight: 20 } }} />
            }
        });

        if (PermissionsService.hasPermission(['incomes.invoices.update.all'], user.permissions) && _invoice.status !== 'Cancelled') {
            items.push({
                key: 'update',
                text: 'Update',
                onRender: () => {
                    return <ActionButton text={"Update"} iconProps={{ iconName: "Edit" }} onClick={() => setActiveSurface('invoice.update')} />
                }
            });
        }

        const hasRecordPermission = PermissionsService.hasPermission(['incomes.invoices.payments.record'], user.permissions);
        if (Number(_invoice.unpaid) > 0 && hasRecordPermission) {
            items.push({
                key: 'recordPayment',
                text: 'Record Payment',
                onRender: () => {
                    return <ActionButton text={"Record Payment"} iconProps={{ iconName: "Add" }} onClick={() => setActiveSurface('payment.record')} />
                }
            });
        }

        /*if (PermissionsService.hasPermission(['incomes.invoices.cancel'], user.permissions) && _invoice.status !== 'Cancelled') {
            farItems.push({
                key: 'cancel',
                text: 'Cancel',
                onRender: () => {
                    return <ActionButton text={"Cancel"} iconProps={{ iconName: "Cancel" }} onClick={() => setActiveSurface('invoice.cancel')} />
                }
            });
        }*/

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (activeSurface === 'delete' && refresh) {
            props.onDismissed(true);
        }

        setActiveSurface(undefined);

        if (refresh) { init(); }
    }

    const _onDownloadInvoice = async (format: string) => {
        try {
            setDownloading(true);
            const name = IncomeInvoiceService.getInvoiceNumber(props.invoiceId);
            await IncomeInvoiceService.download(props.invoiceId, format, name);

            banner.add({
                key: 'download_invoice_success',
                text: `Invoice "${name}" downloaded successfully`,
                icon: faCheck,
                variant: 'success'
            });
            setDownloading(false);
        } catch (e) {
            setDownloading(false);
            banner.add({
                key: 'download_invoice_error',
                text: 'Failed to download invoice. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const renderTotalAmount = (_invoice: IIncomeInvoiceResourceProps) => {
        let totalAmount = _invoice.totalAmount;
        let ppn = _invoice.ppn;
        let pph = _invoice.pph;

        if (_invoice.order) {
            totalAmount = _invoice.order.totalAmount;
            ppn = _invoice.order.ppn;
            pph = _invoice.order.pph;
        }

        let ppnPercentageText = "No PPn";
        if (_invoice.ppnPercentage === 'custom') {
            ppnPercentageText = 'custom';
        } else if (Number(_invoice.ppnPercentage) > 0) {
            ppnPercentageText = Number(_invoice.ppnPercentage) + "%";
        }

        let pphPercentageText = "No PPh";
        if (_invoice.pphPercentage === 'custom') {
            pphPercentageText = 'custom';
        } else if (Number(_invoice.pphPercentage) > 0) {
            pphPercentageText = Number(_invoice.pphPercentage) + "%";
        }

        return <Details title={"Total amount"} style={{ width: '25%' }}>
            <Text>Rp. {GeneralService.getNumberWithSeparator(Number(totalAmount))}</Text>
            <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>PPn: Rp. {GeneralService.getNumberWithSeparator(Number(ppn))}</Text>
            <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>PPh: Rp. {GeneralService.getNumberWithSeparator(Number(pph))}</Text>
        </Details>;
    }

    const renderInvoiceAmount = (_invoice: IIncomeInvoiceResourceProps) => {
        let invoiceAmount = _invoice.invoiceAmount;

        if (_invoice.order) {
            invoiceAmount = _invoice.order.invoiceAmount;
        }

        return <Details title={"Amount to be paid"}
            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(invoiceAmount))}`}
            style={{ width: '25%' }} />;
    }

    return <Panel headerText={invoice && (invoice?.name || "").trim() !== "" ? invoice.name : 'Invoice Details'}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(false)}>
        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Retrieving invoice details ..."} /></Stack> : null}
            {
                loaded && invoice ? <>
                    {invoice.status === 'Paid' ? <SimpleMessageBarComponent properties={{
                        type: MessageBarType.success,
                        text: `This invoice has been fully paid on ${moment(invoice.paidOn).format("DD/MM/YYY")}`
                    }} /> : null}
                    {invoice.status === 'Cancelled' ? <SimpleMessageBarComponent properties={{
                        type: MessageBarType.warning,
                        text: `This invoice has been cancelled${invoice.actionedBy ? " by " + invoice.actionedBy.name : ""}. Justification: ${invoice.justification}`
                    }} /> : null}
                    <CommandBar {...getCommandBarItems(invoice)} styles={{ root: { margin: 0, padding: 0 } }} />
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Details title={"Invoice number"}
                            text={"#" + IncomeInvoiceService.getInvoiceNumber(invoice.id)}
                            style={{ width: '25%' }} />
                        {invoice.status === 'Paid' ? <Details title={`Paid on`}
                            text={moment(invoice.paidOn).format("DD/MM/YYYY")}
                            style={{ width: '25%' }} /> : null}
                        {invoice.status === 'Unpaid' ? <Details title={`Due date`}
                            text={GeneralService.getFriendlyDateFormat(invoice.dueDate)}
                            style={{ width: '25%' }} /> : null}
                        <Details title={`Subsidiary`}
                            text={invoice.order?.subsidiary.name || invoice.subsidiary?.name || "-"}
                            style={{ width: '25%' }} />
                        <Details title={`Billed to`}
                            text={invoice.order?.company?.name || invoice.company?.name || "-"}
                            style={{ width: '25%' }} />
                    </Stack>
                    <Stack>
                        <Details title={'Notes'} text={invoice.notes || "-"}></Details>
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        {renderTotalAmount(invoice)}
                        {renderInvoiceAmount(invoice)}
                        <Stack styles={{ root: { width: '25%' } }}> </Stack>
                        <Stack styles={{ root: { width: '25%' } }}> </Stack>
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Details title={"Paid"}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(invoice.paid))}`}
                            className={{ text: "color-green" }}
                            style={{ width: '25%' }} />
                        <Details title={"Pending"}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(invoice.unpaid))}`}
                            className={{ text: "color-yellow" }}
                            style={{ width: '25%' }} />
                        <Details title={"Unpaid"}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(invoice.unpaid))}`}
                            className={{ text: "color-red" }}
                            style={{ width: '25%' }} />
                        <Stack styles={{ root: { width: '25%' } }}> </Stack>
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Pivot className={'linePivot'}>
                        {(invoice.items || []).length > 0 ? <PivotItem key={'itemsList'} headerText={"Items List"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <IncomeInvoiceRelatedItems invoice={invoice} />
                            </Stack>
                        </PivotItem> : null}
                        {invoice.order ? <PivotItem key={'orderDetails'} headerText={"Order Details"}>
                            <OrdersList orders={[invoice.order]} hideStatusFilter hideCommandBar hideFilter hideCreateOrder hideSearch
                                columns={['orderNumber', 'customer', 'batch', 'deliveryDetails', 'deliveryDetails', 'price']} />
                        </PivotItem> : null}
                        <PivotItem key={'paymentsList'} headerText={"Payments List"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <IncomePaymentsList variant='plain' invoiceId={invoice.id} hideCommandBar={true} />
                            </Stack>
                        </PivotItem>
                        <PivotItem key={'documentList'} headerText={"Document List"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <IncomeInvoiceDocumentsList variant='plain' orderInvoiceId={invoice.id} />
                            </Stack>
                        </PivotItem>
                    </Pivot>
                </> : null
            }
        </Stack>
        {downloading ? <LoadingDialogComponent title='Downloading Invoice' secondaryText="Please wait. Your invoice will be downloaded momentarily." /> : null}
        {activeSurface === 'payment.record' ? <IncomePaymentForm invoiceId={props.invoiceId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'invoice.update' && invoice ? <IncomeInvoiceForm invoiceId={invoice.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'invoice.cancel' && invoice ? <IncomeInvoiceCancelForm invoice={invoice} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'delete' && invoice ? <DeleteOrderInvoiceForm invoice={invoice} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Panel>
});

export default IncomeInvoiceDetails;
