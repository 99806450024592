import React from 'react';

// import stylings
import parentStyles from './../style.module.scss';
import styles from './style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services
import UserService from '../../services/users/user';

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import LoadingComponent from '../../components/feedbacks/loading/index';
import { ActionButton, Icon, Link, PrimaryButton, TextField } from '@fluentui/react';
import ValidationService from '../../services/validation';
import { IRenderMessageBarProps } from '../../props/general';
import GeneralService from '../../services/general';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import SimpleMessageBarComponent from '../../components/feedbacks/simpleMessageBar';
import LoadingDialogComponent from '../../components/feedbacks/loadingDialog/index';
import Text from './../../components/typography/text';
import Label from '../../components/typography/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faUser } from '@fortawesome/pro-light-svg-icons';

interface ILoginProps {
    user?: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface ILoginState {
    loaded?: boolean;
    user?: IUserResourceShortProps;
    mode: 'login' | 'select';
    email: string;
    password: string;
    errorEmail?: string;
    errorPassword?: string;
    processing?: boolean;
    hasAccess?: boolean;
    redirecting?: boolean;
    messageBar?: IRenderMessageBarProps;
}

export default class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            email: "",
            password: "",
            mode: props.user ? 'select' : 'login',
            user: props.user
        }
    }

    private _onEmailValueChanged = (evt?: any, value?: string) => {
        const validation = ValidationService.combination(value, ['required', 'email'], {});
        this.setState({ email: value || "", errorEmail: validation.message });
    }

    private _onPasswordValueChanged = (evt?: any, value?: string) => {
        const validation = ValidationService.combination(value, ['required'], {});
        this.setState({ password: value || "", errorPassword: validation.message });
    }

    private isSubmitButtonDisabled = (): boolean => {
        const { email, password, errorEmail, errorPassword } = this.state;
        if (email.trim() === "" || password.trim() === "" || errorEmail || errorPassword) { return true };
        return false;
    }

    private _onKeyUp = (evt?: any) => {
        const { email, password, errorEmail, errorPassword } = this.state;
        if (evt && evt.which === 13 && email.toLowerCase() !== "" && password.toLowerCase() !== "" && !errorEmail && !errorPassword) {
            this._onSubmit();
        }
    }

    private _onLogin = () => {
        const { user } = this.state;

        if (user) {
            // check if user has permission to access admin page
            let hasAccess = false;
            user.roles.forEach((role) => {
                if (role.group.toLowerCase() === 'backoffice') { hasAccess = true; }
            });

            this.setState({
                redirecting: true,
                hasAccess,
                user
            })

            setTimeout(() => {
                const origin = GeneralService.getParameterByName('_o');
                if (user.isTempPassword) {
                    window.location.href = "/auth/resetPassword";
                } else if (origin && origin !== '') {
                    window.location.href = origin;
                } else {
                    window.location.href = "/";
                }
            }, 4000);
        }
    }

    private _onSubmit = async () => {
        try {
            const { email, password } = this.state;

            this.setState({ processing: true });
            const isRegistered = await UserService.isRegistered(email);
            if (isRegistered) {
                await UserService.login(email, password);
                const user = await UserService.currentUser();
                this.setState({ user }, () => { this._onLogin() })
            } else {
                this.setState({
                    messageBar: {
                        type: MessageBarType.error,
                        content: <Text size={"small"}>Maaf, email {email} belum terdaftar di dalam sistem kami.</Text>
                    },
                    errorEmail: "Email belum terdaftar di dalam sistem kami.",
                    processing: false
                });
            }
        } catch (e) {
            this.setState({
                messageBar: {
                    content: <Text size={"small"}>{GeneralService.generateErrorMessage(e)}</Text>,
                    type: MessageBarType.error
                },
                processing: false
            });
        }
    }

    public render() {
        const { user } = this.props;

        return (
            <Stack className={styles.login} tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Label className={parentStyles.text}>Login</Label>
                </Stack>
                {this.state.messageBar ? <SimpleMessageBarComponent properties={this.state.messageBar} onDismiss={() => { this.setState({ messageBar: undefined }) }} /> : null}
                {
                    user && this.state.mode === 'select' ? (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Text>Pilih akun yang ingin anda gunakan:</Text>
                            <ActionButton className={styles.userCard} styles={{ flexContainer: { display: 'block' } }} onClick={() => { this._onLogin(); }}>
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <Stack verticalAlign={"center"} className={styles.userIcon}>
                                        <FontAwesomeIcon icon={faUser} size={'1x'} />
                                    </Stack>
                                    <Stack verticalAlign={"center"} className={styles.userDetails} grow={1} horizontalAlign={"baseline"}>
                                        <Text size={"large"} style={{ fontWeight: 600 }}>{user.name}</Text>
                                        <Text size={"small"}>{user.email}</Text>
                                    </Stack>
                                </Stack>
                            </ActionButton>
                            <ActionButton className={styles.userCard} styles={{ flexContainer: { display: 'block' } }} onClick={() => { this.setState({ mode: 'login' }) }}>
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <Stack verticalAlign={"center"} className={styles.userIcon}>
                                        <FontAwesomeIcon icon={faLockOpen} size={'1x'} />
                                    </Stack>
                                    <Stack verticalAlign={"center"} className={styles.userDetails} grow={1} horizontalAlign={"baseline"}>
                                        <Text size={"medium"} style={{ fontWeight: 600 }}>Login menggunakan user lain</Text>
                                    </Stack>
                                </Stack>
                            </ActionButton>
                        </Stack>
                    ) : null
                }
                {
                    !user || this.state.mode === 'login' ? (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <TextField label={"Email"}
                                    errorMessage={this.state.errorEmail}
                                    onChange={this._onEmailValueChanged}
                                    disabled={this.state.processing}
                                    required={true}
                                    onKeyUp={this._onKeyUp}
                                    name='email'
                                    styles={{ fieldGroup: { background: 'transparent' } }} />
                            </Stack.Item>
                            <Stack tokens={{ childrenGap: 8 }}>
                                <TextField label={"Password"}
                                    type={"password"}
                                    name='password'
                                    errorMessage={this.state.errorPassword}
                                    onChange={this._onPasswordValueChanged}
                                    disabled={this.state.processing}
                                    onKeyUp={this._onKeyUp}
                                    required={true}
                                    styles={{ fieldGroup: { background: 'transparent' } }} />
                                <Stack.Item>
                                    <Text>Lupa password? <Link href={"/auth/forgetPassword"}>Klik disini</Link> untuk reset password.</Text>
                                </Stack.Item>
                            </Stack>
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                {this.state.processing ? <LoadingComponent label={"Harap tunggu ..."} labelPosition={"right"} spinnerPosition={"center"} /> : null}
                                {
                                    !this.state.processing ? (
                                        <PrimaryButton id='submitButton' disabled={this.isSubmitButtonDisabled()} text={"Masuk"} onClick={this._onSubmit} />
                                    ) : null
                                }
                            </Stack>
                        </Stack>
                    ) : null
                }
                {this.state.redirecting && this.state.user ? <LoadingDialogComponent title={`Halo ${this.state.user.name}!`} secondaryText={this.state.hasAccess ? "Selamat datang di portal administrasi Jejak Logistik. Kami akan meneruskan anda ke portal administrasi Jejak Logistik dalam beberapa saat." : "Maaf, akun {this.state.user.email} tidak memiliki cukup akses untuk dapat mengakses portal administrasi. Kami akan meneruskan anda ke portal pelanggan Jejak Logistik dalam beberapa saat."} /> : null}
            </Stack>
        );
    }
}