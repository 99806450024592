import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';

// assets

// services
import ValidationService from '../../../../../../../services/validation';
import OutcomeService from '../../../../../../../services/finance/outcomes';

// props
import { IOutcomeResourceProps } from '../../../../../../../props/finance/outcomes';

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { FormDataProps } from '../props';
import LoadingComponent from '../../../../../../feedbacks/loading';
import GeneralService from '../../../../../../../services/general';
import LoadingDialogComponent from '../../../../../../feedbacks/loadingDialog';
import SelectSubsidiary from '../../../../../../uiframeworks/forms/selectSubsidiary';
import SelectOutcomeCategory from '../../../../../../uiframeworks/forms/selectOutcomeCategory';
import Label from '../../../../../../typography/label';
import SelectBankAccount from '../../../../../../uiframeworks/forms/bankaccounts';
import moment from 'moment';
import { faCheck } from '@fortawesome/pro-light-svg-icons';


type InternalTransferDetailsFormProps = {
    outcomeId?: string;
    disabled?: boolean;
    onDismissed(refresh?: boolean, outcome?: IOutcomeResourceProps): void;
}

const InternalTransferDetailsForm: React.FC<InternalTransferDetailsFormProps> = (props: InternalTransferDetailsFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IOutcomeResourceProps>>({ id: GeneralService.guid() });
    const [error, setError] = React.useState<any>({});

    const mode = props.outcomeId ? 'update' : 'create';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.outcomeId) {
            const _outcome = await OutcomeService.get(props.outcomeId);
            setData({..._outcome});
        }
        
        setLoaded(true);
    }

    const isSubmitButtonDisabled = () => {
        if ((data.name || "").trim() === "" || ((data.originalAmount || "") + "").trim() === "") {
            return true;
        } else if (error.name || error.notes || error.originalAmount) {
            return true;
        } else if (!data.category || !data.targetSubsidiary || !data.subsidiary || !data.bankAccount) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            const { type, originalAmount, dueDate, name, notes, bankAccount, targetSubsidiary, targetBankAccount, subsidiary, invoiceDate} = data;
            setSubmitting(true);

            const fd = new FormData();
            fd.append("type", type || "others");
            fd.append("dueDate", dueDate || moment().toISOString());
            fd.append("name", name || "");
            fd.append("invoiceNumber", "");
            fd.append("notes", notes || "");
            fd.append("invoiceDate", invoiceDate || moment().toISOString());

            fd.append("includePPn", "0");
            fd.append("includePPh", "0");
            fd.append("ppnPercentage", "0");
            fd.append("pphPercentage", "0");
            fd.append("ppn", "0");
            fd.append("pph", "0");

            fd.append("amount", originalAmount + "");
            fd.append("originalAmount", originalAmount + "");
            fd.append("totalAmount", originalAmount + "");

            fd.append("subsidiaryId", subsidiary?.id || "");
            fd.append("bankAccountId", bankAccount?.id || "");
            fd.append("targetSubsidiaryId", targetSubsidiary?.id || "");
            fd.append("targetBankAccountId", targetBankAccount?.id || "");

            if (props.outcomeId) {
                await OutcomeService.update(props.outcomeId, fd);
            } else {
                await OutcomeService.create(fd);
            }

            banner.add({
                key: mode + '_outcome_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={data?.name || "Internal Transfer Form"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'} verticalAlign='center'>
                {!submitting ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                    <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
                </Stack> : null}
                {submitting ? <LoadingComponent labelPosition={'right'} spinnerPosition={'start'} label={(mode === 'create' ? "Creating" : 'Updating') + " internal transfer ..."} /> : null}
            </Stack>;
        }}>
        {!loaded ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <Stack tokens={{ childrenGap: 15 }}>
            <Label size={'small'}>Transfer from</Label>
            <SelectSubsidiary label={'Subsidiary'}
                required={true}
                selected={data.subsidiary}
                onChange={(selected) => {
                    data.subsidiary = selected;
                    data.bankAccount = undefined;
                    setData({ ...data });
                }} />
            <SelectBankAccount label={'Bank account'}
                waiting={!data.subsidiary}
                required={true}
                selected={data.bankAccount}
                subsidiaryId={data.subsidiary?.id}
                onChange={(selected) => {
                    data.bankAccount = selected;
                    setData({ ...data });
                }} />
            <Stack className={'divider'}> </Stack>
            <Label size={'small'}>Transfer to</Label>
            <SelectSubsidiary label={'Subsidiary'}
                required={true}
                selected={data.targetSubsidiary}
                onChange={(selected) => {
                    data.targetSubsidiary = selected;
                    data.targetBankAccount = undefined;
                    setData({ ...data });
                }} />
            <Stack className={'divider'}> </Stack>
            <SelectOutcomeCategory label={'Select category'}
                required={true}
                selected={data.category}
                onChange={(selected) => {
                    data.category = selected;
                    setData({ ...data });
                }} />
            <Stack.Item>
                <TextField label={"Name"}
                    required={true}
                    value={data.name}
                    onChange={(evt, value) => {
                        data.name = value || "";

                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                        error.name = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.name} />
            </Stack.Item>
            <Stack.Item>
                <TextField label={"Notes"}
                    required={false}
                    multiline
                    rows={5}
                    autoAdjustHeight
                    resizable={false}
                    value={data.notes}
                    onChange={(evt, value) => {
                        data.notes = value || "";

                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                        error.notes = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.notes} />
            </Stack.Item>
            <Stack className={'divider'}></Stack>
            <Stack>
                <TextField label={"Amount"}
                    prefix='Rp'
                    required={true}
                    value={(data.originalAmount || "") + ""}
                    onChange={(evt, value) => {
                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                            data.originalAmount = (value || "");

                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 25 });
                            error.amount = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }
                    }}
                    errorMessage={error.amount} />
            </Stack>
            <Stack.Item>
                <DatePicker label={"Due Date"}
                    isRequired={true}
                    value={moment(data.dueDate).toDate()}
                    onSelectDate={(date) => {
                        data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                        setData({ ...data });
                    }}
                    formatDate={GeneralService.formatDate}
                    disabled={submitting} />
            </Stack.Item>
        </Stack> : null}
    </Panel>
};

export default InternalTransferDetailsForm;
