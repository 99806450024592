import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import EmployeeContractsList from '../../../components/hr/contracts/general/list';
import EmployeePayslipsList from '../../../components/hr/payslips/general/list';

interface HRPayslipsListPageProps {
}

const HRPayslipsListPage: React.FC<HRPayslipsListPageProps> = (props: HRPayslipsListPageProps) => {
    const store = useStore();
    
    useEffect(() => {
        store.topbar.show('Employee Payslips', [
            {key: 'home', icon: faHome},
            {key: 'employees', text: 'Employees'},
            {key: 'payslips', text: 'Payslips'}
        ]);
    }, []);

    return <Stack>
        <EmployeePayslipsList />
    </Stack>;
};

export default HRPayslipsListPage;
