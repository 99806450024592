import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';
import PermissionsService from '../../../../services/permissions';
import { DatePicker, Dropdown, Stack } from '@fluentui/react';
import NoAccess from '../../../uiframeworks/noAccess';
import moment from 'moment';
import { IOutcomeResourceShorterProps } from '../../../../props/finance/outcomes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../services/general';
import NoPermission from '../../../uiframeworks/noPermission';
import OutcomeService from '../../../../services/finance/outcomes';
import ErrorService from '../../../../services/general/error';
import OutcomeInvoicesSummary from './invoices';

// assets

// services

// props

// components

type OutcomesSummaryProps = {
}

export type TSearchBy = 'invoices' | 'payments';
export type TBasedOn = 'created_date' | 'invoice_date' | 'payment_date';

const OutcomesSummary: React.FC<OutcomesSummaryProps> = (props: OutcomesSummaryProps) => {
    const { banner, user } = useStore();
    const [searchBy, setSearchBy] = React.useState<TSearchBy>("invoices");
    const [basedOn, setBasedOn] = React.useState<TBasedOn>("created_date");
    const [dateRange, setDateRange] = React.useState<string>("thisweek");
    const [selectedDate, setSelectedDate] = React.useState<{ startDate: string, endDate: string }>({
        startDate: moment().startOf('week').toISOString(),
        endDate: moment().endOf('week').toISOString()
    });
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [invoices, setInvoices] = React.useState<IOutcomeResourceShorterProps[]>();
    const [filteredInvoices, setFilteredInvoices] = React.useState<IOutcomeResourceShorterProps[]>();

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.read.all', 'outcomes.invoices.payments.read.all'], user.permissions);

    useEffect(() => {

    }, []);

    const _onRetrieveInvoices = async () => {
        try {
            setLoaded(false);
            let groupByFilterText = 'date';

            const qs: string[] = [];
            qs.push(`start_date=${selectedDate.startDate}`);
            qs.push(`end_date=${selectedDate.endDate}`);
            qs.push(`top=all`);

            const _invoices = await OutcomeService.retrieve(qs.join('&'));

            setInvoices(_invoices.data);
            setFilteredInvoices(_invoices.data);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_invoices_error',
                text: 'Failed to retrieve invoices. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <>
        {!hasPermission ? <NoPermission /> : null}
        {hasPermission ? <Stack tokens={{childrenGap: 20}}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Dropdown label={'Search by'}
                    options={[
                        { key: 'invoices', text: 'Invoices' },
                        { key: 'payments', text: 'Payments', disabled: true }
                    ]}
                    styles={{ root: { width: 200 } }}
                    selectedKey={searchBy}
                    onChange={(ev, opt) => {
                        setSearchBy(opt?.key as TSearchBy || 'invoices');
                    }} />
                <Dropdown label={'Based on'}
                    options={[
                        { key: 'created_date', text: 'Created date' },
                        { key: 'invoice_date', text: 'Invoice date' },
                        { key: 'payment_date', text: 'Payment date (only paid invoices)' }
                    ]}
                    styles={{ root: { width: 200 } }}
                    selectedKey={basedOn}
                    onChange={(ev, opt) => {
                        setBasedOn(opt?.key as TBasedOn || 'created_date');
                    }} />
                <Dropdown label={'Date range'}
                    options={[
                        { key: 'thisweek', text: 'This week' },
                        { key: 'lastweek', text: 'Last week' },
                        { key: 'thismonth', text: 'This month' },
                        { key: 'lastmonth', text: 'Last month' },
                        { key: 'last3month', text: 'Last 3 months' },
                        { key: 'thisyear', text: 'This year' },
                        { key: 'lastyear', text: 'Last year' },
                        { key: 'custom', text: 'Custom' }
                    ]}
                    styles={{ root: { width: 200 } }}
                    selectedKey={dateRange}
                    onChange={(ev, opt) => {
                        let _startDate = selectedDate.startDate;
                        let _endDate = selectedDate.endDate;
                        let _dateRange = opt?.key as string || dateRange;

                        if (_dateRange === 'thismonth') {
                            _startDate = moment().startOf('month').toISOString();
                            _endDate = moment().endOf('month').toISOString();
                        } else if (_dateRange === 'lastmonth') {
                            _startDate = moment().startOf('month').add(-1, 'month').toISOString();
                            _endDate = moment().endOf('month').add(-1, 'month').toISOString();
                        } else if (_dateRange === 'last3month') {
                            _startDate = moment().startOf('month').add(-2, 'month').toISOString();
                            _endDate = moment().endOf('month').toISOString();
                        } else if (_dateRange === 'thisweek') {
                            _startDate = moment().startOf('week').toISOString();
                            _endDate = moment().endOf('week').toISOString();
                        } else if (_dateRange === 'lastweek') {
                            _startDate = moment().startOf('week').add(-1, 'week').toISOString();
                            _endDate = moment().endOf('week').add(-1, 'week').toISOString();
                        } else if (_dateRange === 'thisyear') {
                            _startDate = moment().startOf('year').toISOString();
                            _endDate = moment().endOf('year').toISOString();
                        } else if (_dateRange === 'lastyear') {
                            _startDate = moment().startOf('year').add(-1, 'year').toISOString();
                            _endDate = moment().endOf('year').add(-1, 'year').toISOString();
                        }

                        setSelectedDate({ startDate: _startDate, endDate: _endDate });
                        setDateRange(_dateRange);
                    }} />
                {dateRange === 'custom' ? <Stack verticalAlign='center' horizontal tokens={{ childrenGap: 20 }} styles={{ root: { paddingTop: 26 } }}>
                    <DatePicker value={moment(selectedDate.startDate).toDate()}
                        styles={{ root: { width: 150 } }}
                        formatDate={GeneralService.formatDate}
                        onSelectDate={(date) => {
                            const _startDate = date ? moment(date).startOf('day').toISOString() : selectedDate.startDate;
                            setSelectedDate({ startDate: _startDate, endDate: selectedDate.endDate });
                        }} />
                    <Stack><FontAwesomeIcon icon={faArrowRight} /></Stack>
                    <DatePicker value={moment(selectedDate.endDate).toDate()}
                        styles={{ root: { width: 150 } }}
                        formatDate={GeneralService.formatDate}
                        onSelectDate={(date) => {
                            const _endDate = date ? moment(date).endOf('day').toISOString() : selectedDate.endDate;
                            setSelectedDate({ startDate: selectedDate.startDate, endDate: _endDate });
                        }} />
                </Stack> : null}
            </Stack>
            <Stack className={'divider'}></Stack>
            {searchBy === 'invoices' ? <OutcomeInvoicesSummary basedOn={basedOn} {...selectedDate} /> : null}
        </Stack> : null}
    </>;
};

export default OutcomesSummary;
