import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services
import ValidationService from '../../../../../services/validation';

// props

// components
import { ActionButton, CommandBar, DefaultButton, DetailsList, IColumn, Panel, PanelType, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react';
import { FormDataProps } from '.';
import Text from '../../../../typography/text';
import { IVendorPICResourceShortProps } from '../../../../../props/data/vendors/pic';
import Tag from '../../../../uiframeworks/tag';
import { faCancel, faCheck } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../../services/general';

type VendorPICFormProps = {
    data: Partial<FormDataProps>;
    onChange(data: Partial<FormDataProps>): void;
}

const VendorPICForm: React.FC<VendorPICFormProps> = (props: VendorPICFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<FormDataProps>>(props.data);
    const [error, setError] = React.useState<any>({});

    const [picData, setPICData] = React.useState<Partial<IVendorPICResourceShortProps> | undefined>();
    const [showPanel, setShowPanel] = React.useState<boolean>();

    const columns: IColumn[] = [
        {
            key: 'name',
            name: "Name",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: 'phoneNumber',
            name: "Phone Number",
            minWidth: 125,
            maxWidth: 125,
            isMultiline: true,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{item.phoneNumber}</Text>
                </Stack>
            }
        },
        {
            key: 'role',
            name: "Role",
            minWidth: 75,
            maxWidth: 75,
            isMultiline: true,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                <Text size={'small'}>{item.role || ""}</Text>
            </Stack>
            }
        },
        {
            key: 'department',
            name: "Department",
            minWidth: 75,
            maxWidth: 75,
            isMultiline: true,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                <Text size={'small'}>{item.department || ""}</Text>
            </Stack>
            }
        },
        {
            key: 'actions',
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IVendorPICResourceShortProps) => {
                return <Stack>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                            const idx = (data.pics || []).findIndex((d) => d.id === item.id);
                            if (idx > -1) {
                                (data.pics || []).splice(idx, 1);
                                props.onChange(data);
                            }
                        }} />
                    </Stack.Item>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        setData(props.data);
        setShowPanel(false);
        setPICData(undefined);
    }, [props.data]);

    const isSubmitButtonDisabled = () => {
        if (!picData) {
            return true;
        } else if ((picData.name || "").trim() === "" || (picData.phoneNumber || "").trim() === "") {
            return true;
        }

        return false;
    }

    return <Stack>
        <CommandBar styles={{ root: { padding: 0, margin: 0 } }}
            items={[
                { key: "add", text: "Add PIC", iconProps: { iconName: "Add" }, onClick: () => setShowPanel(true) }
            ]} />
        <DetailsList items={data.pics || []}
            columns={columns}
            selectionMode={SelectionMode.none} />
        <Panel headerText={"Vendor PIC"}
            isOpen={showPanel}
            type={PanelType.medium}
            onDismiss={() => setShowPanel(false)}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={'Submit'} disabled={isSubmitButtonDisabled()} onClick={() => {
                        const _pics = data.pics || [];
                        _pics.push({
                            id: GeneralService.guid(),
                            name: picData?.name || "",
                            phoneNumber: picData?.phoneNumber || "",
                            address: picData?.address || "",
                            notes: picData?.notes || "",
                            role: picData?.role || "",
                            department: picData?.department || "",
                            status: "Active"
                        });
                        data.pics = _pics;
                        props.onChange(data);
                    }} />
                    <DefaultButton text={'Cancel'} onClick={() => setShowPanel(false)} />
                </Stack>;
            }}>
            <Stack tokens={{ childrenGap: 15 }}>
                <TextField label={'Name'}
                    value={picData?.name || ""}
                    required={true}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.name = value || "";

                        setPICData({ ..._picData });
                    }} />
                <TextField label={'Phone number'}
                    value={picData?.phoneNumber || ""}
                    required={true}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.phoneNumber = value || "";

                        setPICData({ ..._picData });
                    }} />
                <TextField label={'Address'}
                    multiline
                    rows={3}
                    autoAdjustHeight
                    resizable={false}
                    value={picData?.address || ""}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.address = value || "";

                        setPICData({ ..._picData });
                    }} />
                <TextField label={'Notes'}
                    multiline
                    rows={3}
                    autoAdjustHeight
                    resizable={false}
                    value={picData?.notes || ""}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.notes = value || "";

                        setPICData({ ..._picData });
                    }} />
                <Stack className={'divider'}></Stack>
                <TextField label={'Role'}
                    value={picData?.role || ""}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.role = value || "";

                        setPICData({ ..._picData });
                    }} />
                <TextField label={'Department'}
                    value={picData?.department || ""}
                    onChange={(evt, value) => {
                        let _picData = picData || {};
                        _picData.department = value || "";

                        setPICData({ ..._picData });
                    }} />
            </Stack>
        </Panel>
    </Stack>
};

export default VendorPICForm;
