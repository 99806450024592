import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { DefaultButton, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import ErrorService from '../../../../../services/general/error';
import NoAccess from '../../../../uiframeworks/noAccess';
import { IVendorResourceProps } from '../../../../../props/data/vendors';
import VendorDocumentsForm from './documents';
import VendorDetailsForm from './details';
import VendorPICForm from './pic';
import { IDocumentProps } from '../../../../../props/general';
import VendorPICService from '../../../../../services/data/vendors/pics';

interface IVendorFormProps {
    vendorId?: string;
    onDismissed(refresh?: boolean, vendor?: IVendorResourceProps): void;
}

export type FormDataProps = IVendorResourceProps & {
    systemNPWPFile?: File;
    systemOwnerIdFile?: File;
    systemSKFile?: File;
    systemNIBFile?: File;
    existingNPWPFile?: IDocumentProps;
    existingOwnerIdFile?: IDocumentProps;
    existingNIBFile?: IDocumentProps;
}

const VendorForm: React.FC<IVendorFormProps> = (props: IVendorFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<FormDataProps>>({
        name: "",
        address: "",
        phoneNumber: "",
        npwp: ""
    });
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.vendorId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'update' && PermissionsService.hasPermission(['vendors.update.all'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'create' && PermissionsService.hasPermission(['vendors.create'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.vendorId) {
            const _vendor = await VendorsService.get(props.vendorId);
            const _pics = await VendorPICService.retrieve(props.vendorId);
            setData({
                ..._vendor,
                pics: _pics,
                existingNIBFile: (_vendor.documents || []).find((doc) => doc.type === 'NIB'),
                existingOwnerIdFile: (_vendor.documents || []).find((doc) => doc.type === 'Owner ID'),
                existingNPWPFile: (_vendor.documents || []).find((doc) => doc.type === 'NPWP')
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.address || error.phoneNumber) {
            return true;
        } else if (data.name === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, address, phoneNumber, npwp, ownerAddress, ownerDob, ownerIdentification, ownerName, pics, systemNPWPFile, systemNIBFile, systemOwnerIdFile } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name || "");
            fd.append("address", address || "");
            fd.append("phoneNumber", phoneNumber || "");
            fd.append("npwp", npwp || "");
            fd.append("ownerAddress", ownerAddress || "");
            fd.append("ownerDob", ownerDob || "");
            fd.append("ownerIdentification", ownerIdentification || "");
            fd.append("ownerName", ownerName || "");

            (pics || []).forEach((pic) => {
                fd.append("pics[]", JSON.stringify(pic));
            })

            if (systemNPWPFile) {
                fd.append("documents[]", systemNPWPFile);
                fd.append("metadatas[]", JSON.stringify({type: "NPWP", visibility: "Public"}));
            }

            if (systemNIBFile) {
                fd.append("documents[]", systemNIBFile);
                fd.append("metadatas[]", JSON.stringify({type: "NIB", visibility: "Public"}));
            }

            if (systemOwnerIdFile) {
                fd.append("documents[]", systemOwnerIdFile);
                fd.append("metadatas[]", JSON.stringify({type: "Owner ID", visibility: "Public"}));
            }

            let result;
            if (props.vendorId === undefined) {
                result = await VendorsService.new(fd);
            } else {
                result = await VendorsService.update(props.vendorId, fd);
            }

            banner.add({
                key: mode + '_vendor_success',
                variant: 'success',
                icon: faCheck,
                text: `Vendor "${name}" ${mode}d successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            banner.add({
                key: mode + '_vendor_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Vendor' : 'Update Vendor'}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating vendor ..." : "Updating vendor ..."} /> : null}
            </Stack>;
        }}>
        <Stack>
            {!loaded ? <Stack horizontalAlign={"baseline"} styles={{ root: { marginTop: 20 } }}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack horizontal tokens={{ childrenGap: 40 }}>
                        <Stack grow={1}>
                            <VendorDocumentsForm data={data} onChange={() => {
                                setData({ ...data });
                            }} />
                        </Stack>
                        <Stack styles={{ root: { minWidth: '50%', maxWidth: 750 } }}>
                            <Pivot className={'linePivot'}>
                                <PivotItem key={'details'} headerText='Details'>
                                    <Stack styles={{ root: { marginTop: 15 } }}>
                                        <VendorDetailsForm data={data} onChange={() => {
                                            setData({ ...data });
                                        }} />
                                    </Stack>
                                </PivotItem>
                                <PivotItem key={'pics'} headerText='PICs'>
                                    <Stack styles={{ root: { marginTop: 15 } }}>
                                        <VendorPICForm data={data} onChange={() => {
                                            setData({ ...data });
                                        }} />
                                    </Stack>
                                </PivotItem>
                            </Pivot>
                        </Stack>
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default VendorForm;
