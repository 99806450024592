import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { ActionButton, DefaultButton, ICommandBarItemProps, Label, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, Stack } from '@fluentui/react';
import LoadingComponent from '../../../../../feedbacks/loading';
import Text from '../../../../../typography/text';
import { IOutcomePaymentResourceProps, IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';
import OutcomePaymentDetailsProperties from './properties';
import moment from 'moment';
import FilesList, { DocumentModeView } from '../../../../../uiframeworks/files/list';
import OutcomeService from '../../../../../../services/finance/outcomes';
import { IDocumentProps } from '../../../../../../props/general';

interface OutcomePaymentDetailsProps {
    paymentId: string;
    onDismissed(refresh?: boolean): void;
}

const OutcomePaymentDetails: React.FC<OutcomePaymentDetailsProps> = (props: OutcomePaymentDetailsProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [payment, setPayment] = React.useState<IOutcomePaymentResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [documents, setDocuments] = React.useState<IDocumentProps[]>([]);
    const [viewMode, setViewMode] = useState<DocumentModeView>('card');

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _payment = await OutcomePaymentService.get(props.paymentId);
            console.log(_payment);
            setPayment(_payment);
            //_onRetrieveDocuments(_payment)
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_payment_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (activeSurface === 'delete' && refresh) {
            props.onDismissed(true);
        } else {
            setActiveSurface(undefined);
            if (refresh) { init(); }
        }
    }

    const _onRetrieveDocuments = async (_payment: IOutcomePaymentResourceShortProps) => {
        try {
            setLoaded(false);
            const result = await OutcomePaymentService.documents.retrieve(_payment.id);
            setDocuments(result);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcome_documents_list_error',
                text: 'Failed to retrieve outcome documents list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Panel headerText={"Payment Details"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && payment ? <>
                {payment.status === 'completed' ? <MessageBar messageBarType={MessageBarType.success}>
                    <Text size={"small"}>This payment has been completed/paid by {payment.paidBy?.name} at {moment(payment.paidOn).format("DD/MM/YYYY HH:mm")}</Text>
                </MessageBar> : null}
                {payment.status === 'rejected' ? <MessageBar messageBarType={MessageBarType.error}>
                    <Text size={"small"}>This payment is rejected by {payment.cancelledBy?.name}. Justification: {payment.cancellationReason || "-"}</Text>
                </MessageBar> : null}
                {/*<CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />*/}
                <Pivot className={'linePivot'}>
                    <PivotItem key={'details'} headerText={"Details"}>
                        <Stack styles={{ root: { padding: 10 } }}><OutcomePaymentDetailsProperties payment={payment} /></Stack>
                    </PivotItem>
                    <PivotItem key={'documents'} headerText={"Documents"}>
                        <Stack styles={{ root: { padding: 10 } }}>
                            <FilesList files={[...payment.documents, ...(payment.completionDocuments || [])]}
                                documentTypes={[{ key: "document", text: "Document" }, { key: "Proof of Payment", text: "Proof of Payment" }]}
                                mode={viewMode}
                                loaded={true}  />
                        </Stack>
                    </PivotItem>
                </Pivot>
            </> : null}
        </Stack>
    </Panel>
};

export default OutcomePaymentDetails;
