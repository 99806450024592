import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import SubsidiariesForm from '../form';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import DeleteSubsidiariesForm from '../delete';
import { NavLink } from 'react-router-dom';
import OrdersList from '../../../../orders/general/list';
import InternalBankAccountsList from '../../../../data/bankaccounts/internal/list';
import BankAccountsList from '../../../../bankaccounts/general/list';

type SubsidiaryDetailsProps = {
    subsidiaryId: string;
}

const SubsidiaryDetails: React.FC<SubsidiaryDetailsProps> = (props: SubsidiaryDetailsProps) => {
    const { banner, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [subsidiary, setSubsidiary] = React.useState<ISubsidiariesResourceShort>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetSubsidiaries();
    }, []);

    const _onGetSubsidiaries = async () => {
        try {
            setLoaded(false);
            const _subsidiary = await SubsidiariesService.get(props.subsidiaryId);
            setSubsidiary(_subsidiary);

            topbar.show(_subsidiary.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "subsidiaries", text: 'Subsidiaries' },
                { key: props.subsidiaryId, text: _subsidiary.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_subsidiary_error',
                text: 'Failed to get subsidiary details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update details',
            onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
        });

        farItems.push({
            key: 'delete',
            text: 'Delete',
            onRender: () => <ActionButton text={'Delete'} iconProps={{ iconName: "Delete" }} styles={{ root: { marginLeft: 20 } }} onClick={() => setActiveSurface('delete')} />
        });

        return { items, farItems };
    }

    const getSubsidiariesPropertiesSection = (_subsidiary: ISubsidiariesResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'subsidiaries_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_subsidiary.name}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>NPWP</Label>
                        <Text>{_subsidiary.npwp || "-"}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Phone number</Label>
                        <Text>{_subsidiary.phoneNumber || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Address</Label>
                        <Text>{_subsidiary.address || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetSubsidiaries() }
    }

    const getRelatedItemsSection = (_subsidiary: ISubsidiariesResourceShort): IStackSectionProps => {
        const qs: string[] = [];
        qs.push(`subsidiary=${_subsidiary.id}`);

        const tabs: ISectionTabProps[] = [];
        tabs.push({
            key: 'related_orders',
            title: 'Related Orders',
            content: <Stack>
                <OrdersList qs={qs} hideCreateOrder />
            </Stack>
        });

        tabs.push({
            key: 'bank_accounts',
            title: 'Bank Accounts',
            content: <Stack>
                <BankAccountsList subsidiaryId={_subsidiary.id} />
            </Stack>
        });

        const section: IStackSectionProps = {
            key: 'subsidiary_details',
            isCard: true,
            tabs
        }

        return section;
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving subsidiary details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && subsidiary ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getSubsidiariesPropertiesSection(subsidiary)]} />
                <DetailsStack left={[getRelatedItemsSection(subsidiary)]} />
            </Stack>
            {activeSurface === 'update' ? <SubsidiariesForm subsidiariesId={subsidiary.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteSubsidiariesForm subsidiaries={subsidiary} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default SubsidiaryDetails;
