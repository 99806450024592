import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DatePicker, DefaultButton, Dropdown, DropdownMenuItemType, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Tag from '../../../../uiframeworks/tag';
import UploadFilesComponent from '../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../props/general';

type TerminateEmployeeContractFormProps = {
    contractId: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

const TerminateEmployeeContractForm: React.FC<TerminateEmployeeContractFormProps> = (props: TerminateEmployeeContractFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [justification, setJustification] = React.useState<string>("");
    const [endDate, setEndDate] = React.useState<string>(moment().endOf("month").toISOString());
    const [contract, setContract] = React.useState<IEmployeeContractResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _contract = await EmployeeContractService.read(props.contractId);
        setContract(_contract);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append("justification", justification);
            fd.append("endDate", endDate);

            await EmployeeContractService.terminate(props.contractId, fd);

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'terminate_contract_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to terminate contract. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Terminate Contract"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Terminate"} disabled={(justification || "").trim() === ''} onClick={_onSubmit} />
                            <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Terminating contract ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && contract ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure to terminate this active contract?</Text>
                        <Dropdown label={"Justification"}
                            selectedKey={justification}
                            required
                            options={[
                                { key: 'header_1', text: 'Contract expired', itemType: DropdownMenuItemType.Header },
                                { key: 'Current contract is expired and not extended', text: 'Current contract is expired and not extended' },
                                { key: 'Current contract is expired and new contract is signed', text: 'Current contract is expired and new contract is signed' },
                                { key: 'divider_2', text: '', itemType: DropdownMenuItemType.Divider },
                                { key: 'header_2', text: 'Signing new contract', itemType: DropdownMenuItemType.Header },
                                { key: 'New contract is signed', text: 'New contract is signed' },
                                { key: 'divider_3', text: '', itemType: DropdownMenuItemType.Divider },
                                { key: 'header_3', text: 'Resignation', itemType: DropdownMenuItemType.Header },
                                { key: 'Employee resign', text: 'Employee resign' }
                            ]}
                            onChange={(ev, opt) => setJustification(opt ? opt.key as string : "")} />
                        <DatePicker label={'Contract end date'}
                            value={moment(endDate).toDate()}
                            isRequired
                            formatDate={GeneralService.formatDate}
                            onSelectDate={(date) => setEndDate(date ? moment(date).toISOString() : endDate)} />
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default TerminateEmployeeContractForm;
