import Request from './../request';

// import props
import {
    IOrderResourceProps,
    IOrderResourceShortProps,
    IOrderResourcerShorterProps
} from './../../props/orders/order';
import { IDocumentProps, IPaginationResourceShort } from './../../props/general';
import { IOrderProductResourceShortProps } from './../../props/orders/orderProduct';
import { IOrderContainerResourceShortProps } from './../../props/orders/orderContainer';
import { IOrderAddonResourceShortProps } from '../../props/orders/orderAddon';
import { IPaymentOrderResourceProps } from '../../props/payments/orders';
import { IOutcomeOrderResourceShortProps } from '../../props/finance/outcomes/orders';
import { IOrderSummaryResourceShortProps } from '../../props/orders/summary';
import { IVersionHistoryResourceShortProps } from '../../props/general/versionHistory';
import { IUserResourceShortProps } from '../../props/users/user';

const OrderService = {
    isMine: (order: IOrderResourceShortProps, user?: IUserResourceShortProps | null) => {
        if (user) {
            return (order.sales || []).map((s) => s.sales.id).indexOf(user?.id || "") > -1 || order.customer.id === user.id || order.createdById === user.id;
        }

        return false;
    },
    getOrderNumber: (orderNumber: string) => {
        return orderNumber.split(".").splice(2).join(".");
    },
    retrieve: async (qs?: string): Promise<{ data: IOrderResourceProps[], pagination: IPaginationResourceShort }> => {
        try {
            console.log('retrieve order requested');
            const results = await Request.get(`/api/orders?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    new: async (data: FormData): Promise<IOrderResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    get: async (id: string): Promise<IOrderResourceProps> => {
        try {
            const results = await Request.get(`/api/orders/${id}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    update: async (id: string, data: FormData): Promise<IOrderResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    cancel: async (id: string, reason: string): Promise<void> => {
        try {
            const data = new FormData();
            data.append("cancellationReason", reason);

            await Request.post(`/api/orders/${id}/cancel?_method=PUT`, data, 'multipart/form-data');
            return;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (id: string): Promise<void> => {
        try {
            await Request.delete(`/api/orders/${id}`);
            return;
        } catch (e) {
            throw (e);
        }
    },
    versions: {
        retrieve: async(orderId: string): Promise<IVersionHistoryResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/versions`);
                return results;
            } catch (e) {
                throw (e);
            }
        }
    },
    documents: {
        download: async (orderId: string, id: string) => {
            try {
                const result = await Request.getBlob(`/api/orders/${orderId}/documents/${id}/download`);
                return result;
            } catch (e) {
                throw (e);
            }
        },
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const results = await Request.get(`/api/orders/${id}/documents?${qs || ""}`);
                return results;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/orders/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/orders/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    },
    getUniqueValue: async (key: string, qs?: string): Promise<any> => {
        try {
            const results = await Request.get(`/api/orders/filters/options?key=${key}${qs ? `&${qs}` : ''}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    outcomes: {
        retrieve: async (orderId: string, qs?: string): Promise<IOutcomeOrderResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/outcomes`);
                return results;
            } catch (e) {
                throw (e);
            }
        },
    },
    summary: {
        get: async (orderId: string, qs?: string): Promise<IOrderSummaryResourceShortProps> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/summary`);
                return results;
            } catch (e) {
                throw (e);
            }
        },
    },
    getAddress: (type: string, address: string, city?: string, province?: string, country?: string) => {
        const result: string[] = [address];
        if (city) { result.push(city); }
        if (province) { result.push(province); }
        if (country) { result.push(country); }

        return (type === 'door' ? 'Door' : 'Yard') + " - " + result.join(", ");
    },
    /*public currentStep = {
        update: async (id:string, data:FormData):Promise<ITrackingStepResourceShortProps> => {
            try {
                const result = await Request.post(`/api/orders/${id}/currentStep?_method=PUT`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        }
    }

    public documents = {
        new: async (id:string, data:FormData):Promise<IUploadedFileDataProps[]> => {
            try {
                const results = await Request.post(`/api/orders/${id}/documents`, data, 'multipart/form-data');
                return results;
            } catch(e) {
                throw(e);
            }
        },
        retrieve: async (id:string):Promise<IUploadedFileDataProps[]> => {
            try {    
                const results = await Request.get(`/api/orders/${id}/documents`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        delete: async (id:string, documents:IUploadedFileDataProps[]):Promise<void> => {
            try {
                await Request.post(`/api/orders/${id}/documents/delete`, {documents});
                return;
            } catch(e) {
                throw(e);
            }
        }
    }

    public containers = {
        new: async (orderId:string, data:FormData):Promise<IContainerResourceShortProps> => {
            try {
                const results = await Request.post(`/api/orders/${orderId}/containers`, data, 'multipart/form-data');
                return results;
            } catch(e) {
                throw(e);
            }
        },
        update: async (orderId:string, containerId:string, data:FormData):Promise<IContainerResourceProps> => {
            try {
                const result = await Request.post(`/api/orders/${orderId}/containers/${containerId}?_method=PUT`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        },
        get: async (orderId:string, containerId:string):Promise<IContainerResourceProps> => {
            try {    
                const result = await Request.get(`/api/orders/${orderId}/containers/${containerId}`);
                return result;
            } catch(e) {
                throw(e);
            }
        },
        retrieve: async (orderId:string):Promise<{pagination: IPaginationResourceShort, data: IContainerResourceShortProps[]}> => {
            try {    
                const results = await Request.get(`/api/orders/${orderId}/containers`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        delete: async (orderId: string, containerId: string) => {
            try {
                await Request.delete(`/api/orders/${orderId}/containers/${containerId}`);
                return;
            } catch(e) {
                throw(e);
            }
        }
    }

    public products = {
        new: async (orderId:string, data:FormData):Promise<IContainerProductResourceShortProps> => {
            try {
                const results = await Request.post(`/api/orders/${orderId}/products`, data, 'multipart/form-data');
                return results;
            } catch(e) {
                throw(e);
            }
        },
        update: async (orderId:string, productId:string, data:FormData):Promise<IContainerProductResourceShortProps> => {
            try {
                const result = await Request.post(`/api/orders/${orderId}/products/${productId}?_method=PUT`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        },
        get: async (orderId:string, productId:string):Promise<IContainerProductResourceShortProps> => {
            try {    
                const result = await Request.get(`/api/orders/${orderId}/products/${productId}`);
                return result;
            } catch(e) {
                throw(e);
            }
        },
        retrieve: async (orderId:string):Promise<{pagination: IPaginationResourceShort, data: IContainerProductResourceShortProps[]}> => {
            try {    
                const results = await Request.get(`/api/orders/${orderId}/products`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        delete: async (orderId: string, productId: string) => {
            try {
                await Request.delete(`/api/orders/${orderId}/products/${productId}`);
                return;
            } catch(e) {
                throw(e);
            }
        }
    }

    public trucks = {
        new: async (orderId:string, data:FormData):Promise<ITruckResourceShortProps> => {
            try {
                const results = await Request.post(`/api/orders/${orderId}/trucks`, data, 'multipart/form-data');
                return results;
            } catch(e) {
                throw(e);
            }
        },
        update: async (orderId:string, truckId:string, data:FormData):Promise<ITruckResourceShortProps> => {
            try {
                const result = await Request.post(`/api/orders/${orderId}/trucks/${truckId}?_method=PUT`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        },
        get: async (orderId:string, truckId:string):Promise<ITruckResourceShortProps> => {
            try {    
                const result = await Request.get(`/api/orders/${orderId}/trucks/${truckId}`);
                return result;
            } catch(e) {
                throw(e);
            }
        },
        retrieve: async (orderId:string):Promise<{pagination: IPaginationResourceShort, data: ITruckResourceShortProps[]}> => {
            try {    
                const results = await Request.get(`/api/orders/${orderId}/trucks`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        delete: async (orderId: string, truckId: string) => {
            try {
                await Request.delete(`/api/orders/${orderId}/trucks/${truckId}`);
                return;
            } catch(e) {
                throw(e);
            }
        }
    }

    public payments = {
        retrieve: async (orderId:string, invoiceId:string):Promise<IPaymentResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/invoices/${invoiceId}/payments`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        new: async (orderId:string, invoiceId:string, data:FormData):Promise<IPaymentResourceShortProps> => {
            try {
                const result = await Request.post(`/api/orders/${orderId}/invoices/${invoiceId}/payments`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        }
    }*/
    getTotalPrice: (products?: Partial<IOrderProductResourceShortProps>[], containers?: Partial<IOrderContainerResourceShortProps>[], addons?: Partial<IOrderAddonResourceShortProps>[]): string => {
        try {
            let price = 0;
            (products || []).forEach((prod) => {
                if (prod.price !== null && prod.price !== undefined) {
                    price += parseInt((prod.price || "0") + "")
                }
            });

            (containers || []).forEach((container) => {
                price += (parseInt((container.price || "0") + ""));
            });

            (addons || []).forEach((addon) => {
                price += parseInt((addon.price || "0") + "")
            });

            return price + "";
        } catch (e) {
            return "0";
        }
    },
    getPayments: async (id: string): Promise<IPaymentOrderResourceProps[]> => {
        try {
            const results = await Request.get(`/api/orders/${id}/payments`);
            return results;
        } catch (e) {
            throw (e);
        }
    }
}

export default OrderService;