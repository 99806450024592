import { IBankAccountCutoffResourceProps, IBankAccountCutoffResourceShortProps } from '../../props/bankaccounts/cutoff';
import Request from './../request';

// import props

const BankAccountCutoffService = {
    retrieve: async (accountId: string, qs?: string): Promise<{data: IBankAccountCutoffResourceShortProps[]}> => {
        try {
            const results = await Request.get(`/api/bankaccounts/${accountId}/cutoffs?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (accountId: string, data: FormData): Promise<IBankAccountCutoffResourceProps> => {
        try {
            const result = await Request.post(`/api/bankaccounts/${accountId}/cutoffs`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (accountId: string, cutoffId: string): Promise<IBankAccountCutoffResourceProps> => {
        try {
            const result = await Request.get(`/api/bankaccounts/${accountId}/cutoffs/${cutoffId}`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
};

export default BankAccountCutoffService;