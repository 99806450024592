import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props
import { IBankAccountResourceProps } from '../../../../props/bankaccounts';

// components
import { Stack } from '@fluentui/react';
import Text from './../../../typography/text';
import Label from './../../../typography/label';
import moment from 'moment';
import GeneralService from '../../../../services/general';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../services/general/error';
import BankAccountService from '../../../../services/bankaccounts';
import { IBankAccountSummaryProps } from '../../../../props/bankaccounts/summary';
import LoadingComponent from '../../../feedbacks/loading';
import { IBankAccountCutoffResourceShortProps } from '../../../../props/bankaccounts/cutoff';

interface BankAccountDetailsSummaryProps {
    bankAccount: IBankAccountResourceProps;
}

const BankAccountDetailsSummary: React.FC<BankAccountDetailsSummaryProps> = (props: BankAccountDetailsSummaryProps) => {
    const { banner } = useStore();

    const { bankAccount } = props;
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [summary, setSummary] = React.useState<IBankAccountSummaryProps | undefined>();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        try {
            setLoaded(false);

            const _summary = await BankAccountService.summary(props.bankAccount.id);

            setSummary(_summary);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_bank_account_summary_error',
                text: 'Failed to retrieve bank account summary. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const renderLastCutoffDetails = (cutoff: IBankAccountCutoffResourceShortProps) => {
        const diff = Number(cutoff.actualAmount) - Number(cutoff.expectedAmount);

        return <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '33.3%' } }} tokens={{ childrenGap: 3 }}>
                <Text size={'small'}>Date</Text>
                <Stack>
                    <Label>{moment(cutoff.cutoffDate).format("DD/MM/YYYY - hh:mm A")}</Label>
                    <Text size={'small'} style={{ marginTop: -2 }} className={'color-darkgray'}>({GeneralService.getFriendlyDateFormat(moment(cutoff.cutoffDate).toISOString())})</Text>
                </Stack>
            </Stack>
            <Stack styles={{ root: { width: '33.3%' } }}>
                <Text size={'small'}>Created by</Text>
                <Stack>
                    <Label>{cutoff.createdBy.name}</Label>
                    <Text size={'small'} style={{ marginTop: -2 }} className={'color-darkgray'}>({cutoff.createdBy.email})</Text>
                </Stack>
            </Stack>
            <Stack styles={{ root: { width: '33.3%' } }}>
                <Text size={'small'}>Actual amount</Text>
                <Stack>
                    <Label>Rp. {GeneralService.getNumberWithSeparator(Number(cutoff.actualAmount))}</Label>
                    {diff > 0 ? <Text size={'small'} style={{ marginTop: -2 }} className={'color-green'}>+ Rp {GeneralService.getNumberWithSeparator(diff)} from expected</Text> : null}
                    {diff < 0 ? <Text size={'small'} style={{ marginTop: -2 }} className={'color-red'}>- Rp {GeneralService.getNumberWithSeparator(diff)} from expected</Text> : null}
                    {diff === 0 ? <Text size={'small'} style={{ marginTop: -2 }} className={'color-blue'}>amount matched with expected</Text> : null}
                </Stack>
            </Stack>
        </Stack>;
    }

    const renderSummaryDetails = (_summary: IBankAccountSummaryProps) => {
        const outcomesDiff = Number(_summary.currentSummary.totalOutcomes) - Number(_summary.previousSummary?.totalOutcomes || "0");
        const incomesDiff = Number(_summary.currentSummary.totalIncomes) - Number(_summary.previousSummary?.totalIncomes || "0");
        
        const startAmount = Number(_summary.lastCutoff ? _summary.lastCutoff.actualAmount : "0");
        const expectedAmount = startAmount + Number(_summary.currentSummary.totalIncomes) - Number(_summary.currentSummary.totalOutcomes);
        const amountDiff = expectedAmount - startAmount;

        return <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack tokens={{ childrenGap: 20 }} styles={{ root: { padding: 10, width: '33.3%' } }} className={'card'}>
                <Stack>
                    <Label>Total outcomes</Label>
                    <Text size={'small'} style={{ marginTop: -3 }} className={'color-darkgray'}>since previous cutoff</Text>
                </Stack>
                <Stack>
                    <Label size={'large'} className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(Number(_summary.currentSummary.totalOutcomes))}</Label>
                    {outcomesDiff > 0 ? <Text size={'small'}>+ Rp. {GeneralService.getNumberWithSeparator(outcomesDiff)} (compared to previous cutoff)</Text> : null}
                    {outcomesDiff === 0 ? <Text size={'small'}>same amount compared to previous cutoff</Text> : null}
                    {outcomesDiff < 0 ? <Text size={'small'}>- Rp. {GeneralService.getNumberWithSeparator(outcomesDiff * -1)} (compared to previous cutoff)</Text> : null}
                </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 20 }} styles={{ root: { padding: 10, width: '33.3%' } }} className={'card'}>
                <Stack>
                    <Label>Total incomes</Label>
                    <Text size={'small'} style={{ marginTop: -3 }} className={'color-darkgray'}>since last cutoff</Text>
                </Stack>
                <Stack>
                    <Label size={'large'} className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(Number(_summary.currentSummary.totalIncomes))}</Label>
                    {incomesDiff > 0 ? <Text size={'small'}>+ Rp. {GeneralService.getNumberWithSeparator(incomesDiff)} (compared to previous cutoff)</Text> : null}
                    {incomesDiff === 0 ? <Text size={'small'}>same amount compared to previous cutoff</Text> : null}
                    {incomesDiff < 0 ? <Text size={'small'}>- Rp. {GeneralService.getNumberWithSeparator(incomesDiff * -1)} (compared to previous cutoff)</Text> : null}
                </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 20 }} styles={{ root: { padding: 10, width: '33.3%' } }} className={'card'}>
                <Stack>
                    <Label>Expected amount</Label>
                    <Text size={'small'} style={{ marginTop: -3 }} className={'color-darkgray'}>in bank account until now</Text>
                </Stack>
                <Stack>
                    <Label size={'large'} className={'color-blue'}>Rp. {GeneralService.getNumberWithSeparator(expectedAmount)}</Label>
                    {amountDiff > 0 ? <Text size={'small'} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(amountDiff)} from start</Text> : null}
                    {amountDiff === 0 ? <Text size={'small'} className={'color-blue'}>amout not changed</Text> : null}
                    {amountDiff < 0 ? <Text size={'small'} className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(amountDiff * -1)} from start</Text> : null}
                </Stack>
            </Stack>
        </Stack>;
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded || !summary ? <LoadingComponent label={'Retrieving bank account summary ...'} labelPosition={'right'} spinnerPosition={'baseline'} withBackground /> : null}
        {loaded && summary ? <>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 10 } }} className={'card'}>
                <Label>Previous cutoff details</Label>
                <Stack className='divider'></Stack>
                {summary.lastCutoff ? renderLastCutoffDetails(summary.lastCutoff) : null}
                {!summary.lastCutoff ? <Text size={'small'} style={{ fontStyle: 'italic' }}>This cutoff has no previous cutoff</Text> : null}
            </Stack>
            {renderSummaryDetails(summary)}
        </> : null}
    </Stack>;
};

export default BankAccountDetailsSummary;
