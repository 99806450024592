import { IDropdownOption } from "@fluentui/react";

const PPNPercentageOptions: IDropdownOption[] = [
    {key: "0", text: "No PPN"},
    {key: "1.1", text: "1.1%"},
    {key: "1.2", text: "1.2%"},
    {key: "11", text: "11%"},
    {key: "12", text: "12%"},
    {key: "custom", text: "Custom"}
];

const PPhPercentageOptions: IDropdownOption[] = [
    {key: "0", text: "No PPh"},
    {key: "0.2", text: "0.2%"},
    {key: "0.5", text: "0.5%"},
    {key: "1.2", text: "1.2%"},
    {key: "2", text: "2%"},
    {key: "10", text: "10%"},
    {key: "custom", text: "Custom"}
];

export {PPhPercentageOptions, PPNPercentageOptions};