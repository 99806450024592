import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import OrderContainerService from '../../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../../services/general';

// props

// components
import { ActionButton, DatePicker, DefaultButton, DetailsList, Dropdown, MessageBarType, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import { IBankAccountResourceShortProps } from '../../../../../../props/general/bankAccount';
import OrderInvoiceService from '../../../../../../services/orders/invoices/invoice';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import OrderService from '../../../../../../services/orders/order';
import { IOrderResourceShortProps } from '../../../../../../props/orders/order';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import moment from 'moment';
import BankAccountService from '../../../../../../services/general/bankAccount';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { IIncomeInvoiceResourceProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import { IIncomeInvoiceOrderResourceShortProps } from '../../../../../../props/finance/incomes/invoices/order';
import SelectOrder from '../../../../../uiframeworks/forms/selectOrder';
import IncomeInvoiceRelatedItemForm from './item';
import { IIncomeInvoiceItemResourceShortProps } from '../../../../../../props/finance/incomes/invoices/item';
import ISubsidiariesResourceShort from '../../../../../../props/data/subsidiaries';
import SelectSubsidiary from '../../../../../uiframeworks/forms/selectSubsidiary';
import Details from '../../../../../typography/details';
import SelectCompany from '../../../../../uiframeworks/forms/company';
import SimpleMessageBarComponent from '../../../../../feedbacks/simpleMessageBar';

type IncomeInvoiceFormProps = {
    orderId?: string;
    invoiceId?: string;
    onDismissed(refresh?: boolean): void;
}

const IncomeInvoiceForm: React.FC<IncomeInvoiceFormProps> = (props: IncomeInvoiceFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [incomeType, setIncomeType] = React.useState<string>('orders');
    const [subsidiary, setSubsidiary] = React.useState<ISubsidiariesResourceShort | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [data, setData] = React.useState<Partial<IIncomeInvoiceResourceProps>>({
        id: GeneralService.makeid(25, true, "INV"),
        totalAmount: "0",
        subtotalAmount: "0",
        ppn: "0",
        pph: "0",
        ppnPercentage: "0",
        pphPercentage: "0",
        invoiceAmount: "0"
    });
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.invoiceId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['incomes.invoices.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['incomes.invoices.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.invoiceId) {
            const _invoice = await IncomeInvoiceService.get(props.invoiceId);
            let incomeType = 'items';
            if (_invoice.order) {
                incomeType = 'orders';
                _invoice.company = _invoice.order.company;
                setSubsidiary(_invoice.order.subsidiary);
            } else {
                setSubsidiary(_invoice.subsidiary);
            }

            setData(_invoice);
            setIncomeType(incomeType);
        } else if (props.orderId) {
            const _order = await OrderService.get(props.orderId);
            setData({
                ...data,
                order: _order,
                subtotalAmount: _order.subtotalAmount,
                totalAmount: _order.totalAmount,
                ppn: _order.ppn,
                pph: _order.pph,
                ppnPercentage: _order.ppnPercentage,
                pphPercentage: _order.pphPercentage,
                invoiceAmount: _order.invoiceAmount,
                subsidiary: _order.subsidiary,
                company: _order.company,
                name: "Invoice - Order #" + OrderService.getOrderNumber(_order.orderNumber)
            });
            setSubsidiary(_order.subsidiary);
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if ((data.name || "").trim() === "") {
            return true;
        } else if (error.name || error.notes) {
            return true;
        } else if (incomeType === 'items' && ((data.items || []).length < 1 || !subsidiary || !data.company)) {
            return true;
        } else if (incomeType === 'orders' && !data.order) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            // create form data
            let fd = new FormData();
            fd.append("id", data.id || "");
            fd.append("name", data.name || "");
            fd.append("notes", data.notes || "");
            fd.append("dueDate", data.dueDate || moment().toISOString());
            fd.append("subtotalAmount", data.subtotalAmount || "");
            fd.append("includePPn", data.includePPn ? "1" : "0");
            fd.append("includePPh", data.includePPh ? "1" : "0");
            fd.append("ppnPercentage", data.ppnPercentage || "0");
            fd.append("pphPercentage", data.pphPercentage || "0");
            fd.append("ppn", data.ppn || "0");
            fd.append("pph", data.pph || "0");
            fd.append("totalAmount", data.totalAmount || "0");
            fd.append("invoiceAmount", data.invoiceAmount || "0");
            if (incomeType === "items") {
                fd.append('subsidiaryId', subsidiary?.id || "");
                fd.append('companyId', data.company?.id || "");
                (data.items || []).forEach((item) => {
                    fd.append("items[]", JSON.stringify(item));
                });
            } else if (incomeType === 'orders' && data.order) {
                fd.append('orderId', data.order.id);
            }

            let invoice = undefined;
            if (props.invoiceId === undefined) {
                invoice = await IncomeInvoiceService.create(fd);
            } else {
                invoice = await IncomeInvoiceService.update(props.invoiceId, fd);
            }

            banner.add({
                key: mode + '_order_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "${OrderInvoiceService.getInvoiceNumber(invoice.id)}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const getTotalAmount = (items: IIncomeInvoiceItemResourceShortProps[], order?: IOrderResourceShortProps) => {
        let subtotalAmount = 0;
        let ppn = 0;
        let pph = 0;
        let invoiceAmount = 0;
        let totalAmount = 0;
        let ppnPercentage = '';
        let pphPercentage = '';

        items.forEach((item, idx) => {
            subtotalAmount += Number(item.subtotalAmount);
            ppn += Number(item.ppn);
            pph += Number(item.pph);
            invoiceAmount += Number(item.invoiceAmount);
            totalAmount += Number(item.totalAmount);

            if (idx === 0) {
                ppnPercentage = item.ppnPercentage;
                pphPercentage = item.pphPercentage;
            } else {
                if (ppnPercentage !== item.ppnPercentage) {
                    ppnPercentage = 'custom';
                }

                if (pphPercentage !== item.pphPercentage) {
                    pphPercentage = 'custom';
                }
            }
        });


        return {
            subtotalAmount: subtotalAmount.toFixed(3),
            ppn: ppn.toFixed(3),
            pph: pph.toFixed(3),
            invoiceAmount: invoiceAmount.toFixed(3),
            totalAmount: totalAmount.toFixed(3),
            pphPercentage,
            ppnPercentage
        };
    }

    return <Panel headerText={mode === 'create' ? 'Create Invoice' : 'Update Invoice'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={mode === 'create' ? "Create" : 'Update'} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating invoice ..." : "Updating invoice ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    {Number(data.paid || "0") > 0 && incomeType === 'items' ? <SimpleMessageBarComponent properties={{
                        type: MessageBarType.warning,
                        text: 'Pricing for this invoice can no longer be changed because payment has been made to this invoice.'
                    }} /> : null}
                    <Stack.Item>
                        <TextField label={"Invoice number"} value={data.id} prefix={'#'} disabled={true} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Name"}
                            value={data.name}
                            required={true}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting || props.orderId !== undefined} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label={"Due Date"}
                            isRequired={true}
                            value={moment(data.dueDate).toDate()}
                            onSelectDate={(date) => {
                                data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                                setData({ ...data });
                            }}
                            formatDate={GeneralService.formatDate}
                            disabled={submitting} />
                    </Stack.Item>
                    <SelectSubsidiary selected={subsidiary}
                        label={"Select subsidiary"}
                        disabled={incomeType === 'orders'}
                        required={true}
                        onChange={(subsidiary) => {
                            setSubsidiary(subsidiary);
                        }} />
                    <SelectCompany selected={data.company}
                        label={"Billed to"}
                        disabled={incomeType === 'orders'}
                        required={true}
                        onChange={(company) => {
                            data.company = company;
                            setData({ ...data });
                        }} />
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <Dropdown label={"Income type"}
                            selectedKey={incomeType}
                            options={[
                                { key: 'orders', text: 'Order related incomes' },
                                { key: 'items', text: 'Non-order related incomes' }
                            ]}
                            disabled={props.orderId !== undefined || props.invoiceId !== undefined}
                            onChange={(e, opt) => {
                                data.order = undefined;
                                data.items = [];

                                setData({...data});
                                setIncomeType(opt?.key as string || incomeType);
                                setSubsidiary(undefined);
                            }} />
                    </Stack.Item>
                    {incomeType === 'orders' ? <>
                        <Stack>
                            <SelectOrder label={"Related order"}
                                required={true}
                                selected={data.order}
                                disabled={props.orderId !== undefined || props.invoiceId !== undefined}
                                onChange={(order) => {
                                    const _data = data;
                                    _data.order = order;
                                    _data.company = order?.company;

                                    if (order) {
                                        setSubsidiary(order.subsidiary);
                                        _data.bankAccount = undefined;
                                    } else {
                                        setSubsidiary(undefined);
                                        _data.bankAccount = undefined;
                                    }

                                    const validation = ValidationService.combination(_data.order, ['required'], {});
                                    error.order = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }} />
                        </Stack>
                        <Stack className={'divider'}></Stack>
                    </> : null}
                    {incomeType === 'items' ? <Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label size={'xsmall'}>Related items</Label>
                            {Number(data.paid || "0") < 1 ? <ActionButton text={'Add item'} iconProps={{ iconName: "Add" }} onClick={() => setActiveSurface("items.add")} /> : null}
                        </Stack>
                        {(data.items || []).length < 1 ? <Stack tokens={{childrenGap: 15}}>
                            <Text style={{ fontStyle: 'italic' }} size={'small'}>No related items</Text>
                            <Stack className={'divider'}></Stack>
                        </Stack> : null}
                        {(data.items || []).length > 0 ? <DetailsList items={data.items || []}
                            columns={[
                                {
                                    key: "name",
                                    name: "Name",
                                    minWidth: 125,
                                    maxWidth: 125,
                                    isMultiline: true,
                                    onRender: (item: IIncomeInvoiceItemResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                            <Text size={'small'}>{item.name}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: "notes",
                                    name: "Notes",
                                    minWidth: 100,
                                    isMultiline: true,
                                    onRender: (item: IIncomeInvoiceItemResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                            <Text size={'small'}>{item.notes || ""}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: "price",
                                    name: "Price (aft. tax)",
                                    minWidth: 150,
                                    maxWidth: 150,
                                    isMultiline: true,
                                    onRender: (item: IIncomeInvoiceItemResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAmount))}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: "action",
                                    name: "",
                                    minWidth: 40,
                                    maxWidth: 40,
                                    isMultiline: true,
                                    onRender: (item: IIncomeInvoiceItemResourceShortProps) => {
                                        return <Stack>
                                            <Stack.Item className={"detailsListActionRow"}>
                                                {Number(data.paid || "0") < 1 ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                                                    let _data = data;
                                                    _data.items = (_data.items || []).filter((i) => i.id !== item.id);
                                                    _data = { ..._data, ...getTotalAmount((_data.items || []), (_data.order)) };

                                                    setData({ ..._data });
                                                }} /> : null}
                                            </Stack.Item>
                                        </Stack>
                                    }
                                }
                            ]}
                            selectionMode={SelectionMode.none} /> : null}
                    </Stack> : null}
                    <Details title={`Subtotal`}
                        text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.subtotalAmount))}`} />
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Details title={`PPn ${data.ppnPercentage !== 'custom' ? `(${Number(data.ppnPercentage || "0")}%)` : "(custom)"}`}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.ppn))}`}
                            style={{ width: '50%' }} />
                        <Details title={`PPh ${data.pphPercentage !== 'custom' ? `(${Number(data.pphPercentage || "0")}%)` : "(custom)"}`}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.pph))}`}
                            style={{ width: '50%' }} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Details title={`Total amount`}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.totalAmount))}`}
                            style={{ width: '50%' }} />
                        <Details title={`Invoice amount`}
                            text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.invoiceAmount))}`}
                            style={{ width: '50%' }} />
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
        {activeSurface === 'items.add' ? <IncomeInvoiceRelatedItemForm onDismissed={(item) => {
            let _data = data;
            _data.items = _data.items || [];

            if (item) {
                _data.items.push(item);
            } else {
                _data.items = [];
            }
            _data = { ..._data, ...getTotalAmount((_data.items || []), _data.order) };

            const validation = ValidationService.combination(_data.items, ['required'], {});
            error.subtotalAmount = validation.message;

            setData({ ..._data });
            setError({ ...error });

            setActiveSurface(undefined);
        }} /> : null}
    </Panel>
};

export default IncomeInvoiceForm;
