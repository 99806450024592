import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { PPhPercentageOptions, PPNPercentageOptions } from '../../../../../../manifests/taxPercentage';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props

// components
import { Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IIncomeInvoiceItemResourceShortProps } from '../../../../../../props/finance/incomes/invoices/item';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';

type IncomeInvoiceRelatedItemFormProps = {
    onDismissed(invoiceOrder?: IIncomeInvoiceItemResourceShortProps): void;
}

const IncomeInvoiceRelatedItemForm: React.FC<IncomeInvoiceRelatedItemFormProps> = (props: IncomeInvoiceRelatedItemFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IIncomeInvoiceItemResourceShortProps>>({
        id: GeneralService.guid(),
        includePPh: false,
        includePPn: false,
        subtotalAmount: "0",
        totalAmount: "0",
        ppnPercentage: "0",
        pphPercentage: "0",
        ppn: "0",
        pph: "0",
        invoiceAmount: "0",
        notes: ""
    });
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        setLoaded(true);
    }, []);

    const isSubmitButtonDisabled = () => {
        if ((data.name || "").trim() === "" || (data.subtotalAmount || "").trim() === "") {
            return true;
        } else if (error.name || error.notes || error.subtotalAmount) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            props.onDismissed(data as IIncomeInvoiceItemResourceShortProps);
        } catch (e) {
        }
    }

    return <Panel headerText={'Add Related Order'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={"Add"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            required={true}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}> </Stack>
                    <Stack.Item grow={1}>
                        <TextField required={true}
                            label={"Subtotal"}
                            value={data.subtotalAmount}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || value === "0" || !isNaN(Number(value))) {
                                    data.subtotalAmount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 22 });
                                    error.subtotalAmount = validation.message;

                                    setData({ ...data, ...GeneralService.calculatePricingAmount(data)});
                                    setError({ ...error });
                                }
                            }}
                            prefix={"Rp"}
                            errorMessage={error.subtotalAmount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}> </Stack>
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack.Item>
                            <Checkbox checked={data.includePPn}
                                label='Include PPn'
                                onChange={(ev, checked) => {
                                    data.includePPn = checked;
                                    setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox checked={data.includePPh}
                                label='Include PPh'
                                onChange={(ev, checked) => {
                                    data.includePPh = checked;
                                    setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'xsmall'}>PPn</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPNPercentageOptions}
                                    selectedKey={data.ppnPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.ppn = (Number(opt.key) / 100 * Number(data.subtotalAmount)).toFixed();
                                        }
                                        data.ppnPercentage = (opt?.key || "") as string;

                                        setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.ppn}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || value === "0" || !isNaN(Number(value))) {
                                            data.ppn = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.ppn = validation.message;

                                            setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                            setError({ ...error });
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.ppn}
                                    disabled={submitting || data.ppnPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'xsmall'}>PPh</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPhPercentageOptions}
                                    selectedKey={data.pphPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.pph = (Number(opt.key) / 100 * Number(data.subtotalAmount)).toFixed();
                                        }
                                        data.pphPercentage = (opt?.key || "") as string;

                                        setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.pph}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || value === "0" || !isNaN(Number(value))) {
                                            data.pph = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.pph = validation.message;

                                            setData({ ...data, ...(GeneralService.calculatePricingAmount(data)) });
                                            setError({ ...error });
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.pph}
                                    disabled={submitting || data.pphPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}> </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>Total amount</Label>
                            <Text>Rp. {GeneralService.getNumberWithSeparator(Number(data.totalAmount))}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>Invoiced amount</Label>
                            <Text>Rp. {GeneralService.getNumberWithSeparator(Number(data.invoiceAmount))}</Text>
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default IncomeInvoiceRelatedItemForm;
