import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../../stores/root';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props
import { IEmployeeContractResourceProps } from '../../../../../../props/employees/contracts/contract';

// components
import { ActionButton, CommandBar, IButtonProps, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import Timeline, { TimelineItemProps } from '../../../../../uiframeworks/timeline';

interface EmployeeContractDetailsApprovalProps {
    contract: IEmployeeContractResourceProps;
}

const EmployeeContractDetailsApproval: React.FC<EmployeeContractDetailsApprovalProps> = observer((props: EmployeeContractDetailsApprovalProps) => {
    const { banner, topbar, user } = useStore();
    const {contract} = props;

    const getTimelineItems = () => {
        const timelineItems: TimelineItemProps[] = [];

        timelineItems.push({
            key: 'created',
            date: moment(contract.createdAt).format("DD/MM/YYYY"),
            title: 'Contract created',
            description: 'Created by ' + contract.createdBy.name,
            status: 'completed',
            line: contract.approvedByHrAt ? 'solid' : 'dashed'
        });

        if (contract.approvedByHrAt && contract.hrRepresentative) {
            timelineItems.push({
                key: 'approvedByHr',
                date: moment(contract.approvedByHrAt).format("DD/MM/YYYY"),
                title: 'Approved by HR',
                description: 'HR representative: ' + contract.hrRepresentative.name,
                status: 'completed',
                line: contract.approvedByLegalAt ? 'solid' : 'dashed'
            });
        } else if (contract.status !== 'Cancelled') {
            timelineItems.push({
                key: 'approvedByHr',
                notes: 'Pending HR representative approval',
                status: 'infuture',
                line: contract.approvedByLegalAt ? 'solid' : 'dashed'
            });
        }

        if (contract.approvedByLegalAt && contract.legalRepresentative) {
            timelineItems.push({
                key: 'approvedByLegal',
                date: moment(contract.approvedByLegalAt).format("DD/MM/YYYY"),
                title: 'Approved by legal',
                description: 'Legal representative: ' + contract.legalRepresentative.name,
                status: 'completed',
                line: contract.signedByEmployeeAt ? 'solid' : 'dashed'
            });
        } else if (contract.status !== 'Cancelled') {
            timelineItems.push({
                key: 'approvedByLegal',
                notes: 'Pending legal representative approval',
                status: 'infuture',
                line: contract.signedByEmployeeAt ? 'solid' : 'dashed'
            });
        }

        if (contract.signedByEmployeeAt) {
            timelineItems.push({
                key: 'signedByEmployee',
                date: moment(contract.signedByEmployeeAt).format("DD/MM/YYYY"),
                title: 'Signed by employee',
                status: 'completed',
                line: contract.signedByBodAt ? 'solid' : 'dashed'
            });
        } else if (contract.status !== 'Cancelled') {
            timelineItems.push({
                key: 'signedByEmployee',
                notes: 'Pending employee sign',
                status: 'infuture',
                line: contract.signedByBodAt ? 'solid' : 'dashed'
            });
        }

        if (contract.signedByBodAt && contract.bodRepresentative) {
            timelineItems.push({
                key: 'signedByBOD',
                date: moment(contract.signedByBodAt).format("DD/MM/YYYY"),
                title: 'Signed by company representative',
                description: 'Company representative: ' + contract.bodRepresentative.name,
                status: 'completed',
                line: 'solid'
            });
        } else if (contract.status !== 'Cancelled') {
            timelineItems.push({
                key: 'signedByBOD',
                notes: 'Pending company representative sign',
                status: 'infuture'
            });
        }

        if (contract.status === 'Active') {
            timelineItems.push({
                key: 'contractActive',
                title: 'Contract active',
                status: 'active'
            });
        } else if (contract.status === 'Cancelled') {
            timelineItems.push({
                key: 'contractCancelled',
                date: moment(contract.cancelledAt).format("DD/MM/YYYY"),
                title: 'Contract cancelled',
                description: contract.cancellationReason,
                status: 'cancelled'
            });
        } else if (contract.status === 'Terminated') {
            timelineItems.push({
                key: 'contractTerminated',
                date: moment(contract.terminatedAt).format("DD/MM/YYYY"),
                title: 'Contract terminated',
                description: contract.terminationReason,
                status: 'cancelled'
            });
        }

        return timelineItems;
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <Timeline items={getTimelineItems()} />
    </Stack>;
});

export default EmployeeContractDetailsApproval;
