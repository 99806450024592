import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';

// assets

// services
import ErrorService from '../../../../../../services/general/error';
import OutcomeSalaryService from '../../../../../../services/finance/outcomes/salaries';
import UserService from '../../../../../../services/users/user';

// props
import { IUserResourceShortProps } from '../../../../../../props/users/user';

// components
import { CommandBar, DefaultButton, DetailsList, ICommandBarItemProps, Link, MessageBar, MessageBarType, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import { faArrowRight, faDownload, faHome, faMoneyCheckDollarPen, faTrash, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import { IOutcomeSalaryResourceProps } from '../../../../../../props/finance/outcomes/salaries';
import { IOutcomeSalaryAllowanceResourceShortProps } from '../../../../../../props/finance/outcomes/salaries/allowance';
import { IOutcomeSalaryDeductionResourceShortProps } from '../../../../../../props/finance/outcomes/salaries/deduction';
import { IOutcomeSalaryCommissionResourceShortProps } from '../../../../../../props/finance/outcomes/salaries/commission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderService from '../../../../../../services/orders/order';
import DeliveredGoodsType from '../../../../../../manifests/deliveredGoodsType';
import PermissionsService from '../../../../../../services/permissions';
import Tag from '../../../../../uiframeworks/tag';
import DeleteSalarySlipForm from '../../../../../hr/salaries/slips/general/form/delete';
import EmployeePaymentListPanel from '../../payments/general/list';

interface EmployeePayslipDetailsProps {
    employeeId: string;
    payslipId: string;
}

const EmployeePayslipDetails: React.FC<EmployeePayslipDetailsProps> = (props: EmployeePayslipDetailsProps) => {
    const { banner, topbar, user, route } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [employee, setEmployee] = React.useState<IUserResourceShortProps | undefined>();
    const [payslip, setPayslip] = React.useState<IOutcomeSalaryResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        topbar.show("Payslip", [
            { key: "home", icon: faHome, href: "/" },
            { key: "users", text: 'Users' },
            { key: "administrators", text: 'Administrators' },
            { key: "payslip", text: 'Payslip' }
        ]);

        _onRetrievePayslip();
    }, []);

    const _onRetrievePayslip = async (pageNumber?: number,) => {
        try {
            setLoaded(false);

            const _employee = await UserService.get(props.employeeId);
            const _payslip = await OutcomeSalaryService.read(props.payslipId);

            const paymentMonth = moment(_payslip.paymentMonth).format("MMMM YYYY");
            topbar.show("Payslip - " + paymentMonth, [
                { key: "home", icon: faHome, href: "/" },
                { key: "users", text: 'Users' },
                { key: "administrators", text: 'Administrators', href: '/users/administrators' },
                { key: "employeeName", text: _employee.name, href: `/users/administrators/${_employee.id}` },
                { key: "payslip", text: 'Payslips', href: `/users/administrators/${_employee.id}/payslips` },
                { key: "paymentMonth", text: paymentMonth }
            ]);

            setPayslip(_payslip);
            setEmployee(_employee);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_payslip_error',
                text: 'Failed to retrieve payslip details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (payslip?.paymentStatus.toLowerCase() === 'paid') {
            items.push({
                key: 'download',
                onRender: () => {
                    return <PrimaryButton text={'Download Payslip'} iconProps={{ iconName: "Download" }} />
                }
            });
        }

        items.push({
            key: 'paymentsList',
            onRender: () => {
                return <DefaultButton onClick={() => {
                    setActiveSurface('payments.list')
                }} text={'Payments List'} styles={{ root: { marginLeft: 10 } }} onRenderIcon={() => <FontAwesomeIcon icon={faMoneyCheckDollarPen} style={{ marginRight: 5 }} />} />
            }
        });

        if (PermissionsService.hasPermission(['superuser'], user.roles) && payslip?.paymentStatus.toLowerCase() !== 'paid') {
            farItems.push({
                key: 'delete',
                onRender: () => {
                    return <DefaultButton text={'Delete Payslip'}
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => {
                            setActiveSurface('payslip.delete');
                        }} />
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) {
            route.route?.history.push(`/users/administrators/${employee?.id}/payslips`);
        }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        {loaded && payslip && payslip.paymentStatus.toLowerCase() === 'paid' ? <MessageBar messageBarType={MessageBarType.success}>
            <Text size={'small'}>Payslip has been fully paid.</Text>
        </MessageBar> : null}
        {loaded && payslip && payslip.paymentStatus.toLowerCase() === 'unpaid' ? <MessageBar messageBarType={MessageBarType.warning}>
            <Text size={'small'}>Payslip is still waiting to be paid.</Text>
        </MessageBar> : null}
        <Stack>
            {loaded && payslip && employee ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 20 }} grow={1}>
                    <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Salary, Insurance, and Tax</Label>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <Text size={'small'}>Base salary</Text>
                                    <Label className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.salaryAmount))}</Label>
                                </Stack>
                                {payslip.bonusAmount > 0 ? <Stack styles={{ root: { width: '25%' } }}>
                                    <Text size={'small'}>Bonus</Text>
                                    <Label className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.bonusAmount))}</Label>
                                </Stack> : <Stack styles={{ root: { width: '25%' } }}></Stack>}
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <Text size={'small'}>Health insurance</Text>
                                    <Label className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.healthInsuranceAmount))}</Label>
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <Text size={'small'}>Working insurance</Text>
                                    <Label className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.workingInsuranceAmount))}</Label>
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <Text size={'small'}>Tax</Text>
                                    <Label className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.taxAmount))}</Label>
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    {payslip.allowances.length > 0 ? <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Allowances</Label>
                        </Stack>
                        <Stack>
                            <DetailsList items={payslip.allowances}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: IOutcomeSalaryAllowanceResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "name",
                                        name: "Name",
                                        minWidth: 200,
                                        maxWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryAllowanceResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{item.name}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "notes",
                                        name: "Notes",
                                        minWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryAllowanceResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{item.notes || ""}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "amount",
                                        name: "Amount",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: IOutcomeSalaryAllowanceResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                            </Stack>
                                        }
                                    }
                                ]} />
                        </Stack>
                    </Stack> : null}
                    {payslip.deductions.length > 0 ? <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Deductions</Label>
                        </Stack>
                        <Stack>
                            <DetailsList items={payslip.deductions}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: IOutcomeSalaryDeductionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "name",
                                        name: "Name",
                                        minWidth: 200,
                                        maxWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryDeductionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{item.name}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "notes",
                                        name: "Notes",
                                        minWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryDeductionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{item.notes || ""}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "amount",
                                        name: "Amount",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: IOutcomeSalaryDeductionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                            </Stack>
                                        }
                                    }
                                ]} />
                        </Stack>
                    </Stack> : null}
                    {payslip.commissions.length > 0 ? <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Order Commissions</Label>
                        </Stack>
                        <Stack>
                            <DetailsList items={payslip.commissions}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "order",
                                        name: "Order Details",
                                        minWidth: 200,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            const goodsType = DeliveredGoodsType.find((type) => type.key === item.order?.goodsType);

                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 5 }}>
                                                <Label size={'xsmall'}>
                                                    <Link href={`/orders/${item.order.id}`} target={'_blank'}>#{OrderService.getOrderNumber(item.order.orderNumber)}</Link>
                                                </Label>
                                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                    <Stack styles={{ root: { width: 75 } }}>
                                                        <Text size={'xsmall'}>Departure date</Text>
                                                    </Stack>
                                                    <Text size={'xsmall'}>:</Text>
                                                    {item.order.actualDeparture ? <Text size={'xsmall'}>{moment(item.order.actualDeparture).format("DD/MM/YYYY")}</Text> : null}
                                                </Stack>
                                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                    <Stack styles={{ root: { width: 75 } }}>
                                                        <Text size={'xsmall'}>Paid on</Text>
                                                    </Stack>
                                                    <Text size={'xsmall'}>:</Text>
                                                    {item.order.finalPaymentDate ? <Text size={'xsmall'}>{moment(item.order.finalPaymentDate).format("DD/MM/YYYY")}</Text> : null}
                                                </Stack>
                                                <Stack className={'divider'}></Stack>
                                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                    <Stack styles={{ root: { width: '50%' } }}>
                                                        <ol style={{ padding: 0, paddingLeft: 12 }}>
                                                            {item.order.originAddresses.map((address) => {
                                                                const addr = [address.address];
                                                                if (address.city) {
                                                                    addr.push(address.city);
                                                                }
                                                                if (address.province) {
                                                                    addr.push(address.province);
                                                                }

                                                                return <li><Text size={'xsmall'}>{address.type} - {addr.join(", ")}</Text></li>
                                                            })}
                                                        </ol>
                                                    </Stack>
                                                    <FontAwesomeIcon icon={faArrowRight} style={{ paddingTop: 3 }} />
                                                    <Stack styles={{ root: { width: '50%' } }}>
                                                        <ol style={{ padding: 0, paddingLeft: 12 }}>
                                                            {item.order.destinationAddresses.map((address) => {
                                                                const addr = [address.address];
                                                                if (address.city) {
                                                                    addr.push(address.city);
                                                                }
                                                                if (address.province) {
                                                                    addr.push(address.province);
                                                                }

                                                                return <li><Text size={'xsmall'}>{address.type} - {addr.join(", ")}</Text></li>
                                                            })}
                                                        </ol>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "customer",
                                        name: "Customer",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <>
                                                    <Text size={'small'}>{item.order.customer.name}</Text>
                                                    {item.order.company ? <Text size={'small'}>({item.order.company.name})</Text> : null}
                                                </> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "price",
                                        name: "Price",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <Text size='small'>Rp. {GeneralService.getNumberWithSeparator(Number(Number(item.order.totalAmount || "0").toFixed(2)))}</Text> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "profit",
                                        name: "Company Profit",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(Number(item.order.grossProfit || "0").toFixed(2)))}</Text> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "commission",
                                        name: "Commission",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: IOutcomeSalaryCommissionResourceShortProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                            </Stack>
                                        }
                                    }
                                ]} />
                        </Stack>
                    </Stack> : null}
                </Stack>
                <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: 275 } }}>
                    <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
                        <Stack className={styles.header}>
                            <Label>Summary</Label>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Status</Text>
                                {payslip.paymentStatus.toLowerCase() === 'paid' ? <Tag text={'Paid'} variant={'success'} /> : null}
                                {payslip.paymentStatus.toLowerCase() === 'unpaid' ? <Tag text={'Unpaid'} variant={'error'} /> : null}
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Payment month</Text>
                                <Label size={'small'}>{moment(payslip.paymentMonth).format("MMM YYYY")}</Label>
                            </Stack>
                        </Stack>

                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Salary</Text>
                                <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(Number(payslip.salaryAmount))}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Bonus</Text>
                                <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(Number(payslip.bonusAmount))}</Label>
                            </Stack>
                        </Stack>
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Health insurance</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.healthInsuranceAmount))}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Working insurance</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.workingInsuranceAmount))}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Tax</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.taxAmount))}</Label>
                            </Stack>
                        </Stack>
                        {payslip.allowances.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Allowances</Label>
                                {payslip.allowances.map((allowance) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>{allowance.name.trim() !== "" ? allowance.name : "-"}</Text>
                                            {allowance.notes && allowance.notes.trim() !== "" ? <Text size={'xsmall'}>{allowance.notes}</Text> : null}
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(Number(allowance.amount))}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        {payslip.deductions.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Deductions</Label>
                                {payslip.deductions.map((deduction) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>{deduction.name.trim() !== "" ? deduction.name : "-"}</Text>
                                            {deduction.notes && deduction.notes.trim() !== "" ? <Text size={'xsmall'}>{deduction.notes}</Text> : null}
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.deduction}>+ Rp. {GeneralService.getNumberWithSeparator(Number(deduction.amount))}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        {payslip.commissions.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Order commissions</Label>
                                {payslip.commissions.map((commission) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>#{OrderService.getOrderNumber(commission.order?.orderNumber || "")}</Text>
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(Number(commission.amount))}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Total</Text>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.totalSalary))}</Label>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack> : null}
        </Stack>
        {activeSurface === 'payslip.delete' && payslip ? <DeleteSalarySlipForm payslip={payslip} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'payments.list' ? <EmployeePaymentListPanel salaryId={props.payslipId} employeeId={props.employeeId} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default EmployeePayslipDetails;
