import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// assets

// services

// props
import { IOrderResourceProps } from '../../../../../props/orders/order';
import { IOrderSummaryResourceShortProps } from '../../../../../props/orders/summary';

// components
import { ActionButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import GeneralService from '../../../../../services/general';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/root';
import { IOrderSalesResourceShortProps } from '../../../../../props/orders/sales';
import OrderService from '../../../../../services/orders/order';
import OrderSalesService from '../../../../../services/orders/sales';
import ErrorService from '../../../../../services/general/error';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import PermissionsService from '../../../../../services/permissions';

interface OrderDetailsCommissionsProps {
    orderId: string;
}

const OrderDetailsCommissions: React.FC<OrderDetailsCommissionsProps> = observer((props: OrderDetailsCommissionsProps) => {
    const { banner, user } = useStore();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [sales, setSales] = React.useState<IOrderSalesResourceShortProps[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);

            const qs: string[] = [];
            qs.push(`top=all`);
            qs.push(`order_id=${props.orderId}`);

            const _sales = await OrderSalesService.retrieve(qs.join("&"));
            setSales(_sales);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_sales',
                text: 'Failed to retrieve order sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            })
        }
    }

    const renderSales = (_sales: IOrderSalesResourceShortProps, idx?: number) => {
        const canReadCommission = PermissionsService.hasPermission(['orders.commissions.read.all'], user.permissions) || _sales.sales.id === user.data?.id;

        return <>
            {(idx || 0) > 0 ? <Stack className={'divider'} /> : null}
            <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign={'space-between'}>
                <Stack>
                    <NavLink to={"/users/administrators/" + _sales.sales.id}><Label size={'xsmall'}>{_sales.sales.name}</Label></NavLink>
                    {Number(_sales.feeAmount) > 0 ? <Text size={'xsmall'}>(Rp. {GeneralService.getNumberWithSeparator(Number(_sales.feeAmount))})</Text> : null}
                    {Number(_sales.feePercentage) > 0 ? <Text size={'xsmall'}>({GeneralService.getNumberWithSeparator(Number(_sales.feePercentage))}%)</Text> : null}
                    {Number(_sales.feePercentage) < 1 && Number(_sales.feeAmount) < 1 ? <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>(as per contract)</Text> : null}
                </Stack>
                <Stack>
                    {canReadCommission && _sales.commission ? <>
                        <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(_sales.commission.amount)}</Text>
                    </> : null}
                    {canReadCommission && !_sales.commission ? <>
                        <Text size={'small'} style={{fontStyle: 'italic', marginTop: 2}}>(TBA)</Text>
                    </> : null}
                </Stack>
            </Stack>
        </>
    }

    return <Stack tokens={{ childrenGap: 10 }}>
        {!loaded ? <LoadingComponent label={'Retrieving order sales ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded && sales.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No sales/commissions</Text> : null}
        {loaded && sales.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
            {(sales || []).map(renderSales)}
        </Stack> : null}
    </Stack>
});

export default OrderDetailsCommissions;
