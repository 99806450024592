import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import CompanyService from '../../../../services/users/company';
import PermissionsService from '../../../../services/permissions';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';

// components
import { ActionButton, Link, SpinnerSize, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { IUserResourceShortProps } from '../../../../props/users/user';
import UserService from '../../../../services/users/user';
import AdministratorForm from '../../../users/administrators/general/form';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import SubsidiariesForm from '../../../settings/subsidiaries/general/form';
import LoadingComponent from '../../../feedbacks/loading';

type SelectSubsidiaryProps = {
    selected?: ISubsidiariesResourceShort;
    selecteds?: ISubsidiariesResourceShort[];
    options?: ISubsidiariesResourceShort[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    multiple?: boolean;
    errorMessage?: string;
    onChange?(selected?: ISubsidiariesResourceShort): void;
    onChangeMultiple?(selecteds?: ISubsidiariesResourceShort[]): void;
}

const SelectSubsidiary: React.FC<SelectSubsidiaryProps> = (props: SelectSubsidiaryProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ISubsidiariesResourceShort | undefined>();
    const [selecteds, setSelecteds] = React.useState<ISubsidiariesResourceShort[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<ISubsidiariesResourceShort[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        setSelecteds(props.selecteds || []);
    }, [props.selecteds]);

    useEffect(() => {
        setOptions(props.options || []);
    }, [props.options]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (!props.options) {
            const _options = await SubsidiariesService.retrieve();
            setOptions(_options);
        } else {
            setOptions(props.options);
        }
        setLoaded(true);
    }

    const _onSearchSubsidiary = async (keyword: string) => {
        try {
            keyword = keyword.toLowerCase();
            return options.filter((opt) => {
                return opt.name.toLowerCase().indexOf(keyword) > -1 || opt.npwp.toLowerCase().indexOf(keyword) > -1;
            }).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));
        } catch (error) {
            return [];
        }
    }

    const renderSelected = (s: ISubsidiariesResourceShort) => {
        return <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack>
                <Label size={'small'}>{s.name}</Label>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => {
                if (props.multiple && props.onChangeMultiple) {
                    const _selecteds = selecteds.filter((_s) => _s.id !== s.id);
                    props.onChangeMultiple(_selecteds);
                } else if (props.onChange) {
                    props.onChange();
                }
            }} /> : null}
        </Stack>;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Subsidiary"}</Label>}
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            {!selected || props.multiple || !loaded ? <Stack grow={1}>
                <TagPicker
                    disabled={props.disabled || !loaded}
                    selectedItems={[]}
                    removeButtonAriaLabel='Remove'
                    itemLimit={props.multiple ? undefined : 1}
                    onItemSelected={(item: any) => {
                        const _selected = item ? item.metadata : undefined;

                        if (props.multiple && props.onChangeMultiple) {
                            const _selecteds = [...selecteds.filter((_s) => _s.id !== _selected.id), _selected];
                            props.onChangeMultiple(_selecteds);
                        } else if (props.onChange) {
                            props.onChange(_selected);
                        }

                        return null;
                    }}
                    onResolveSuggestions={_onSearchSubsidiary}
                    onEmptyResolveSuggestions={() => _onSearchSubsidiary('')}
                />
            </Stack> : null}
        </Stack>
        {loaded ? <>
            {props.allowCreate && PermissionsService.hasPermission(['subsidiaries.create'], user.permissions) ? <Text size={'small'}>Cannot find subsidiary? <Link onClick={() => { setActiveSurface('create') }}>Click here</Link> to create subsidiary</Text> : null}
            {selected ? renderSelected(selected) : null}
            {selecteds ? <Stack tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 5 } }}>
                {selecteds.map((s) => renderSelected(s))}
            </Stack> : null}
            {props.errorMessage ? <Text style={{ marginTop: 3 }} size={'xsmall'} className={'color-red'}>{props.errorMessage}</Text> : null}
            {activeSurface === 'create' ? <SubsidiariesForm onDismissed={(refresh, subsidiary) => {
                setActiveSurface(undefined);

                if (props.multiple && props.onChangeMultiple && subsidiary) {
                    const _selecteds = [...selecteds, subsidiary];
                    props.onChangeMultiple(_selecteds);
                } else if (props.onChange && subsidiary) {
                    props.onChange(subsidiary);
                }
            }} /> : null}
        </> : null}
    </Stack>;
};

export default SelectSubsidiary;
