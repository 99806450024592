import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import moment from 'moment';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';
import IncomePaymentService from '../../../../../../services/finance/incomes/payments/payment';

// props
import { IOrderResourceShortProps } from '../../../../../../props/orders/order';
import { IBankAccountResourceShortProps } from '../../../../../../props/general/bankAccount';

// components
import { ActionButton, DatePicker, DefaultButton, DetailsList, Dropdown, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField, Tooltip, TooltipHost } from '@fluentui/react';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import ErrorService from '../../../../../../services/general/error';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import UploadFilesComponent from '../../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../../props/general';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import IncomeInvoicePaymentService from '../../../../../../services/finance/incomes/invoices/payment';
import { IIncomePaymentResourceProps } from '../../../../../../props/finance/incomes/payments/payment';
import { IIncomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/payments/invoice';
import SelectIncomeInvoice from '../../../../../uiframeworks/forms/invoices/income';
import SelectBankAccount from '../../../../../uiframeworks/forms/bankaccounts';
import SelectSubsidiary from '../../../../../uiframeworks/forms/selectSubsidiary';
import ISubsidiariesResourceShort from '../../../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../../../services/data/subsidiaries';
import IncomePaymentRelatedInvoiceForm from './invoice';

type IncomePaymentFormProps = {
    invoiceId?: string;
    paymentId?: string;
    onDismissed(refresh?: boolean): void;
}

const IncomePaymentForm: React.FC<IncomePaymentFormProps> = (props: IncomePaymentFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [files, setFiles] = React.useState<IFileDetailsProps[]>([]);
    const [subsidiary, setSubsidiary] = React.useState<ISubsidiariesResourceShort | undefined>();
    const [activePaymentInvoice, setActivePaymentInvoice] = React.useState<IIncomePaymentInvoiceResourceShortProps | undefined>();
    const [data, setData] = React.useState<Partial<IIncomePaymentResourceProps>>({
        id: GeneralService.guid()
    });
    const [error, setError] = React.useState<any>({});

    const hasPermission = PermissionsService.hasPermission(['incomes.invoices.payments.record'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            let _data = data;
            if (props.invoiceId) {
                const _invoice = await IncomeInvoiceService.get(props.invoiceId);
                _data.invoices = [{ id: GeneralService.guid(), invoice: _invoice, paymentId: data.id || "", amount: "0" }];

                if (_invoice.order) {
                    setSubsidiary(_invoice.order.subsidiary);
                } else if (_invoice.subsidiary) {
                    setSubsidiary(_invoice.subsidiary)
                } else if (_invoice.bankAccount?.subsidiaryId) {
                    const _subsidiary = await SubsidiariesService.get(_invoice.bankAccount.subsidiaryId);
                    setSubsidiary(_subsidiary)
                }
            } else if (props.paymentId) {
                const _payment = await IncomePaymentService.get(props.paymentId);
                _data = _payment;
            }

            setData({ ..._data });
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_payment_form_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to get retrieve payment form. Error: ${ErrorService.getMessage(e)}`
            });
            props.onDismissed();
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.amount || error.notes) {
            return true;
        } else if (data.amount === '') {
            return true;
        } else if (files.length < 1) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            // create form data
            let fd = new FormData();
            fd.append("amount", data.amount || "");
            fd.append("paymentDate", data.paymentDate || moment().toISOString());
            fd.append("notes", data.notes || "")
            fd.append("method", data.method || "Cash")
            if (data.transferTo) {
                fd.append("transferToId", data.transferTo?.id || "");
            }

            (data.invoices || []).forEach((invoice) => {
                fd.append("invoices[]", JSON.stringify({
                    notes: invoice.notes || "",
                    amount: invoice.amount,
                    incomeInvoiceId: invoice.invoice.id
                }));
            })

            files.map(f => {
                const file = f.data as File
                const allMetaData = {
                    name: file.name,
                    size: file.size,
                }
                fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, type: f.type, ...allMetaData }))
            });
            files.map(f => fd.append('documents[]', f.data))

            const payment = await IncomePaymentService.create(fd);

            banner.add({
                key: 'create_income_payment_success',
                variant: 'success',
                icon: faCheck,
                text: `Payment recorded successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const getTotalPaidAmount = () => {
        let totalPaidAmount = 0;

        (data.invoices || []).forEach((invoice) => {
            totalPaidAmount += Number(invoice.amount);
        });

        return totalPaidAmount;
    }

    const _onUploadDocuments = async (files: IFileDetailsProps[]) => {
        setFiles(files);
    }

    return <Panel headerText={'Record Payment'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <Dropdown label={"Method"}
                            required={true}
                            disabled={submitting}
                            selectedKey={data.method}
                            options={PaymentMethods}
                            onChange={(evt, opt) => {
                                data.method = opt ? opt.key as string : "";
                                data.transferTo = undefined;
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Amount"}
                            value={data.amount}
                            required={true}
                            prefix={'Rp'}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    let _data = data;
                                    _data.amount = value || "";
                                    _data.invoices = _data.invoices || [];

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 25 });
                                    error.amount = validation.message;

                                    if (_data.invoices.length < 2) {
                                        if (Number(_data.invoices[0].invoice.unpaid || "0") >= Number(value || "0")) {
                                            _data.invoices[0].amount = value || "0";
                                        } else {
                                            _data.invoices[0].amount = (_data.invoices[0].invoice.unpaid || "0");
                                        }
                                    }

                                    const totalPaidAmount = getTotalPaidAmount();
                                    if (!error.amount && Number(value || "0") !== totalPaidAmount) {
                                        error.amount = "Amount missmatched";
                                    }

                                    /*if (!validation.message && Number(invoice.unpaid) < Number(value)) {
                                        error.amount = `Amount cannot be greater than invoice's unpaid amount (Rp. ${GeneralService.getNumberWithSeparator(invoice.unpaid)})`
                                    }*/

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <SelectSubsidiary label={"Subsidiary"}
                            selected={subsidiary}
                            disabled={true}
                            onChange={() => {}} />
                    </Stack.Item>
                    {data.method === 'transfer' ? <Stack.Item>
                        <SelectBankAccount label={"Transfer to"}
                            subsidiaryId={subsidiary?.id}
                            waiting={!subsidiary}
                            onChange={(account) => {
                                let _data = data;
                                _data.transferTo = account;

                                const validation = ValidationService.combination(account, ['required'], {});
                                error.transferTo = validation.message;

                                setData({ ..._data });
                                setError({ ...error });
                            }} />
                    </Stack.Item> : null}
                    <Stack.Item>
                        <DatePicker label={"Payment Date"}
                            isRequired={true}
                            value={moment(data.paymentDate).toDate()}
                            onSelectDate={(date) => {
                                data.paymentDate = date ? moment(date).toISOString() : data.paymentDate;
                                setData({ ...data });
                            }}
                            formatDate={GeneralService.formatDate}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}> </Stack>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign={'center'} horizontalAlign={'space-between'}>
                            <Label size={'xsmall'}>Related invoices</Label>
                            <ActionButton iconProps={{ iconName: "Add" }} 
                                text={"Add invoice"}
                                onClick={() => {
                                    setActiveSurface("invoices.add");
                                }} />
                        </Stack>
                        {(data.invoices || []).length > 0 ? <DetailsList items={data.invoices || []}
                            compact={true}
                            selectionMode={SelectionMode.none}
                            columns={[
                                {
                                    key: 'invoice',
                                    name: 'Invoice',
                                    minWidth: 200,
                                    onRender: (item: IIncomePaymentInvoiceResourceShortProps, idx?: number) => {
                                        return <Stack>
                                            <SelectIncomeInvoice selected={item.invoice}
                                                hideLabel
                                                disabled
                                                onChange={() => { }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'amount',
                                    name: 'Amount Paid',
                                    minWidth: 125,
                                    maxWidth: 125,
                                    onRender: (item: IIncomePaymentInvoiceResourceShortProps, idx?: number) => {
                                        return <Stack styles={{ root: { padding: '6px 0px' } }}>
                                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'amount',
                                    name: '',
                                    minWidth: 80,
                                    maxWidth: 80,
                                    onRender: (item: IIncomePaymentInvoiceResourceShortProps, idx?: number) => {
                                        return <Stack horizontal>
                                            <ActionButton iconProps={{ iconName: "Edit" }} onClick={() => {
                                                setActivePaymentInvoice(item);
                                                setActiveSurface("invoices.add");
                                            }} />
                                            <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => {
                                                let _data = data;
                                                _data.invoices = (_data.invoices || []).filter((i) => i.id !== item.id);
                                                setData({ ..._data });
                                            }} />
                                        </Stack>
                                    }
                                }
                            ]} /> : null}
                    </Stack>
                    <Stack className={'divider'}> </Stack>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.notes = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.notes = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack styles={{ root: { maxHeight: 600 } }} tokens={{childrenGap: 3}}>
                        <Label size={'xsmall'} required={true}>Proof of Payments</Label>
                        <UploadFilesComponent onUpload={_onUploadDocuments} documentTypes={[
                            { key: "document", text: "Document" }
                        ]} />
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
        {activeSurface === 'invoices.add' ? <IncomePaymentRelatedInvoiceForm data={data} 
            subsidiary={subsidiary}
            paymentInvoice={activePaymentInvoice}
            onDismissed={(item) => {
                let _data = data;
                if (item) {
                    _data.invoices = _data.invoices || [];
                    const existingId = _data.invoices.findIndex((i) => i.id === item.id);
                    if (existingId > -1) {
                        _data.invoices[existingId] = item;
                    } else {
                        _data.invoices.push(item);
                    }

                    const validation = ValidationService.combination(_data.invoices, ['required'], {});
                    error.invoices = validation.message;

                    const totalPaidAmount = getTotalPaidAmount();
                    if (Number(data.amount || "0") !== totalPaidAmount) {
                        error.amount = "Amount missmatched";
                    }

                    setData({ ..._data });
                    setError({ ...error });
                }
                
                setActiveSurface(undefined);
                setActivePaymentInvoice(undefined);
            }} /> : null}
    </Panel>
};

export default IncomePaymentForm;
