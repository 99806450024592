import React from 'react';

// import manifests

// import configs

// import stylings

// import props
import { IRenderMessageBarProps } from '../../../props/general';

// import services

// import components
import { MessageBar } from '@fluentui/react';
import Text from './../../typography/text';

export interface ISimpleMessageBarComponentProps {
    properties: IRenderMessageBarProps;
    onDismiss?():void;
}

export default class SimpleMessageBarComponent extends React.Component<ISimpleMessageBarComponentProps, {}> {

    constructor(props: ISimpleMessageBarComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return <MessageBar messageBarType={this.props.properties.type} isMultiline={true} onDismiss={this.props.onDismiss}>
            {this.props.properties.content}
            {this.props.properties.text ? <Text size={'small'}>{this.props.properties.text}</Text> : null}
        </MessageBar>
    }
}