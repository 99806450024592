import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { DefaultButton, ICommandBarItemProps, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, Stack } from '@fluentui/react';
import LoadingComponent from '../../../../../feedbacks/loading';
import Text from '../../../../../typography/text';
import moment from 'moment';
import FilesList, { DocumentModeView } from '../../../../../uiframeworks/files/list';
import IncomeInvoicePaymentService from '../../../../../../services/finance/incomes/invoices/payment';
import { IIncomePaymentResourceProps } from '../../../../../../props/finance/incomes/payments/payment';
import IncomePaymentService from '../../../../../../services/finance/incomes/payments/payment';
import Details from '../../../../../typography/details';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import IncomePaymentsList from '../list';
import IncomePaymentInvoicesList from '../../invoices/general/list';

type IncomePaymentDetailsProps = {
    paymentId: string;
    onDismissed(refresh?: boolean): void;
}

const IncomePaymentDetails: React.FC<IncomePaymentDetailsProps> = (props: IncomePaymentDetailsProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [payment, setPayment] = React.useState<IIncomePaymentResourceProps | undefined>();
    const [viewMode, setViewMode] = useState<DocumentModeView>('card');

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _payment = await IncomePaymentService.get(props.paymentId);
            setPayment(_payment);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_payment_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        return { items, farItems };
    }

    return <Panel headerText={"Payment Details"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && payment ? <>
                {payment.status === 'Verified' && payment.verifiedBy && payment.verifiedAt ? <MessageBar messageBarType={MessageBarType.success}>
                    <Text size={'small'}>This payment has been verified by {payment.verifiedBy.name} at {moment(payment.verifiedAt).format("DD/MM/YYYY HH:mm")}</Text>
                </MessageBar> : null}
                {payment.status === 'Rejected' && payment.rejectedBy && payment.rejectedAt ? <MessageBar messageBarType={MessageBarType.error}>
                    <Text size={'small'}>This payment is rejected by {payment.rejectedBy.name} at {moment(payment.rejectedAt).format("DD/MM/YYYY HH:mm")}</Text>
                </MessageBar> : null}
                <Details title={'Total amount'} text={`Rp. ${GeneralService.getNumberWithSeparator(payment.amount)}`} />
                <Details title={"Method"} text={PaymentMethods.find((pm) => pm.key === payment.method)?.text || 'Cash'} />
                {payment.method === 'transfer' ? <Details title={'Transfer to'} text={`${payment.transferTo.accountNumber} a/n ${payment.transferTo.accountName} (${payment.transferTo.bank.name})`} /> : null}
                <Pivot className={'linePivot'}>
                    <PivotItem key={'breakdown'} headerText={"Breakdown"}>
                        <IncomePaymentInvoicesList payment={payment} 
                            paymentInvoices={payment.invoices}
                            columns={['invoice', 'amount']}
                            hideCommandBar />
                    </PivotItem>
                    <PivotItem key={'documents'} headerText={"Documents"}>
                        <Stack styles={{ root: { padding: 10 } }}>
                            <FilesList files={payment.documents}
                                documentTypes={[{ key: "document", text: "Document" }]}
                                mode={viewMode}
                                loaded={loaded}  />
                        </Stack>
                    </PivotItem>
                </Pivot>
            </> : null}
        </Stack>
    </Panel>
};

export default IncomePaymentDetails;
