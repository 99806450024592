import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import BankAccountService from '../../../../../services/bankaccounts';

// props
import { IBankAccountResourceShortProps } from '../../../../../props/bankaccounts';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import PermissionsService from '../../../../../services/permissions';
import { NavLink } from 'react-router-dom';
import { IBankAccountCutoffResourceShortProps } from '../../../../../props/bankaccounts/cutoff';
import BankAccountCutoffService from '../../../../../services/bankaccounts/cutoff';
import BankAccountCutoffForm from '../form';
import moment from 'moment';
import GeneralService from '../../../../../services/general';

type BankAccountCutoffsListProps = {
    qs?: string[];
    bankAccountId: string;
}

const BankAccountCutoffsList: React.FC<BankAccountCutoffsListProps> = (props: BankAccountCutoffsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [bankAccountCutoffs, setBankAccountCutoffs] = React.useState<IBankAccountCutoffResourceShortProps[]>([]);
    const [activeBankAccountCutoff, setActiveBankAccountCutoff] = React.useState<IBankAccountCutoffResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "cutoffDate",
            name: "Cutoff Date",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{moment(item.cutoffDate).format("DD/MM/YYYY - HH:mm A")}</Text>
                </Stack>;
            }
        },
        {
            key: "createdBY",
            name: "Created By",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.createdBy.name}</Text>
                </Stack>;
            }
        },
        {
            key: "expectedAmount",
            name: "Expected Amount",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.expectedAmount))}</Text>
                </Stack>;
            }
        },
        {
            key: "actualAmount",
            name: "Actual Amount",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.actualAmount))}</Text>
                </Stack>;
            }
        },
        {
            key: "difference",
            name: "Amount Difference",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                const diff = Number(item.actualAmount) - Number(item.expectedAmount);  
                return <Stack>
                    {diff > 0 ? <Text size={'small'} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(diff)}</Text> : null}
                    {diff < 0 ? <Text size={'small'} className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(diff)}</Text> : null}
                    {diff === 0 ? <Text size={'small'} className={'color-blue'}>amount matched</Text> : null}
                </Stack>;
            }
        },
        {
            key: "justification",
            name: "Justification",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IBankAccountCutoffResourceShortProps) => {
                return <Stack>
                    {(item.justification || '').trim() !== "" ? <Text size={'small'}>{item.justification}</Text> : null}
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        setLoaded(false);
        try {
            setLoaded(false);
            const qs: string[] = [];
            qs.push('top=all');

            const results = await BankAccountCutoffService.retrieve(props.bankAccountId, qs.join("&"));
            setBankAccountCutoffs(results.data);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_bank_account_cutoffs_error',
                text: 'Failed to retrieve bank account cutoffs. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['bankaccounts.cutoffs.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create cutoff",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create cutoff"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('bankaccounts.cutoffs.create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (_refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveBankAccountCutoff(undefined);

        if (_refresh) { refresh() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack>
                {
                    !loaded || (loaded && bankAccountCutoffs.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={bankAccountCutoffs}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && bankAccountCutoffs.length < 1 ? <Stack>
                    <Text size={'small'} style={{fontStyle: 'italic'}}>No cutoffs found</Text>
                </Stack> : null}
            </Stack>
        </Stack>
        {activeSurface === 'bankaccounts.cutoffs.create' ? <BankAccountCutoffForm bankAccountId={props.bankAccountId} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'bankaccount.update' && activeBankAccount ? <BankAccountForm type={type} subsidiaryId={props.subsidiaryId} vendorId={props.vendorId} employeeId={props.employeeId} id={activeBankAccount.id} onDismissed={_onSurfaceDismissed} /> : null*/}
        {/*activeSurface === 'delete' && activeSubsidiaries ? <DeleteSubsidiariesForm subsidiaries={activeSubsidiaries} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Stack>;
};

export default BankAccountCutoffsList;
