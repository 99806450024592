import React from 'react';

// assets

// services

// props
import { IOrderResourceProps } from '../../../../../props/orders/order';

// components
import { Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import GeneralService from '../../../../../services/general';

interface OrderDetailsPriceSummaryProps {
    order: IOrderResourceProps;
}

const OrderDetailsPriceSummary: React.FC<OrderDetailsPriceSummaryProps> = (props: OrderDetailsPriceSummaryProps) => {
    const order = props.order;

    return <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Total Price</Text>
            <Label>Rp. {GeneralService.getNumberWithSeparator(Number(order.totalAmount))}</Label>
        </Stack>
        <Stack horizontal horizontalAlign={'end'}>
            <Text size={'small'}>(PPN) Rp. {GeneralService.getNumberWithSeparator(order.ppn)}</Text>
        </Stack>
        <Stack horizontal horizontalAlign={'end'}>
            <Text size={'small'}>(PPh) Rp. {GeneralService.getNumberWithSeparator(order.pph)}</Text>
        </Stack>
        <Stack className={'divider'}> </Stack>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Paid</Text>
            <Label className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(order.paidAmount)}</Label>
        </Stack>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Unpaid</Text>
            <Label className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(order.unpaidAmount)}</Label>
        </Stack>
    </Stack>
};

export default OrderDetailsPriceSummary;
