import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../../stores/root';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props
import { IEmployeeContractResourceProps } from '../../../../../../props/employees/contracts/contract';

// components
import { ActionButton, CommandBar, IButtonProps, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import Details from '../../../../../typography/details';
import GeneralService from '../../../../../../services/general';
import Tag from '../../../../../uiframeworks/tag';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';

interface EmployeePayslipDetailsSalaryProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsSalary: React.FC<EmployeePayslipDetailsSalaryProps> = observer((props: EmployeePayslipDetailsSalaryProps) => {
    const { payslip } = props;

    const getTotalCommissions = () => {
        let total = 0;
        (payslip.commissions || []).forEach((commision) => {
            total += Number(commision.amount);
        });

        return total;
    }

    const getTotalBonuses = () => {
        let total = 0;
        (payslip.bonuses || []).forEach((bonus) => {
            total += Number(bonus.amount);
        });

        return total;
    }

    const getTotalDeductions = () => {
        let total = 0;
        (payslip.deductions || []).forEach((deduction) => {
            total += Number(deduction.amount);
        });

        return total;
    }

    return <Stack tokens={{ childrenGap: 10 }}>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>Base salary</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.salaryAmount))}</Text>
            </Stack>
        </Stack>
        <Stack className={'divider'}> </Stack>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>BPJS Kes.</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.healthInsuranceAmount))}</Text>
            </Stack>
        </Stack>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>BPJS Tk.</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.workplaceInsuranceAmount))}</Text>
            </Stack>
        </Stack>
        {(payslip.bonuses || []).length > 0 || (payslip.deductions || []).length > 0 || (payslip.commissions || []).length > 0 ? <Stack className={'divider'}> </Stack> : null}
        {(payslip.commissions || []).length > 0 ? <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>Commissions</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(getTotalCommissions())}</Text>
            </Stack>
        </Stack> : null}
        {(payslip.bonuses || []).length > 0 ? <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>Bonuses</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(getTotalBonuses())}</Text>
            </Stack>
        </Stack> : null}
        {(payslip.deductions || []).length > 0 ? <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>Deductions</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(getTotalDeductions())}</Text>
            </Stack>
        </Stack> : null}
        <Stack className={'divider'}> </Stack>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>PPn</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.ppnAmount))}</Text>
            </Stack>
        </Stack>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>PPh</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Text className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(payslip.pphAmount))}</Text>
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{childrenGap: 10}} horizontal horizontalAlign={'space-between'}>
            <Text>Total salary</Text>
            <Stack horizontalAlign={'end'} styles={{root: {width: 125}}}>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(payslip.totalAmount))}</Label>
            </Stack>
        </Stack>
    </Stack>;
});

export default EmployeePayslipDetailsSalary;
