import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import ValidationService from '../../../../services/validation';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import ISalesTeamResourceShort from '../../../../props/users/team';
import ExternalBankAccountService from '../../../../services/data/bankaccounts/external';
import SelectBank from '../../../uiframeworks/forms/selectBank';
import { IExternalBankAccountResourceProps } from '../../../../props/data/bankaccounts/external';
import VendorsService from '../../../../services/data/vendors';
import UserService from '../../../../services/users/user';
import VendorField from '../../../uiframeworks/forms/vendor';
import SelectEmployee from '../../../uiframeworks/forms/selectEmployee';
import { IBankAccountResourceProps, IBankAccountResourceShortProps } from '../../../../props/bankaccounts';
import BankAccountService from '../../../../services/bankaccounts';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import SelectSubsidiary from '../../../uiframeworks/forms/selectSubsidiary';

type TBankAccountType = 'vendor' | 'employee' | 'subsidiary';

type BankAccountFormProps = {
    id?: string;
    vendorId?: string;
    employeeId?: string;
    subsidiaryId?: string;
    type?: TBankAccountType;
    onDismissed(refresh?: boolean, item?: IBankAccountResourceShortProps): void;
}

const BankAccountForm: React.FC<BankAccountFormProps> = (props: BankAccountFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IBankAccountResourceProps>>({});
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.id === undefined ? 'create' : 'update';

    let type = props.type;
    if (!type && props.subsidiaryId) {
        type = 'subsidiary';
    } else if (!type && props.employeeId) {
        type = 'employee';
    } else if (!type && props.vendorId) {
        type = 'vendor';
    }
    type = type as TBankAccountType;

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.id) {
            const _data = await BankAccountService.read(props.id);
            setData(_data);
        } else if (props.vendorId && type === 'vendor') {
            const _vendor = await VendorsService.get(props.vendorId);
            setData({vendor: _vendor, accountName: _vendor.name});
        } else if (props.employeeId && type === 'employee') {
            const _employee = await UserService.get(props.employeeId);
            setData({employee: _employee, accountName: _employee.name});
        } else if (props.subsidiaryId && type === 'subsidiary') {
            const _subsidiary = await SubsidiariesService.get(props.subsidiaryId);
            setData({subsidiary: _subsidiary, accountName: _subsidiary.name});
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.accountName || error.accountNumber || (error.vendor && type === 'vendor') || (error.employee && type === 'employee') || error.bank) {
            return true;
        } else if ((data.accountName || "").trim() === '' || (data.accountNumber || "").trim() === '' || (!data.vendor && type === 'vendor') || (!data.employee && type === 'employee') || !data.bank) {
            return true;
        }
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { accountName, accountNumber, bank, vendor, employee, subsidiary, bankCity } = data;

            // create form data
            let fd = new FormData();
            fd.append("accountName", accountName || "");
            fd.append("accountNumber", accountNumber || "");
            fd.append("bankId", bank?.id || "");
            fd.append("bankCity", bankCity || "");

            if (type === 'vendor') {
                fd.append("vendorId", vendor?.id || "");
            } else if (type === 'employee') {
                fd.append("employeeId", employee?.id || "");
            } else if (type === 'subsidiary') {
                fd.append("subsidiaryId", subsidiary?.id || "");
            }

            let result;
            if (props.id === undefined) {
                result = await BankAccountService.create(fd);
            } else {
                result = await BankAccountService.update(props.id, fd);
            }

            banner.add({
                key: 'create_bankaccount_success',
                variant: 'success',
                icon: faCheck,
                text: `Bank account "${accountName} (${accountNumber})" created successfully`
            });
            
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }


    return <Panel headerText={mode === 'create' ? "Create Bank Account" : "Update Bank Account"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating bank account ..." : "Updating bank account ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    {type === 'vendor' ? <VendorField label={'Vendor'}
                        required={true}
                        selected={data.vendor}
                        errorMessage={error.vendor}
                        disabled={submitting || props.vendorId !== undefined}
                        onChange={(vendor) => {
                            data.vendor = vendor;
                            data.accountName = (data.accountName || "").trim() === "" ? vendor?.name : data.accountName;

                            const validation = ValidationService.combination(vendor, ['required'], {});
                            error.vendor = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} /> : null}
                    {type === 'employee' ? <SelectEmployee label={'Employee'}
                        required={true}
                        selected={data.employee}
                        errorMessage={error.employee}
                        disabled={submitting || props.vendorId !== undefined}
                        onChange={(employee) => {
                            data.employee = employee;
                            data.accountName = (data.accountName || "").trim() === "" ? employee?.name : data.accountName;

                            const validation = ValidationService.combination(employee, ['required'], {});
                            error.employee = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} /> : null}
                    {type === 'subsidiary' ? <SelectSubsidiary label={'Subsidiary'}
                        required={true}
                        selected={data.subsidiary}
                        errorMessage={error.subsidiary}
                        disabled={submitting || props.vendorId !== undefined}
                        onChange={(subsidiary) => {
                            data.subsidiary = subsidiary;
                            data.accountName = (data.accountName || "").trim() === "" ? subsidiary?.name : data.accountName;

                            const validation = ValidationService.combination(subsidiary, ['required'], {});
                            error.vendor = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} /> : null}
                    <Stack.Item>
                        <TextField
                            label={"Account name"}
                            required={true}
                            value={data.accountName || ""}
                            onChange={(evt, value) => {
                                data.accountName = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Account number"}
                            required={true}
                            value={data.accountNumber || ""}
                            onChange={(evt, value) => {
                                data.accountNumber = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.accountNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <SelectBank label={'Select bank'}
                        required={true}
                        selected={data.bank}
                        errorMessage={error.bank}
                        disabled={submitting}
                        onChange={(bank) => {
                            data.bank = bank;

                            const validation = ValidationService.combination(bank, ['required'], {});
                            error.bank = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} />
                    <Stack.Item>
                        <TextField
                            label={"Bank city"}
                            value={data.bankCity || ""}
                            onChange={(evt, value) => {
                                data.bankCity = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.bankCity}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default BankAccountForm;
