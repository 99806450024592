import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

// assets

// services

// props

// components
import { Stack } from '@fluentui/react';
import Details from '../../../../../typography/details';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import GeneralService from '../../../../../../services/general';

interface EmployeePayslipDetailsPropertiesProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsProperties: React.FC<EmployeePayslipDetailsPropertiesProps> = observer((props: EmployeePayslipDetailsPropertiesProps) => {
    const { payslip } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Payslip number'} text={'#' + payslip.id} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Month / Year'} text={moment(payslip.payslipMonth, 'MM/YYYY').format("MMMM YYYY")} />
            </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Employee'} text={payslip.employee.name} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                {payslip.contract ? <Details title={'Contract #'} text={'#' + payslip.contract.id} /> : null}
            </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Details title={'Total salary'} text={'Rp. ' + GeneralService.getNumberWithSeparator(Number(payslip.totalAmount))} />
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}> </Stack>
        </Stack>
    </Stack>;
});

export default EmployeePayslipDetailsProperties;
