import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../services/orders/order';
import OrderStatusService from '../../../../services/orders/orderStatus';

// props
import { IOrderResourceProps } from '../../../../props/orders/order';

// components
import { DefaultButton, Dropdown, Panel, PanelType, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import { IVersionHistoryResourceShortProps } from '../../../../props/general/versionHistory';
import GeneralService from '../../../../services/general';
import moment from 'moment';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import Tag from '../../../uiframeworks/tag';
import OrderInvoiceService from '../../../../services/orders/invoices/invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type OrderVersionHistoryProps = {
    orderId: string;
    onDismissed(refresh?: boolean): void;
}

const OrderVersionHistory: React.FC<OrderVersionHistoryProps> = (props: OrderVersionHistoryProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [versions, setVersions] = React.useState<IVersionHistoryResourceShortProps[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _versions = await OrderService.versions.retrieve(props.orderId);
            setLoaded(true);
            setVersions(_versions);
        } catch (e) {

        }
    }

    const renderVersion = (_version: IVersionHistoryResourceShortProps, idx: number) => {
        const order = _version.data as Partial<IOrderResourceProps>;


        return <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.indicator}>
                <Stack className={`${styles.dot} ${idx === 0 ? styles.active : ''}`}></Stack>
                {idx < versions.length - 1 ? <Stack className={styles.line}></Stack> : null}
            </Stack>
            <Stack grow={1} tokens={{ childrenGap: 10 }} className={styles.card}>
                <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Stack>
                        <Persona text={_version.createdBy.name} imageInitials={GeneralService.getInitial(_version.createdBy.name)} size={PersonaSize.size24} />
                    </Stack>
                    <Text size={'small'}>|</Text>
                    <Text size={'small'}>{moment(_version.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{_version.notes}</Text>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack>
                    <table className={styles.table}>
                        {order.subsidiary ? <tr>
                            <td className={styles.title}><Text size={'small'}>Subsidiary</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.subsidiary?.name}</Text></td>
                        </tr> : null}
                        {order.customer || order.company ? <tr>
                            <td className={styles.title}><Text size={'small'}>Customer</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.customer ? order.customer.name : ""} {order.company ? `(${order.company.name})` : ""}</Text></td>
                        </tr> : null}
                        {order.freightCategory || order.freightCategory ? <tr>
                            <td className={styles.title}><Text size={'small'}>Freight category</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.freightCategory ? order.freightCategory.name : ""}</Text></td>
                        </tr> : null}
                        {order.deliveryType || order.deliveryType ? <tr>
                            <td className={styles.title}><Text size={'small'}>Delivery type</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.deliveryType ? order.deliveryType.name : ""}</Text></td>
                        </tr> : null}
                        {order.goodsType || order.goodsType ? <tr>
                            <td className={styles.title}><Text size={'small'}>Goods type</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{DeliveredGoodsType.find((t) => t.key === order.goodsType)?.text}</Text></td>
                        </tr> : null}
                        {order.sales && order.sales.length > 0 ? <tr>
                            <td className={styles.title} style={{ paddingTop: 10 }}><Text size={'small'}>Sales</Text></td>
                            <td className={styles.separator} style={{ paddingTop: 10 }}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                {order.sales.map((sales) => <Tag text={sales.sales.name} />)}
                            </Stack></td>
                        </tr> : null}
                        {order.originAddresses && order.originAddresses.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'}>Origin</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}>
                                <ol style={{ padding: '0px 12px', margin: 0 }}>
                                    {order.originAddresses.map((address) => {
                                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                        return <Text size={'small'}><li>{text}</li></Text>
                                    })}
                                </ol>
                            </td>
                        </tr> : null}
                        {order.destinationAddresses && order.destinationAddresses.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'}>Destination</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}>
                                <ol style={{ padding: '0px 12px', margin: 0 }}>
                                    {order.destinationAddresses.map((address) => {
                                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                        return <Text size={'small'}><li>{text}</li></Text>
                                    })}
                                </ol>
                            </td>
                        </tr> : null}
                        {order.containers && order.containers.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Containers</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {order.containers.map((container, idx) => <Tag text={`${container.containerNumber || "Container #" + (idx + 1)} (Rp. ${GeneralService.getNumberWithSeparator(Number(container.totalPrice))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {order.trucks && order.trucks.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Trucks</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {order.trucks.map((truck, idx) => <Tag text={`${truck.vehicleRegistrationNumber || truck.name || "Truck #" + (idx + 1)} (Rp. ${GeneralService.getNumberWithSeparator(Number(truck.totalPrice))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {order.goods && order.goods.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Goods</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {order.goods.map((goods, idx) => <Tag text={`${goods.goods.name} (${GeneralService.getNumberWithSeparator(Number(goods.quantityAmount))} ${goods.goods.quantityUnit}) (Rp. ${GeneralService.getNumberWithSeparator(Number(goods.totalPrice))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {order.addons && order.addons.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Additional charges</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {order.addons.map((addon, idx) => <Tag text={`${addon.name} (Rp. ${GeneralService.getNumberWithSeparator(Number(addon.totalPrice))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {order.invoices && order.invoices.length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Invoices</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {order.invoices.map((invoice, idx) => <Tag text={`${OrderInvoiceService.getInvoiceNumber(invoice.id)} (Rp. ${GeneralService.getNumberWithSeparator(Number(invoice.amount))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {order.totalAmount !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Total price</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.totalAmount))}</Text></td>
                        </tr> : null}
                        {order.totalOutcomes !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Total expenses</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.totalOutcomes))}</Text></td>
                        </tr> : null}
                        {order.pph !== undefined || order.ppn !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Tax</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.ppn) + Number(order.pph))}</Text></td>
                        </tr> : null}
                        {order.grossProfit !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Gross profit</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.grossProfit))}</Text></td>
                        </tr> : null}
                        {order.nettProfit !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Nett profit</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.nettProfit))}</Text></td>
                        </tr> : null}
                        {order.paidAmount !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Paid amount</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.paidAmount))}</Text></td>
                        </tr> : null}
                        {order.unpaidAmount !== undefined ? <tr>
                            <td className={styles.title}><Text size={'small'}>Unpaid amount</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(order.unpaidAmount))}</Text></td>
                        </tr> : null}
                        {order.documents && order.documents.length > 0 ? <tr>
                            <td className={styles.title} style={{ paddingTop: 10 }}><Text size={'small'}>Documents</Text></td>
                            <td className={styles.separator} style={{ paddingTop: 10 }}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                {order.documents.map((document: any) => <Tag text={(document.path || "").split("/").pop()} />)}
                            </Stack></td>
                        </tr> : null}
                        {order.status ? <tr>
                            <td className={styles.title}><Text size={'small'}>Status</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.status?.name}</Text></td>
                        </tr> : null}
                        {order.cancellationReason ? <tr>
                            <td className={styles.title}><Text size={'small'}>Cancellation reason</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{order.cancellationReason}</Text></td>
                        </tr> : null}
                    </table>
                </Stack>
            </Stack>
        </Stack>;
    }

    const compareVersion = (currentVersion: IVersionHistoryResourceShortProps, previousVersion: IVersionHistoryResourceShortProps) => {
        const currentOrder = currentVersion.data as IOrderResourceProps;
        const previousOrder = previousVersion.data as IOrderResourceProps;
        const cleanOrder: Partial<IOrderResourceProps> = {};

        if (currentOrder.customer?.id !== previousOrder.customer?.id) {
            cleanOrder.customer = currentOrder.customer;
        }
        if (currentOrder.company?.id !== previousOrder.company?.id) {
            cleanOrder.company = currentOrder.company;
        }
        if (currentOrder.subsidiary?.id !== previousOrder.subsidiary?.id) {
            cleanOrder.subsidiary = currentOrder.subsidiary;
        }
        if (currentOrder.freightCategory?.id !== previousOrder.freightCategory?.id) {
            cleanOrder.freightCategory = currentOrder.freightCategory;
        }
        if (currentOrder.deliveryType?.id !== previousOrder.deliveryType?.id) {
            cleanOrder.deliveryType = currentOrder.deliveryType;
        }
        if (currentOrder.status?.id !== previousOrder.status?.id) {
            cleanOrder.status = currentOrder.status;
        }
        if (currentOrder.goodsType !== previousOrder.goodsType) {
            cleanOrder.goodsType = currentOrder.goodsType;
        }
        if ((currentOrder.sales || []).map((s) => s.id).join(';') !== (previousOrder.sales || []).map((s) => s.id).join(';')) {
            cleanOrder.sales = currentOrder.sales;
        }
        if ((currentOrder.originAddresses || []).map((add) => add.type + add.address + add.city + add.province + add.orderNumber).join(';').toLowerCase() !== (previousOrder.originAddresses || []).map((add) => add.type + add.address + add.city + add.province + add.orderNumber).join(';').toLowerCase()) {
            cleanOrder.originAddresses = currentOrder.originAddresses;
        }
        if ((currentOrder.destinationAddresses || []).map((add) => add.type + add.address + add.city + add.province + add.orderNumber).join(';').toLowerCase() !== (previousOrder.destinationAddresses || []).map((add) => add.type + add.address + add.city + add.province + add.orderNumber).join(';').toLowerCase()) {
            cleanOrder.destinationAddresses = currentOrder.destinationAddresses;
        }
        if (currentOrder.totalAmount !== previousOrder.totalAmount) {
            cleanOrder.totalAmount = currentOrder.totalAmount;
        }
        if (currentOrder.totalOutcomes !== previousOrder.totalOutcomes) {
            cleanOrder.totalOutcomes = currentOrder.totalOutcomes;
        }
        if (currentOrder.ppn !== previousOrder.ppn) {
            cleanOrder.ppn = currentOrder.ppn;
        }
        if (currentOrder.pph !== previousOrder.pph) {
            cleanOrder.pph = currentOrder.pph;
        }
        if (currentOrder.grossProfit !== previousOrder.grossProfit) {
            cleanOrder.grossProfit = currentOrder.grossProfit;
        }
        if (currentOrder.nettProfit !== previousOrder.nettProfit) {
            cleanOrder.nettProfit = currentOrder.nettProfit;
        }
        if (currentOrder.paidAmount !== previousOrder.paidAmount) {
            cleanOrder.paidAmount = currentOrder.paidAmount;
        }
        if (currentOrder.unpaidAmount !== previousOrder.unpaidAmount) {
            cleanOrder.unpaidAmount = currentOrder.unpaidAmount;
        }
        if ((currentOrder.containers || []).map((cont) => cont.id + cont.containerNumber + cont.totalPrice).join(';').toLowerCase() !== (previousOrder.containers || []).map((cont) => cont.id + cont.containerNumber + cont.totalPrice).join(';').toLowerCase()) {
            cleanOrder.containers = currentOrder.containers;
        }
        if ((currentOrder.addons || []).map((addon) => addon.id + addon.name + addon.price).join(';').toLowerCase() !== (previousOrder.addons || []).map((addon) => addon.id + addon.name + addon.price).join(';').toLowerCase()) {
            cleanOrder.addons = currentOrder.addons;
        }
        if ((currentOrder.trucks || []).map((truck) => truck.id + truck.name + truck.vehicleRegistrationNumber + truck.price).join(';').toLowerCase() !== (previousOrder.trucks || []).map((truck) => truck.id + truck.name + truck.vehicleRegistrationNumber + truck.price).join(';').toLowerCase()) {
            cleanOrder.trucks = currentOrder.trucks;
        }
        if ((currentOrder.goods || []).map((goods) => goods.id + goods.goods.name + goods.quantityAmount + goods.goods.quantityUnit + goods.totalPrice).join(';').toLowerCase() !== (previousOrder.goods || []).map((goods) => goods.id + goods.goods.name + goods.quantityAmount + goods.goods.quantityUnit + goods.totalPrice).join(';').toLowerCase()) {
            cleanOrder.goods = currentOrder.goods;
        }
        if ((currentOrder.invoices || []).map((invoice) => invoice.id + invoice.amount).join(';').toLowerCase() !== (previousOrder.invoices || []).map((invoice) => invoice.id + invoice.amount).join(';').toLowerCase()) {
            cleanOrder.invoices = currentOrder.invoices;
        }
        if ((currentOrder.documents || []).map((document: any) => document.id).join(';').toLowerCase() !== (previousOrder.documents || []).map((document: any) => document.id).join(';').toLowerCase()) {
            cleanOrder.documents = currentOrder.documents;
        }
        if (currentOrder.cancellationReason !== previousOrder.cancellationReason) {
            cleanOrder.cancellationReason = currentOrder.cancellationReason;
        }

        return { ...currentVersion, data: cleanOrder };
    }

    return <Panel headerText={"Version History"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Retrieving version history ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Stack tokens={{ childrenGap: 20 }} className={styles.timeline}>
                    {versions.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)).reverse().map((version, idx) => {
                        const currentVersion = version;
                        const previousVersion = versions[idx + 1] ? versions[idx + 1] : undefined;

                        if (previousVersion) {
                            const updated = compareVersion(currentVersion, previousVersion);
                            return renderVersion(updated, idx);
                        } else {
                            return renderVersion(currentVersion, idx);
                        }
                    })}
                </Stack>
            </> : null}
        </Stack>
    </Panel>
};

export default OrderVersionHistory;
