import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { PPhPercentageOptions, PPNPercentageOptions } from '../../../../../../manifests/taxPercentage';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props

// components
import { Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IIncomeInvoiceItemResourceShortProps } from '../../../../../../props/finance/incomes/invoices/item';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { IIncomePaymentInvoiceResourceProps, IIncomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/payments/invoice';
import SelectIncomeInvoice from '../../../../../uiframeworks/forms/invoices/income';
import { IIncomeInvoiceResourceProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import { IIncomePaymentResourceProps } from '../../../../../../props/finance/incomes/payments/payment';
import ISubsidiariesResourceShort from '../../../../../../props/data/subsidiaries';

type IncomePaymentRelatedInvoiceFormProps = {
    subsidiary?: ISubsidiariesResourceShort;
    paymentInvoice?: IIncomePaymentInvoiceResourceShortProps;
    data: Partial<IIncomePaymentResourceProps>;
    onDismissed(paymentInvoice?: IIncomePaymentInvoiceResourceProps): void;
}

const IncomePaymentRelatedInvoiceForm: React.FC<IncomePaymentRelatedInvoiceFormProps> = (props: IncomePaymentRelatedInvoiceFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IIncomePaymentInvoiceResourceProps>>({
        id: GeneralService.guid(),
        amount: "0",
        notes: ""
    });
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        setLoaded(true);
        init();
    }, []);

    const getTotalAmountLeft = () => {
        let totalPaidAmount = 0;

        (props.data.invoices || []).forEach((invoice) => {
            totalPaidAmount += Number(invoice.amount);
        });

        let amountLeft = Number(props.data.amount || "0") - totalPaidAmount;
        return amountLeft < 0 ? 0 : amountLeft;
    }

    const init = () => {
        let _data = data;

        if (props.paymentInvoice) {
            _data = {
                ..._data,
                id: props.paymentInvoice.id,
                amount: props.paymentInvoice.amount + "",
                invoice: props.paymentInvoice.invoice,
                notes: props.paymentInvoice.notes
            }
        } else {
            _data.amount = (getTotalAmountLeft() || 0) + "";
        }

        setData({..._data});
    }

    const isSubmitButtonDisabled = () => {
        if ((data.amount || "").trim() === "" || !data.invoice) {
            return true;
        } else if (error.notes || error.amount || error.invoice) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            props.onDismissed(data as IIncomePaymentInvoiceResourceProps);
        } catch (e) {
        }
    }

    return <Panel headerText={'Add Related Order'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.data ? "Add" : "Update"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    {props.data ?<Stack.Item>
                        <TextField label={"Amount left"}
                            value={GeneralService.getNumberWithSeparator(getTotalAmountLeft())}
                            prefix={"Rp"}
                            disabled={true} />
                    </Stack.Item> : null}
                    <Stack.Item>
                        <SelectIncomeInvoice label={"Select invoice"}
                            selected={data.invoice}
                            onChange={(invoice) => {
                                let _data = data;
                                _data.invoice = invoice;

                                const validation = ValidationService.combination(invoice, ['required'], {});
                                error.subtotalAmount = validation.message;

                                setData({..._data});
                                setError({ ...error });
                            }} />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <TextField required={true}
                            label={"Amount"}
                            value={data.amount}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || value === "0" || !isNaN(Number(value))) {
                                    data.amount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 25, max: Number(data.invoice?.unpaid || "0") });
                                    error.amount = validation.message;

                                    setData({ ...data});
                                    setError({ ...error });
                                }
                            }}
                            prefix={"Rp"}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}> </Stack>
                    <Stack.Item grow={1}>
                        <TextField required={false}
                            label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={true}
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 1000 });
                                error.notes = validation.message;

                                setData({ ...data});
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default IncomePaymentRelatedInvoiceForm;
