import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services

// props
import { IPaginationResourceShort } from '../../../../../props/general';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { ActionButton, IColumn, IconButton, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import { IVendorPICResourceShortProps } from '../../../../../props/data/vendors/pic';
import Text from './../../../../typography/text';
import VendorPICService from '../../../../../services/data/vendors/pics';
import { IVendorServiceResourceShortProps } from '../../../../../props/data/vendors/service';
import VendorServiceService from '../../../../../services/data/vendors/service';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import GeneralService from '../../../../../services/general';
import Tag from '../../../../uiframeworks/tag';
import VendorServiceForm from '../form/services';

type VendorDetailsServicesProps = {
    vendorId: string;
}

const VendorDetailsServices: React.FC<VendorDetailsServicesProps> = (props: VendorDetailsServicesProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [services, setServices] = React.useState<IVendorServiceResourceShortProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [activeService, setActiveService] = React.useState<IVendorServiceResourceShortProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(false);
        const qs: string[] = ['top=all'];
        qs.push(`vendor_id=${props.vendorId}`);

        const _services = await VendorServiceService.retrieve(qs.join("&"));
        setServices(_services.data);
        setLoaded(true);
    }

    const _onSurfaceDismissed = (refresh?: boolean, service?: IVendorServiceResourceShortProps) => {
        setActiveService(undefined);
        setActiveSurface(undefined);

        if (refresh) {init();}
    }

    const renderServiceItem = (service: IVendorServiceResourceShortProps, idx?: number) => {
        return <>
            {(idx || 0) > 0 ? <Stack className={'divider'}></Stack> : null}
            <Stack tokens={{ childrenGap: 8 }}>
                {/*<Label size={'small'}>{service.service.name}</Label>*/}
                <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'}>
                    <Tag text={service.service.name} variant={'active'} />
                    <Stack horizontal tokens={{childrenGap: 5}}>
                        <IconButton iconProps={{iconName: "Edit"}}
                            styles={{root: {
                                paddingLeft: '0px !important', 
                                paddingRight: '0px !important'
                            }}}
                            onClick={() => {
                                setActiveService(service);
                                setActiveSurface('service.update');
                            }} />
                        <IconButton iconProps={{iconName: "Delete"}}
                            styles={{root: {
                                paddingLeft: '0px !important', 
                                paddingRight: '0px !important'
                            }}}
                            onClick={() => {
                                setActiveService(service);
                                setActiveSurface('service.delete');
                            }} />
                    </Stack>
                </Stack>
                <Stack tokens={{childrenGap: 3}}>
                    <Stack horizontal tokens={{ childrenGap: 3 }}>
                        <Stack styles={{ root: { width: 35 } }}><Text size={'small'}>Areas</Text></Stack>
                        <Text size={'small'}>:</Text>
                        <Text size={'small'}>{(service.areas || []).map((area) => {
                            return area.city?.name || area.province?.name || area.country?.name || ""
                        }).join(", ")}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 3 }}>
                        <Stack styles={{ root: { width: 35 } }}><Text size={'small'}>Fleets</Text></Stack>
                        <Text size={'small'}>:</Text>
                        <Stack tokens={{ childrenGap: 2 }}>
                            {(service.fleets || []).map((fleet) => {
                                return <Text size={'small'}>{fleet.ship?.name || fleet.truck?.name} ({GeneralService.getNumberWithSeparator(fleet.size || "0")} ton) (x{GeneralService.getNumberWithSeparator(fleet.quantity || "0")})</Text>
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label={'Retrieving vendor services ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded && services.length > 0 ? <Stack tokens={{childrenGap: 10}}>
            {services.map(renderServiceItem)}
        </Stack> : null}
        {loaded && services.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No services found</Text> : null}
        {activeSurface === 'service.update' && activeService ? <VendorServiceForm onDismissed={_onSurfaceDismissed} vendorId={props.vendorId} serviceId={activeService.id} /> : null}
    </Stack>;
};

export default VendorDetailsServices;