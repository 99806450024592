import Request from './../../request';

// import props
import { IVendorApprovalHistoryResourceShortProps } from '../../../props/data/vendors/approvalHistory';

const VendorApprovalHistoryService = {
  retrieve: async (vendorId: string): Promise<IVendorApprovalHistoryResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/data/vendors/${vendorId}/approvals`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default VendorApprovalHistoryService;
