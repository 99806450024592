import React, { useEffect } from 'react';

// assets

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';

// props
import { IRoleResourceShortProps } from '../../../../props/users/role';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import ValidationService from '../../../../services/validation';
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';
import { IUserResourceShortProps } from '../../../../props/users/user';

type UpdateProfileFormProps = {
    onDismissed(refresh?: boolean, user?: IUserResourceShortProps): void;
}

type FormDataProps = {
    name: string;
    address?: string;
    phoneNumber?: string;
    email: string;
}

const UpdateProfileForm: React.FC<UpdateProfileFormProps> = (props: UpdateProfileFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>(user.data || {name: "", address: "", phoneNumber: "", email: ""});
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (user.data) {
            setData(user.data);
        }
        
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        return false;
    }

    const _onSubmit = async () => {
        try {
            const {email, name, address, phoneNumber} = data;

            setSubmitting(true);
            // create form data
            let fd = new FormData();
            fd.append("email", email);
            fd.append("name", name);
            fd.append("address", address || "");
            fd.append("phoneNumber", phoneNumber || "");

            if (user.data) {
                const result = await UserService.update(user.data.id, fd);

                banner.add({
                    key: 'update_profile_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `Profile updated successfully`
                });

                props.onDismissed(true, result);
            } else {
                throw new Error("User not found");
            }
        } catch (e) {
            banner.add({
                key: 'create_administration_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Update Profile"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating profile ..." } /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Email Address"}
                            required={true}
                            value={data.email}
                            onChange={(evt, value) => {
                                data.email = value || "";

                                const validation = ValidationService.combination(value, ['required', 'email', 'limit'], { maxChars: 100 });
                                error.email = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.email}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'} />
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Address"}
                            required={false}
                            multiline
                            rows={5}
                            value={data.address}
                            errorMessage={error.address}
                            onChange={(evt, value) => {
                                data.address = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.address = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Phone Number"}
                            required={false}
                            value={data.phoneNumber}
                            errorMessage={error.phoneNumber}
                            onChange={(evt, value) => {
                                if (value === "" || !isNaN(Number(value))) {
                                    data.phoneNumber = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.phoneNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default UpdateProfileForm;
