import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import DeliveredGoodsType, { IDeliveredGoodsTypeResourceShortProps } from '../../../../../manifests/deliveredGoodsType';

// assets

// services
import DataCountryService from '../../../../../services/data/geographics/country';
import DataCityService from '../../../../../services/data/geographics/city';
import DataProvinceService from '../../../../../services/data/geographics/province';

// props
import { IDeliveryTypeResourceShortProps } from '../../../../../props/orders/deliveryType';
import { IDataCountryResourceShortProps } from '../../../../../props/data/geographics/countries';
import { IDataProvinceResourceShortProps } from '../../../../../props/data/geographics/provinces';
import { IDataCityResourceShortProps } from '../../../../../props/data/geographics/cities';

// components
import { ActionButton, Dropdown, SpinnerSize, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Tag, { TTagVariant } from '../../../tag';

type SelectedAreaProps = {
    countries?: IDataCountryResourceShortProps[];
    provinces?: IDataProvinceResourceShortProps[];
    cities?: IDataCityResourceShortProps[];
}

type SelectAreaProps = {
    deliveryType?: IDeliveryTypeResourceShortProps;
    selected?: SelectedAreaProps;
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    allowMultiple?: boolean;
    onChange(selected: SelectedAreaProps): void;
    onRemove?(selected: SelectedAreaProps): void;
}

const SelectArea: React.FC<SelectAreaProps> = (props: SelectAreaProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<{
        countries: IDataCountryResourceShortProps[];
        provinces: IDataProvinceResourceShortProps[];
        cities: IDataCityResourceShortProps[];
    }>({countries: [], cities: [], provinces: []});
    const [selected, setSelected] = React.useState<SelectedAreaProps>({});

    useEffect(() => {
        setSelected(props.selected || {});
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [_countries, _provinces, _cities] = await Promise.all([await DataCountryService.retrieve(), await DataProvinceService.retrieve(), await DataCityService.retrieve()]);
        setOptions({countries: _countries, provinces: _provinces, cities: _cities});
        setLoaded(true);
    }

    const _onSearch = (keyword: string) => {
        keyword = (keyword || "").toLowerCase();
        const _countries = options.countries.filter((c) => c.name.toLowerCase().indexOf(keyword) > -1);
        const _provinces = options.provinces.filter((p) => p.name.toLowerCase().indexOf(keyword) > -1 || p.country.name.toLowerCase().indexOf(keyword) > -1);
        const _cities = options.cities.filter((c) => c.name.toLowerCase().indexOf(keyword) > -1 || c.province.name.toLowerCase().indexOf(keyword) > -1 || c.province.country.name.toLowerCase().indexOf(keyword) > -1);
    
        return [
            ..._countries.map((c) => {
                return {key: c.key, name: c.name, country: c, type: 'Country', variant: 'active'}
            }),
            ..._provinces.map((p) => {
                return {key: p.id, name: p.name, province: p, type: 'Province', variant: 'primary'}
            }),
            ..._cities.map((c) => {
                return {key: c.id, name: c.name, city: c, type: 'City', variant: 'success'}
            })
        ];
    }

    const renderTag = (tag: any, className?: string, allowDelete?: boolean) => {
        const country = tag.country ? tag.country as IDataCountryResourceShortProps : undefined;
        const province = tag.province ? tag.province as IDataProvinceResourceShortProps : undefined;
        const city = tag.city ? tag.city as IDataCityResourceShortProps : undefined;

        return <Stack className={className} styles={{ root: { padding: 5, width: '100%' }}} horizontalAlign={'space-between'} horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
            <Stack horizontalAlign={'baseline'} styles={{root: {position: 'relative', top: 1}}}>
                <Label size={'small'}>{tag.name}</Label>
                {province ? <Text size={'small'}>{province.country.name}</Text> : null}
                {city ? <Text size={'small'}>{city.province.name}, {city.province.country.name}</Text> : null}
            </Stack>
            <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <Tag size={'small'} text={tag.type} variant={tag.variant as TTagVariant} />
                </Stack.Item>
                {allowDelete ? <>
                    <ActionButton iconProps={{iconName: "Delete"}} onClick={() => {
                        const _selected = selected;
                        if (tag.country) {
                            _selected.countries = (_selected.countries || []).filter((s) => s.key !== tag.country.key);
                        } else if (tag.province) {
                            _selected.provinces = (_selected.provinces || []).filter((s) => s.id !== tag.province.id);
                        } else if (tag.city) {
                            _selected.cities = (_selected.cities || []).filter((s) => s.id !== tag.city.id);
                        }

                        props.onChange(_selected);
                        return null;
                    }} />
                </> : null}
            </Stack>
        </Stack>;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Area"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack grow={1} tokens={{childrenGap: 8}}>
                    {!props.disabled && (props.allowMultiple || ((selected.countries || []).length < 1 && (selected.provinces || []).length < 1 && (selected.cities || []).length < 1)) ? <>
                        <TagPicker
                            disabled={!loaded}
                            selectedItems={[]}
                            removeButtonAriaLabel='Remove'
                            itemLimit={1}
                            onItemSelected={(item: any) => {
                                const _selected = selected;
                                if (item.country) {
                                    _selected.countries = [...(_selected.countries || []), item.country].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                                } else if (item.province) {
                                    _selected.provinces = [...(_selected.provinces || []), item.province].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                                } else if (item.city) {
                                    _selected.cities = [...(_selected.cities || []), item.city].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                                }

                                props.onChange(_selected);
                                return null;
                            }}
                            onRenderSuggestionsItem={(tag: any) => renderTag(tag)}
                            onResolveSuggestions={_onSearch}
                            onEmptyResolveSuggestions={() => _onSearch('')}
                        />
                    </> : null}
                    {(selected.countries || []).length > 0 || (selected.provinces || []).length > 0 || (selected.cities || []).length > 0  ? <Stack tokens={{childrenGap: 8}}>
                        {
                            [
                                ...(selected.countries || []).map((c) => {
                                    return {key: c.key, name: c.name, country: c, type: 'Country', variant: 'active'}
                                }),
                                ...(selected.provinces || []).map((p) => {
                                    return {key: p.id, name: p.name, province: p, type: 'Province', variant: 'primary'}
                                }),
                                ...(selected.cities || []).map((c) => {
                                    return {key: c.id, name: c.name, city: c, type: 'City', variant: 'success'}
                                })
                            ].map((tag) => renderTag(tag, 'card', !props.disabled && loaded))
                        }
                    </Stack> : null}
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectArea;
