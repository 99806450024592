import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import EmployeePayslipService from '../../../../../services/employees/payslip';
import { IEmployeePayslipResourceProps } from '../../../../../props/employees/payslips/payslip';

type DeleteEmployeePayslipFormProps = {
    payslipId: string;
    onDismissed(refresh?: boolean): void;
}

const DeleteEmployeePayslipForm: React.FC<DeleteEmployeePayslipFormProps> = (props: DeleteEmployeePayslipFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [payslip, setPayslip] = React.useState<IEmployeePayslipResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _payslip = await EmployeePayslipService.read(props.payslipId);
        setPayslip(_payslip);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await EmployeePayslipService.delete(props.payslipId);

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'delete_payslip_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to delete payslip. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Payslip"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting payslip ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && payslip ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure to delete this payslip?</Text>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default DeleteEmployeePayslipForm;
