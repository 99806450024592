import React from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';

// components
import { Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import { NavLink } from 'react-router-dom';
import Details from '../../../../../typography/details';

interface IOutcomeInvoiceDetailsPropertiesProps {
    outcome: IOutcomeResourceProps;
}

const OutcomeInvoiceDetailsProperties: React.FC<IOutcomeInvoiceDetailsPropertiesProps> = (props: IOutcomeInvoiceDetailsPropertiesProps) => {
    const { outcome } = props;
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const renderDueDateTag = () => {
        let tagVariant: TTagVariant = 'active';
        const diff = moment(outcome.dueDate).diff(moment(), 'days');

        if (diff < 1) {
            tagVariant = 'error';
        } else if (diff < 2) {
            tagVariant = 'warning';
        }

        return <Stack horizontal>
            <Tag size={'small'} text={GeneralService.getFriendlyDateFormat(outcome.dueDate)} variant={tagVariant} />
        </Stack>
    }

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Details title={'Subsidiary'} text={outcome.subsidiary?.name || "-"} style={{ width: '25%' }} />
            <Details title={'Category'} text={outcome.category?.name || "-"} style={{ width: '25%' }} />
            <Stack.Item styles={{ root: { width: '25%' } }}><Text> </Text></Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}><Text> </Text></Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Details title={'Invoice number'} text={`#${outcome.invoiceNumber || "-"}`} style={{ width: '25%' }} />
            <Details title={'Invoice date'} text={GeneralService.formatDate(moment(outcome.invoiceDate).toDate())} style={{ width: '25%' }} />
            {Number(outcome.unpaid) > 0 ? <Details title={'Due date'} style={{ width: '25%' }}>{renderDueDateTag()}</Details> : null}
            {Number(outcome.unpaid) <= 0 ? <Stack.Item styles={{ root: { width: '25%' } }}><Text> </Text></Stack.Item> : null}
            <Stack.Item styles={{ root: { width: '25%' } }}><Text> </Text></Stack.Item>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Details title={'Name'} text={outcome.name} />
        <Details title={'Notes'} text={outcome.notes || "-"} />
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Details title={'Price (as per invoice)'} style={{ width: '25%' }}>
                <Stack tokens={{ childrenGap: 2 }}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.originalAmount))}</Label>
                    <Stack horizontal tokens={{ childrenGap: 3 }}>
                        {outcome.includePPn ? <Text className={'color-green'} style={{ fontStyle: 'italic' }} size={'small'}>(include PPn)</Text> : null}
                        {!outcome.includePPn ? <Text className={'color-red'} style={{ fontStyle: 'italic' }} size={'small'}>(exclude PPn)</Text> : null}
                        {outcome.includePPh ? <Text className={'color-green'} style={{ fontStyle: 'italic' }} size={'small'}>(include PPh)</Text> : null}
                        {!outcome.includePPh ? <Text className={'color-red'} style={{ fontStyle: 'italic' }} size={'small'}>(exclude PPh)</Text> : null}
                    </Stack>
                </Stack>
            </Details>
            <Details title={'PPn'} style={{ width: '25%' }}>
                {Number(outcome.ppn) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.ppn))}</Label>
                    {outcome.ppnPercentage !== 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.ppnPercentage}%)</Text> : null}
                    {outcome.ppnPercentage === 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.ppnPercentage})</Text> : null}
                </Stack> : <Text size={'small'} style={{ fontStyle: 'italic' }}>No PPn</Text>}
            </Details>
            <Details title={'PPh'} style={{ width: '25%' }}>
                {Number(outcome.pph) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.pph))}</Label>
                    {outcome.pphPercentage !== 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.pphPercentage}%)</Text> : null}
                    {outcome.pphPercentage === 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.pphPercentage})</Text> : null}
                </Stack> : <Text size={'small'} style={{ fontStyle: 'italic' }}>No PPh</Text>}
            </Details>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Details title={'Total amount'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(outcome.totalAmount))}`} style={{ width: '25%' }} />
            <Details title={'Total amount to be paid'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(outcome.amount))}`} style={{ width: '25%' }} />
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Details title={'Paid'} text={`Rp. ${GeneralService.getNumberWithSeparator(outcome.paid)}`} style={{ width: '25%' }} className={{ text: 'color-green' }} />
            <Details title={'Pending payment'} text={`Rp. ${GeneralService.getNumberWithSeparator(outcome.pendingPayment)}`} style={{ width: '25%' }} className={{ text: 'color-yellow' }} />
            <Details title={'Unpaid'} text={`Rp. ${GeneralService.getNumberWithSeparator(outcome.unpaid)}`} style={{ width: '25%' }} className={{ text: 'color-red' }} />
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        {outcome.vendor || outcome.train || outcome.ship || outcome.shipSchedule || outcome.trainSchedule || outcome.truck ? <>
            <Stack className='divider'></Stack>
            {outcome.vendor ? <Stack.Item>
                <Text size={'small'}>Vendor</Text>
                <NavLink to={"/operationals/vendors/" + outcome.vendor.id}>
                    <Label size={'small'}>{outcome.vendor.name}</Label>
                </NavLink>
            </Stack.Item> : null}
            {outcome.ship ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                {outcome.ship ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Ship</Text>
                    <Label size={'small'}>{outcome.ship.name}</Label>
                </Stack> : null}
                {outcome.shipSchedule ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Voy</Text>
                    <Label size={'small'}>{outcome.shipSchedule.voy}</Label>
                </Stack> : null}
            </Stack> : null}
            {outcome.shipSchedule ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Port of Loading</Text>
                    <Label size={'small'}>{outcome.shipSchedule.originAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.shipSchedule.actualDeparture ? `(Actual) ${moment(outcome.shipSchedule.actualDeparture).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.shipSchedule.estimatedDeparture).format("DD/MM/YYYY")}`}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Port of Discharge</Text>
                    <Label size={'small'}>{outcome.shipSchedule.destinationAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.shipSchedule.actualArrival ? `(Actual) ${moment(outcome.shipSchedule.actualArrival).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.shipSchedule.estimatedArrival).format("DD/MM/YYYY")}`}</Text>
                </Stack>
            </Stack> : null}
            {outcome.train ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                {outcome.train ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Train</Text>
                    <Label size={'small'}>{outcome.train.name}</Label>
                </Stack> : null}
                {outcome.trainSchedule ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Voy</Text>
                    <Label size={'small'}>{outcome.trainSchedule.voy}</Label>
                </Stack> : null}
            </Stack> : null}
            {outcome.trainSchedule ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Origin Station</Text>
                    <Label size={'small'}>{outcome.trainSchedule.originAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.trainSchedule.actualDeparture ? `(Actual) ${moment(outcome.trainSchedule.actualDeparture).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.trainSchedule.estimatedDeparture).format("DD/MM/YYYY")}`}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Destination Station</Text>
                    <Label size={'small'}>{outcome.trainSchedule.destinationAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.trainSchedule.actualArrival ? `(Actual) ${moment(outcome.trainSchedule.actualArrival).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.trainSchedule.estimatedArrival).format("DD/MM/YYYY")}`}</Text>
                </Stack>
            </Stack> : null}
            {outcome.truck ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Truck</Text>
                    <Label size={'small'}>{outcome.truck.registrationNumber}</Label>
                </Stack>
            </Stack> : null}
        </> : null}
        {outcome.targetSubsidiary ? <>
            <Stack className='divider'></Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Details title={'Transfer from'} text={outcome.subsidiary.name} style={{ width: '25%' }} />
                <Details title={'Transfer to'} text={outcome.targetSubsidiary.name} style={{ width: '25%' }} />
                <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
                <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
            </Stack>
        </> : null}
        {outcome.targetEmployee ? <>
            <Stack className='divider'></Stack>
            <Details title={'Transfer to'} text={outcome.targetEmployee.name} />
        </> : null}
    </Stack>
};

export default OutcomeInvoiceDetailsProperties;
