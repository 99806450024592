import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { Dropdown, DropdownMenuItemType, IDropdownOption, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { IDataServiceResourceShortProps } from '../../../../props/data/services';
import DataServiceService from '../../../../services/data/service';

type SelectServiceProps = {
    selected?: IDataServiceResourceShortProps;
    options?: IDataServiceResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IDataServiceResourceShortProps): void;
}

const SelectService: React.FC<SelectServiceProps> = (props: SelectServiceProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IDataServiceResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IDataServiceResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _services = await DataServiceService.retrieve();
        setOptions(_services);
        setLoaded(true);
    }

    const groupOptions = (): {key?: string; options: IDataServiceResourceShortProps[]}[] => {
        const grouped: {key?: string; options: IDataServiceResourceShortProps[]}[] = [];

        options.forEach((opt) => {
            const idx = grouped.findIndex((g) => g.key === opt.group);
            if (idx > -1) {
                grouped[idx].options.push(opt);
            } else {
                grouped.push({key: opt.group, options: [opt]});
            }
        });

        return grouped.sort((a, b) => ((a.key || "") > (b.key || "")) ? 1 : (((b.key || "") > (a.key || "")) ? -1 : 0));
    }

    const getDropdownOptions = (): IDropdownOption[] => {
        const doptions: IDropdownOption[] = [];
        const goptions: {key?: string; options: IDataServiceResourceShortProps[]}[] = groupOptions();

        goptions.forEach((group, idx) => {
            if (group.key) {
                doptions.push({
                    key: 'header_' + group.key, 
                    text: group.key, 
                    itemType: DropdownMenuItemType.Header
                });
            }
            group.options.forEach((option) => {
                doptions.push({
                    key: option.id,
                    text: option.name
                });
            });

            if (idx < (goptions.length - 1)) {
                doptions.push({
                    key: 'divider_' + group.key, 
                    text: "",
                    itemType: DropdownMenuItemType.Divider
                });
            }
        });

        return doptions;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} styles={{root: {width: '100%'}}}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack styles={{root: {width: '100%'}}}>
                    <Dropdown disabled={props.disabled || !loaded}
                        selectedKey={selected?.id}
                        options={getDropdownOptions()}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectService;
