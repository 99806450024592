import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../../services/general/error';

// props

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../../typography/text';
import { IOutcomeResourceShortProps } from '../../../../../../../props/finance/outcomes';
import { IPaginationResourceShort } from '../../../../../../../props/general';
import Pagination from '../../../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../../../config';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';
import { NavLink } from 'react-router-dom';
import OrderService from '../../../../../../../services/orders/order';
import Tag, { TTagVariant } from '../../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../../services/general';
import Label from '../../../../../../typography/label';
import PermissionsService from '../../../../../../../services/permissions';
import SelectOrder from '../../../../../../uiframeworks/forms/selectOrder';
import { Select } from 'antd';
import { IIncomePaymentResourceShortProps } from '../../../../../../../props/finance/incomes/payments/payment';
import moment from 'moment';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import IncomePaymentService from '../../../../../../../services/finance/incomes/payments/payment';
import { IIncomePaymentInvoiceResourceShortProps } from '../../../../../../../props/finance/incomes/payments/invoice';
import SelectInvoice from '../../../../../../uiframeworks/forms/selectInvoice';
import SelectIncomeInvoice from '../../../../../../uiframeworks/forms/invoices/income';

interface IncomePaymentInvoicesListProps {
    payment: IIncomePaymentResourceShortProps;
    paymentInvoices: IIncomePaymentInvoiceResourceShortProps[];
    columns?: TIncomePaymentInvoicesListCoumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    variant?: 'card' | 'plain';
}

export type TIncomePaymentInvoicesListCoumn = 'paymentDate' | 'method' | 'transferto' | 'status' | 'amount' | 'history' | 'invoice';

const IncomePaymentInvoicesList: React.FC<IncomePaymentInvoicesListProps> = (props: IncomePaymentInvoicesListProps) => {
    const shownColumns: TIncomePaymentInvoicesListCoumn[] = props.columns || [
        'paymentDate',
        'method',
        'transferto',
        'amount',
        'status',
        'history',
        'invoice'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(true);
    const [activePaymentInvoice, setActivePaymentInvoice] = React.useState<IIncomePaymentInvoiceResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "paymentDate",
            name: "Date",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActivePaymentInvoice(item);
                        setActiveSurface('payment.details');
                    }}><Text size={'small'}>{moment(props.payment.paymentDate).format("DD/MM/YYYY")}</Text></Link>
                </Stack>
            }
        },
        {
            key: "method",
            name: "Method",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === props.payment.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{method?.text || 'Cash'}</Text>
                </Stack>
            }
        },
        {
            key: "transferto",
            name: "Transfer to",
            minWidth: 175,
            maxWidth: 175,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{childrenGap: 2}}>
                    <Label size={'xsmall'}>{props.payment.transferTo.accountNumber} a/n {props.payment.transferTo.accountName}</Label>
                    <Text size={'small'}>{props.payment.transferTo.bank.name}</Text>
                </Stack>
            }
        },
        {
            key: "history",
            name: "History",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'} style={{fontStyle: 'italic'}}>Recorded by: {props.payment.recordedBy.name} on {moment(props.payment.recordedAt).format("DD/MM/YYYY")}</Text>
                    {props.payment.verifiedBy && props.payment.verifiedAt ? <Text className={'color-green'} size={'small'} style={{fontStyle: 'italic'}}>Verified by: {props.payment.verifiedBy.name} on {moment(props.payment.verifiedAt).format("DD/MM/YYYY")}</Text> : null}
                    {props.payment.rejectedBy && props.payment.rejectedAt ? <Text className={'color-red'} size={'small'} style={{fontStyle: 'italic'}}>Rejected by: {props.payment.rejectedBy.name} on {moment(props.payment.rejectedAt).format("DD/MM/YYYY")}</Text> : null}
                </Stack>
            }
        },
        {
            key: "invoice",
            name: "Invoice Details",
            minWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <SelectIncomeInvoice selected={item.invoice}
                        hideLabel
                        disabled
                        onChange={() => {}} />
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Paid Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 85,
            maxWidth: 85,
            isMultiline: true,
            onRender: (item: IIncomePaymentInvoiceResourceShortProps) => {
                let tagVariant: TTagVariant = 'success';
                let tagText: string = 'Verified';

                if (props.payment.status === 'Pending') {
                    tagVariant = 'warning';
                    tagText = 'Pending'
                } else if (props.payment.status === 'Rejected') {
                    tagVariant = 'error';
                }

                return <Stack horizontal>
                    <Tag size={'small'} variant={tagVariant} text={tagText} />
                </Stack>
            }
        }
    ];

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        return { items, farItems };
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && props.paymentInvoices.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={props.paymentInvoices}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TIncomePaymentInvoicesListCoumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && props.paymentInvoices.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                    <Text>Payment invoices not found</Text>
                </Stack> : null}
            </Stack>
        </Stack>
    </Stack>;
};

export default IncomePaymentInvoicesList;
