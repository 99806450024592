import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props
import { IEmployeeContractJobdeskResourceShortProps } from '../../../../../../props/employees/contracts/jobdesk';

// components
import { ActionButton, DefaultButton, DetailsList, IconButton, Panel, PanelType, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import DetailsStack, { IStackSectionProps } from '../../../../../uiframeworks/detailsStack';
import { IEmployeePayslipCommissionResourceShortProps } from '../../../../../../props/employees/payslips/commision';
import OrderService from '../../../../../../services/orders/order';
import Label from '../../../../../typography/label';
import SelectOrder from '../../../../../uiframeworks/forms/selectOrder';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import LoadingComponent from '../../../../../feedbacks/loading';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import { IUserResourceShortProps } from '../../../../../../props/users/user';

type EmployeePayslipCommissionFormProps = {
    employee?: IUserResourceShortProps;
    disabled?: boolean;
    loading?: boolean;
    commissions: IEmployeePayslipCommissionResourceShortProps[];
    onRecalculate(): void;
    onChange(commissions: IEmployeePayslipCommissionResourceShortProps[]): void;
}

const EmployeePayslipCommissionForm: React.FC<EmployeePayslipCommissionFormProps> = (props: EmployeePayslipCommissionFormProps) => {
    const [commissions, setCommissions] = React.useState<IEmployeePayslipCommissionResourceShortProps[]>(props.commissions);
    const [disabled, setDisabled] = React.useState<boolean>(props.disabled || false);
    const [surfaceMode, setSurfaceMode] = React.useState<'add' | 'update' | undefined>();
    const [activeCommission, setActiveCommission] = React.useState<Partial<IEmployeePayslipCommissionResourceShortProps> | undefined>();

    useEffect(() => {
        setCommissions(props.commissions);
    }, [props.commissions]);

    useEffect(() => {
        setDisabled(props.disabled || false);
    }, [props.disabled]);

    const renderLeftStack = (): IStackSectionProps[] => {
        return [
            {
                key: 'commissions',
                isCard: true,
                header: {
                    title: 'Commissions', buttons: [
                        {
                            text: 'Re-calculate commission',
                            iconProps: { iconName: "Refresh" },
                            disabled: disabled || props.loading,
                            onClick: () => {
                                props.onRecalculate();
                            }
                        },
                        {
                            text: 'Add commission',
                            iconProps: { iconName: "Add" },
                            disabled: disabled || props.loading,
                            onClick: () => {
                                setActiveCommission({
                                    id: GeneralService.guid(),
                                    amount: "0"
                                });
                                setSurfaceMode('add');
                            }
                        }
                    ]
                },
                content: <Stack tokens={{ childrenGap: 10 }}>
                    {props.loading ? <LoadingComponent spinnerPosition={'start'} label={'Retrieving commissions ...'} labelPosition={'right'} /> : null}
                    {!props.loading && commissions.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No commissions on this payslip</Text> : null}
                    {!props.loading && commissions.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
                        <DetailsList items={commissions}
                            isHeaderVisible={true}
                            compact={true}
                            selectionMode={SelectionMode.none}
                            columns={[
                                {
                                    key: 'idx',
                                    name: 'No.',
                                    minWidth: 20,
                                    maxWidth: 20,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps, idx?: number) => {
                                        return <Stack styles={{ root: { padding: '10px 0px' } }} horizontalAlign={'center'}>
                                            <Text size={'small'}>{(idx || 0) + 1}.</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'order',
                                    name: 'Order Details',
                                    minWidth: 100,
                                    isMultiline: true,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack>
                                            <SelectOrder selected={item.order}
                                                hideLabel
                                                target={'_blank'}
                                                disabled={true}
                                                onChange={() => { }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'loadingDate',
                                    name: 'Start Date',
                                    minWidth: 85,
                                    maxWidth: 85,
                                    isMultiline: true,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                                            {item.order.actualDeparture ? <Text size={'small'}>{moment(item.order.actualDeparture).format("DD/MM/YYYY")}</Text> : null}
                                            {!item.order.actualDeparture ? <Text size={'small'}>-</Text> : null}
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'profit',
                                    name: 'Profit',
                                    minWidth: 125,
                                    maxWidth: 125,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.order.nettProfit))}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'description',
                                    name: 'Description / Warnings',
                                    minWidth: 250,
                                    maxWidth: 250,
                                    isMultiline: true,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '10px 0px' } }} tokens={{childrenGap: 10}}>
                                            {(item.description || "").trim() !== "" ? <Text size={'small'}>{item.description || ""}</Text> : null}
                                            {(item.description || "").trim() !== "" && (item.warnings || []).length > 0 ? <Stack className={'divider'}> </Stack> : null}
                                            {(item.warnings || []).length > 0 ? <Stack tokens={{ childrenGap: 5 }}>
                                                {(item.warnings || []).map((warning) => {
                                                    return <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                        <Stack styles={{ root: { width: 15, marginTop: 3 } }}><FontAwesomeIcon icon={faTriangleExclamation} fontSize={12} className={'color-yellow'} /></Stack>
                                                        <Text size={'small'} className={'color-yellow'}>{warning}</Text>
                                                    </Stack>
                                                })}
                                            </Stack> : null}
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'commission',
                                    name: 'Commission',
                                    minWidth: 125,
                                    maxWidth: 125,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '10px 0px', marginRight: 10 } }}>
                                            <Text size={'small'} className={'color-green'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'actions',
                                    name: '',
                                    minWidth: 65,
                                    maxWidth: 65,
                                    onRender: (item: IEmployeePayslipCommissionResourceShortProps) => {
                                        return <Stack styles={{ root: { padding: '8px 0px' } }}>
                                            <Stack horizontal tokens={{ childrenGap: 2 }} horizontalAlign={'end'} className={"detailsListActionRow"}>
                                                <ActionButton disabled={disabled} className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                                    setSurfaceMode('update');
                                                    setActiveCommission({ ...item });
                                                }} />
                                                <ActionButton disabled={disabled} className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Delete' }} onClick={() => {
                                                    if (window.confirm('Are you sure to delete commission "' + item.amount + '"?')) {
                                                        const _commissions = commissions.filter((b) => b.id !== item.id);
                                                        props.onChange(_commissions);
                                                    }
                                                }} />
                                            </Stack>
                                        </Stack>
                                    }
                                }
                            ]} />
                    </Stack> : null}
                </Stack>
            }
        ]
    }

    return <>
        <DetailsStack left={renderLeftStack()} />
        <Panel headerText={surfaceMode === 'add' ? "Add Commission" : "Update Commission"}
            isOpen={activeCommission !== undefined}
            type={PanelType.medium}
            onDismiss={() => {
                setSurfaceMode(undefined);
                setActiveCommission(undefined);
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={surfaceMode === 'add' ? "Add" : "Update"}
                        disabled={!activeCommission?.order || (activeCommission?.amount || "").trim() === ""}
                        onClick={() => {
                            if (activeCommission) {
                                const _commissions = commissions;
                                const idx = _commissions.findIndex((b) => b.id === activeCommission.id);

                                if (idx > -1) {
                                    _commissions[idx] = activeCommission as IEmployeePayslipCommissionResourceShortProps;
                                } else {
                                    _commissions.push(activeCommission as IEmployeePayslipCommissionResourceShortProps);
                                }

                                props.onChange(_commissions);
                                setActiveCommission(undefined);
                            }
                        }} />
                    <DefaultButton text={"Cancel"} onClick={() => {
                        setSurfaceMode(undefined);
                        setActiveCommission(undefined);
                    }} />
                </Stack>
            }}>
            {activeCommission ? <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <SelectOrder label={'Order details'}
                        selected={activeCommission.order}
                        required
                        qs={[`sales=${props.employee?.id}`]}
                        disabled={surfaceMode === 'update'}
                        onChange={(order) => {
                            if (surfaceMode === 'add') {
                                let _activeCommission = activeCommission;
                                const warnings = _activeCommission.warnings || [];

                                _activeCommission.order = order;

                                if (!order?.actualDeparture) {
                                    warnings.push("No actual departure date recorded");
                                } else if (order?.actualDeparture) {
                                    warnings.push("No contract found during this period");
                                }

                                if (order?.status.key === 'cancelled') {
                                    warnings.push("Order is cancelled");
                                } else if (order?.status.key !== 'completed') {
                                    warnings.push("Order is not yet completed");
                                }

                                _activeCommission.warnings = warnings;
                                setActiveCommission({..._activeCommission});
                            }
                        }} />
                    <TextField label={'Start / loading / departure date'}
                        disabled
                        value={activeCommission.order?.actualDeparture ? moment(activeCommission.order?.actualDeparture).format("DD/MM/YYYY") : "-"} />
                    <TextField label={"Description"}
                        multiline
                        rows={5}
                        autoAdjustHeight
                        resizable={false}
                        value={activeCommission.description || ""}
                        onChange={(evt, value) => {
                            if (activeCommission && (value || "").length <= 1000) {
                                setActiveCommission({ ...activeCommission, description: value || "" });
                            }
                        }} />
                    {(activeCommission.warnings || []).length > 0 ? <Stack tokens={{ childrenGap: 5 }}>
                        <Label size={'xsmall'}>Warnings</Label>
                        {(activeCommission.warnings || []).map((warning) => {
                            return <Stack horizontal tokens={{ childrenGap: 5 }}>
                                <Stack styles={{ root: { width: 15, marginTop: 3 } }}><FontAwesomeIcon icon={faTriangleExclamation} fontSize={12} className={'color-yellow'} /></Stack>
                                <Text size={'small'} className={'color-yellow'}>{warning}</Text>
                            </Stack>
                        })}
                    </Stack> : null}
                    <TextField label={'Commission amount'}
                        prefix={"Rp"}
                        value={activeCommission.amount}
                        required
                        onChange={(evt, value) => {
                            if (activeCommission && (value || "").trim() === "" || !isNaN(Number(value))) {
                                setActiveCommission({ ...activeCommission, amount: value || "" });
                            }
                        }} />
                </Stack>
            </> : null}
        </Panel>
    </>
};

export default EmployeePayslipCommissionForm;
