import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { TTagVariant } from '../../../../uiframeworks/tag';
import { IUserResourceShortProps } from '../../../../../props/users/user';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import UserService from '../../../../../services/users/user';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import AdministratorForm from '../form';
import ResetAdministratorPasswordForm from '../resetPassword';
import DeleteUserForm from '../../../general/delete';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import OrdersList from '../../../../orders/general/list';
import AdministratorMonthlyRecap from './recap';
import IncomeInvoicesList from '../../../../finances/incomes/invoices/general/list';
import SalesCommissionList from '../../../salesComissions/general/list';
import PermissionsService from '../../../../../services/permissions';
import SalesCommissionForm from '../../../salesComissions/general/form';
import EmployedSalaryForm from '../../../employments/salaries/general/form';
import EmployeePayslipsList from '../../payslips/general/list';
import NoAccess from '../../../../uiframeworks/noAccess';
import AdministratorDetailsPerformanceRecap from './performanceRecap';

interface IAdministratorDetailsProps {
    userId: string;
}

const AdministratorDetails: React.FC<IAdministratorDetailsProps> = (props: IAdministratorDetailsProps) => {
    const { banner, topbar, user, route } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [administrator, setAdministrator] = React.useState<IUserResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = props.userId === user.data?.id || PermissionsService.hasPermission(['administrators.read.all'], user.permissions);

    useEffect(() => {
        _onGetAdministrator();
    }, []);

    const _onGetAdministrator = async () => {
        try {
            setLoaded(false);
            const _administrator = await UserService.get(props.userId);
            setAdministrator(_administrator);

            topbar.show(_administrator.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "users", text: 'Users' },
                { key: "administrators", text: 'Administrators', href: "/users/administrators" },
                { key: props.userId, text: _administrator.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_administrator_error',
                text: 'Failed to get administrator details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update details',
            onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
        });

        items.push({
            key: 'reset_password',
            text: 'Reset Password',
            onRender: () => <ActionButton text={'Reset Password'} iconProps={{ iconName: "Permissions" }} onClick={() => setActiveSurface('resetPassword')} styles={{ root: { marginLeft: 20 } }} />
        });

        farItems.push({
            key: 'delete',
            text: 'Delete',
            onRender: () => <ActionButton text={'Delete'} iconProps={{ iconName: "Delete" }} styles={{ root: { marginLeft: 20 } }} onClick={() => setActiveSurface('delete')} />
        });

        return { items, farItems };
    }

    const getUserPropertiesSection = (_administrator: IUserResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'user_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_administrator.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Email Address</Label>
                        <Text>{_administrator.email}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Phone Number</Label>
                        <Text>{_administrator.phoneNumber || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Address</Label>
                        <Text>{_administrator.address || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getEmploymentDetails = (_administrator: IUserResourceShortProps): IStackSectionProps => {
        const salesCommissionButtons = [];
        const employmentDetailsButtons = [];

        if (PermissionsService.hasPermission(['superuser', 'hr', 'finance'], user.roles)) {
            salesCommissionButtons.push({
                text: 'Create Commission',
                iconProps: { iconName: "Add" },
                onClick: () => {
                    setActiveSurface('create.commission');
                }
            });

            employmentDetailsButtons.push({
                text: 'See Details',
                onClick: () => {
                    //route.route?.history.push("/users/employments/" + _administrator.id + "/dashboard");
                }
            });
        }

        /*return {
            key: 'sales_commissions',
            subsections: [
                {
                    key: 'sales_comission',
                    header: {
                        title: "Sales Commissions",
                        buttons: salesCommissionButtons
                    },
                    isCard: true,
                    content: <SalesCommissionList isHeaderVisible={false}
                        qs={[`sales_id=${_administrator.id}`]}
                        hideCreateButton
                        hideSearch
                        columns={['percentage', 'actions', 'amount']} />
                },
                {
                    key: 'employment_details',
                    header: {
                        title: "Employment Details",
                        buttons: employmentDetailsButtons
                    },
                    isCard: true,
                    content: <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Text>Join Date</Text>
                            <Label size={'small'}>{moment(_administrator.createdAt).format("DD/MM/YYYY")}</Label>
                        </Stack>
                        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Text>Current Contract</Text>
                            <Label size={'small'}>{moment(_administrator.createdAt).format("DD/MM/YYYY")}</Label>
                        </Stack>
                        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Text>Current Salary</Text>
                            <Label size={'small'}>{moment(_administrator.createdAt).format("DD/MM/YYYY")}</Label>
                        </Stack>
                    </Stack>
                }
            ]
        }*/

        return {key: 'temp', subsections: []};
    }

    const getMonthlyRecapSession = (_administrator: IUserResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "My Performance"
            },
            key: 'myPerformance',
            isCard: true,
            width: '450px',
            content: <Stack tokens={{ childrenGap: 20 }}>
                <AdministratorDetailsPerformanceRecap userId={_administrator.id} />
            </Stack>
        }
    }

    const getRelatedItemsSection = (_administrator: IUserResourceShortProps): IStackSectionProps => {
        const qs: string[] = [];
        qs.push(`sales=${props.userId}`);

        const tabs: ISectionTabProps[] = [];
        const requiredPermissions = [];
        if (user.data?.id !== props.userId) {
            requiredPermissions.push('orders.read.all');
            requiredPermissions.push('orders.read.me');
        } else {
            requiredPermissions.push('orders.read.all');
        }

        if (PermissionsService.hasPermission(requiredPermissions, user.permissions)) {
            tabs.push({
                key: 'related_orders',
                title: 'Related Orders',
                content: <Stack>
                    <OrdersList qs={qs} filters={['delivery_type', 'freight_category', 'goods_type', 'order_status']} hideCreateOrder />
                </Stack>
            });

            tabs.push({
                key: 'invoices_due',
                title: 'Invoices Due',
                content: <Stack>
                    <IncomeInvoicesList qs={[`sales_id=${props.userId}`]} />
                </Stack>
            });
        }

        const section: IStackSectionProps = {
            key: 'user_companies',
            isCard: true,
            tabs
        }

        /*if (PermissionsService.hasPermission(['superuser', 'hr', 'finance'], user.roles) || user.data?.id === props.userId) {
            section.tabs?.push({
                key: 'payslips',
                title: 'Payslips',
                content: <Stack>
                    <EmployeePayslipsList employeeId={props.userId} />
                </Stack>
            })
        }*/

        return section;
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetAdministrator() }
    }

    const renderDetailsStack = (_administrator: IUserResourceShortProps) => {
        const leftSections = [];
        const rightSections = [];
        leftSections.push(getUserPropertiesSection(_administrator));

        if (user.data?.id === props.userId || PermissionsService.hasPermission(['superuser', 'finance', 'hr'], user.roles)) {
            leftSections.push(getEmploymentDetails(_administrator));
            rightSections.push(getMonthlyRecapSession(_administrator));
        }

        return <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
            <DetailsStack left={leftSections} right={rightSections} />
            <DetailsStack left={[getRelatedItemsSection(_administrator)]} />
        </Stack>;
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving administrator details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && hasPermission && administrator ? <>
            {PermissionsService.hasPermission(['superuser', 'admin'], user.roles) ? <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
            {renderDetailsStack(administrator)}
            {activeSurface === 'update' ? <AdministratorForm userId={administrator.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'resetPassword' ? <ResetAdministratorPasswordForm user={administrator} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteUserForm user={administrator} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'create.commission' ? <SalesCommissionForm sales={administrator} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update.salary' ? <EmployedSalaryForm employeeId={administrator.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default AdministratorDetails;
