import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import moment from 'moment';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { NavLink } from 'react-router-dom';

type IncomesDashboardPageProps = {
}

const IncomesDashboardPage: React.FC<IncomesDashboardPageProps> = (props: IncomesDashboardPageProps) => {
    const { topbar } = useStore();
    const [selectedDate, setSelectedDate] = React.useState<{ start: string, end: string }>({
        start: moment().startOf('month').toISOString(),
        end: moment().endOf('month').toISOString()
    });

    useEffect(() => {
        topbar.show("Incomes", [
            { key: "home", icon: faHome, href: "/" },
            { key: "finance", text: 'Finance', href: "/finance" },
            { key: "incomes", text: 'Incomes' }
        ])
    }, []);

    return <Stack horizontal tokens={{childrenGap: 10}}>
        <NavLink to={"/finance/incomes/invoices"}><PrimaryButton text={'Income Invoices'} /></NavLink>
        <NavLink to={"/finance/incomes/payments"}><DefaultButton text={'Income Payments'} /></NavLink>
    </Stack>;
};

export default IncomesDashboardPage;
