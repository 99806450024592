import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

// assets

// services

// props

// components
import { DetailsList, SelectionMode, Stack } from '@fluentui/react';
import Details from '../../../../../typography/details';
import { IEmployeePayslipResourceProps } from '../../../../../../props/employees/payslips/payslip';
import GeneralService from '../../../../../../services/general';
import { IEmployeePayslipBonusResourceShortProps } from '../../../../../../props/employees/payslips/bonus';
import Text from '../../../../../typography/text';

interface EmployeePayslipDetailsBonusesProps {
    payslip: IEmployeePayslipResourceProps;
}

const EmployeePayslipDetailsBonuses: React.FC<EmployeePayslipDetailsBonusesProps> = observer((props: EmployeePayslipDetailsBonusesProps) => {
    const { payslip } = props;

    return <Stack tokens={{ childrenGap: 20 }}>
        {payslip.bonuses.length < 1 ? <Text size={'small'} style={{fontStyle: 'italic'}}>No bonuses found</Text> : null}
        {payslip.bonuses.length > 0 ? <DetailsList items={payslip.bonuses}
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: 'idx',
                    name: 'No.',
                    minWidth: 25,
                    maxWidth: 25,
                    onRender: (item: IEmployeePayslipBonusResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{(idx || 0) + 1}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'name',
                    name: 'Name',
                    minWidth: 200,
                    maxWidth: 200,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipBonusResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{item.name}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'description',
                    name: 'Description',
                    minWidth: 100,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipBonusResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>{item.description || ""}</Text>
                        </Stack>;
                    }
                },
                {
                    key: 'amount',
                    name: 'Amount',
                    minWidth: 100,
                    isMultiline: true,
                    onRender: (item: IEmployeePayslipBonusResourceShortProps, idx?: number) => {
                        return <Stack>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                        </Stack>;
                    }
                }
            ]} /> : null}
    </Stack>;
});

export default EmployeePayslipDetailsBonuses;
