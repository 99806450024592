import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';
import BankAccountService from '../../../../services/bankaccounts';

// props
import { IBankAccountResourceShortProps } from '../../../../props/bankaccounts';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../typography/text';
import PermissionsService from '../../../../services/permissions';
import BankAccountForm from './../form';
import { NavLink } from 'react-router-dom';

type BankAccountsListProps = {
    vendorId?: string;
    employeeId?: string;
    subsidiaryId?: string;
}

const BankAccountsList: React.FC<BankAccountsListProps> = (props: BankAccountsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [activeBankAccount, setActiveBankAccount] = React.useState<IBankAccountResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    let type: 'subsidiary' | 'vendor' | 'employee' = 'subsidiary';
    if (props.vendorId) {
        type = 'vendor';
    } else if (props.employeeId) {
        type = 'employee';
    }

    const columns: IColumn[] = [
        {
            key: "accountNumber",
            name: "Account Number",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IBankAccountResourceShortProps) => {
                return <Stack>
                    <NavLink to={'/bankaccounts/' + item.id}>
                        <Text size={'small'}>{item.accountNumber}</Text>
                    </NavLink>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IBankAccountResourceShortProps) => {
                const menuItems: IContextualMenuItem[] = [
                    {
                        key: "update",
                        text: "Update details",
                        iconProps: { iconName: "Edit" },
                        onClick: () => {
                            setActiveBankAccount(item);
                            setActiveSurface('bankaccount.update')
                        }
                    }
                ];

                return <Stack.Item>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            },
        },
        {
            key: "accountName",
            name: "Account Name",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IBankAccountResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.accountName}</Text>
                </Stack>;
            }
        },
        {
            key: "bank",
            name: "Bank Details",
            minWidth: 250,
            maxWidth: 250,
            isMultiline: true,
            onRender: (item: IBankAccountResourceShortProps) => {
                return <Stack>
                    <Text size={'small'}>{item.bank.name}</Text>
                    <Text size={'xsmall'}>{[item.bankCity || "", item.bank.country].filter((b) => (b || "").trim() !== "" && (b || "").trim() !== "-").join(", ")}</Text>
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveBankAccounts();
    }, []);

    const _onRetrieveBankAccounts = async () => {
        setLoaded(false);
        try {
            const qs: string[] = [];
            if (props.vendorId) {qs.push('vendor_id=' + props.vendorId);}
            if (props.employeeId) {qs.push('employee_id=' + props.employeeId);}
            if (props.subsidiaryId) {qs.push('subsidiary_id=' + props.subsidiaryId);}
            qs.push('top=all');

            const results = await BankAccountService.retrieve(qs.join("&"));
            setBankAccounts(results.data);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_bankaccounts_error',
                text: 'Failed to retrieve bank accounts. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        if (PermissionsService.hasPermission(['vendors.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create bank account",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create bank account"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('bankaccount.create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveBankAccount(undefined);

        if (refresh) { _onRetrieveBankAccounts() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack>
                {
                    !loaded || (loaded && bankAccounts.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={bankAccounts.filter((ba) => {
                                    const key = keyword.toLowerCase();
                                    return ba.accountName.toLowerCase().indexOf(key) > -1 || 
                                        ba.accountNumber.toLowerCase().indexOf(key) > -1 || 
                                        ba.bank.name.toLowerCase().indexOf(key) > -1 || 
                                        ba.bank.id.toLowerCase().indexOf(key) > -1;
                                })}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && bankAccounts.length < 1 ? <Stack>
                    <Text size={'small'} style={{fontStyle: 'italic'}}>No bank accounts found</Text>
                </Stack> : null}
            </Stack>
        </Stack>
        {activeSurface === 'bankaccount.create' ? <BankAccountForm type={type} vendorId={props.vendorId} employeeId={props.employeeId} subsidiaryId={props.subsidiaryId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'bankaccount.update' && activeBankAccount ? <BankAccountForm type={type} subsidiaryId={props.subsidiaryId} vendorId={props.vendorId} employeeId={props.employeeId} id={activeBankAccount.id} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'delete' && activeSubsidiaries ? <DeleteSubsidiariesForm subsidiaries={activeSubsidiaries} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Stack>;
};

export default BankAccountsList;
