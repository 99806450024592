import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import OutcomeInvoicesList from '../../components/finances/outcomes/invoices/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import BankAccounDetails from '../../components/bankaccounts/general/details';
import { useParams } from 'react-router-dom';

type BankAccountDetailsPage = {
}

const BankAccountDetailsPage: React.FC<BankAccountDetailsPage> = (props: BankAccountDetailsPage) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Bank Accounts", [
            {key: "home", icon: faHome, href: "/"},
            {key: "bankAccounts", text: 'Bank Accounts'}
        ])
    }, []);

    return <Stack>
        <BankAccounDetails bankAccountId={params.bankAccountId} />
    </Stack>;
};

export default BankAccountDetailsPage;
